import { Component, OnDestroy } from '@angular/core';
import { BonusRequestService } from '../../services/request/bonusRequest.service';
import { ComboNotificationService } from '../../services/comboNotification.service';
import { NavigationService } from '../../services/navigation.service';
import { commonPagesHelper } from '../../helpers/commonPages.helper';
import { ActivatedRoute, Router } from '@angular/router';
import { ScrollToService, ScrollToConfigOptions } from '@nicky-lenaers/ngx-scroll-to';
import { PlatformService } from '../../services/platform.service';
import { ComboNotificationType } from '../../enums/alert-type.enum';
import { UserService } from '../../services';

@Component({
  selector: 'bonus',
  templateUrl: './bonus.component.html',
  styleUrls: ['./bonus.component.scss']
})

export class BonusComponent implements OnDestroy {
  bonuses: any
  bonusListSubscriber
  currentBonus = -1

  bonusType
  scrollToSetTimeout
  openedInfo

  /**
   * @constructor
   */
  constructor(
    private bonusRequestService: BonusRequestService,
    private comboNotificationService: ComboNotificationService,
    private __navigationService: NavigationService,
    private _scrollToService: ScrollToService,
    route: ActivatedRoute,
    private _router: Router,
    private platformService: PlatformService,
    private __userService: UserService
  ) {
    commonPagesHelper.loader('show');
    this.bonusListSubscriber = this.bonusRequestService.getBonuses()
      .subscribe(
        data => {
          if (data.result === 'ok') {
            this.bonuses = data.bonuses;
          }
          commonPagesHelper.loader('hide');
        },
        error => {
          // * Show SnackBar Notification
          this.comboNotificationService.notification(error.message, ComboNotificationType.Error);
        }
      );

    route.queryParams.subscribe((data) => {
      if (typeof (data.slug) !== 'undefined') {
        const slug = data.slug;
        this.bonusType = slug;
      }
    });
  }

  /**
   * It's native Angular 'on initiation' method.
   * Fired after constructor.
   */
  ngOnInit() {
    if (this.platformService.browser) {
      this.scrollToSetTimeout = setTimeout(() => {
        if (typeof (this.bonusType) === 'undefined') {
          return;
        }
        const config: ScrollToConfigOptions = {
          target: `type-b1-${this.bonusType}`,
          duration: 333,
          offset: 0
        };
        //this.showInfo(this.bonusType);
        this._scrollToService.scrollTo(config);
      }, 3000);
    }
  }


  ngOnDestroy() {
    this.bonusListSubscriber.unsubscribe();
    if (typeof (this.scrollToSetTimeout) !== 'undefined') {
      clearTimeout(this.scrollToSetTimeout);
    }
  }

  // * Navigate to the Claim Bonus Page Cashier, If Logged In, Else Redirect to Login/Register Page
  claimBonus(bonus: any) {
    if (this.__userService.isLogged()) {
      commonPagesHelper.userPanel('show');
      commonPagesHelper.bonusPanelState = true;
      this.bonusRequestService.bonusActivateRequest(bonus.bonusCode);
    } else {
      this.__navigationService.goToPage(this.__navigationService.constructUrl('REGISTER'));
    }
  }

  readMore(currentBonus: any) {
    let termsPage = currentBonus.slug.replace('promo', 'terms');
    let currentLanguage = this.__navigationService.getCurrentLanguage();
    this.__navigationService.goToPage(`${currentLanguage}/page/${termsPage}`);
  }
}
