import { TranslateLoader } from '@ngx-translate/core';
import { Observable, of } from 'rxjs';

// * Import Files
import * as langDe from '../../../assets/i18n/de.json';
import * as langEn from '../../../assets/i18n/en.json';
import * as langFi from '../../../assets/i18n/fi.json';
import * as langNo from '../../../assets/i18n/no.json';
import * as langPl from '../../../assets/i18n/pl.json';
import * as langSe from '../../../assets/i18n/se.json';
import * as langBr from '../../../assets/i18n/br.json';

const TRANSLATIONS = {
  de: langDe,
  en: langEn,
  fi: langFi,
  no: langNo,
  pl: langPl,
  se: langSe,
  br: langBr,
};

export class TranslateInlineLoader implements TranslateLoader {
  getTranslation(lang: string): Observable<any> {
    switch (lang.toLowerCase()) {
      case 'de':
        return of(TRANSLATIONS[lang].default);
      case 'en':
        return of(TRANSLATIONS[lang].default);
      case 'fi':
        return of(TRANSLATIONS[lang].default);
      case 'no':
        return of(TRANSLATIONS[lang].default);
      case 'pl':
        return of(TRANSLATIONS[lang].default);
      case 'se':
        return of(TRANSLATIONS[lang].default);
      case 'br':
        return of(TRANSLATIONS[lang].default);
      default:
        console.log(`Language ${lang} is unsupported.`);

        return of(TRANSLATIONS[lang].default);
    }
  }
}

