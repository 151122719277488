<div class="container container--noVerticalPadding container--darker">
	<h1 class="big withoutStar">{{ 'ACCOUNT_ACTIVATION.HEADER' | translate }}</h1>
</div>

<div class="container">
	<div class="accountActivationComponent">
		<div class="paragraphsContainer">
			<p [innerHTML]="'ACCOUNT_ACTIVATION.INFO_PARAGRAPH' | translate"></p>
		</div>

		<!-- TODO Move to new Sharable Component, Passing Type and Message -->
		<div class="alert alert--success" *ngIf="(successEmailTranslation.value && successEmailTranslation.value != '')">
			<p class="alert__header">{{ 'ACCOUNT_ACTIVATION.SUCCESS_HEADER' | translate }}</p>
			<p class="alert__paragraph" [innerHTML]="'ACCOUNT_ACTIVATION.SUCCESS_PARAGRAPH' | translate:successEmailTranslation"></p>
		</div>

		<form class="accountActivationComponentInside" [formGroup]="form" (ngSubmit)="onSubmit(form.value)" (keyup.enter)="onSubmit(form.value)" *ngIf="!(successEmailTranslation.value && successEmailTranslation.value != '')">
			<div class="accountActivationComponentInside__item">
				<label for="email">{{ 'ACCOUNT_ACTIVATION.EMAIL_INPUT_LABEL' | translate }}<span class="required">*</span></label>
				<input type="email" id="email" formControlName="email" required>
				<span class="errorMessage" *ngIf="formErrors.email.length">
                    <span class="errorMessage__inside">
                        <span *ngFor="let emailMessage of formErrors.email">{{ emailMessage | translate }}</span>
                    </span>
                </span>
			</div>

			<div class="accountActivationComponentInside__item">
				<a class="button button--red" *ngIf="(form.valid || sending)" (click)="onSubmit(form.value)">{{ 'ACCOUNT_ACTIVATION.SUBMIT_BUTTON_LABEL' | translate }}<i class="fa fa-angle-right" aria-hidden="true"></i></a>
				<a class="button button--red button--disabled" tooltip="{{ 'GLOBAL.INVALID_VALIDATION_FORM_FIELDS_TOOLTIP' | translate }}" *ngIf="!(form.valid || sending)">{{ 'ACCOUNT_ACTIVATION.SUBMIT_BUTTON_LABEL' | translate }}<i class="fa fa-angle-right" aria-hidden="true"></i></a>
			</div>
		</form>
	</div>
</div>
