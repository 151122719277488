import { Injectable, EventEmitter, Output } from '@angular/core';
import {
  GameRequestService
} from './request/gameRequest.service';


@Injectable() export class SearchBarService {

  @Output() gameSelected$ = new EventEmitter<any>()

  /**
     * @constructor
     */
  constructor(private gameRequestService: GameRequestService) {}

  /**
     * Gets all game list.
     */
  getAllGameList() {
    return this.gameRequestService.allGameListRequest();
  }
}
