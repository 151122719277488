export const liveCasinoGames = {
  top: {
    'evolution_main_lobby_livecasino': {
      slug: 'evolution-game-shows-lobby',
      btnText: 'START.PLAY_NOW_BUTTON',
      hasMobileImage: true
    }
  },
  second: {
    'pragmatic_play_mega_wheel_live': {
      slug: 'pragmatic-play-mega-wheel-live',
      btnText: 'START.PLAY_NOW_BUTTON',
      hasMobileImage: true
    },
    'pragmatic_play_mega_sic_bo_live': {
      slug: 'pragmatic-play-mega-sic-bo-live',
      btnText: 'START.PLAY_NOW_BUTTON',
      hasMobileImage: true
    },
    'pragmatic_play_mega_roulette_live': {
      slug: 'pragmatic-play-mega-roulette-live',
      btnText: 'START.PLAY_NOW_BUTTON',
      hasMobileImage: true
    }
  },
  third: {
    'pragmatic_play_blackjack_lobby_live': {
      slug: 'pragmatic-play-blackjack-lobby-live',
      liveText: 'GAME.CASINO_LOBBY',
      btnText: 'START.ENTER_LOBBY_BUTTON',
      hasMobileImage: true
    },
    'pragmatic_play_roulette_lobby_live': {
      slug: 'pragmatic-play-roulette-lobby-live',
      liveText: 'GAME.CASINO_LOBBY',
      btnText: 'START.ENTER_LOBBY_BUTTON',
      hasMobileImage: true
    }
  },
  fourth: {
    'pragmatic_play_dragon_tiger_live': {
      slug: 'pragmatic-play-dragon-tiger-live',
      btnText: 'START.PLAY_NOW_BUTTON',
      hasMobileImage: true
    },
    'pragmatic_play_speed_roulette_live': {
      slug: 'pragmatic-play-speed-roulette-live',
      btnText: 'START.PLAY_NOW_BUTTON',
      hasMobileImage: true
    },
    'pragmatic_play_one_blackjack_live': {
      slug: 'pragmatic-play-one-blackjack-live',
      btnText: 'START.PLAY_NOW_BUTTON',
      hasMobileImage: true
    }
  }
};
