<div
  #slider
  class="slider testSlider"
  [ngClass]="{'platform-browser': platformBrowser, 'platform-server': platformServer}"
  (mousedown)="pointerMoveBeginMouse($event)"
  (mousemove)="pointerMovePositionUpdateMouse($event)"
  (mouseout)="playAutoUnpause()"
  (mouseover)="playAutoPause()"
  (mouseup)="pointerMoveEndMouse($event); playAutoUnpause()"
  (click)="pointerClicked($event)"
  (touchstart)="pointerMoveBeginTouch($event)"
  (touchmove)="pointerMovePositionUpdateTouch($event)"
  (touchend)="pointerMoveEndTouch($event); playAutoUnpause()"
  (touchcancel)="pointerMoveEndTouch($event); playAutoUnpause()">
  <div
    #slide
    class="slide"
    [ngClass]="{'initializing': initializing, 'moving': pointerMovePositionX}"
    [style.width]="slideStyle.width"
    [style.transform]="slideStyle.transform"
    [style.transition]="slideStyle.transition">
    <ng-template #viewContainerRef></ng-template>
  </div>
  <div *ngIf="optionsCurrent && optionsCurrent.paginatorVisible">
    <ng-content select=".slider-paginator"></ng-content>
  </div>
</div>
