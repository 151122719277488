export const deactivationPeriodSelectOptions = [
  {
    value: '',
    translationKeyText: 'CASHIER.TABS.BLOCK_ACCOUNT.DEACTIVATION_PERIOD_DATA.PLEASE_SELECT'
  },
  {
    value: '1',
    translationKeyText: 'CASHIER.TABS.BLOCK_ACCOUNT.DEACTIVATION_PERIOD_DATA.ONE_DAY'
  },
  {
    value: '2',
    translationKeyText: 'CASHIER.TABS.BLOCK_ACCOUNT.DEACTIVATION_PERIOD_DATA.TWO_DAYS'
  },
  {
    value: '7',
    translationKeyText: 'CASHIER.TABS.BLOCK_ACCOUNT.DEACTIVATION_PERIOD_DATA.SEVEN_DAYS'
  },
  {
    value: '14',
    translationKeyText: 'CASHIER.TABS.BLOCK_ACCOUNT.DEACTIVATION_PERIOD_DATA.FOURTEEN_DAYS'
  },
  {
    value: '30',
    translationKeyText: 'CASHIER.TABS.BLOCK_ACCOUNT.DEACTIVATION_PERIOD_DATA.THIRTY_DAYS'
  },
  {
    value: '90',
    translationKeyText: 'CASHIER.TABS.BLOCK_ACCOUNT.DEACTIVATION_PERIOD_DATA.NINETY_DAYS'
  },
  {
    value: '180',
    translationKeyText: 'CASHIER.TABS.BLOCK_ACCOUNT.DEACTIVATION_PERIOD_DATA.ONE_EIGHTY_DAYS'
  },
  {
    value: 'indefinitely',
    translationKeyText: 'CASHIER.TABS.BLOCK_ACCOUNT.DEACTIVATION_PERIOD_DATA.INDEFINITELY'
  }
];