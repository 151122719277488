import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { regExpValidationConstants } from '../../../constants/index';
import { ForgotPasswordTokenValidationFormModel } from '../../../models/index';
import { commonPagesHelper, formHelper } from '../../../helpers/index';
import { UserRequestService, ComboNotificationService } from '../../../services/index';
import { ComboNotificationType } from '../../../enums/alert-type.enum';

@Component({
  selector: 'register--confirm',
  templateUrl: './confirm.component.html',
  styleUrls: ['./confirm.component.scss']
})

export class PasswordResetConfirmComponent implements OnInit, OnDestroy {
  private temporaryToken: any;
  passwordResetConfirmationSubscriber: any;
  tokenIsValid: any;

  formOptions = {
    forgottenPasswordValidation: {
      form: undefined,
      model: undefined,
      sending: false,
      validationMessages: {
        'first': {
          'required': 'GLOBAL.VALIDATION_MESSAGES.REQUIRED',
          'minlength': 'GLOBAL.VALIDATION_MESSAGES.LEAST_SIX_CHARACTERS_LONG',
          'maxlength': 'GLOBAL.VALIDATION_MESSAGES.MORE_THAN_NINETYSIX_CHARACTERS_LONG',
          'pattern': 'GLOBAL.VALIDATION_MESSAGES.WRONG_PASSWORD_FORMAT',
        },
        'second': {
          'required': 'GLOBAL.VALIDATION_MESSAGES.REQUIRED',
          'minlength': 'GLOBAL.VALIDATION_MESSAGES.LEAST_SIX_CHARACTERS_LONG',
          'maxlength': 'GLOBAL.VALIDATION_MESSAGES.MORE_THAN_NINETYSIX_CHARACTERS_LONG',
          'validateEqual': 'GLOBAL.VALIDATION_MESSAGES.MISMATCH_PASSWORD'
        }
      },
      errors: {}
    }
  }

  /**
	 * @constructor
	 */
  constructor(
    public router: Router,
    private route: ActivatedRoute,
    private userRequestService: UserRequestService,
    private comboNotificationService: ComboNotificationService,
    private _translate: TranslateService,
    private formBuilder: FormBuilder
  ) {
    this.formOptions.forgottenPasswordValidation.model = new ForgotPasswordTokenValidationFormModel();
    this.temporaryToken = this.route.snapshot.params['temporaryToken'];
  }

  /**
	 * It's native Angular 'on initiation' method.
	 * Fired after constructor.
	 */
  ngOnInit() {
    if (!this.temporaryToken) {
      this.tokenIsValid = false;
      
      return;
    }

    this.buildForms();

  }

  /**
	 * Builds forms.
	 */
  buildForms() {
    const formOptions = {
      first: [
        this.formOptions.forgottenPasswordValidation.model.first,
        [
          Validators.required,
          Validators.minLength(6),
          Validators.maxLength(96),
          Validators.pattern(regExpValidationConstants['PASSWORD'])
        ]
      ],
      second: [
        this.formOptions.forgottenPasswordValidation.model.second,
        [
          Validators.required,
          Validators.minLength(6),
          Validators.maxLength(96)
        ]//musi byc validators passwords! i w templatcie trezeba cos!
      ]
    };

    this.formOptions.forgottenPasswordValidation.form = this.formBuilder.group(formOptions);
    this.formOptions.forgottenPasswordValidation.form.valueChanges.subscribe(() => {
      formHelper.onValueChanged(
        this.formOptions.forgottenPasswordValidation.form,
        this.formOptions.forgottenPasswordValidation.errors,
        this.formOptions.forgottenPasswordValidation.validationMessages
      );
    });

    formHelper.onValueChanged(
      this.formOptions.forgottenPasswordValidation.form,
      this.formOptions.forgottenPasswordValidation.errors,
      this.formOptions.forgottenPasswordValidation.validationMessages
    ); //potrzebne?
  }

  /**
	 * Token verification pre process.
	 * @returns {Object} parametersMap
	 */
  tokenVerificationPreProcess() {
    const parametersMap = this.formOptions.forgottenPasswordValidation.form.value;
    
    return {
      'plainPassword[first]': parametersMap['first'],
      'plainPassword[second]': parametersMap['second']
    };
  }

  /**
	 * Verifications token.
	 * @param {String} temporaryToken
	 */
  tokenVerification() {
    const self = this;
    if (this.formOptions.forgottenPasswordValidation.sending || !this.formOptions.forgottenPasswordValidation.form.valid) {
      return;
    }

    const parametersMap = this.tokenVerificationPreProcess();
    commonPagesHelper.loader('show');

    this.passwordResetConfirmationSubscriber = this.userRequestService.passwordResetConfirmation(parametersMap, this.temporaryToken)
      .subscribe((success) => {
        this.formOptions.forgottenPasswordValidation.sending = true;
        if (success.result !== 'ok') {
          // * Show SnackBar Notification
          self.comboNotificationService.notification(success.message, ComboNotificationType.Error);
          self.tokenIsValid = false;
          this.formOptions.forgottenPasswordValidation.sending = false;
          commonPagesHelper.loader('hide');
          
          return;
        }

        self._translate.get(['GLOBAL.NOTIFICATIONS.SUCCESS_LABEL', 'CONTACT.MAIL_SENT_SUCCESSFULLY_LABEL'])
          .subscribe((translatedValue: string) => {
            // * Show SnackBar Notification
            self.comboNotificationService.notification(translatedValue['CONTACT.MAIL_SENT_SUCCESSFULLY_LABEL'], ComboNotificationType.Success);
          });
        commonPagesHelper.loader('hide');
        this.formOptions.forgottenPasswordValidation.sending = false;
        self.tokenIsValid = true;
      }, (error) => {
        console.error('error data -->', error);
        this.formOptions.forgottenPasswordValidation.sending = false;
        self.tokenIsValid = false;
        commonPagesHelper.loader('hide');
      });
  }

  /**
	 * It's native Angular 'on destroy' method.
	 * Destroys component.
	 */
  ngOnDestroy() {
    if (typeof (this.passwordResetConfirmationSubscriber) !== 'undefined') {
      this.passwordResetConfirmationSubscriber.unsubscribe();
    }
  }

}
