export const partnerLogos = [
  {
    img: 'assets/images/logos/paysafecard.png',
    url: '',
    pageUrl: 'PAYMENT_PROVIDERS',
    pageUrlData: {
      type: 'paysafecard'
    }
  },
  {
    img: 'assets/images/logos/visa.png',
    url: '',
    pageUrl: 'PAYMENT_PROVIDERS',
    pageUrlData: {
      type: 'visa'
    }
  },
  {
    img: 'assets/images/logos/mastercard.png',
    url: '',
    pageUrl: 'PAYMENT_PROVIDERS',
    pageUrlData: {
      type: 'mastercard'
    }
  },
  {
    img: 'assets/images/logos/skrill.png',
    url: '',
    pageUrl: 'PAYMENT_PROVIDERS',
    pageUrlData: {
      type: 'skrill'
    }
  },
  {
    img: 'assets/images/logos/neteller.png',
    url: '',
    pageUrl: 'PAYMENT_PROVIDERS',
    pageUrlData: {
      type: 'neteller'
    }
  },
  {
    img: 'assets/images/logos/sofort.png',
    url: '',
    pageUrl: 'PAYMENT_PROVIDERS',
    pageUrlData: {
      type: 'sofort'
    }
  },
  {
    img: 'assets/images/logos/monetaru.png',
    url: '',
    pageUrl: 'PAYMENT_PROVIDERS',
    pageUrlData: {
      type: 'monetaru'
    }
  },
  {
    img: 'assets/images/logos/brite.png',
    url: '',
    pageUrl: 'PAYMENT_PROVIDERS',
    pageUrlData: {
      type: 'brite'
    }
  },
];
