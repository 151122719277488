import { ModuleWithProviders } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AuthGuard, NotLoggedIn, FullyRegistered } from './guards/index';
import { routingConstants } from './constants/index';
//import { MetaGuard } from '@ngx-meta/core';

import {
  LanguageComponent,
  NotFoundComponent,
  NoAccessComponent,
  MaintenanceModeComponent,
  StartComponent,
  RegisterComponent,
  RegisterConfirmComponent,
  LoginComponent,
  PasswordResetComponent,
  PasswordResetConfirmComponent,
  AccountActivationComponent,
  UserAccountComponent,
  CashierComponent,
  AllGamesComponent,
  GameDetailsComponent,
  PaymentProvidersComponent,
  ContactComponent,
  ConfirmEmailChangeComponent,
  LandingPageComponent,
  LogoutComponent,
  SupportComponent,
  BonusComponent,
  BonusConditionsComponent,
  RestrictedAreaComponent,
  AccountFrozenComponent,
  LoginAsUserComponent,
} from './pages/index';
import { CoreLayout } from './layouts';
import { LandingPageResolver } from './pages/landingPage/landingPage.resolver';
import { StartResolver } from './pages/start/start.resolver';
import { GameDetailsResolver } from './pages/gameDetails/gameDetails.resolver';
import { StaticComponent } from './pages/static/static.component';
import { StaticResolver } from './pages/static/static.resolver';
import { StaticGuard } from './pages/static/static.guard';
import { NewsletterUnsubscribeComponent } from './pages/newsletter-unsubscribe/newsletter-unsubscribe.component';
import { BonusDetailsComponent } from './pages/bonusDetails/bonusDetails.component';

// Route Configuration
export const routes: Routes = [
  {
    path: '',
    component: LanguageComponent,
  },
  {
    path: 'login-as-user/:token',
    component: LoginAsUserComponent,
  },

  {
    path: 'newsletter-unsubscribe/:publicId',
    component: NewsletterUnsubscribeComponent,
  },

  // landing pages (with provided layout)
  {
    path: 'de',
    component: LanguageComponent,
    //canActivateChild: [MetaGuard],
    canActivateChild: [],
    children: [
      {
        path: '',
        redirectTo: `/de/${routingConstants['DE']['START']['slug']}`,
        pathMatch: 'full',
      },
      {
        path: `${routingConstants['DE']['LANDINGPAGE']['slug']}/:refId/:slug/:refCode`,
        pathMatch: 'full',
        component: LandingPageComponent,
        data: routingConstants['DE']['LANDINGPAGE']['data'],
        resolve: {
          landingPage: LandingPageResolver,
        },
      },
      {
        path: `${routingConstants['DE']['LANDINGPAGE']['slug']}`,
        component: LandingPageComponent,
        data: routingConstants['DE']['LANDINGPAGE']['data'],
        resolve: {
          landingPage: LandingPageResolver,
        },
      },
    ],
  },
  {
    path: 'en',
    component: LanguageComponent,
    //canActivateChild: [MetaGuard],
    canActivateChild: [],
    children: [
      {
        path: '',
        redirectTo: `/en/${routingConstants['EN']['START']['slug']}`,
        pathMatch: 'full',
      },
      {
        path: `${routingConstants['EN']['LANDINGPAGE']['slug']}/:refId/:slug/:refCode`,
        pathMatch: 'full',
        component: LandingPageComponent,
        data: routingConstants['EN']['LANDINGPAGE']['data'],
        resolve: {
          landingPage: LandingPageResolver,
        },
      },
      {
        path: `${routingConstants['EN']['LANDINGPAGE']['slug']}`,
        component: LandingPageComponent,
        data: routingConstants['EN']['LANDINGPAGE']['data'],
        resolve: {
          landingPage: LandingPageResolver,
        },
      },
    ],
  },
  {
    path: 'br',
    component: LanguageComponent,
    //canActivateChild: [MetaGuard],
    canActivateChild: [],
    children: [
      {
        path: '',
        redirectTo: `/br/${routingConstants['BR']['START']['slug']}`,
        pathMatch: 'full',
      },
      {
        path: `${routingConstants['BR']['LANDINGPAGE']['slug']}/:refId/:slug/:refCode`,
        pathMatch: 'full',
        component: LandingPageComponent,
        data: routingConstants['BR']['LANDINGPAGE']['data'],
        resolve: {
          landingPage: LandingPageResolver,
        },
      },
      {
        path: `${routingConstants['BR']['LANDINGPAGE']['slug']}`,
        component: LandingPageComponent,
        data: routingConstants['BR']['LANDINGPAGE']['data'],
        resolve: {
          landingPage: LandingPageResolver,
        },
      },
    ],
  },
  {
    path: 'fi',
    component: LanguageComponent,
    //canActivateChild: [MetaGuard],
    canActivateChild: [],
    children: [
      {
        path: '',
        redirectTo: `/fi/${routingConstants['FI']['START']['slug']}`,
        pathMatch: 'full',
      },
      {
        path: `${routingConstants['FI']['LANDINGPAGE']['slug']}/:refId/:slug/:refCode`,
        pathMatch: 'full',
        component: LandingPageComponent,
        data: routingConstants['FI']['LANDINGPAGE']['data'],
        resolve: {
          landingPage: LandingPageResolver,
        },
      },
      {
        path: `${routingConstants['FI']['LANDINGPAGE']['slug']}`,
        component: LandingPageComponent,
        data: routingConstants['FI']['LANDINGPAGE']['data'],
        resolve: {
          landingPage: LandingPageResolver,
        },
      },
    ],
  },
  // core layout pages
  {
    path: '',
    component: CoreLayout,
    children: [
      {
        path: 'de',
        component: LanguageComponent,
        //canActivateChild: [MetaGuard],
        canActivateChild: [],
        children: [
          {
            path: '',
            redirectTo: `/de/${routingConstants['DE']['START']['slug']}`,
            pathMatch: 'full',
          },
          {
            path: routingConstants['DE']['START']['slug'],
            component: StartComponent,
            data: routingConstants['DE']['START']['data'],
            resolve: {
              start: StartResolver,
            },
            runGuardsAndResolvers: 'paramsOrQueryParamsChange',
          },
          {
            path: routingConstants['DE']['ALL_GAMES']['slug'],
            component: AllGamesComponent,
            data: routingConstants['DE']['ALL_GAMES']['data'],
          },
          {
            path: routingConstants['DE']['ALL_GAMES_VENDOR']['slug'],
            component: AllGamesComponent,
            data: routingConstants['DE']['ALL_GAMES_VENDOR']['data'],
          },
          {
            path: routingConstants['DE']['ALL_GAMES_CATEGORY']['slug'],
            component: AllGamesComponent,
            data: routingConstants['DE']['ALL_GAMES_CATEGORY']['data'],
          },
          {
            path: routingConstants['DE']['ALL_GAMES_CATEGORY_VENDOR']['slug'],
            component: AllGamesComponent,
            data: routingConstants['DE']['ALL_GAMES_CATEGORY_VENDOR']['data'],
          },
          {
            path: routingConstants['DE']['GAME_DETAILS']['slug'],
            component: GameDetailsComponent,
            data: routingConstants['DE']['GAME_DETAILS']['data'],
            resolve: {
              gameDetails: GameDetailsResolver,
            },
          },
          {
            path: routingConstants.DE.STATIC_PAGE.slug,
            component: StaticComponent,
            resolve: {
              static: StaticResolver,
            },
            canActivate: [StaticGuard],
          },
          {
            path: routingConstants['DE']['PAYMENT_PROVIDERS']['slug'],
            component: PaymentProvidersComponent,
            data: routingConstants['DE']['PAYMENT_PROVIDERS']['data'],
          },
          {
            path: routingConstants['DE']['CONTACT']['slug'],
            component: ContactComponent,
            data: routingConstants['DE']['CONTACT']['data'],
          },
          {
            path: routingConstants['DE']['CONFIRM_EMAIL_CHANGE']['slug'],
            component: ConfirmEmailChangeComponent,
            data: routingConstants['DE']['CONFIRM_EMAIL_CHANGE']['data'],
          },
          {
            path: routingConstants['DE']['REGISTER']['slug'],
            component: RegisterComponent,
            data: routingConstants['DE']['REGISTER']['data'],
            canActivate: [NotLoggedIn],
          },
          {
            path: routingConstants['DE']['REGISTER_CONFIRM']['slug'],
            component: RegisterConfirmComponent,
            data: routingConstants['DE']['REGISTER_CONFIRM']['data'],
            canActivate: [NotLoggedIn],
          },
          {
            path: routingConstants['DE']['LOGIN']['slug'],
            component: LoginComponent,
            data: routingConstants['DE']['LOGIN']['data'],
            canActivate: [NotLoggedIn],
          },
          {
            path: routingConstants['DE']['PASSWORD_RESET']['slug'],
            component: PasswordResetComponent,
            data: routingConstants['DE']['PASSWORD_RESET']['data'],
            canActivate: [NotLoggedIn],
          },
          {
            path: routingConstants['DE']['PASSWORD_RESET_CONFIRM']['slug'],
            component: PasswordResetConfirmComponent,
            data: routingConstants['DE']['PASSWORD_RESET_CONFIRM']['data'],
          },
          {
            path: routingConstants['DE']['USER_ACCOUNT']['slug'],
            component: UserAccountComponent,
            data: routingConstants['DE']['USER_ACCOUNT']['data'],
            canActivate: [AuthGuard],
          },
          {
            path: routingConstants['DE']['CASHIER']['slug'],
            component: CashierComponent,
            data: routingConstants['DE']['CASHIER']['data'],
            canActivate: [AuthGuard, FullyRegistered],
          },
          {
            path: routingConstants['DE']['LOGOUT']['slug'],
            component: LogoutComponent,
            data: routingConstants['DE']['LOGOUT']['data'],
          },
          {
            path: routingConstants['DE']['SUPPORT']['slug'],
            component: SupportComponent,
            data: routingConstants['DE']['SUPPORT']['data'],
          },
          // {
          //   path: routingConstants['DE']['BONUS']['slug'],
          //   component: BonusComponent,
          //   data: routingConstants['DE']['BONUS']['data'],
          // },
          {
            path: routingConstants['DE']['BONUS_CONDITION']['slug'],
            component: BonusConditionsComponent,
            data: routingConstants['DE']['BONUS_CONDITION']['data'],
          },
          {
            path: routingConstants['DE']['BONUS_DETAILS']['slug'],
            component: BonusDetailsComponent,
            data: routingConstants['DE']['BONUS_DETAILS']['data'],
          },
          {
            path: routingConstants['DE']['ACCOUNT_ACTIVATION']['slug'],
            component: AccountActivationComponent,
            data: routingConstants['DE']['ACCOUNT_ACTIVATION']['data'],
          },
          {
            path: routingConstants['DE']['RESTRICTED_AREA']['slug'],
            component: RestrictedAreaComponent,
            data: routingConstants['DE']['RESTRICTED_AREA']['data'],
          },
          {
            path: routingConstants['DE']['ACCOUNT_FROZEN']['slug'],
            component: AccountFrozenComponent,
            data: routingConstants['DE']['ACCOUNT_FROZEN']['data'],
          },
          {
            path: '**',
            component: NotFoundComponent,
            data: routingConstants['DE']['NOT_FOUND']['data'],
          },
        ],
      },
      {
        path: 'en',
        component: LanguageComponent,
        //canActivateChild: [MetaGuard],
        canActivateChild: [],
        children: [
          {
            path: '',
            redirectTo: `/en/${routingConstants['EN']['START']['slug']}`,
            pathMatch: 'full',
          },
          {
            path: routingConstants['EN']['START']['slug'],
            component: StartComponent,
            data: routingConstants['EN']['START']['data'],
            resolve: {
              start: StartResolver,
            },
            runGuardsAndResolvers: 'paramsOrQueryParamsChange',
          },
          {
            path: routingConstants['EN']['ALL_GAMES']['slug'],
            component: AllGamesComponent,
            data: routingConstants['EN']['ALL_GAMES']['data'],
          },
          {
            path: routingConstants['EN']['ALL_GAMES_VENDOR']['slug'],
            component: AllGamesComponent,
            data: routingConstants['EN']['ALL_GAMES_VENDOR']['data'],
          },
          {
            path: routingConstants['EN']['ALL_GAMES_CATEGORY']['slug'],
            component: AllGamesComponent,
            data: routingConstants['EN']['ALL_GAMES_CATEGORY']['data'],
          },
          {
            path: routingConstants['EN']['ALL_GAMES_CATEGORY_VENDOR']['slug'],
            component: AllGamesComponent,
            data: routingConstants['EN']['ALL_GAMES_CATEGORY_VENDOR']['data'],
          },
          {
            path: routingConstants['EN']['GAME_DETAILS']['slug'],
            component: GameDetailsComponent,
            data: routingConstants['EN']['GAME_DETAILS']['data'],
            resolve: {
              gameDetails: GameDetailsResolver,
            },
          },
          {
            path: routingConstants.EN.STATIC_PAGE.slug,
            component: StaticComponent,
            resolve: {
              static: StaticResolver,
            },
            canActivate: [StaticGuard],
          },
          {
            path: routingConstants['EN']['PAYMENT_PROVIDERS']['slug'],
            component: PaymentProvidersComponent,
            data: routingConstants['EN']['PAYMENT_PROVIDERS']['data'],
          },
          {
            path: routingConstants['EN']['CONTACT']['slug'],
            component: ContactComponent,
            data: routingConstants['EN']['CONTACT']['data'],
          },
          {
            path: routingConstants['EN']['CONFIRM_EMAIL_CHANGE']['slug'],
            component: ConfirmEmailChangeComponent,
            data: routingConstants['EN']['CONFIRM_EMAIL_CHANGE']['data'],
          },
          {
            path: routingConstants['EN']['REGISTER']['slug'],
            component: RegisterComponent,
            data: routingConstants['EN']['REGISTER']['data'],
            canActivate: [NotLoggedIn],
          },
          {
            path: routingConstants['EN']['REGISTER_CONFIRM']['slug'],
            component: RegisterConfirmComponent,
            data: routingConstants['EN']['REGISTER_CONFIRM']['data'],
            canActivate: [NotLoggedIn],
          },
          {
            path: routingConstants['EN']['LOGIN']['slug'],
            component: LoginComponent,
            data: routingConstants['EN']['LOGIN']['data'],
            canActivate: [NotLoggedIn],
          },
          {
            path: routingConstants['EN']['PASSWORD_RESET']['slug'],
            component: PasswordResetComponent,
            data: routingConstants['EN']['PASSWORD_RESET']['data'],
            canActivate: [NotLoggedIn],
          },
          {
            path: routingConstants['EN']['PASSWORD_RESET_CONFIRM']['slug'],
            component: PasswordResetConfirmComponent,
            data: routingConstants['EN']['PASSWORD_RESET_CONFIRM']['data'],
          },
          {
            path: routingConstants['EN']['USER_ACCOUNT']['slug'],
            component: UserAccountComponent,
            data: routingConstants['EN']['USER_ACCOUNT']['data'],
            canActivate: [AuthGuard],
          },
          {
            path: routingConstants['EN']['CASHIER']['slug'],
            component: CashierComponent,
            data: routingConstants['EN']['CASHIER']['data'],
            canActivate: [AuthGuard, FullyRegistered],
          },
          {
            path: routingConstants['EN']['LOGOUT']['slug'],
            component: LogoutComponent,
            data: routingConstants['EN']['LOGOUT']['data'],
          },
          {
            path: routingConstants['EN']['SUPPORT']['slug'],
            component: SupportComponent,
            data: routingConstants['EN']['SUPPORT']['data'],
          },
          // {
          //   path: routingConstants['EN']['BONUS']['slug'],
          //   component: BonusComponent,
          //   data: routingConstants['EN']['BONUS']['data'],
          // },
          {
            path: routingConstants['EN']['BONUS_CONDITION']['slug'],
            component: BonusConditionsComponent,
            data: routingConstants['EN']['BONUS_CONDITION']['data'],
          },
          {
            path: routingConstants['EN']['BONUS_DETAILS']['slug'],
            component: BonusDetailsComponent,
            data: routingConstants['EN']['BONUS_DETAILS']['data'],
          },
          {
            path: routingConstants['EN']['ACCOUNT_ACTIVATION']['slug'],
            component: AccountActivationComponent,
            data: routingConstants['EN']['ACCOUNT_ACTIVATION']['data'],
          },
          {
            path: routingConstants['EN']['RESTRICTED_AREA']['slug'],
            component: RestrictedAreaComponent,
            data: routingConstants['EN']['RESTRICTED_AREA']['data'],
          },
          {
            path: routingConstants['EN']['ACCOUNT_FROZEN']['slug'],
            component: AccountFrozenComponent,
            data: routingConstants['EN']['ACCOUNT_FROZEN']['data'],
          },
          {
            path: '**',
            component: NotFoundComponent,
            data: routingConstants['EN']['NOT_FOUND']['data'],
          },
        ],
      },
      {
        path: 'br',
        component: LanguageComponent,
        //canActivateChild: [MetaGuard],
        canActivateChild: [],
        children: [
          {
            path: '',
            redirectTo: `/br/${routingConstants['BR']['START']['slug']}`,
            pathMatch: 'full',
          },
          {
            path: routingConstants['BR']['START']['slug'],
            component: StartComponent,
            data: routingConstants['BR']['START']['data'],
            resolve: {
              start: StartResolver,
            },
            runGuardsAndResolvers: 'paramsOrQueryParamsChange',
          },
          {
            path: routingConstants['BR']['ALL_GAMES']['slug'],
            component: AllGamesComponent,
            data: routingConstants['BR']['ALL_GAMES']['data'],
          },
          {
            path: routingConstants['BR']['ALL_GAMES_VENDOR']['slug'],
            component: AllGamesComponent,
            data: routingConstants['BR']['ALL_GAMES_VENDOR']['data'],
          },
          {
            path: routingConstants['BR']['ALL_GAMES_CATEGORY']['slug'],
            component: AllGamesComponent,
            data: routingConstants['BR']['ALL_GAMES_CATEGORY']['data'],
          },
          {
            path: routingConstants['BR']['ALL_GAMES_CATEGORY_VENDOR']['slug'],
            component: AllGamesComponent,
            data: routingConstants['BR']['ALL_GAMES_CATEGORY_VENDOR']['data'],
          },
          {
            path: routingConstants['BR']['GAME_DETAILS']['slug'],
            component: GameDetailsComponent,
            data: routingConstants['BR']['GAME_DETAILS']['data'],
            resolve: {
              gameDetails: GameDetailsResolver,
            },
          },
          {
            path: routingConstants.BR.STATIC_PAGE.slug,
            component: StaticComponent,
            resolve: {
              static: StaticResolver,
            },
            canActivate: [StaticGuard],
          },
          {
            path: routingConstants['BR']['PAYMENT_PROVIDERS']['slug'],
            component: PaymentProvidersComponent,
            data: routingConstants['BR']['PAYMENT_PROVIDERS']['data'],
          },
          {
            path: routingConstants['BR']['CONTACT']['slug'],
            component: ContactComponent,
            data: routingConstants['BR']['CONTACT']['data'],
          },
          {
            path: routingConstants['BR']['CONFIRM_EMAIL_CHANGE']['slug'],
            component: ConfirmEmailChangeComponent,
            data: routingConstants['BR']['CONFIRM_EMAIL_CHANGE']['data'],
          },
          {
            path: routingConstants['BR']['REGISTER']['slug'],
            component: RegisterComponent,
            data: routingConstants['BR']['REGISTER']['data'],
            canActivate: [NotLoggedIn],
          },
          {
            path: routingConstants['BR']['REGISTER_CONFIRM']['slug'],
            component: RegisterConfirmComponent,
            data: routingConstants['BR']['REGISTER_CONFIRM']['data'],
            canActivate: [NotLoggedIn],
          },
          {
            path: routingConstants['BR']['LOGIN']['slug'],
            component: LoginComponent,
            data: routingConstants['BR']['LOGIN']['data'],
            canActivate: [NotLoggedIn],
          },
          {
            path: routingConstants['BR']['PASSWORD_RESET']['slug'],
            component: PasswordResetComponent,
            data: routingConstants['BR']['PASSWORD_RESET']['data'],
            canActivate: [NotLoggedIn],
          },
          {
            path: routingConstants['BR']['PASSWORD_RESET_CONFIRM']['slug'],
            component: PasswordResetConfirmComponent,
            data: routingConstants['BR']['PASSWORD_RESET_CONFIRM']['data'],
          },
          {
            path: routingConstants['BR']['USER_ACCOUNT']['slug'],
            component: UserAccountComponent,
            data: routingConstants['BR']['USER_ACCOUNT']['data'],
            canActivate: [AuthGuard],
          },
          {
            path: routingConstants['BR']['CASHIER']['slug'],
            component: CashierComponent,
            data: routingConstants['BR']['CASHIER']['data'],
            canActivate: [AuthGuard, FullyRegistered],
          },
          {
            path: routingConstants['BR']['LOGOUT']['slug'],
            component: LogoutComponent,
            data: routingConstants['BR']['LOGOUT']['data'],
          },
          {
            path: routingConstants['BR']['SUPPORT']['slug'],
            component: SupportComponent,
            data: routingConstants['BR']['SUPPORT']['data'],
          },
          // {
          //   path: routingConstants['BR']['BONUS']['slug'],
          //   component: BonusComponent,
          //   data: routingConstants['BR']['BONUS']['data'],
          // },
          {
            path: routingConstants['BR']['BONUS_CONDITION']['slug'],
            component: BonusConditionsComponent,
            data: routingConstants['BR']['BONUS_CONDITION']['data'],
          },
          {
            path: routingConstants['BR']['BONUS_DETAILS']['slug'],
            component: BonusDetailsComponent,
            data: routingConstants['BR']['BONUS_DETAILS']['data'],
          },
          {
            path: routingConstants['BR']['ACCOUNT_ACTIVATION']['slug'],
            component: AccountActivationComponent,
            data: routingConstants['BR']['ACCOUNT_ACTIVATION']['data'],
          },
          {
            path: routingConstants['BR']['RESTRICTED_AREA']['slug'],
            component: RestrictedAreaComponent,
            data: routingConstants['BR']['RESTRICTED_AREA']['data'],
          },
          {
            path: routingConstants['BR']['ACCOUNT_FROZEN']['slug'],
            component: AccountFrozenComponent,
            data: routingConstants['BR']['ACCOUNT_FROZEN']['data'],
          },
          {
            path: '**',
            component: NotFoundComponent,
            data: routingConstants['BR']['NOT_FOUND']['data'],
          },
        ],
      },
      {
        path: 'fi',
        component: LanguageComponent,
        //canActivateChild: [MetaGuard],
        canActivateChild: [],
        children: [
          {
            path: '',
            redirectTo: `/fi/${routingConstants['FI']['START']['slug']}`,
            pathMatch: 'full',
          },
          {
            path: routingConstants['FI']['START']['slug'],
            component: StartComponent,
            data: routingConstants['FI']['START']['data'],
            resolve: {
              start: StartResolver,
            },
            runGuardsAndResolvers: 'paramsOrQueryParamsChange',
          },
          {
            path: routingConstants['FI']['ALL_GAMES']['slug'],
            component: AllGamesComponent,
            data: routingConstants['FI']['ALL_GAMES']['data'],
          },
          {
            path: routingConstants['FI']['ALL_GAMES_VENDOR']['slug'],
            component: AllGamesComponent,
            data: routingConstants['FI']['ALL_GAMES_VENDOR']['data'],
          },
          {
            path: routingConstants['FI']['ALL_GAMES_CATEGORY']['slug'],
            component: AllGamesComponent,
            data: routingConstants['FI']['ALL_GAMES_CATEGORY']['data'],
          },
          {
            path: routingConstants['FI']['ALL_GAMES_CATEGORY_VENDOR']['slug'],
            component: AllGamesComponent,
            data: routingConstants['FI']['ALL_GAMES_CATEGORY_VENDOR']['data'],
          },
          {
            path: routingConstants['FI']['GAME_DETAILS']['slug'],
            component: GameDetailsComponent,
            data: routingConstants['FI']['GAME_DETAILS']['data'],
            resolve: {
              gameDetails: GameDetailsResolver,
            },
          },
          {
            path: routingConstants.FI.STATIC_PAGE.slug,
            component: StaticComponent,
            resolve: {
              static: StaticResolver,
            },
            canActivate: [StaticGuard],
          },
          {
            path: routingConstants['FI']['PAYMENT_PROVIDERS']['slug'],
            component: PaymentProvidersComponent,
            data: routingConstants['FI']['PAYMENT_PROVIDERS']['data'],
          },
          {
            path: routingConstants['FI']['CONTACT']['slug'],
            component: ContactComponent,
            data: routingConstants['FI']['CONTACT']['data'],
          },
          {
            path: routingConstants['FI']['CONFIRM_EMAIL_CHANGE']['slug'],
            component: ConfirmEmailChangeComponent,
            data: routingConstants['FI']['CONFIRM_EMAIL_CHANGE']['data'],
          },
          {
            path: routingConstants['FI']['REGISTER']['slug'],
            component: RegisterComponent,
            data: routingConstants['FI']['REGISTER']['data'],
            canActivate: [NotLoggedIn],
          },
          {
            path: routingConstants['FI']['REGISTER_CONFIRM']['slug'],
            component: RegisterConfirmComponent,
            data: routingConstants['FI']['REGISTER_CONFIRM']['data'],
            canActivate: [NotLoggedIn],
          },
          {
            path: routingConstants['FI']['LOGIN']['slug'],
            component: LoginComponent,
            data: routingConstants['FI']['LOGIN']['data'],
            canActivate: [NotLoggedIn],
          },
          {
            path: routingConstants['FI']['PASSWORD_RESET']['slug'],
            component: PasswordResetComponent,
            data: routingConstants['FI']['PASSWORD_RESET']['data'],
            canActivate: [NotLoggedIn],
          },
          {
            path: routingConstants['FI']['PASSWORD_RESET_CONFIRM']['slug'],
            component: PasswordResetConfirmComponent,
            data: routingConstants['FI']['PASSWORD_RESET_CONFIRM']['data'],
          },
          {
            path: routingConstants['FI']['USER_ACCOUNT']['slug'],
            component: UserAccountComponent,
            data: routingConstants['FI']['USER_ACCOUNT']['data'],
            canActivate: [AuthGuard],
          },
          {
            path: routingConstants['FI']['CASHIER']['slug'],
            component: CashierComponent,
            data: routingConstants['FI']['CASHIER']['data'],
            canActivate: [AuthGuard, FullyRegistered],
          },
          {
            path: routingConstants['FI']['LOGOUT']['slug'],
            component: LogoutComponent,
            data: routingConstants['FI']['LOGOUT']['data'],
          },
          {
            path: routingConstants['FI']['SUPPORT']['slug'],
            component: SupportComponent,
            data: routingConstants['FI']['SUPPORT']['data'],
          },
          // {
          //   path: routingConstants['FI']['BONUS']['slug'],
          //   component: BonusComponent,
          //   data: routingConstants['FI']['BONUS']['data'],
          // },
          {
            path: routingConstants['FI']['BONUS_CONDITION']['slug'],
            component: BonusConditionsComponent,
            data: routingConstants['FI']['BONUS_CONDITION']['data'],
          },
          {
            path: routingConstants['FI']['BONUS_DETAILS']['slug'],
            component: BonusDetailsComponent,
            data: routingConstants['FI']['BONUS_DETAILS']['data'],
          },
          {
            path: routingConstants['FI']['ACCOUNT_ACTIVATION']['slug'],
            component: AccountActivationComponent,
            data: routingConstants['FI']['ACCOUNT_ACTIVATION']['data'],
          },
          {
            path: routingConstants['FI']['RESTRICTED_AREA']['slug'],
            component: RestrictedAreaComponent,
            data: routingConstants['FI']['RESTRICTED_AREA']['data'],
          },
          {
            path: routingConstants['FI']['ACCOUNT_FROZEN']['slug'],
            component: AccountFrozenComponent,
            data: routingConstants['FI']['ACCOUNT_FROZEN']['data'],
          },
          {
            path: '**',
            component: NotFoundComponent,
            data: routingConstants['FI']['NOT_FOUND']['data'],
          },
        ],
      },
    ],
  },

  {
    path: 'no-access',
    component: NoAccessComponent,
  },
  {
    path: 'maintenance-mode',
    component: MaintenanceModeComponent,
  },
  {
    path: '**',
    component: NotFoundComponent,
    data: routingConstants['EN']['NOT_FOUND']['data'],
  },
];

export const AppRouting: ModuleWithProviders<any> = RouterModule.forRoot(routes, {
    initialNavigation: 'enabledNonBlocking',
    relativeLinkResolution: 'legacy'
});

