/* eslint-disable @typescript-eslint/camelcase */
import { Injectable, OnDestroy } from '@angular/core';
import { AccessToken } from '../models/accessToken.model';
import { PlatformService } from './platform.service';
import { storageConstants } from '../constants/storage.constants';

@Injectable() export class AccessTokenService implements OnDestroy {
  private accessToken: AccessToken = null;

  constructor(private _platform: PlatformService) { }

  // * Set the Access Token Object and Store Also in Local Storage
  setAccessToken(accessToken: any): void {
    this.accessToken = this.parseAccessToken(accessToken);
    localStorage.setItem(
      storageConstants['CURRENT_TOKEN'],
      JSON.stringify(accessToken)
    );
  }

  // * Parse Token, from API Response
  parseAccessToken(data: any): AccessToken {
    // * Set AccessToken
    return {
      access_token: data['access_token'],
      expires_in: data['expires_in'],
      token_type: data['token_type'],
      scope: data['scope'],
      refresh_token: data['refresh_token'],
      email: data['email']
    };
  }

  // * Returns true/false if Access Token is Set
  isAccessTokenSet(): boolean {
    return !!this.accessToken;
  }

  // * Returns the Player Access Token
  getToken(): AccessToken {
    // * Check After Refresh if we have Access Token in Local Storage
    if (!this.accessToken) {
      this.accessToken = JSON.parse(localStorage.getItem(storageConstants['CURRENT_TOKEN']));
    }

    return (this._platform.browser && this.accessToken) ? this.accessToken : null;
  }

  // * Clears Access Token
  clearAccessToken(): void {
    this.accessToken = null;
    localStorage.removeItem(storageConstants['CURRENT_TOKEN']);
  }

  ngOnDestroy(): void {
    this.accessToken = null;
  }

}
