import { Component } from '@angular/core';

@Component({
  selector: 'FAQ',
  templateUrl: './FAQ.component.html',
  styleUrls: ['./FAQ.component.scss']
})

export class FAQComponent {
  public FAQData = [
    {
      translationKeyQuestion: 'FAQ.QUESTIONS.QUESTION_1',
      translationKeyAnswer: 'FAQ.ANSWERS.ANSWER_1'
    },
    {
      translationKeyQuestion: 'FAQ.QUESTIONS.QUESTION_2',
      translationKeyAnswer: 'FAQ.ANSWERS.ANSWER_2'
    },
    {
      translationKeyQuestion: 'FAQ.QUESTIONS.QUESTION_3',
      translationKeyAnswer: 'FAQ.ANSWERS.ANSWER_3'
    },
    {
      translationKeyQuestion: 'FAQ.QUESTIONS.QUESTION_4',
      translationKeyAnswer: 'FAQ.ANSWERS.ANSWER_4'
    },
    {
      translationKeyQuestion: 'FAQ.QUESTIONS.QUESTION_5',
      translationKeyAnswer: 'FAQ.ANSWERS.ANSWER_5'
    },
    {
      translationKeyQuestion: 'FAQ.QUESTIONS.QUESTION_6',
      translationKeyAnswer: 'FAQ.ANSWERS.ANSWER_6'
    },
    {
      translationKeyQuestion: 'FAQ.QUESTIONS.QUESTION_7',
      translationKeyAnswer: 'FAQ.ANSWERS.ANSWER_7'
    },
    {
      translationKeyQuestion: 'FAQ.QUESTIONS.QUESTION_8',
      translationKeyAnswer: 'FAQ.ANSWERS.ANSWER_8'
    },
    {
      translationKeyQuestion: 'FAQ.QUESTIONS.QUESTION_9',
      translationKeyAnswer: 'FAQ.ANSWERS.ANSWER_9'
    },
    {
      translationKeyQuestion: 'FAQ.QUESTIONS.QUESTION_10',
      translationKeyAnswer: 'FAQ.ANSWERS.ANSWER_10'
    },
    {
      translationKeyQuestion: 'FAQ.QUESTIONS.QUESTION_11',
      translationKeyAnswer: 'FAQ.ANSWERS.ANSWER_11'
    },
    {
      translationKeyQuestion: 'FAQ.QUESTIONS.QUESTION_12',
      translationKeyAnswer: 'FAQ.ANSWERS.ANSWER_12'
    },
  ]
}