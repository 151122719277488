<ng-template [ngIf]="gameType === 'default'">
  <div class="absolute_game_content">
    <div class="gameItem-inside">
      <div class="gameItem-inside_imageWrapper" [defaultImage]="defaultImages.SMALL" [lazyLoad]="game.gameThumbImageUrl" [errorImage]="defaultImages.SMALL" [offset]="50">
        <div class="errorLazy">
            <p class="gameItem-inside_name">
              {{ game.name }}
            </p>
            <p class="gameItem-inside_vendorName">
              {{ game.vendorName }}
            </p>
        </div>
      </div>


      <div *ngIf="game.isPromotedAsNew || game.isPromotedAsTop || game.isFavourite" class="ribbon">
        <span *ngIf="game.isPromotedAsNew && !game.isFavourite" class="ribbon-label" [class.new]="game.isPromotedAsNew"
          [class.top]="game.isPromotedAsTop" [class.favourite]="game.isFavourite">
          {{ 'START.RIBBON_NEW_LABEL' | translate }}
          <span class="ribbon-label-helper1"></span>
          <span class="ribbon-label-helper2"></span>
        </span>
        <span *ngIf="game.isPromotedAsTop && !game.isFavourite" class="ribbon-label" [class.new]="game.isPromotedAsNew"
          [class.top]="game.isPromotedAsTop" [class.favourite]="game.isFavourite">{{ 'START.RIBBON_TOP_LABEL' |
          translate }}
          <span class="ribbon-label-helper1"></span>
          <span class="ribbon-label-helper2"></span>
        </span>
        <span *ngIf="game.isFavourite" class="ribbon-label" [class.new]="game.isPromotedAsNew"
          [class.top]="game.isPromotedAsTop" [class.favourite]="game.isFavourite">{{ 'START.RIBBON_FAVOURITE_LABEL' | translate }}
          <span class="ribbon-label-helper1"></span>
          <span class="ribbon-label-helper2"></span>
        </span>
      </div>

      <div class="gameItem-inside_hover">


        <div class="gameItem-inside_gameButons">
          <ng-template [ngIf]="casinoService.isDesktop()">
            <ng-template [ngIf]="isLogged && !userService.isFullyRegistered()">
              <div class="gameItem-buttons">
<!--                <a class="gameItem-button fa fa-gamepad fa-lg" [routerLink]="navigationService.constructUrl('USER_ACCOUNT')">-->
<!--                  {{ 'START.PLAY_NOW_BUTTON' | translate }}-->
<!--                </a>-->
                <a class="gameItem-button fa fa-arrow-right fa-lg" (click)="playDemo(game)" *ngIf="game.isDemoEnabled">
                  {{ 'START.DEMO_BUTTON' | translate }}
                </a>
              </div>
            </ng-template>

            <ng-template [ngIf]="isLogged && userService.isFullyRegistered()">
              <div class="gameItem-buttons">
<!--                <a class="gameItem-button fa fa-gamepad fa-lg" (click)="playGame(game)">-->
<!--                  {{ 'START.PLAY_NOW_BUTTON' | translate }}-->
<!--                </a>-->
                <a class="gameItem-button fa fa-arrow-right fa-lg" (click)="playDemo(game)" *ngIf="game.isDemoEnabled">
                  {{ 'START.DEMO_BUTTON' | translate }}
                </a>
              </div>
            </ng-template>

            <ng-template [ngIf]="!isLogged">
              <div class="gameItem-buttons">
<!--                <a class="gameItem-button fa fa-gamepad fa-lg" (click)="showLoginPanel(game)">-->
<!--                  {{ 'START.PLAY_NOW_BUTTON' | translate }}-->
<!--                </a>-->
                <a class="gameItem-button fa fa-arrow-right fa-lg" (click)="playDemo(game)" *ngIf="game.isDemoEnabled">
                  {{ 'START.DEMO_BUTTON' | translate }}
                </a>
              </div>
            </ng-template>
          </ng-template>

          <ng-template [ngIf]="casinoService.isMobile()">
            <div class="gameItem-buttons">
<!--              <a class="gameItem-button fa fa-gamepad fa-lg" (click)="gameService.loadGameSidebar(game.urlSlug)">-->
<!--                {{ 'START.PLAY_NOW_BUTTON' | translate }}-->
<!--              </a>-->
              <a class="gameItem-button fa fa-arrow-right fa-lg" (click)="gameService.loadGameSidebar(game.urlSlug)" *ngIf="game.isDemoEnabled">
                {{ 'START.DEMO_BUTTON' | translate }}
              </a>
            </div>
          </ng-template>

          <ng-template [ngIf]="isLogged">
              <a class="addToFavourites" hide-delay="0" tooltip="{{ 'GLOBAL.ADD_TO_FAVOURITE_TOOLTIP' | translate }}" placement="top"
                (click)="addToFavourites($event, game)" *ngIf="!game.isFavourite"></a>
              <a class="addToFavourites active" hide-delay="0" tooltip="{{ 'GLOBAL.REMOVE_FROM_FAVOURITES_TOOLTIP' | translate }}" placement="top"
                (click)="removeFromFavourites($event, game)" *ngIf="game.isFavourite"></a>
          </ng-template>


          <!--<img src="assets/images/games/game-ratio-spacer.png" alt="hover" class="gameItem-inside_image">-->

          <ng-template [ngIf]="!casinoService.isDesktop()">
            <a (click)="gameService.loadGameSidebar(game.urlSlug)"  class="gameItem-inside_image_link">
            </a>
          </ng-template>
          <ng-template [ngIf]="casinoService.isDesktop()">
            <ng-template [ngIf]="!isLogged">
              <a (click)="playDemo(game)" title="{{ 'START.DEMO_BUTTON' | translate }}"   class="gameItem-inside_image_link">
              </a>
            </ng-template>
            <ng-template [ngIf]="isLogged && !userService.isFullyRegistered()">
              <a (click)="playDemo(game)" title="{{ 'START.DEMO_BUTTON' | translate }}"   class="gameItem-inside_image_link">
              </a>
            </ng-template>
            <ng-template [ngIf]="isLogged && userService.isFullyRegistered()">
<!--              <a (click)="playGame(game)" title="{{ 'START.PLAY_NOW_BUTTON' | translate }}"   class="gameItem-inside_image_link">-->
<!--              </a>-->
            </ng-template>
          </ng-template>

        </div>
        <p class="gameItem-inside_name">
          {{ game.name }}
        </p>
        <p class="gameItem-inside_vendorName">
          {{ game.vendorName }}
        </p>



      </div>


    </div>
  </div>
</ng-template>




<ng-template [ngIf]="gameType == 'jackpot'">
  <div class="absolute_game_content">
    <div class="gameItem-inside">
      <div class="gameItem-inside_imageWrapper" [defaultImage]="defaultImages.SMALL" [lazyLoad]="game.gameThumbImageUrl" [errorImage]="defaultImages.SMALL" [offset]="50">
      </div>

      <div class="gameItem-inside_hover">


        <div class="gameItem-inside_gameButons">
          <ng-template [ngIf]="casinoService.isDesktop()">
            <ng-template [ngIf]="isLogged && !userService.isFullyRegistered()">
              <div class="gameItem-buttons">
<!--                <a class="gameItem-button fa fa-gamepad fa-lg" [routerLink]="navigationService.constructUrl('USER_ACCOUNT')">-->
<!--                  {{ 'START.PLAY_NOW_BUTTON' | translate }}-->
<!--                </a>-->
              </div>
            </ng-template>

            <ng-template [ngIf]="isLogged && userService.isFullyRegistered() || !isLogged">
              <div class="gameItem-buttons">
<!--                <a class="gameItem-button fa fa-gamepad fa-lg" (click)="playGame(game)">-->
<!--                  {{ 'START.PLAY_NOW_BUTTON' | translate }}-->
<!--                </a>-->
              </div>
            </ng-template>
          </ng-template>

          <ng-template [ngIf]="!casinoService.isDesktop()">
            <div class="gameItem-buttons">
<!--              <a class="gameItem-button fa fa-gamepad fa-lg" (click)="gameService.loadGameSidebar(game.urlSlug)">-->
<!--                {{ 'START.PLAY_NOW_BUTTON' | translate }}-->
<!--              </a>-->
            </div>

            <a (click)="gameService.loadGameSidebar(game.urlSlug)">
              <img src="assets/images/games/game-ratio-spacer.png" alt="hover" class="gameItem-inside_image">
            </a>
          </ng-template>

          <ng-template [ngIf]="casinoService.isDesktop()">
            <a (click)="playGame(game)">
              <img src="assets/images/games/game-ratio-spacer.png" alt="hover" class="gameItem-inside_image">
            </a>
          </ng-template>

        </div>
        <p class="gameItem-inside_name">
          {{ game.name }}
        </p>
        <p class="gameItem-inside_vendorName">
          <span *ngIf="casinoService.isDesktop()">Jackpot: </span>{{ game.jackpotAmount | currency:'EUR':'symbol':'1.2-2' }}
        </p>
      </div>
    </div>
  </div>
</ng-template>




<ng-template [ngIf]="gameType == 'livecasino'">
  <div class="liveCasino__item">
    <div class="gameList__box">
      <div class="gameList__title_wrapper">
        <h2 class="gameList__title" *ngIf="game.title">
          {{ game.title | translate }}
        </h2>
      </div>
      <img class="gameList__boxImg" alt="{{ game.name }}" [defaultImage]="defaultImages.BIG" [lazyLoad]="game.gameImageUrl" (error)="replaceGameImage($event)" [errorImage]="defaultImages.BIG" [offset]="50" />
      <p class="gameList__boxLive">
        <span class="extra-live-text" *ngIf="game.liveText">
          {{ game.liveText | translate }}
        </span>
        <span>live</span>
      </p>
      <div class="gameItem-button-wrapper">
<!--        <a class="gameItem-button button&#45;&#45;red" (click)="playGame(game)">-->
<!--          {{ (game.btnText || 'START.PLAY_NOW_BUTTON') | translate }}-->
<!--        </a>-->
      </div>
      <div class="gameList__vendor_wrapper">
        <div class="gameList__vendor_bg">
          <img class="gameList__vendor" alt="{{ game.vendorName }}" [defaultImage]="defaultImages.BIG" [lazyLoad]="game.vendorImageUrl" [errorImage]="defaultImages.BIG" [offset]="50" />
        </div>
      </div>
    </div>
  </div>
</ng-template>


<!--
<ng-template [ngIf]="gameType == 'jackpot'">
  <div class="gameItem gameItem--jackpot">
    <div class="gameItem-inside">
      <div class="gameItem-inside_hover"></div>
      <div class="gameItem-inside_imageWrapper" [defaultImage]="defaultImages.SMALL" [lazyLoad]="game.gameThumbImageUrl" [errorImage]="fallbackImage()" [offset]="50">
        <ng-template [ngIf]="casinoService.isDesktop()">
          <ng-template [ngIf]="isLogged && !userService.isFullyRegistered()">
            <div class="gameItem-buttons">
              <a class="gameItem-button fa fa-gamepad fa-lg" [routerLink]="navigationService.constructUrl('USER_ACCOUNT')">
                {{ 'START.PLAY_NOW_BUTTON' | translate }}
              </a>
            </div>
          </ng-template>

          <ng-template [ngIf]="isLogged && userService.isFullyRegistered() || !isLogged">
            <div class="gameItem-buttons">
              <a class="gameItem-button fa fa-gamepad fa-lg" (click)="playGame(game)">
                {{ 'START.PLAY_NOW_BUTTON' | translate }}
              </a>
            </div>
          </ng-template>
        </ng-template>

        <ng-template [ngIf]="!casinoService.isDesktop()">
          <div class="gameItem-buttons">
            <a class="gameItem-button fa fa-gamepad fa-lg" (click)="gameService.loadGameSidebar(game.urlSlug)">
              {{ 'START.PLAY_NOW_BUTTON' | translate }}
            </a>
          </div>

          <a (click)="gameService.loadGameSidebar(game.urlSlug)">
            <img src="assets/images/games/game-ratio-spacer.png" alt="hover" class="gameItem-inside_image">
          </a>
        </ng-template>

        <ng-template [ngIf]="casinoService.isDesktop()">
          <a (click)="playGame(game)">
            <img src="assets/images/games/game-ratio-spacer.png" alt="hover" class="gameItem-inside_image">
          </a>
        </ng-template>
      </div>
      <p class="gameItem-inside_name">
        {{ game.name }}
      </p>
      <p class="gameItem-inside_jackpotAmount">
        <span *ngIf="casinoService.isDesktop()">Jackpot: </span>{{ game.jackpotAmount | currency:'EUR':'symbol':'1.2-2' }}
      </p>
    </div>
  </div>
</ng-template>

<ng-template [ngIf]="gameType == 'livecasino'">
  <div class="liveCasino__item">
    <div class="gameList__box">
      <div class="gameList__title_wrapper">
        <h2 class="gameList__title" *ngIf="game.title">
          {{ game.title | translate }}
        </h2>
      </div>
      <img class="gameList__boxImg" alt="{{ game.name }}" [defaultImage]="defaultImages.BIG" [lazyLoad]="game.gameImageUrl" (error)="replaceGameImage($event)" [errorImage]="defaultImages.BIG" [offset]="50" />
      <p class="gameList__boxLive">
        <span class="extra-live-text" *ngIf="game.liveText">
          {{ game.liveText | translate }}
        </span>
        <span>live</span>
      </p>
      <div class="gameItem-button-wrapper">
        <a class="gameItem-button button--red" (click)="playGame(game)">
          {{ (game.btnText || 'START.PLAY_NOW_BUTTON') | translate }}
        </a>
      </div>
      <div class="gameList__vendor_wrapper">
        <div class="gameList__vendor_bg">
          <img class="gameList__vendor" alt="{{ game.vendorName }}" [defaultImage]="defaultImages.BIG" [lazyLoad]="game.vendorImageUrl" [errorImage]="defaultImages.BIG" [offset]="50" />
        </div>
      </div>
    </div>
  </div>
</ng-template>
-->
