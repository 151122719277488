import { Injectable, EventEmitter } from '@angular/core';
import { storageConstants } from '../constants/index';
import { PlatformService } from './platform.service';

@Injectable() export class LatestGamesService {
  public latestGamesChange$ = new EventEmitter()
  public latestGames = []

  /**
   * @constructor
   */
  constructor(private platformService: PlatformService) {
    //...
  }

  /**
	 * Removes from 'latest games'.
	 * @param game {Object}
	 */
  remove(game) {
    let latestGames = JSON.parse(localStorage.getItem(
      storageConstants['LATEST_GAMES'],
    )) || [];

    latestGames = latestGames.filter((element) => {
      return element.name !== game.name;
    });

    this.emitAndsetData(latestGames);
  }

  /**
	 * Adds to 'latest games'.
	 * @param game {Object}
	 * @param game.name {String}
	 * @param game.vendorName {String}
	 * @param game.vendorImageUrl {String}
	 * @param game.urlSlug {String}
	 * @param game.gameThumbImageUrl {String}
	 */
  add(game) {
    if (this.platformService.server) return;

    let latestGames = JSON.parse(localStorage.getItem(
      storageConstants['LATEST_GAMES'],
    )) || [];

    latestGames = latestGames.filter((element) => {
      return element.name !== game.name;
    });

    latestGames.unshift(game);
    this.emitAndsetData(latestGames);
  }

  /**
   * Emits and sets data.
	 * @param {Array<Object>} data
	 */
  emitAndsetData(data) {
    localStorage.setItem(
      storageConstants['LATEST_GAMES'],
      JSON.stringify(data)
    );

    const splicedData = this.getList();

    localStorage.setItem(
      storageConstants['LATEST_GAMES'],
      JSON.stringify(splicedData)
    );

    this.latestGamesChange$.emit(splicedData);
  }

  /**
	 * Returns 'latest games'.
	 * @param {Number} limit
	 * @returns {Array<Object>}
	 */
  getList(limit = 30) {
    const latestGames = this.platformService.browser
      ? (JSON.parse(localStorage.getItem(storageConstants['LATEST_GAMES'])) || [])
      : [];

    if (limit === 0) {
      return latestGames;
    }
    
    return latestGames.splice(0, limit);
  }

  /**
	 * Clears list.
	 */
  clearList() {
    localStorage.setItem(
      storageConstants['LATEST_GAMES'],
      '[]'
    );

    return [];
  }



}