import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

import { commonPagesHelper } from '../../helpers/index';
import { UserRequestService, ComboNotificationService, NavigationService } from '../../services/index';
import { PlatformService } from '../../services/platform.service';
import { ComboNotificationType } from '../../enums/alert-type.enum';

@Component({
  selector: 'confirm-email-change',
  templateUrl: './confirmEmailChange.component.html',
  styleUrls: ['./confirmEmailChange.component.scss']
})

export class ConfirmEmailChangeComponent implements OnInit, OnDestroy {
  private temporaryToken
  confirmEmailChangeSubscriber
  isRequestFlag = false
  tokenIsValid
  autoRedirectSetTimeout

  /**
	 * @constructor
	 */
  constructor(
    public router: Router,
    private route: ActivatedRoute,
    private userRequestService: UserRequestService,
    private comboNotificationService: ComboNotificationService,
    private _translate: TranslateService,
    private navigationService: NavigationService,
    private platformService: PlatformService
  ) {
    this.temporaryToken = this.route.snapshot.params['temporaryToken'];
  }

  /**
	 * It's native Angular 'on initiation' method.
	 * Fired after constructor.
	 */
  ngOnInit() {
    if (! this.temporaryToken) {
      this.tokenIsValid = false;
      
      return;
    }

    if (this.platformService.browser) {
      setTimeout(() => {
        this.tokenVerification(this.temporaryToken);
      }, 2000);
    }

  }

  /**
	 * Verifications token.
	 * @param {String} temporaryToken
	 */
  tokenVerification(temporaryToken) {
    const self = this;
    commonPagesHelper.loader('show');
    this.confirmEmailChangeSubscriber = this.userRequestService.confirmEmailChange(temporaryToken).subscribe((success) => {
      this.isRequestFlag = true;
      if (success.result !== 'ok') {
        // * Show SnackBar Notification
        self.comboNotificationService.notification(success.message, ComboNotificationType.Error);
        self.tokenIsValid = false;
        commonPagesHelper.loader('hide');
        
        return;
      }

      self._translate.get(['GLOBAL.NOTIFICATIONS.SUCCESS_LABEL', 'CONTACT.MAIL_SENT_SUCCESSFULLY_LABEL'])
        .subscribe((translatedValue: string) => {
          // * Show SnackBar Notification
          self.comboNotificationService.notification(translatedValue['CONTACT.MAIL_SENT_SUCCESSFULLY_LABEL'], ComboNotificationType.Success);
        });
      commonPagesHelper.loader('hide');

      self.tokenIsValid = true;

      self.autoRedirectSetTimeout = setTimeout(() => {
        self.navigationService.goToDefaultPage();
      }, 10000);
    }, (error) => {
      console.error('error data -->', error);
      this.isRequestFlag = true;
      self.tokenIsValid = false;
      commonPagesHelper.loader('hide');
    });
  }

  /**
	 * It's native Angular 'on destroy' method.
	 * Destroys component.
	 */
  ngOnDestroy() {
    if (typeof(this.confirmEmailChangeSubscriber) !== 'undefined') {
      this.confirmEmailChangeSubscriber.unsubscribe();
    }
    clearTimeout(this.autoRedirectSetTimeout);
  }




}
