<div class="container container--noVerticalPadding container--darker">
    <h1 class="big withoutStar">{{ 'LOGIN.HEADER_LOG_IN' | translate }}</h1>
</div>

<div class="container">
    <div class="loginComponent">
        <form class="form" [formGroup]="form" (ngSubmit)="onSubmit()" (keyup.enter)="onSubmit()">
            <div class="columns">
                <div class="columns__half">
                    <h3>{{ 'LOGIN.EMAIL_LOGIN_HEADER' | translate }}</h3>

                    <div class="form-element inputWrapper">
                        <label for="loginEmail">{{ 'LOGIN.EMAIL_PLACEHOLDER' | translate }}</label>
                        <input id="loginEmail" formControlName="email" required autocomplete="off">
                        <span class="errorMessage" *ngIf="formErrors.email.length">
                            <span class="errorMessage__inside">
                                <span *ngFor="let emailMessage of formErrors.email">{{ emailMessage | translate }}</span>
                            </span>
                        </span>
                    </div>

                    <div class="form-element inputWrapper quarterMarginBottom">
                        <label for="loginPassword">{{ 'LOGIN.PASSWORD_PLACEHOLDER' | translate }}</label>
                        <input id="loginPassword" type="password" formControlName="password" required autocomplete="off">
                        <span class="errorMessage" *ngIf="formErrors.password.length">
                            <span class="errorMessage__inside">
                                <span *ngFor="let passwordMessage of formErrors.password">{{ passwordMessage | translate }}</span>
                            </span>
                        </span>
                    </div>

                    <div class="inputWrapper moreMarginBottom">
                        <a class="anchor" [routerLink]="navigationService.constructUrl('PASSWORD_RESET')">{{ 'LOGIN.FORGOT_PASSWORD_LABEL' | translate }}</a>
                    </div>

                    <div class="inputWrapper">
                        <a class="button button--green button--disabled" hide-delay="0" tooltip="{{ 'GLOBAL.INVALID_VALIDATION_FORM_FIELDS_TOOLTIP' | translate }}" *ngIf="!form.valid || sending"><i class="fa fa-user-circle-o" aria-hidden="true"></i><span class="textInside">{{ 'LOGIN.LOGIN_LABEL' | translate }}</span></a>
                        <a class="button button--green" *ngIf="(! (!form.valid || sending))" (click)="onSubmit()"><i class="fa fa-user-circle-o" aria-hidden="true"></i><span class="textInside">{{ 'LOGIN.LOGIN_LABEL' | translate }}</span></a>
                    </div>
                </div>

                <div class="columns__half">
<!--                    <ng-template [ngIf]="facebookLogin">-->
<!--                      <h3>{{ 'LOGIN.FACEBOOK_LOGIN_HEADER' | translate }}</h3>-->

<!--                      <div class="inputWrapper">-->
<!--                          <a class="button button&#45;&#45;blue" (click)="layout.loginViaFacebook()"><i class="fa fa-facebook-official" aria-hidden="true"></i><span class="textInside">{{ 'LOGIN.FACEBOOK_LOGIN_LABEL' | translate }}</span></a>-->
<!--                      </div>-->
<!--                    </ng-template>-->

                    <div class="inputWrapper">
<!--                        <h3 class="withTopMargin">{{ 'LOGIN.DO_NOT_HAVE_AN_ACCOUNT_LABEL' | translate }}</h3>-->
<!--                        <a class="button button&#45;&#45;gray" [routerLink]="navigationService.constructUrl('REGISTER')"><i class="fa fa-user-plus" aria-hidden="true"></i><span class="textInside">{{ 'LOGIN.REGISTER_LABEL' | translate }}</span></a>-->
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>
