import { NgModule, OnDestroy, APP_INITIALIZER, ErrorHandler } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ResizableModule } from 'angular-resizable-element';
import { SocialLoginModule, SocialAuthServiceConfig, FacebookLoginProvider } from '@abacritt/angularx-social-login';
import { ScrollToModule } from '@nicky-lenaers/ngx-scroll-to';
import { TeximateModule } from 'ngx-teximate';
import { CookieService } from 'ngx-cookie-service';
import { HttpClient, HttpClientModule  } from '@angular/common/http';
import { TooltipModule  } from 'ng2-tooltip-directive';

import {
  TranslateModule,
  TranslateService,
  TranslateLoader,
} from '@ngx-translate/core';

import {
  CommonModule, DOCUMENT
} from '@angular/common';

import {
  Router,
  NavigationError,
  NavigationStart,
  NavigationEnd,
  NavigationCancel,
  RouterEvent
} from '@angular/router';

import { AppRouting } from './app.routes';
import { AuthGuard, NotLoggedIn, FullyRegistered } from './guards/index';

import {
  AppLoadService,
  AuthenticationService,
  RequestService,
  UserRequestService,
  GameRequestService,
  GameService,
  GeoLocationService,
  UserService,
  WinnerRequestService,
  JackpotRequestService,
  VendorRequestService,
  BonusRequestService,
  LanguageRequestService,
  ContactRequestService,
  NewsletterRequestService,
  CashierRequestService,
  AppLanguageService,
  NavigationService,
  LatestGamesService,
  ComboNotificationService,
  SearchBarService,
  CasinoService,
  SliderRequestService,
  CustomCategoryRequestService,
  WINDOW_PROVIDERS
} from './services/index';
import { AccessTokenService } from './services/access-token.service';
import {
  commonPagesHelper
} from './helpers/index';

import { SharedModule } from './components/shared.module';
import { CoreLayout } from './layouts/index';

import {
  LanguageComponent,
  NotFoundComponent,
  NoAccessComponent,
  MaintenanceModeComponent,
  StartComponent,
  GameDetailsComponent,
  AllGamesComponent,
  FAQComponent,
  AboutUsComponent,
  PaymentProvidersComponent,
  ContactComponent,
  ConfirmEmailChangeComponent,
  SafetyComponent,
  ResponsibleGamblingComponent,
  YouthProtectionComponent,
  TACComponent,
  TermsOfUseComponent,
  PrivacyPolicyComponent,
  LandingPageComponent,
  RegisterComponent,
  RegisterConfirmComponent,
  LoginComponent,
  PasswordResetComponent,
  PasswordResetConfirmComponent,
  AccountActivationComponent,
  UserAccountComponent,
  CashierComponent,
  LogoutComponent,
  SupportComponent,
  BonusComponent,
  BonusConditionsComponent,
  RestrictedAreaComponent,
  AccountFrozenComponent,
  LoginAsUserComponent
} from './pages/index';

import {
  BaseCashierComponent,
  PayInComponent,
  PayOutComponent,
  PromoCodeComponent,
  TransactionsComponent,
  GameRoundsComponent,
  LimitsComponent,
  BlockAccountComponent,
  DocumentsComponent
} from './pages/cashier/components/index';

import { globalConstants } from './constants/index';

import {
  ForbiddenValidatorDirective,
  GoogleplaceDirective,
  RouteTransformerDirective,
  CPFMaskDirective
} from './directives/index';

import { InfiniteScrollModule } from 'ngx-infinite-scroll';

import {
  PerfectScrollbarModule,
  PERFECT_SCROLLBAR_CONFIG,
  PerfectScrollbarConfigInterface
} from 'ngx-perfect-scrollbar';

import { BarRatingModule } from 'ngx-bar-rating';

import { AgmCoreModule } from '@agm/core';
import { AngularMultiSelectModule } from 'angular2-multiselect-dropdown';
import { DeviceDetectorService } from 'ngx-device-detector';
// import { JasperoAlertsModule } from '@jaspero/ng-alerts';
//import { MetaModule, MetaLoader, MetaStaticLoader, PageTitlePositioning } from '@ngx-meta/core';
//Change ngx-meta to platform-browser
import { BrowserModule, Meta } from '@angular/platform-browser';

import { CurrencyFormatPipe  } from './pipes/currency-format.pipe';
import { SafeHtmlPipe } from './pipes/safehtml.pipe';
import { AppComponent } from './app.component';
import { LandingPageService } from './pages/landingPage/landingPage.service';
import { LandingPageResolver } from './pages/landingPage/landingPage.resolver';
/* Why Livechat is loaded by extenal instead node_modeludes.  */
//import { ExternalModule } from './external/external.module';
import { LiveChatWidgetModule } from '@livechat/widget-angular'
import { PlatformService } from './services/platform.service';
import { CommonPagesService } from './services/commonPagesService';
import { StaticComponent } from './pages/static/static.component';
import { NewsletterUnsubscribeComponent } from './pages/newsletter-unsubscribe/newsletter-unsubscribe.component';

// * Shared Component
import { ConfirmDialogComponent } from './components/confirmation-dialog/confirmation-dialog.component';
import { SnackbarNotificationComponent } from './components/snackbar-notification/snackbar-notification.component';

// * Import Translations
import { TranslateInlineLoader } from './components/translation/translation-class.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

// * Import Material Module
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { MatToolbarModule } from '@angular/material/toolbar';
import { filter, tap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { BonusDetailsComponent } from './pages/bonusDetails/bonusDetails.component';
import { GeneralBonusTermsComponent } from './pages/generalBonusTerms/generalBonusTerms.component';

import {
  create,
  parseCreationOptionsFromJSON,
} from "@github/webauthn-json/browser-ponyfill";
/*
export function metaFactory(): MetaLoader {
  return new MetaStaticLoader({
    pageTitlePositioning: PageTitlePositioning.PrependPageTitle,
    pageTitleSeparator: ' | ',
    applicationName: globalConstants['APPLICATION_NAME'],
    defaults: {
      title: 'GLOBAL.DEFAULT_TITLE',
      description: 'GLOBAL.DEFAULT_DESC',
      'og:image': '',
      'og:type': 'casino',
      'og:locale': 'en_US',
      'og:locale:alternate': 'en_US,nl_NL,tr_TR'
    }
  });
}
*/

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true
};
/*
export function socialProviderConfig() {
  return new SocialAuthServiceConfig([
    {
      id: FacebookLoginProvider.PROVIDER_ID,
      provider: new FacebookLoginProvider(globalConstants.SOCIAL_PROVIDERS.facebook.clientId)
    },
  ]);
}*/

// TODO: Add Angular decorator.
@NgModule({
  imports: [
    BarRatingModule,
    CommonModule,
    SharedModule,
    BrowserModule.withServerTransition({ appId: 'elcarado-app' }),
    //LivechatWidgetModule,
    FormsModule,
    ReactiveFormsModule,
    AppRouting,
    InfiniteScrollModule,
    ResizableModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useClass: TranslateInlineLoader
      }
    }),
    PerfectScrollbarModule,
    SocialLoginModule,
    AgmCoreModule.forRoot({
      apiKey: globalConstants['GOOGLE_MAPS_KEY']
    }),
    AngularMultiSelectModule,
    /*
    MetaModule.forRoot({
      provide: MetaLoader,
      useFactory: (metaFactory),
      deps: [TranslateService]
    }),*/
    BrowserModule,
    ScrollToModule.forRoot(),
    TeximateModule,
    HttpClientModule,
    TooltipModule,
    //ExternalModule,
    LiveChatWidgetModule,
    BrowserAnimationsModule,
    MatButtonModule,
    MatSnackBarModule,
    MatDialogModule,
    MatToolbarModule
  ],
  declarations: [
    AppComponent,
    CoreLayout,
    LanguageComponent,
    NotFoundComponent,
    NoAccessComponent,
    MaintenanceModeComponent,
    StartComponent,
    GameDetailsComponent,
    AllGamesComponent,
    StaticComponent,
    FAQComponent,
    AboutUsComponent,
    PaymentProvidersComponent,
    ContactComponent,
    ConfirmEmailChangeComponent,
    SafetyComponent,
    ResponsibleGamblingComponent,
    YouthProtectionComponent,
    TACComponent,
    TermsOfUseComponent,
    PrivacyPolicyComponent,
    LandingPageComponent,
    RegisterComponent,
    RegisterConfirmComponent,
    LoginComponent,
    PasswordResetComponent,
    PasswordResetConfirmComponent,
    AccountActivationComponent,
    UserAccountComponent,
    CashierComponent,
    LogoutComponent,
    SupportComponent,
    BonusComponent,
    BonusConditionsComponent,
    RestrictedAreaComponent,
    AccountFrozenComponent,
    BaseCashierComponent,
    PayInComponent,
    PayOutComponent,
    PromoCodeComponent,
    TransactionsComponent,
    GameRoundsComponent,
    LimitsComponent,
    BlockAccountComponent,
    DocumentsComponent,
    GoogleplaceDirective,
    ForbiddenValidatorDirective,
    RouteTransformerDirective,
    CPFMaskDirective,
    SafeHtmlPipe,
    CurrencyFormatPipe,
    LoginAsUserComponent,
    NewsletterUnsubscribeComponent,
    BonusDetailsComponent,
    GeneralBonusTermsComponent
  ],
  entryComponents: [
    BaseCashierComponent,
    PayInComponent,
    PayOutComponent,
    PromoCodeComponent,
    TransactionsComponent,
    GameRoundsComponent,
    LimitsComponent,
    BlockAccountComponent,
    DocumentsComponent,
    ConfirmDialogComponent,
    SnackbarNotificationComponent
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: AppLoadService.checkWebApiAvailabilityFactory,
      multi: true,
      deps: [HttpClient, TranslateService, PlatformService, DOCUMENT]
    },
    AuthGuard,
    AccessTokenService,
    NotLoggedIn,
    FullyRegistered,
    TranslateService,
    AuthenticationService,
    RequestService,
    UserRequestService,
    GameRequestService,
    GameService,
    GeoLocationService,
    UserService,
    WinnerRequestService,
    JackpotRequestService,
    VendorRequestService,
    BonusRequestService,
    LanguageRequestService,
    ContactRequestService,
    NewsletterRequestService,
    CashierRequestService,
    AppLanguageService,
    NavigationService,
    LatestGamesService,
    ComboNotificationService,
    SearchBarService,
    CookieService,
    HttpClient,
    CasinoService,
    AppLoadService,
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
    },
    WINDOW_PROVIDERS,
    SliderRequestService,
    LandingPageResolver,
    LandingPageService,
    CustomCategoryRequestService,
    /*{
      provide: 'SocialAuthServiceConfig',
      useFactory: socialProviderConfig
    },*/
    {
      provide: 'SocialAuthServiceConfig',
      useValue: {
        autoLogin: false,
        providers: [
          {
            id: FacebookLoginProvider.PROVIDER_ID,
            provider: new FacebookLoginProvider(globalConstants.SOCIAL_PROVIDERS.facebook.clientId)
          }
        ],
        onError: (err) => {
          console.error(err);
        }
      } as SocialAuthServiceConfig,
    },
    Meta,
    {
      provide: DeviceDetectorService
    },
    
  ],
  bootstrap: [
    AppComponent
  ]
})

export class AppModule implements OnDestroy {
  public title = (globalConstants['APPLICATION_NAME'] || 'Angular App');
  routerEvents: any;

  public constructor(private router: Router, private appLoadService: AppLoadService, private commonPagesService: CommonPagesService) {
    this.appLoadService.initializeApp();
    commonPagesService.loader('hide');

    // * Subscribe to Router Events, Filter https://angular.io/api/router/RouterEvent
    this.routerEvents = this.subscribeRouterEvent();
  }

  subscribeRouterEvent(): any {
    return this.router.events.pipe(
      filter(e => e instanceof NavigationCancel || e instanceof NavigationEnd ||
        e instanceof NavigationError || e instanceof NavigationStart))
      .subscribe(e => {
        if (e instanceof NavigationStart) {
          window.scrollTo(0, 0);
          commonPagesHelper.loader('show');
        } else {
          commonPagesHelper.loader('hide');
        }
      });
  }

  ngOnDestroy(): void {
    // * Unsubscribe over the Router Events -
    // * https://stackoverflow.com/questions/50353164/angular-6-router-events-filter-filter-does-not-exist-on-type-observableevent
    this.routerEvents.unsubscribe();
  }
}
