export const routingConstants = {
  PL: {
    START: {
      slug: 'startowa',
      data: {
        meta: {
          title: 'Strona startowa',
          description: '...',
        },
      },
    },
    LIVE_CASINO: {
      slug: 'kasyno-na-zywo',
      data: {
        meta: {
          title: 'Kasyno na Å¼ywo',
          description: '...',
        },
      },
    },
    BONUS: {
      slug: 'bonus',
      data: {
        meta: {
          title: 'Bonus',
          description: '...',
        },
      },
    },
    BONUS_CONDITION: {
      slug: 'warunki-bonusu',
      data: {
        meta: {
          title: 'Warunki bonusu',
          description: '...',
        },
      },
    },
    CONTACT: {
      slug: 'kontakt',
      data: {
        meta: {
          title: 'Kontakt',
          description: '...',
        },
      },
    },
    STATIC_PAGE: {
      slug: 'strona/:slug',
    },
    FAQ: {
      slug: 'strona/faq',
      data: {
        meta: {
          title: 'NajczÄ™Å›ciej zadawane pytania',
          description: '...',
        },
      },
    },
    ALL_GAMES: {
      slug: 'gry/:filter',
      data: {
        meta: {
          title: 'elcarado.com | Online Casino Games, Slots, Keno, Videopoker, Black Jack and Roulette',
          description:
            'Choose now your online casino game. You have the choice between online Slots with and without freegames, Videopoker, Keno, Black Jack and Roulette.',
        },
      },
    },
    ALL_GAMES_VENDOR: {
      slug: 'gry/:filter/:vendor',
      data: {
        meta: {
          title: 'elcarado.com | Online Casino Games, Slots, Keno, Videopoker, Black Jack and Roulette',
          description:
            'Choose now your online casino game. You have the choice between online Slots with and without freegames, Videopoker, Keno, Black Jack and Roulette.',
        },
      },
    },
    ALL_GAMES_CATEGORY: {
      slug: 'kategorie-gier/:category',
      data: {
        meta: {
          title: 'elcarado.com | Online Casino Games, Slots, Keno, Videopoker, Black Jack and Roulette',
          description:
            'Choose now your online casino game. You have the choice between online Slots with and without freegames, Videopoker, Keno, Black Jack and Roulette.',
        },
      },
    },
    ALL_GAMES_CATEGORY_VENDOR: {
      slug: 'kategorie-gier/:category/:vendor',
      data: {
        meta: {
          title: 'elcarado.com | Online Casino Games, Slots, Keno, Videopoker, Black Jack and Roulette',
          description:
            'Choose now your online casino game. You have the choice between online Slots with and without freegames, Videopoker, Keno, Black Jack and Roulette.',
        },
      },
    },
    GAME_DETAILS: {
      slug: 'detale-gry/:slug',
      data: {
        meta: {
          title: 'Detale gry :slug',
          description: '...',
        },
      },
    },
    GAME: {
      slug: 'gra/:slug',
      data: {
        meta: {
          title: 'Gra :slug',
          description: '...',
        },
      },
    },
    SUPPORT: {
      slug: 'wsparcie',
      data: {
        meta: {
          title: 'Wsparcie',
          description: '...',
        },
      },
    },
    PAYMENT_METHODS: {
      slug: 'metody-platnosci',
      data: {
        meta: {
          title: 'Metody pÅ‚atnoÅ›ci',
          description: '...',
        },
      },
    },
    RESPONSIBLE_GAMBLING: {
      slug: 'strona/odpowiedzialny-hazard',
      data: {
        meta: {
          title: 'Odpowiedzialny hazard',
          description: '...',
        },
      },
    },
    SAFETY: {
      slug: 'strona/bezpieczenstwo',
      data: {
        meta: {
          title: 'BezpieczeÅ„stwo',
          description: '...',
        },
      },
    },
    ABOUT_US: {
      slug: 'strona/o-nas',
      data: {
        meta: {
          title: 'O nas',
          description: '...',
        },
      },
    },
    TAC: {
      slug: 'strona/tac',
      data: {
        meta: {
          title: 'TAC',
          description: '...',
        },
      },
    },
    PRIVACY_POLICY: {
      slug: 'strona/polityka-prywatnosci',
      data: {
        meta: {
          title: 'Polityka prywatnoÅ›ci',
          description: '...',
        },
      },
    },
    COOKIE_POLICY: {
      slug: 'strona/cookie-policy',
      data: {
        meta: {
          title: 'Cookie Policy',
          description: 'Cookie Policy',
        },
      },
    },
    CONFIRM_EMAIL_CHANGE: {
      slug: 'confirm-email-change/:temporaryToken',
      data: {
        meta: {
          title: '[pl] Email change (confirm)',
          description: '...',
        },
      },
    },
    PAYMENT_PROVIDERS: {
      slug: 'dostawcy-uslug-platniczych',
      data: {
        meta: {
          title: 'Dostawcy usÅ‚ug pÅ‚atniczych',
          description: '...',
        },
      },
    },
    YOUTH_PROTECTION: {
      slug: 'strona/ochrona-mlodziezy',
      data: {
        meta: {
          title: 'Ochrona mÅ‚odzieÅ¼y',
          description: '...',
        },
      },
    },
    TERMS_OF_USE: {
      slug: 'strona/warunki-korzystania',
      data: {
        meta: {
          title: 'Warunki korzystania z serwisu',
          description: '...',
        },
      },
    },
    LANDINGPAGE: {
      slug: 'landingpage',
      data: {
        meta: {
          title: 'Landing Page',
          description: '...',
        },
      },
    },
    REGISTER: {
      slug: 'rejestracja',
      data: {
        meta: {
          title: 'Rejestracja',
          description: '...',
        },
      },
    },
    REGISTER_CONFIRM: {
      slug: 'rejestracja/potwierdzenie/:temporaryToken',
      data: {
        meta: {
          title: 'Potwierdzenie rejestracji',
          description: '...',
        },
      },
    },
    LOGIN: {
      slug: 'logowanie',
      data: {
        meta: {
          title: 'Logowanie',
          description: '...',
        },
      },
    },
    PASSWORD_RESET: {
      slug: 'reset-hasla',
      data: {
        meta: {
          title: 'Reset hasÅ‚a',
          description: '...',
        },
      },
    },
    PASSWORD_RESET_CONFIRM: {
      slug: 'reset-hasla/potwierdzenie/:temporaryToken',
      data: {
        meta: {
          title: 'Reset hasÅ‚a (potwierdzenie)',
          description: '...',
        },
      },
    },
    USER_ACCOUNT: {
      slug: 'moj-profil',
      data: {
        meta: {
          title: 'MÃ³j profil',
          description: '...',
        },
      },
    },
    CASHIER: {
      slug: 'kasjer',
      data: {
        meta: {
          title: 'Kasjer',
          description: '...',
        },
      },
    },
    SPORTSBOOK: {
      slug: 'sportsbook',
      data: {
        meta: {
          title: 'Sportsbook',
          description: '...',
        },
      },
    },
    LOGIN_WITH_EMAIL: {
      slug: 'logowanie/:email',
      data: {
        meta: {
          title: 'Logowanie',
          description: '...',
        },
      },
    },
    LOGOUT: {
      slug: 'wylogowanie',
      data: {
        meta: {
          title: 'wylogowanie',
          description: '...',
        },
      },
    },
    ACCOUNT_ACTIVATION: {
      slug: 'aktywacja-konta',
      data: {
        meta: {
          title: 'Aktywacja konta',
          description: '...',
        },
      },
    },
    RESTRICTED_AREA: {
      slug: 'full-access-restricted',
      data: {
        meta: {
          title: 'Brak dostÄ™pu',
        },
      },
    },
    ACCOUNT_FROZEN: {
      slug: 'account-frozen',
      data: {
        meta: {
          title: 'Konto nieaktywne',
        },
      },
    },
    NOT_FOUND: {
      data: {
        meta: {
          title: '404 - Nie znaleziono strony',
        },
      },
    },
  },
  DE: {
    START: {
      slug: 'startseite',
      data: {
        meta: {
          title: 'Elcarado Online Casino',
          description: 'Elcarado Online Casino: Top-Slot-Spiele, klassische Tischspiele, großzügige Boni! Jetzt spielen im seriösen Online',
        },
      },
    },
    LIVE_CASINO: {
      slug: 'live-casino',
      data: {
        meta: {
          title: 'Live Casino',
          description: '...',
        },
      },
    },
    BONUS: {
      slug: 'bonus',
      data: {
        meta: {
          title: 'Online Casino Bonus',
          description:
            'Online Casino Bonus bei Elcarado. âœ“ 250% Bonus auf Ihre erste Einzahlung âœ“ 1.000â‚¬ Willkommensbonus. Jetzt online spielen bei elcarado.com!',
        },
      },
    },
    BONUS_CONDITION: {
      slug: 'bonus-bedingungen',
      data: {
        meta: {
          title: 'Bonus Bedingungen',
          description:
            'Online Casino Bonus bei Elcarado. âœ“ 250% Bonus auf Ihre erste Einzahlung âœ“ 1.000â‚¬ Willkommensbonus. Jetzt online spielen bei elcarado.com!',
        },
      },
    },
    BONUS_DETAILS: {
      slug: 'bonus-einzelheiten',
      data: {
        meta: {
          title: 'Bonus Einzelheiten',
          description: '...',
        },
      },
    },
    CONTACT: {
      slug: 'kontakt',
      data: {
        meta: {
          title: 'Kontakt',
          description: '...',
        },
      },
    },
    STATIC_PAGE: {
      slug: 'page/:slug',
    },
    FAQ: {
      slug: 'page/faq',
      data: {
        meta: {
          title: 'Online Casino Fragen',
          description:
            'Hier finden Sie Antworten zu den allgemeinen und hÃ¤ufigen Fragen wie zum Beispiel die Zahlungsanbieter und Gebühren.',
        },
      },
    },
    ALL_GAMES: {
      slug: 'spiele/:filter',
      data: {
        meta: {
          title: 'Online Casino Games ',
          description:
            'Choose now your online casino game. You have the choice between online Slots with and without freegames, Videopoker, Keno, Black Jack and Roulette.',
        },
      },
    },
    ALL_GAMES_VENDOR: {
      slug: 'spiele/:filter/:vendor',
      data: {
        meta: {
          title: 'Online Casino Games',
          description:
            'Choose now your online casino game. You have the choice between online Slots with and without freegames, Videopoker, Keno, Black Jack and Roulette.',
        },
      },
    },
    ALL_GAMES_CATEGORY: {
      slug: 'kategorien/:category',
      data: {
        meta: {
          title: 'Online Casino Games',
          description:
            'Choose now your online casino game. You have the choice between online Slots with and without freegames, Videopoker, Keno, Black Jack and Roulette.',
        },
      },
    },
    ALL_GAMES_CATEGORY_VENDOR: {
      slug: 'kategorien/:category/:vendor',
      data: {
        meta: {
          title: 'Online Casino Games, Slots, Keno, Videopoker, Black Jack and Roulette',
          description:
            'Choose now your online casino game. You have the choice between online Slots with and without freegames, Videopoker, Keno, Black Jack and Roulette.',
        },
      },
    },
    GAME_DETAILS: {
      slug: 'spiel/:slug',
      data: {
        meta: {
          title: ':slug online spielen im Online Casino',
          description: '...',
        },
      },
    },
    GAME: {
      slug: 'spiel/:slug',
      data: {
        meta: {
          title: ':slug online spielen im Online Casino',
          description: '...',
        },
      },
    },
    SUPPORT: {
      slug: 'support',
      data: {
        meta: {
          title: 'Ihr direkter Kontakt zu Elcarado',
          description: 'Sie haben ein Problem, eine Frage oder einen Vorschlag? Bitte schreiben Sie uns per Kontaktformular.',
        },
      },
    },
    PAYMENT_METHODS: {
      slug: 'zahlungsarten',
      data: {
        meta: {
          title: 'Zahlungsarten',
          description: '...',
        },
      },
    },
    RESPONSIBLE_GAMBLING: {
      slug: 'page/verantwortungsbewusstes-spielen',
      data: {
        meta: {
          title: 'Verantwortungsbewusstes Spielen',
          description:
            'Verantwortungsbewusstes Spielen: Selbsttest, Limitierung und Spielersperre, Jugendschutz, Beratungsangebote im Internet, Beratungsangebote vor Ort',
        },
      },
    },
    SAFETY: {
      slug: 'page/sicherheit',
      data: {
        meta: {
          title: 'Sicherheit',
          description:
            'Elcarado verwendet die modernsten und besten SSL /TSL* VerschlÃ¼sselungstechnologien, die derzeit technisch umsetzbar sind, um unseren Spielern ein HÃ¶chtmaÃŸ an Sicherheit bei allen Datentransfers und Transaktionen zu gewÃ¤hrleisten.',
        },
      },
    },
    ABOUT_US: {
      slug: 'page/ueber-uns',
      data: {
        meta: {
          title: 'Ãœber uns',
          description: '...',
        },
      },
    },
    TAC: {
      slug: 'page/agb',
      data: {
        meta: {
          title: 'AGB',
          description: 'AGB',
        },
      },
    },
    GENERAL_BONUS_TERMS: {
      slug: 'page/allgemeine-bonusbedingungen',
      data: {
        meta: {
          title: 'Allgemeine Bonusbedingungen',
          description: 'Allgemeine Bonusbedingungen',
        },
      },
    },
    PRIVACY_POLICY: {
      slug: 'page/datenschutzbestimmungen',
      data: {
        meta: {
          title: 'Datenschutz',
          description: 'Datenschutz',
        },
      },
    },
    COOKIE_POLICY: {
      slug: 'page/cookie-richtlinie',
      data: {
        meta: {
          title: 'Cookie-Richtlinie',
          description: 'Cookie-Richtlinie',
        },
      },
    },
    CONFIRM_EMAIL_CHANGE: {
      slug: 'confirm-email-change/:temporaryToken',
      data: {
        meta: {
          title: 'E-Mail Ã„nderung - BestÃ¤tigung',
          description: '...',
        },
      },
    },
    PAYMENT_PROVIDERS: {
      slug: 'zahlungsanbieter',
      data: {
        meta: {
          title: 'Zahlungsanbieter bei Elcarado Online Casino',
          description:
            'Zahlungsanbieter im Elcarado Online Casino. Spiele bei Elcarado. âœ“ schnelle & sichere Einzahlung âœ“ keine Extrakosten! Online spielen bei elcarado.com!',
        },
      },
    },
    YOUTH_PROTECTION: {
      slug: 'page/jugendschutz',
      data: {
        meta: {
          title: 'Jugendschutz',
          description: 'MinderjÃ¤hrigen â€“ Schutz von Kindern.',
        },
      },
    },
    TERMS_OF_USE: {
      slug: 'page/nutzungsbedingungen',
      data: {
        meta: {
          title: 'Nutzungsbedingungen',
          description: '...',
        },
      },
    },
    LANDINGPAGE: {
      slug: 'landingpage',
      data: {
        meta: {
          title: 'Landing Page',
          description: '...',
        },
      },
    },
    REGISTER: {
      slug: 'registrieren',
      data: {
        meta: {
          title: 'Online Casino Registrierung',
          description:
            'Kostenlose Registrierung bei Elcarado Online Casino âœ“ Top-Slot-Spiele âœ“ Klassische Tischspiele âœ“ GroÃŸzÃ¼gige Boni! Jetzt spielen bei elcarado.com!',
        },
      },
    },
    REGISTER_CONFIRM: {
      slug: 'registrieren/bestaetigen/:temporaryToken',
      data: {
        meta: {
          title: 'Registrierung bestÃ¤tigen',
          description: '...',
        },
      },
    },
    LOGIN: {
      slug: 'einloggen',
      data: {
        meta: {
          title: 'Einloggen',
          description: '...',
        },
      },
    },
    PASSWORD_RESET: {
      slug: 'passwort-zuruecksetzen',
      data: {
        meta: {
          title: 'Passwort vergessen?',
          description: 'Kein Problem, das kommt vor! Wir senden Ihnen gerne ein Neues.',
        },
      },
    },
    PASSWORD_RESET_CONFIRM: {
      slug: 'passwort-zuruecksetzen/bestaetigen/:temporaryToken',
      data: {
        meta: {
          title: 'Passwort zurucksetzen - BestÃ¤tigung',
          description: '...',
        },
      },
    },
    USER_ACCOUNT: {
      slug: 'mein-profil',
      data: {
        meta: {
          title: 'Mein Profil',
          description: '...',
        },
      },
    },
    CASHIER: {
      slug: 'kasse',
      data: {
        meta: {
          title: 'Kasse',
          description: '...',
        },
      },
    },
    SPORTSBOOK: {
      slug: 'sportsbook',
      data: {
        meta: {
          title: 'Sportwetten',
          description: '...',
        },
      },
    },
    LOGIN_WITH_EMAIL: {
      slug: 'anmeldung/:email',
      data: {
        meta: {
          title: 'Anmeldung',
          description: '...',
        },
      },
    },
    LOGOUT: {
      slug: 'ausloggen',
      data: {
        meta: {
          title: 'Ausloggen',
          description: '...',
        },
      },
    },
    ACCOUNT_ACTIVATION: {
      slug: 'aktivierung',
      data: {
        meta: {
          title: 'Konto Aktivierung',
          description: '...',
        },
      },
    },
    RESTRICTED_AREA: {
      slug: 'full-access-restricted',
      data: {
        meta: {
          title: 'Full access required',
        },
      },
    },
    ACCOUNT_FROZEN: {
      slug: 'account-frozen',
      data: {
        meta: {
          title: 'Ihr Konto ist derzeit gesperrt',
        },
      },
    },
    NOT_FOUND: {
      data: {
        meta: {
          title: '404 - Seite nicht gefunden',
        },
      },
    },
  },
  EN: {
    START: {
      slug: 'dashboard',
      data: {
        meta: {
          title: 'Online casino',
          description: 'elcarado.com - Your online casino - slots and live dealer casino',
        },
      },
    },
    LIVE_CASINO: {
      slug: 'live-casino',
      data: {
        meta: {
          title: 'Live casino',
          description: '...',
        },
      },
    },
    BONUS: {
      slug: 'bonus',
      data: {
        meta: {
          title: 'Elcarado.com | Current Bonus offers on your deposit - 100% Deposit Bonus and more',
          description:
            'Choose a bonus on your deposit to double your cash in our online casino. For example: 100% Welcome Deposit Bonus on your first deposit!',
        },
      },
    },
    BONUS_CONDITION: {
      slug: 'bonus-condition',
      data: {
        meta: {
          title: 'Elcarado.com | Current Bonus offers on your deposit - 100% Deposit Bonus and more',
          description:
            'Choose a bonus on your deposit to double your cash in our online casino. For example: 100% Welcome Deposit Bonus on your first deposit!',
        },
      },
    },
    BONUS_DETAILS: {
      slug: 'bonus-details',
      data: {
        meta: {
          title: 'Bonus Details',
          description: '...',
        },
      },
    },
    CONTACT: {
      slug: 'contact',
      data: {
        meta: {
          title: 'Contact',
          description: '...',
        },
      },
    },
    STATIC_PAGE: {
      slug: 'page/:slug',
    },
    FAQ: {
      slug: 'page/faq',
      data: {
        meta: {
          title: 'Elcarado.com | Frequently asked questions',
          description: 'Here you will find answers to the frequently asked questions like the payment methods and fees.',
        },
      },
    },
    ALL_GAMES: {
      slug: 'games/:filter',
      data: {
        meta: {
          title: 'Elcarado.com | Online Casino Games, Slots, Keno, Videopoker, Black Jack and Roulette',
          description:
            'Choose now your online casino game. You have the choice between online Slots with and without freegames, Videopoker, Keno, Black Jack and Roulette.',
        },
      },
    },
    ALL_GAMES_VENDOR: {
      slug: 'games/:filter/:vendor',
      data: {
        meta: {
          title: 'Elcarado.com | Online Casino Games, Slots, Keno, Videopoker, Black Jack and Roulette',
          description:
            'Choose now your online casino game. You have the choice between online Slots with and without freegames, Videopoker, Keno, Black Jack and Roulette.',
        },
      },
    },
    ALL_GAMES_CATEGORY: {
      slug: 'games-category/:category',
      data: {
        meta: {
          title: 'Elcarado.com | Online Casino Games, Slots, Keno, Videopoker, Black Jack and Roulette',
          description:
            'Choose now your online casino game. You have the choice between online Slots with and without freegames, Videopoker, Keno, Black Jack and Roulette.',
        },
      },
    },
    ALL_GAMES_CATEGORY_VENDOR: {
      slug: 'games-category/:category/:vendor',
      data: {
        meta: {
          title: 'Elcarado.com | Online Casino Games, Slots, Keno, Videopoker, Black Jack and Roulette',
          description:
            'Choose now your online casino game. You have the choice between online Slots with and without freegames, Videopoker, Keno, Black Jack and Roulette.',
        },
      },
    },
    GAME_DETAILS: {
      slug: 'game/:slug',
      data: {
        meta: {
          title: 'Play :slug online in online casino',
          description: '...',
        },
      },
    },
    GAME: {
      slug: 'game/:slug',
      data: {
        meta: {
          title: 'Play :slug online in online casino',
          description: '...',
        },
      },
    },
    SUPPORT: {
      slug: 'support',
      data: {
        meta: {
          title: 'Your direct contact to Elcarado',
          description: 'Do you have any problems, questions or suggestions? Please get in touch with us via the contact form below.',
        },
      },
    },
    PAYMENT_METHODS: {
      slug: 'payment-methods',
      data: {
        meta: {
          title: 'Payment methods',
          description: '...',
        },
      },
    },
    RESPONSIBLE_GAMBLING: {
      slug: 'page/responsible-gambling',
      data: {
        meta: {
          title: 'Responsible Gambling',
          description: 'Responsible Gambling',
        },
      },
    },
    SAFETY: {
      slug: 'page/safety',
      data: {
        meta: {
          title: 'Safety',
          description:
            'Elcarado uses state of the art SSL/TSL* encrypting technologies in order to provide you the best and most secure gaming experience.',
        },
      },
    },
    ABOUT_US: {
      slug: 'page/about-us',
      data: {
        meta: {
          title: 'About Us',
          description: 'About Us',
        },
      },
    },
    TAC: {
      slug: 'page/tac',
      data: {
        meta: {
          title: 'TAC',
          description: 'TAC',
        },
      },
    },
    GENERAL_BONUS_TERMS: {
      slug: 'page/general-bonus-terms',
      data: {
        meta: {
          title: 'General Bonus Terms',
          description: 'General Bonus Terms',
        },
      },
    },
    PRIVACY_POLICY: {
      slug: 'page/privacy-policy',
      data: {
        meta: {
          title: 'Privacy Policy',
          description: 'Privacy Policy',
        },
      },
    },
    COOKIE_POLICY: {
      slug: 'page/cookie-policy',
      data: {
        meta: {
          title: 'Cookie Policy',
          description: 'Cookie Policy',
        },
      },
    },
    CONFIRM_EMAIL_CHANGE: {
      slug: 'confirm-email-change/:temporaryToken',
      data: {
        meta: {
          title: 'Email change (confirm)',
          description: '...',
        },
      },
    },
    PAYMENT_PROVIDERS: {
      slug: 'payment-providers',
      data: {
        meta: {
          title: 'Payment methods',
          description:
            'Payment methods in Elcarado Online Casino. Games at Elcarado. âœ“ fast & secure depost âœ“ no extra costs! Play online at Elcarado.com!',
        },
      },
    },
    YOUTH_PROTECTION: {
      slug: 'page/youth-protection',
      data: {
        meta: {
          title: 'Youth protection',
          description:
            'Gambling is prohibited for persons under the age of adolescence. Furthermore it must be ensured that the customer has full control over his property.',
        },
      },
    },
    TERMS_OF_USE: {
      slug: 'page/terms-of-use',
      data: {
        meta: {
          title: 'Terms of use',
          description: '...',
        },
      },
    },
    LANDINGPAGE: {
      slug: 'landingpage',
      data: {
        meta: {
          title: 'Landing Page',
          description: '...',
        },
      },
    },
    REGISTER: {
      slug: 'register',
      data: {
        meta: {
          title: 'Elcarado.com | Register for free in our online casino',
          description: 'Register now for free in our online casino.',
        },
      },
    },
    REGISTER_CONFIRM: {
      slug: 'register/confirm/:temporaryToken',
      data: {
        meta: {
          title: 'Register confirm',
          description: '...',
        },
      },
    },
    LOGIN: {
      slug: 'login',
      data: {
        meta: {
          title: 'Login',
          description: '...',
        },
      },
    },
    PASSWORD_RESET: {
      slug: 'password-reset',
      data: {
        meta: {
          title: 'Forgot your password? Resend activation E-mail?',
          description: 'Forgot your password? Resend activation E-mail?',
        },
      },
    },
    PASSWORD_RESET_CONFIRM: {
      slug: 'password-reset/confirm/:temporaryToken',
      data: {
        meta: {
          title: 'Password reset (confirm)',
          description: '...',
        },
      },
    },
    USER_ACCOUNT: {
      slug: 'my-profile',
      data: {
        meta: {
          title: 'My profile',
          description: '...',
        },
      },
    },
    CASHIER: {
      slug: 'cashier',
      data: {
        meta: {
          title: 'Cashier',
          description: '...',
        },
      },
    },
    SPORTSBOOK: {
      slug: 'sportsbook',
      data: {
        meta: {
          title: 'Sportsbook',
          description: '...',
        },
      },
    },
    LOGIN_WITH_EMAIL: {
      slug: 'login/:email',
      data: {
        meta: {
          title: 'Login',
          description: '...',
        },
      },
    },
    LOGOUT: {
      slug: 'logout',
      data: {
        meta: {
          title: 'Logout',
          description: '...',
        },
      },
    },
    ACCOUNT_ACTIVATION: {
      slug: 'account-activation',
      data: {
        meta: {
          title: 'Account activation',
          description: '...',
        },
      },
    },
    RESTRICTED_AREA: {
      slug: 'full-access-restricted',
      data: {
        meta: {
          title: 'Full access required',
        },
      },
    },
    ACCOUNT_FROZEN: {
      slug: 'cashier?tab=documents',
      data: {
        meta: {
          title: 'Account frozen',
        },
      },
    },
    NOT_FOUND: {
      data: {
        meta: {
          title: '404 - Page not found',
        },
      },
    },
  },
  BR: {
    START: {
      slug: 'dashboard',
      data: {
        meta: {
          title: 'Online casino',
          description: 'elcarado.com - Your online casino - slots and live dealer casino',
        },
      },
    },
    LIVE_CASINO: {
      slug: 'live-casino',
      data: {
        meta: {
          title: 'Live casino',
          description: '...',
        },
      },
    },
    BONUS: {
      slug: 'bonus',
      data: {
        meta: {
          title: 'Elcarado.com | Current Bonus offers on your deposit - 100% Deposit Bonus and more',
          description:
            'Choose a bonus on your deposit to double your cash in our online casino. For example: 100% Welcome Deposit Bonus on your first deposit!',
        },
      },
    },
    BONUS_CONDITION: {
      slug: 'bonus-condition',
      data: {
        meta: {
          title: 'Elcarado.com | Current Bonus offers on your deposit - 100% Deposit Bonus and more',
          description:
            'Choose a bonus on your deposit to double your cash in our online casino. For example: 100% Welcome Deposit Bonus on your first deposit!',
        },
      },
    },
    BONUS_DETAILS: {
      slug: 'bonus-details',
      data: {
        meta: {
          title: 'Bonus Details',
          description: '...',
        },
      },
    },
    CONTACT: {
      slug: 'contact',
      data: {
        meta: {
          title: 'Contact',
          description: '...',
        },
      },
    },
    STATIC_PAGE: {
      slug: 'page/:slug',
    },
    FAQ: {
      slug: 'page/faq',
      data: {
        meta: {
          title: 'Elcarado.com | Frequently asked questions',
          description: 'Here you will find answers to the frequently asked questions like the payment methods and fees.',
        },
      },
    },
    ALL_GAMES: {
      slug: 'jogos-de-cassino-online/:filter',
      data: {
        meta: {
          title: 'Elcarado.com | Online Casino Games, Slots, Keno, Videopoker, Black Jack and Roulette',
          description:
            'Choose now your online casino game. You have the choice between online Slots with and without freegames, Videopoker, Keno, Black Jack and Roulette.',
        },
      },
    },
    ALL_GAMES_VENDOR: {
      slug: 'jogos-de-cassino-online/:filter/:vendor',
      data: {
        meta: {
          title: 'Elcarado.com | Online Casino Games, Slots, Keno, Videopoker, Black Jack and Roulette',
          description:
            'Choose now your online casino game. You have the choice between online Slots with and without freegames, Videopoker, Keno, Black Jack and Roulette.',
        },
      },
    },
    ALL_GAMES_CATEGORY: {
      slug: 'games-category/:category',
      data: {
        meta: {
          title: 'Elcarado.com | Online Casino Games, Slots, Keno, Videopoker, Black Jack and Roulette',
          description:
            'Choose now your online casino game. You have the choice between online Slots with and without freegames, Videopoker, Keno, Black Jack and Roulette.',
        },
      },
    },
    ALL_GAMES_CATEGORY_VENDOR: {
      slug: 'games-category/:category/:vendor',
      data: {
        meta: {
          title: 'Elcarado.com | Online Casino Games, Slots, Keno, Videopoker, Black Jack and Roulette',
          description:
            'Choose now your online casino game. You have the choice between online Slots with and without freegames, Videopoker, Keno, Black Jack and Roulette.',
        },
      },
    },
    GAME_DETAILS: {
      slug: 'jogos/detalhes/:slug',
      data: {
        meta: {
          title: 'Play :slug online in online casino',
          description: '...',
        },
      },
    },
    GAME: {
      slug: 'jogos/detalhes/:slug',
      data: {
        meta: {
          title: 'Play :slug online in online casino',
          description: '...',
        },
      },
    },
    SUPPORT: {
      slug: 'support',
      data: {
        meta: {
          title: 'Your direct contact to Elcarado',
          description: 'Do you have any problems, questions or suggestions? Please get in touch with us via the contact form below.',
        },
      },
    },
    PAYMENT_METHODS: {
      slug: 'payment-methods',
      data: {
        meta: {
          title: 'Payment methods',
          description: '...',
        },
      },
    },
    RESPONSIBLE_GAMBLING: {
      slug: 'page/responsible-gambling',
      data: {
        meta: {
          title: 'Responsible Gambling',
          description: 'Responsible Gambling',
        },
      },
    },
    SAFETY: {
      slug: 'page/safety',
      data: {
        meta: {
          title: 'Safety',
          description:
            'Elcarado uses state of the art SSL/TSL* encrypting technologies in order to provide you the best and most secure gaming experience.',
        },
      },
    },
    ABOUT_US: {
      slug: 'page/about-us',
      data: {
        meta: {
          title: 'About Us',
          description: 'About Us',
        },
      },
    },
    TAC: {
      slug: 'page/termos-e-condicoes',
      data: {
        meta: {
          title: 'TAC',
          description: 'TAC',
        },
      },
    },
    GENERAL_BONUS_TERMS: {
      slug: 'page/yleiset-bonusehdot',
      data: {
        meta: {
          title: 'General Bonus Terms',
          description: 'General Bonus Terms',
        },
      },
    },
    PRIVACY_POLICY: {
      slug: 'page/protecao-de-dados',
      data: {
        meta: {
          title: 'Privacy Policy',
          description: 'Privacy Policy',
        },
      },
    },
    COOKIE_POLICY: {
      slug: 'page/cookie-policy',
      data: {
        meta: {
          title: 'Cookie Policy',
          description: 'Cookie Policy',
        },
      },
    },
    CONFIRM_EMAIL_CHANGE: {
      slug: 'confirmar-email-novo/:temporaryToken',
      data: {
        meta: {
          title: 'Email change (confirm)',
          description: '...',
        },
      },
    },
    PAYMENT_PROVIDERS: {
      slug: 'servicos-de-pagamento',
      data: {
        meta: {
          title: 'Payment methods',
          description:
            'Payment methods in Elcarado Online Casino. Games at Elcarado. âœ“ fast & secure depost âœ“ no extra costs! Play online at Elcarado.com!',
        },
      },
    },
    YOUTH_PROTECTION: {
      slug: 'page/protecao-a-juventude',
      data: {
        meta: {
          title: 'Youth protection',
          description:
            'Gambling is prohibited for persons under the age of adolescence. Furthermore it must be ensured that the customer has full control over his property.',
        },
      },
    },
    TERMS_OF_USE: {
      slug: 'page/terms-of-use',
      data: {
        meta: {
          title: 'Terms of use',
          description: '...',
        },
      },
    },
    LANDINGPAGE: {
      slug: 'landingpage',
      data: {
        meta: {
          title: 'Landing Page',
          description: '...',
        },
      },
    },
    REGISTER: {
      slug: 'cadastro',
      data: {
        meta: {
          title: 'Elcarado.com | Register for free in our online casino',
          description: 'Register now for free in our online casino.',
        },
      },
    },
    REGISTER_CONFIRM: {
      slug: 'cadastro/confirmacao/:temporaryToken',
      data: {
        meta: {
          title: 'Register confirm',
          description: '...',
        },
      },
    },
    LOGIN: {
      slug: 'login',
      data: {
        meta: {
          title: 'Login',
          description: '...',
        },
      },
    },
    PASSWORD_RESET: {
      slug: 'redefinindo-senha',
      data: {
        meta: {
          title: 'Forgot your password? Resend activation E-mail?',
          description: 'Forgot your password? Resend activation E-mail?',
        },
      },
    },
    PASSWORD_RESET_CONFIRM: {
      slug: 'redefinindo-senha/redefinir/:temporaryToken',
      data: {
        meta: {
          title: 'Password reset (confirm)',
          description: '...',
        },
      },
    },
    USER_ACCOUNT: {
      slug: 'my-profile',
      data: {
        meta: {
          title: 'My profile',
          description: '...',
        },
      },
    },
    CASHIER: {
      slug: 'deposito',
      data: {
        meta: {
          title: 'Deposito',
          description: '...',
        },
      },
    },
    SPORTSBOOK: {
      slug: 'sportsbook',
      data: {
        meta: {
          title: 'Sportsbook',
          description: '...',
        },
      },
    },
    LOGIN_WITH_EMAIL: {
      slug: 'login/:email',
      data: {
        meta: {
          title: 'Login',
          description: '...',
        },
      },
    },
    LOGOUT: {
      slug: 'logout',
      data: {
        meta: {
          title: 'Logout',
          description: '...',
        },
      },
    },
    ACCOUNT_ACTIVATION: {
      slug: 'account-activation',
      data: {
        meta: {
          title: 'Account activation',
          description: '...',
        },
      },
    },
    RESTRICTED_AREA: {
      slug: 'acesso-restrito',
      data: {
        meta: {
          title: 'Full access required',
        },
      },
    },
    ACCOUNT_FROZEN: {
      slug: 'limites?tab=documents',
      data: {
        meta: {
          title: 'Account frozen',
        },
      },
    },
    NOT_FOUND: {
      data: {
        meta: {
          title: '404 - Page not found',
        },
      },
    },
  },
  FI: {
    START: {
      slug: 'dashboard',
      data: {
        meta: {
          title: 'Dashboard',
          description: '...',
        },
      },
    },
    LIVE_CASINO: {
      slug: 'live-casino',
      data: {
        meta: {
          title: 'live-casino',
          description: '...',
        },
      },
    },
    BONUS: {
      slug: 'bonus',
      data: {
        meta: {
          title: 'Bonus',
          description: '...',
        },
      },
    },
    BONUS_CONDITION: {
      slug: 'bonus-condition',
      data: {
        meta: {
          title: 'bonus-condition',
          description: '...',
        },
      },
    },
    BONUS_DETAILS: {
      slug: 'bonus-details',
      data: {
        meta: {
          title: 'bonus-details',
          description: '...',
        },
      },
    },
    CONTACT: {
      slug: 'contact',
      data: {
        meta: {
          title: 'contact',
          description: '...',
        },
      },
    },
    STATIC_PAGE: {
      slug: 'page/:slug',
    },
    FAQ: {
      slug: 'page/faq',
      data: {
        meta: {
          title: 'faq',
          description: '...',
        },
      },
    },
    ALL_GAMES: {
      slug: 'games/:filter',
      data: {
        meta: {
          title: 'Elcarado.com | Online Casino Games, Slots, Keno, Videopoker, Black Jack and Roulette',
          description:
            'Choose now your online casino game. You have the choice between online Slots with and without freegames, Videopoker, Keno, Black Jack and Roulette.',
        },
      },
    },
    ALL_GAMES_VENDOR: {
      slug: 'games/:filter/:vendor',
      data: {
        meta: {
          title: 'Elcarado.com | Online Casino Games, Slots, Keno, Videopoker, Black Jack and Roulette',
          description:
            'Choose now your online casino game. You have the choice between online Slots with and without freegames, Videopoker, Keno, Black Jack and Roulette.',
        },
      },
    },
    ALL_GAMES_CATEGORY: {
      slug: 'games-category/:category',
      data: {
        meta: {
          title: 'Elcarado.com | Online Casino Games, Slots, Keno, Videopoker, Black Jack and Roulette',
          description:
            'Choose now your online casino game. You have the choice between online Slots with and without freegames, Videopoker, Keno, Black Jack and Roulette.',
        },
      },
    },
    ALL_GAMES_CATEGORY_VENDOR: {
      slug: 'games-category/:category/:vendor',
      data: {
        meta: {
          title: 'Elcarado.com | Online Casino Games, Slots, Keno, Videopoker, Black Jack and Roulette',
          description:
            'Choose now your online casino game. You have the choice between online Slots with and without freegames, Videopoker, Keno, Black Jack and Roulette.',
        },
      },
    },
    GAME_DETAILS: {
      slug: 'game/:slug',
      data: {
        meta: {
          title: 'game :slug',
          description: '...',
        },
      },
    },
    GAME: {
      slug: 'game/:slug',
      data: {
        meta: {
          title: 'game :slug',
          description: '...',
        },
      },
    },
    SUPPORT: {
      slug: 'support',
      data: {
        meta: {
          title: 'support',
          description: '...',
        },
      },
    },
    PAYMENT_METHODS: {
      slug: 'payment-methods',
      data: {
        meta: {
          title: 'payment-methods',
          description: '...',
        },
      },
    },
    RESPONSIBLE_GAMBLING: {
      slug: 'page/responsible-gambling',
      data: {
        meta: {
          title: 'Vastuullinen pelaaminen',
          description: '...',
        },
      },
    },
    SAFETY: {
      slug: 'page/safety',
      data: {
        meta: {
          title: 'safety',
          description: '...',
        },
      },
    },
    ABOUT_US: {
      slug: 'page/about-us',
      data: {
        meta: {
          title: 'about-us',
          description: '...',
        },
      },
    },
    TAC: {
      slug: 'page/tac',
      data: {
        meta: {
          title: 'tac',
          description: '...',
        },
      },
    },
    GENERAL_BONUS_TERMS: {
      slug: 'page/general-bonus-terms',
      data: {
        meta: {
          title: 'Yleiset bonusehdot',
          description: 'Yleiset bonusehdot',
        },
      },
    },
    PRIVACY_POLICY: {
      slug: 'page/privacy-policy',
      data: {
        meta: {
          title: 'privacy-policy',
          description: '...',
        },
      },
    },
    COOKIE_POLICY: {
      slug: 'page/cookie-policy',
      data: {
        meta: {
          title: 'Cookie Policy',
          description: 'Cookie Policy',
        },
      },
    },
    CONFIRM_EMAIL_CHANGE: {
      slug: 'confirm-email-change/:temporaryToken',
      data: {
        meta: {
          title: 'Email change (confirm)',
          description: '...',
        },
      },
    },
    PAYMENT_PROVIDERS: {
      slug: 'payment-providers',
      data: {
        meta: {
          title: 'payment-providers',
          description: '...',
        },
      },
    },
    YOUTH_PROTECTION: {
      slug: 'page/youth-protection',
      data: {
        meta: {
          title: 'youth-protection',
          description: '...',
        },
      },
    },
    TERMS_OF_USE: {
      slug: 'page/terms-of-use',
      data: {
        meta: {
          title: 'terms-of-use',
          description: '...',
        },
      },
    },
    LANDINGPAGE: {
      slug: 'landingpage',
      data: {
        meta: {
          title: 'Landing Page',
          description: '...',
        },
      },
    },
    REGISTER: {
      slug: 'register',
      data: {
        meta: {
          title: 'register',
          description: '...',
        },
      },
    },
    REGISTER_CONFIRM: {
      slug: 'register/confirm/:temporaryToken',
      data: {
        meta: {
          title: 'register confirm',
          description: '...',
        },
      },
    },
    LOGIN: {
      slug: 'login',
      data: {
        meta: {
          title: 'login',
          description: '...',
        },
      },
    },
    PASSWORD_RESET: {
      slug: 'password-reset',
      data: {
        meta: {
          title: 'password-reset',
          description: '...',
        },
      },
    },
    PASSWORD_RESET_CONFIRM: {
      slug: 'password-reset/confirm/:temporaryToken',
      data: {
        meta: {
          title: 'password-reset (confirm)',
          description: '...',
        },
      },
    },
    USER_ACCOUNT: {
      slug: 'my-profile',
      data: {
        meta: {
          title: 'my-profile',
          description: '...',
        },
      },
    },
    CASHIER: {
      slug: 'cashier',
      data: {
        meta: {
          title: 'cashier',
          description: '...',
        },
      },
    },
    SPORTSBOOK: {
      slug: 'sportsbook',
      data: {
        meta: {
          title: 'Sportsbook',
          description: '...',
        },
      },
    },
    LOGIN_WITH_EMAIL: {
      slug: 'login/:email',
      data: {
        meta: {
          title: 'login',
          description: '...',
        },
      },
    },
    LOGOUT: {
      slug: 'logout',
      data: {
        meta: {
          title: 'logout',
          description: '...',
        },
      },
    },
    ACCOUNT_ACTIVATION: {
      slug: 'account-activation',
      data: {
        meta: {
          title: '[fi] Account activation',
          description: '...',
        },
      },
    },
    RESTRICTED_AREA: {
      slug: 'full-access-restricted',
      data: {
        meta: {
          title: 'Full access required',
        },
      },
    },
    ACCOUNT_FROZEN: {
      slug: 'account-frozen',
      data: {
        meta: {
          title: 'Account frozen',
        },
      },
    },
    NOT_FOUND: {
      data: {
        meta: {
          title: '404 - Page not found',
        },
      },
    },
  },
  SE: {
    START: {
      slug: 'dashboard',
      data: {
        meta: {
          title: 'Dashboard',
          description: '...',
        },
      },
    },
    LIVE_CASINO: {
      slug: 'live-casino',
      data: {
        meta: {
          title: 'live-casino',
          description: '...',
        },
      },
    },
    BONUS: {
      slug: 'bonus',
      data: {
        meta: {
          title: 'Bonus',
          description: '...',
        },
      },
    },
    BONUS_CONDITION: {
      slug: 'bonus-condition',
      data: {
        meta: {
          title: 'bonus-condition',
          description: '...',
        },
      },
    },
    CONTACT: {
      slug: 'contact',
      data: {
        meta: {
          title: 'contact',
          description: '...',
        },
      },
    },
    STATIC_PAGE: {
      slug: 'page/:slug',
    },
    FAQ: {
      slug: 'page/faq',
      data: {
        meta: {
          title: 'faq',
          description: '...',
        },
      },
    },
    ALL_GAMES: {
      slug: 'games/:filter',
      data: {
        meta: {
          title: 'Elcarado.com | Online Casino Games, Slots, Keno, Videopoker, Black Jack and Roulette',
          description:
            'Choose now your online casino game. You have the choice between online Slots with and without freegames, Videopoker, Keno, Black Jack and Roulette.',
        },
      },
    },
    ALL_GAMES_VENDOR: {
      slug: 'games/:filter/:vendor',
      data: {
        meta: {
          title: 'Elcarado.com | Online Casino Games, Slots, Keno, Videopoker, Black Jack and Roulette',
          description:
            'Choose now your online casino game. You have the choice between online Slots with and without freegames, Videopoker, Keno, Black Jack and Roulette.',
        },
      },
    },
    ALL_GAMES_CATEGORY: {
      slug: 'games-category/:category',
      data: {
        meta: {
          title: 'Elcarado.com | Online Casino Games, Slots, Keno, Videopoker, Black Jack and Roulette',
          description:
            'Choose now your online casino game. You have the choice between online Slots with and without freegames, Videopoker, Keno, Black Jack and Roulette.',
        },
      },
    },
    ALL_GAMES_CATEGORY_VENDOR: {
      slug: 'games-category/:category/:vendor',
      data: {
        meta: {
          title: 'Elcarado.com | Online Casino Games, Slots, Keno, Videopoker, Black Jack and Roulette',
          description:
            'Choose now your online casino game. You have the choice between online Slots with and without freegames, Videopoker, Keno, Black Jack and Roulette.',
        },
      },
    },
    GAME_DETAILS: {
      slug: 'game/:slug',
      data: {
        meta: {
          title: 'game :slug',
          description: '...',
        },
      },
    },
    GAME: {
      slug: 'game/:slug',
      data: {
        meta: {
          title: 'game :slug',
          description: '...',
        },
      },
    },
    SUPPORT: {
      slug: 'support',
      data: {
        meta: {
          title: 'support',
          description: '...',
        },
      },
    },
    PAYMENT_METHODS: {
      slug: 'payment-methods',
      data: {
        meta: {
          title: 'payment-methods',
          description: '...',
        },
      },
    },
    RESPONSIBLE_GAMBLING: {
      slug: 'page/responsible-gambling',
      data: {
        meta: {
          title: 'responsible-gambling',
          description: '...',
        },
      },
    },
    SAFETY: {
      slug: 'page/safety',
      data: {
        meta: {
          title: 'safety',
          description: '...',
        },
      },
    },
    ABOUT_US: {
      slug: 'page/about-us',
      data: {
        meta: {
          title: 'about-us',
          description: '...',
        },
      },
    },
    TAC: {
      slug: 'page/tac',
      data: {
        meta: {
          title: 'tac',
          description: '...',
        },
      },
    },
    PRIVACY_POLICY: {
      slug: 'page/privacy-policy',
      data: {
        meta: {
          title: 'privacy-policy',
          description: '...',
        },
      },
    },
    COOKIE_POLICY: {
      slug: 'page/cookie-policy',
      data: {
        meta: {
          title: 'Cookie Policy',
          description: 'Cookie Policy',
        },
      },
    },
    CONFIRM_EMAIL_CHANGE: {
      slug: 'confirm-email-change/:temporaryToken',
      data: {
        meta: {
          title: 'Email change (confirm)',
          description: '...',
        },
      },
    },
    PAYMENT_PROVIDERS: {
      slug: 'payment-providers',
      data: {
        meta: {
          title: 'payment-providers',
          description: '...',
        },
      },
    },
    YOUTH_PROTECTION: {
      slug: 'page/youth-protection',
      data: {
        meta: {
          title: 'youth-protection',
          description: '...',
        },
      },
    },
    TERMS_OF_USE: {
      slug: 'page/terms-of-use',
      data: {
        meta: {
          title: 'terms-of-use',
          description: '...',
        },
      },
    },
    LANDINGPAGE: {
      slug: 'landingpage',
      data: {
        meta: {
          title: 'Landing Page',
          description: '...',
        },
      },
    },
    REGISTER: {
      slug: 'register',
      data: {
        meta: {
          title: 'register',
          description: '...',
        },
      },
    },
    REGISTER_CONFIRM: {
      slug: 'register/confirm/:temporaryToken',
      data: {
        meta: {
          title: 'register confirm',
          description: '...',
        },
      },
    },
    LOGIN: {
      slug: 'login',
      data: {
        meta: {
          title: 'login',
          description: '...',
        },
      },
    },
    PASSWORD_RESET: {
      slug: 'password-reset',
      data: {
        meta: {
          title: 'password-reset',
          description: '...',
        },
      },
    },
    PASSWORD_RESET_CONFIRM: {
      slug: 'password-reset/confirm/:temporaryToken',
      data: {
        meta: {
          title: 'password-reset (confirm)',
          description: '...',
        },
      },
    },

    USER_ACCOUNT: {
      slug: 'my-profile',
      data: {
        meta: {
          title: 'my-profile',
          description: '...',
        },
      },
    },
    CASHIER: {
      slug: 'cashier',
      data: {
        meta: {
          title: 'cashier',
          description: '...',
        },
      },
    },
    SPORTSBOOK: {
      slug: 'sportsbook',
      data: {
        meta: {
          title: 'Sportsbook',
          description: '...',
        },
      },
    },
    LOGIN_WITH_EMAIL: {
      slug: 'login/:email',
      data: {
        meta: {
          title: 'login',
          description: '...',
        },
      },
    },
    LOGOUT: {
      slug: 'logout',
      data: {
        meta: {
          title: 'logout',
          description: '...',
        },
      },
    },
    ACCOUNT_ACTIVATION: {
      slug: 'account-activation',
      data: {
        meta: {
          title: '[se] Account activation',
          description: '...',
        },
      },
    },
    RESTRICTED_AREA: {
      slug: 'full-access-restricted',
      data: {
        meta: {
          title: 'Full access required',
        },
      },
    },
    ACCOUNT_FROZEN: {
      slug: 'account-frozen',
      data: {
        meta: {
          title: 'Account frozen',
        },
      },
    },
    NOT_FOUND: {
      data: {
        meta: {
          title: '404 - Page not found',
        },
      },
    },
  },
  NO: {
    START: {
      slug: 'dashboard',
      data: {
        meta: {
          title: 'Dashboard',
          description: '...',
        },
      },
    },
    LIVE_CASINO: {
      slug: 'live-casino',
      data: {
        meta: {
          title: 'live-casino',
          description: '...',
        },
      },
    },
    BONUS: {
      slug: 'bonus',
      data: {
        meta: {
          title: 'Bonus',
          description: '...',
        },
      },
    },
    BONUS_CONDITION: {
      slug: 'bonus-condition',
      data: {
        meta: {
          title: 'bonus-condition',
          description: '...',
        },
      },
    },
    CONTACT: {
      slug: 'contact',
      data: {
        meta: {
          title: 'contact',
          description: '...',
        },
      },
    },
    STATIC_PAGE: {
      slug: 'page/:slug',
    },
    FAQ: {
      slug: 'page/faq',
      data: {
        meta: {
          title: 'faq',
          description: '...',
        },
      },
    },
    ALL_GAMES: {
      slug: 'games/:filter',
      data: {
        meta: {
          title: 'Elcarado.com | Online Casino Games, Slots, Keno, Videopoker, Black Jack and Roulette',
          description:
            'Choose now your online casino game. You have the choice between online Slots with and without freegames, Videopoker, Keno, Black Jack and Roulette.',
        },
      },
    },
    ALL_GAMES_VENDOR: {
      slug: 'games/:filter/:vendor',
      data: {
        meta: {
          title: 'Elcarado.com | Online Casino Games, Slots, Keno, Videopoker, Black Jack and Roulette',
          description:
            'Choose now your online casino game. You have the choice between online Slots with and without freegames, Videopoker, Keno, Black Jack and Roulette.',
        },
      },
    },
    ALL_GAMES_CATEGORY: {
      slug: 'games-category/:category',
      data: {
        meta: {
          title: 'Elcarado.com | Online Casino Games, Slots, Keno, Videopoker, Black Jack and Roulette',
          description:
            'Choose now your online casino game. You have the choice between online Slots with and without freegames, Videopoker, Keno, Black Jack and Roulette.',
        },
      },
    },
    ALL_GAMES_CATEGORY_VENDOR: {
      slug: 'games-category/:category/:vendor',
      data: {
        meta: {
          title: 'Elcarado.com | Online Casino Games, Slots, Keno, Videopoker, Black Jack and Roulette',
          description:
            'Choose now your online casino game. You have the choice between online Slots with and without freegames, Videopoker, Keno, Black Jack and Roulette.',
        },
      },
    },
    GAME_DETAILS: {
      slug: 'game/:slug',
      data: {
        meta: {
          title: 'game :slug',
          description: '...',
        },
      },
    },
    GAME: {
      slug: 'game/:slug',
      data: {
        meta: {
          title: 'game :slug',
          description: '...',
        },
      },
    },
    SUPPORT: {
      slug: 'support',
      data: {
        meta: {
          title: 'support',
          description: '...',
        },
      },
    },
    PAYMENT_METHODS: {
      slug: 'payment-methods',
      data: {
        meta: {
          title: 'payment-methods',
          description: '...',
        },
      },
    },
    RESPONSIBLE_GAMBLING: {
      slug: 'page/responsible-gambling',
      data: {
        meta: {
          title: 'responsible-gambling',
          description: '...',
        },
      },
    },
    SAFETY: {
      slug: 'page/safety',
      data: {
        meta: {
          title: 'safety',
          description: '...',
        },
      },
    },
    ABOUT_US: {
      slug: 'page/about-us',
      data: {
        meta: {
          title: 'about-us',
          description: '...',
        },
      },
    },
    TAC: {
      slug: 'page/tac',
      data: {
        meta: {
          title: 'tac',
          description: '...',
        },
      },
    },
    PRIVACY_POLICY: {
      slug: 'page/privacy-policy',
      data: {
        meta: {
          title: 'privacy-policy',
          description: '...',
        },
      },
    },
    COOKIE_POLICY: {
      slug: 'page/cookie-policy',
      data: {
        meta: {
          title: 'Cookie Policy',
          description: 'Cookie Policy',
        },
      },
    },
    CONFIRM_EMAIL_CHANGE: {
      slug: 'confirm-email-change/:temporaryToken',
      data: {
        meta: {
          title: 'Email change (confirm)',
          description: '...',
        },
      },
    },
    PAYMENT_PROVIDERS: {
      slug: 'payment-providers',
      data: {
        meta: {
          title: 'payment-providers',
          description: '...',
        },
      },
    },
    YOUTH_PROTECTION: {
      slug: 'page/youth-protection',
      data: {
        meta: {
          title: 'youth-protection',
          description: '...',
        },
      },
    },
    TERMS_OF_USE: {
      slug: 'page/terms-of-use',
      data: {
        meta: {
          title: 'terms-of-use',
          description: '...',
        },
      },
    },
    LANDINGPAGE: {
      slug: 'landingpage',
      data: {
        meta: {
          title: 'Landing Page',
          description: '...',
        },
      },
    },
    REGISTER: {
      slug: 'register',
      data: {
        meta: {
          title: 'register',
          description: '...',
        },
      },
    },
    REGISTER_CONFIRM: {
      slug: 'register/confirm/:temporaryToken',
      data: {
        meta: {
          title: 'register confirm',
          description: '...',
        },
      },
    },
    LOGIN: {
      slug: 'login',
      data: {
        meta: {
          title: 'login',
          description: '...',
        },
      },
    },
    PASSWORD_RESET: {
      slug: 'password-reset',
      data: {
        meta: {
          title: 'password-reset',
          description: '...',
        },
      },
    },
    PASSWORD_RESET_CONFIRM: {
      slug: 'password-reset/confirm/:temporaryToken',
      data: {
        meta: {
          title: 'password-reset (confirm)',
          description: '...',
        },
      },
    },

    USER_ACCOUNT: {
      slug: 'my-profile',
      data: {
        meta: {
          title: 'my-profile',
          description: '...',
        },
      },
    },
    CASHIER: {
      slug: 'cashier',
      data: {
        meta: {
          title: 'cashier',
          description: '...',
        },
      },
    },
    SPORTSBOOK: {
      slug: 'sportsbook',
      data: {
        meta: {
          title: 'Sportsbook',
          description: '...',
        },
      },
    },
    LOGIN_WITH_EMAIL: {
      slug: 'login/:email',
      data: {
        meta: {
          title: 'login',
          description: '...',
        },
      },
    },
    LOGOUT: {
      slug: 'logout',
      data: {
        meta: {
          title: 'logout',
          description: '...',
        },
      },
    },
    ACCOUNT_ACTIVATION: {
      slug: 'account-activation',
      data: {
        meta: {
          title: '[no] Account activation',
          description: '...',
        },
      },
    },
    RESTRICTED_AREA: {
      slug: 'full-access-restricted',
      data: {
        meta: {
          title: 'Full access required',
        },
      },
    },
    ACCOUNT_FROZEN: {
      slug: 'account-frozen',
      data: {
        meta: {
          title: 'Account frozen',
        },
      },
    },
    NOT_FOUND: {
      data: {
        meta: {
          title: '404 - Page not found',
        },
      },
    },
  },
};

