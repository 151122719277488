<!--<div class="container container--noVerticalPadding container--darker">
	<h1 class="big withoutStar">LandingPage - data</h1>
</div>-->

<div class="container container--landingPage container container--noVerticalPadding">
  <div
    class="loaderContainer"
    #loaderContainer
    attr.data-text="{{ 'LAYOUT.LOADING_LABEL' | translate }}">
      <span class="loader"></span>
  </div>
  <div class="panelMask">
    <div
      app-register-modal
      class="register__dropdown"
      [class.active]="registerModalVisible"
      (close)="registerModalClose()"
      [loginHidden]="true"
    ></div>
  </div>

  <div class="lp-header">
    <div class="lp-header__logo">
      <p>ElCarado logo</p>
    </div>
    <div class="lp-header__text">
      <h1>{{ 'LANDING_PAGE.HEADER.TITLE' | translate }}</h1>
      <h2>{{ 'LANDING_PAGE.HEADER.SUBTITLE' | translate }}</h2>
    </div>
    <div class="lp-header__logos visible-only-desktop">
      <img src="/assets/images/ssl.png" alt="ssl" />
<!--      <img src="/assets/images/mga.png" alt="mga" />-->
      <img src="/assets/images/fast-payments.png" alt="fase payments" />
    </div>
  </div>
  <div class="lp-content">
    <div class="lp-content__elements">
      <div class="lp-content__texts">
        <h3>{{ landingPage.title }}</h3>
        <h4>
          <span>{{ landingPage.bonusAmount }}%</span>&nbsp;{{ 'LANDING_PAGE.BODY.DEPOSIT_BONUS_LABEL' | translate }}<br />
          <span>+{{ landingPage.freeSpinAmount }}</span>&nbsp;{{ 'LANDING_PAGE.BODY.FREE_SPINS_LABEL' | translate }}
        </h4>
      </div>
      <div class="lp-content__buttons">
        <a class="button" (click)="registerModalOpen()">{{ 'LANDING_PAGE.BODY.REGISTER_BUTTON' | translate }}</a>
        <a class="button button-blue" (click)="facebookRegister()" [attr.disabled]="facebookLogin$ || facebookRegister$ || userDetails$">{{ 'LANDING_PAGE.BODY.FB_BUTTON' | translate }}</a>
      </div>
    </div>
    <img
      src="/assets/images/landing-page/trustly_white.png"
      alt="Trustly"
      class="lp-content__image visible-only-desktop"
    />
    <img
      src="{{landingPage.backgroundImageDesktop}}"
      class="lp-content__bg visible-only-desktop"
      alt="Landing page"
      width="100%"
    />
    <img
      src="{{landingPage.backgroundImageMobile}}"
      class="lp-content__bg visible-only-mobile"
      alt="Landing page"
      width="100%"
    />
  </div>
  <div class="lp-steps">
    <ul>
      <li>
        <p>{{ 'LANDING_PAGE.STEPS.STEP_DESC' | translate }}</p>
      </li>
      <li class="lp-steps__step">
        <p>{{ 'LANDING_PAGE.STEPS.STEP_1' | translate }}</p>
      </li>
      <li class="lp-steps__step">
        <p>{{ 'LANDING_PAGE.STEPS.STEP_2' | translate }}</p>
      </li>
      <li class="lp-steps__step">
        <p>{{ 'LANDING_PAGE.STEPS.STEP_3' | translate }}</p>
      </li>
    </ul>
  </div>

  <!-- Payment providers, Vendors -->

	<div class="lp-steps__licenseBar">
		<div class="licenseBar">
	        <div class="licenseBarContainer">
	            <div class="licenseBar__element licenseBar__element-crests">
	                <div class="licenseBar__element-row payments">
                    <slider *ngIf="licenseBarVendorLogos" class="carousel paymentsSlider" [optionsResolver]="paymentsSliderOptionsResolver">
                      <ng-container *ngFor="let logo of licenseBarPaymentLogos">
                          <div *slide-item>
                              <div class="licenseBar__element-image">
                                  <a [routerLink]="navigationService.constructUrl(logo.pageUrl)"
                                      [queryParams]="logo.pageUrlData">
                                      <img [src]="logo.img" [alt]="logo.pageUrlData.type" [class]="logo.pageUrlData.type">
                                  </a>
                              </div>
                          </div>
                      </ng-container>
                    </slider>
	                </div>
	                <div class="licenseBar__element-row vendors">
                    <slider *ngIf="licenseBarVendorLogos" class="carousel paymentsSlider" [optionsResolver]="vendorsSliderOptionsResolver">
                      <ng-container *ngFor="let logo of licenseBarVendorLogos">
                          <div *slide-item>
                              <div class="licenseBar__element-image" >
                                  <a [routerLink]="navigationService.constructUrl(logo.pageUrl, logo.pageUrlData)">
                                      <img [src]="logo.img" [alt]="logo.pageUrlData.vendor" [class]="logo.pageUrlData.vendor">
                                  </a>
                              </div>
                          </div>
                      </ng-container>
                    </slider>
	                </div>
	            </div>
	        </div>
	    </div>
	</div>


  <!-- bottom footer -->
  <div class="footer container container--withoutImage container--last">
    <div class="columnContainer__column footerCopyright">
      <div class="footerCopyright__item footerCopyright__item--left">
        <div class="footerLogos">
<!--          <div class="footerLogos__item">-->
<!--            <a-->
<!--              href="https://authorisation.mga.org.mt/verification.aspx?lang=en&company=66fea165-070a-4cfa-bbb1-809c044196cc"-->
<!--              target="_blank"-->
<!--            >-->
<!--              <img-->
<!--                src="assets/images/footer-color-logo1.png"-->
<!--                alt="mga licensed"-->
<!--              />-->
<!--            </a>-->
<!--          </div>-->
          <div class="footerLogos__item">
            <a
              *ngIf="!userAuthenticated"
              [routerLink]="navigationUrl('RESPONSIBLE_GAMBLING')"
            >
              <img
                src="assets/images/badges/responsiblegaming.png"
                alt="Responsible Gaming"
              />
            </a>
            <a
              *ngIf="userAuthenticated"
              [routerLink]="navigationUrl('CASHIER')"
              [queryParams]="{ tab: 'limits' }"
            >
              <img
                src="assets/images/badges/responsiblegaming.png"
                alt="Responsible Gaming"
              />
            </a>
          </div>
        </div>
      </div>

      <div class="footerCopyright__item footerCopyright__item--center">
        <div [innerHtml]="'LAYOUT.FOOTER_INFO.FIRST_CONTENT' | translate"></div>
        <p class="applicationVersion">
          Application v. {{ applicationVersion }}.
        </p>
      </div>

      <div
        class="footerCopyright__item footerCopyright__item--right"
        [innerHtml]="'LAYOUT.FOOTER_INFO.SECOND_CONTENT' | translate"
      ></div>
    </div>
  </div>
</div>
