<div class="container">
    <h1 class="bottomDecorator">
    <span class="decorator">
      <i *ngIf="!translationKeyImage" [ngClass]="categoryIcon"></i>
      <img *ngIf="translationKeyImage" class="header-custom-img" src="{{ translationKeyImage }}"
           alt="{{ translationKeyTitle | translate }}"/>
    </span> {{ translationKeyTitle | translate }}
    </h1>

    <div class="gameFilterBar gameFilterBar--vendor" *ngIf="vendors?.length > 0 && filterValue != 'livecasino'">
        <div class="gameFilterBar__item">
            <p *ngIf="!isMobile">{{ 'ALL_GAMES.SELECT_VENDOR' | translate }}: </p>
            <p *ngIf="isMobile" class="gameFilterBar__toggle">
                <a class="button button--gray mt-15" *ngIf="!showVendors" (click)="showVendors = true">
                    {{ 'ALL_GAMES.SELECT_VENDOR' | translate }}</a>
                <a class="button button--gray mt-15 mb-15" *ngIf="showVendors" (click)="showVendors = false">
                    {{ 'ALL_GAMES.HIDE_FILTERS' | translate }}</a>
            </p>
        </div>

        <div class="gameFilterBar__item" *ngIf="showVendors">
            <div class="vendorContainer clearfix">
                <ng-template [ngIf]="!selectedCategory">
                    <a
                        [routerLink]="navigationService.constructUrl('ALL_GAMES', { filter: filterValue })"
                        class="vendorContainer__item vendorContainer__item-reset"
                        [ngClass]="{'vendorContainer__item--active': !selectedVendor, 'vendorContainer__item-reset--bigger': currentLanguage == 'en'}"
                        tooltip="{{ 'ALL_GAMES.SHOW_ALL' | translate }}"
                        placement="bottom">
                        {{ 'ALL_GAMES.SHOW_ALL' | translate }}
                    </a>
                    <a
                        [routerLink]="navigationService.constructUrl('ALL_GAMES_VENDOR', {filter: filterValue, vendor: vendor.slug })"
                        class="vendorContainer__item"
                        routerLinkActive="vendorContainer__item--active"
                        tooltip="{{ vendor.name }}"
                        hide-delay="0"
                        placement="bottom"
                        *ngFor="let vendor of vendors"
                    >
                        <img src="assets/images/vendors/{{ vendor.slug }}.png" alt="{{ vendor.name }}">
                    </a>
                </ng-template>
                <ng-template [ngIf]="selectedCategory">
                    <a
                        [routerLink]="navigationService.constructUrl('ALL_GAMES_CATEGORY', { category: selectedCategory })"
                        class="vendorContainer__item vendorContainer__item-reset"
                        [ngClass]="{'vendorContainer__item--active': !selectedVendor, 'vendorContainer__item-reset--bigger': currentLanguage == 'en'}"
                        tooltip="{{ 'ALL_GAMES.SHOW_ALL' | translate }}"
                        placement="bottom">
                        {{ 'ALL_GAMES.SHOW_ALL' | translate }}
                    </a>
                    <a
                        [routerLink]="navigationService.constructUrl('ALL_GAMES_CATEGORY_VENDOR', {category: selectedCategory, vendor: vendor.slug })"
                        class="vendorContainer__item"
                        routerLinkActive="vendorContainer__item--active"
                        tooltip="{{ vendor.name }}"
                        hide-delay="0"
                        placement="bottom"
                        *ngFor="let vendor of vendors"
                    >
                        <img src="assets/images/vendors/{{ vendor.slug }}.png" alt="{{ vendor.name }}">
                    </a>
                </ng-template>
            </div>
        </div>
    </div>

    <div class="gameList" infiniteScroll [infiniteScrollDistance]="4" [infiniteScrollThrottle]="500"
         (scrolled)="onScroll()" *ngIf="filterValue != 'livecasino'">
        <div class="gameList__item gameList__item--no-data" *ngIf="!gameList.loading && (gameList.dataPages.length === 0 || gameList.dataPages[0].length === 0)">{{
            'ALL_GAMES.NO_DATA' | translate }}
        </div>
        <ng-template ngFor let-page [ngForOf]="gameList.dataPages">
            <game-item [game]="game" 
                       [filter]="filterValue"
                       (removeFromFavouritesArray)="removeFromFavouritesArray(game)"
                *ngFor="let game of page; trackBy: trackByFn"
                class="no-splide"
                ></game-item>
        </ng-template>
        <div class="gameList__item gameList__item--loading" *ngIf="gameList.loading"></div>
    </div>

    <ng-template [ngIf]="filterValue !== 'livecasino'">
        <div class="columnContainer">
            <section>
                <h1 class="bottomDecorator"><span class="decorator"><i class="fa fa-diamond" aria-hidden="true"></i></span>
                    {{ 'ALL_GAMES.CONTENT.TITLE' | translate }}</h1>
                <span [innerHtml]="'ALL_GAMES.CONTENT.TEXT' | translate"></span>
            </section>
        </div>
    </ng-template>

    <ng-template [ngIf]="filterValue === 'livecasino'">
        <div class="livecasino_container_main">
            <div class="gameList gameList__livecasino">
                <div class="gameList__item gameList__item--no-data"
                     *ngIf="!gameList.loading && !gameList.hasData">{{ 'ALL_GAMES.NO_DATA' | translate }}
                </div>
                <div class="top-games" *ngIf="!gameList.loading && gameList.liveCasinoDataPages['top'].length">
                    <game-item [game]="game" [gameType]="'livecasino'" *ngFor="let game of gameList.liveCasinoDataPages['top']"
                        [ngClass]="game.type + ' ' + game.place" class="liveCasino__wrapper no-splide"></game-item>
                </div>
                <div class="second-games" *ngIf="!gameList.loading && gameList.liveCasinoDataPages['second'].length">
                    <game-item [game]="game" [gameType]="'livecasino'" *ngFor="let game of gameList.liveCasinoDataPages['second']"
                        [ngClass]="game.type + ' ' + game.place" class="liveCasino__wrapper no-splide"></game-item>
                </div>
                <div class="third-games" *ngIf="!gameList.loading && gameList.liveCasinoDataPages['third'].length">
                    <game-item [game]="game" [gameType]="'livecasino'" *ngFor="let game of gameList.liveCasinoDataPages['third']"
                        [ngClass]="game.type + ' ' + game.place" class="liveCasino__wrapper no-splide"></game-item>
                </div>
                <div class="bottom-games" *ngIf="!gameList.loading && gameList.liveCasinoDataPages['bottom'].length">
                    <game-item [game]="game" [gameType]="'livecasino'" *ngFor="let game of gameList.liveCasinoDataPages['bottom']"
                        [ngClass]="game.type + ' ' + game.place" class="liveCasino__wrapper no-splide"></game-item>
                </div>
                <div class="gameList__item gameList__item--loading" *ngIf="gameList.loading"></div>
            </div>
        </div>
    </ng-template>

    <div class="pageDescription" [innerHtml]="pageDescription" *ngIf="pageDescription"></div>
</div>
