import {
  Component,
  Input,
  OnChanges,
  OnInit,
  ChangeDetectionStrategy,
  OnDestroy,
  ChangeDetectorRef,
  Output,
  EventEmitter,
} from '@angular/core';
import { globalConstants, storageConstants } from '../../constants/index';
import {
  GameService,
  UserService,
  ComboNotificationService,
  NavigationService,
  CasinoService,
} from '../../services/index';
import { commonPagesHelper } from '../../helpers/index';
import { Subscription } from 'rxjs';

@Component({
  selector: 'game-item',
  templateUrl: './gameItem.component.html',
  styleUrls: ['./gameItem.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GameItemComponent implements OnChanges, OnInit, OnDestroy {
  private component$: Subscription[] = []

  imagesPath = globalConstants['IMAGES_DOMAIN']
  defaultImages = globalConstants['DEFAULT_IMAGES']

  @Input()
  game = undefined

  @Input()
  gameType = 'default'

  @Input()
  filter = undefined

  @Output()
  removeFromFavouritesArray = new EventEmitter<any>();

  routerEventsSubscribe
  isFavorite
  isUserAllowedToPlay = false

  isLogged = false

  /**
   * @costructor
   */
  constructor(
    private gameService: GameService,
    private userService: UserService,
    private comboNotificationService: ComboNotificationService,
    private navigationService: NavigationService,
    private changeDetectionRef: ChangeDetectorRef,
    public casinoService: CasinoService,
    private cdr: ChangeDetectorRef
  ) {
    this.isLogged = userService.isLogged();
  }

  ngOnInit() {
    //console.log(this.game);
    if (this.gameType === 'jackpot') {
      this.game.name = this.game.gameName;
    }
    this.game.isFavourite = this.isGameFavorite();
    this.isUserAllowedToPlay = this.userService.isFullyRegistered();
    this.component$ = [
      this.userService.userDataChange.subscribe(() => {
        this.isLogged = this.userService.isLogged();
        this.changeDetectionRef.markForCheck();
      })
    ];


    this.game.gameThumbImageUrl= this.game.gameThumbImageUrl.replace(/\/assets\/vendors/g, '/elcarado_com/assets/vendors');


  }

  ngOnDestroy() {
    this.component$.forEach(it => it.unsubscribe());
    this.component$ = [];
  }
  //This is a hack to trigger Dynamic UI changes
  changeStatus(): void {
    setTimeout(() => {
      if (!this.cdr['destroyed']) {
        this.cdr.detectChanges();
      }
    }, 1000);
  }
  /**
   * Opens (and checks acces) the game.
   * @param {Object} game
   */
  openGame(game) {
    if (this.userService.isLogged()) {
      this.gameService.goToGameDetailsWithPlay(game, true);
    } else {
      this.showLoginPanel(game);
    }
  }

  /**
   * Opens game
   * @param game Game data
   */
  playGame(game) {
    if (this.userService.isLogged()) {
      if (this.userService.isFullyRegistered()) {
        this.gameService.goToGameDetailsWithPlay(game, true);
      } else {
        this.navigationService.goToUserAccount();
      }
    } else {
      this.showLoginPanel(game);
    }
  }

  playDemo(game) {
    this.gameService.goToGameDetailsWithDemo(game, true);
  }

  /**
   * Returns game details.
   * @param {Object} game
   */
  goToGameDetails(game) {
    return this.gameService.goToGameDetails(game);
  }

  /**
   * Adds game to favourite.
   * @param {Object} $event
   * @param {Object} game
   */
  addToFavourites($event, game) {
    $event.stopPropagation();
    if (this.userService.isLogged()) {
      this.gameService.addToFavourites($event, game);
      this.changeStatus();
    } else {
      this.showLoginPanel();
    }
  }

  /**
   * Removes game from favourite.
   * @param {Object} $event
   * @param {Object} game
   */
  removeFromFavourites($event, game) {
    $event.stopPropagation();
    if (this.userService.isLogged()) {
      this.gameService.removeFromFavourites($event, game);
      this.removeFromFavouritesArray.emit(game);
      this.changeStatus();
      if (this.filter === 'your-favourite') {
        // @todo: hide element?
      }
    } else {
      this.showLoginPanel();
    }
  }

  /**
   * Replace image to thumbnail if normal doesn't exists
   */
  replaceGameImage() {
    this.game.gameImageUrl = this.game.gameThumbImageUrl;
  }

  /**
   * Checks if game is favorite one.
   * @param {Object} game
   */
  isGameFavorite() {
    if (!this.userService.isLogged()) {
      return false;
    }
    const favorites = this.userService.getFavoriteGamesFromStorage();

    if (typeof(favorites) !== 'undefined' && favorites) {
      return favorites.includes(this.game.urlSlug);
    }
  }

  /**
   * Show login form
   * @param {Object} game
   */
  showLoginPanel(gameToOpen = null) {
    localStorage.setItem(
      storageConstants['PREVIOUS_URL'],
      this.gameService.goToGameDetails(gameToOpen)
    );
    commonPagesHelper.loginPanel('show');
  }

  /**
   * It's native Angular 'on changes' method.
   */
  ngOnChanges(changes) {
    if (typeof changes['game'] !== 'undefined') {
      if (changes['game']['firstChange']) {
        return;
      }

      this.game = changes['game']['currentValue'];
    }
  }
}
