import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { LandingPageData, LandingPageFilter } from './landingPage.model';
import { requestConstants, globalConstants } from '../../constants';
import { RequestService } from '../../services';
import { requestHelper } from '../../helpers';
import { catchError, map, flatMap } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { DeviceDetectorService } from 'ngx-device-detector';

@Injectable({
  providedIn: 'root'
})
export class LandingPageService {
  constructor(
    private requestService: RequestService,
    private translate: TranslateService,
    private deviceService: DeviceDetectorService
  ) {}

  getDefault(): Observable<LandingPageData> {
    return this.translate.get('LANDING_PAGE.BODY.TITLE').pipe(
      map<any, LandingPageData>(title => ({
        backgroundImageDesktop: '/assets/images/landing-page/lp-bg-desktop.jpg',
        backgroundImageMobile: '/assets/images/landing-page/lp-bg-mobile.jpg',
        bonusAmount: globalConstants.LANDING_PAGE.DEFAULT_BONUS_AMOUNT,
        freeSpinAmount: globalConstants.LANDING_PAGE.DEFAULT_FREE_SPIN_AMOUNT,
        title: title,
      }))
    );
  }

  find(
    filter: LandingPageFilter,
    defaultIfNotFound = true
  ): Observable<LandingPageData> {
    const withToken = false;
    const url =
      requestConstants.API_URL +
      requestHelper.urlReplace(requestConstants.LANDING_PAGE.URL, filter);
    const default_ = defaultIfNotFound ? this.getDefault() : of(undefined);
    
    return this.requestService.GET(url, {}, withToken).pipe(
      flatMap(response =>
        response.result === 'ok'
          ? response.landingPage
            ? of(response.landingPage)
            : default_
          : default_
      ),
      catchError(() => default_)
    );
  }
}
