import { Component, Output, EventEmitter, Input, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { regExpValidationConstants, requestConstants } from '../../constants';
import { RegisterFormModel } from '../../models';
import { Subscription, Observable, from } from 'rxjs';
import { commonPagesHelper } from '../../helpers';
import { UserRequestService, ComboNotificationService, NavigationService, UserService, AppLanguageService, WebauthnRequestService } from '../../services';
import { ResponseModel, FacebookRegisterResponse } from '../../models/request.model';
import { TranslateService } from '@ngx-translate/core';
import { startWith, switchMap } from 'rxjs/operators';
import { PlatformService } from '../../services/platform.service';
import { AccessTokenService } from '../../services/access-token.service';
import { ComboNotificationType } from '../../enums/alert-type.enum';
import { countriesTranslated } from '../../data/index';
import {
  create,
  parseCreationOptionsFromJSON,
} from "@github/webauthn-json/browser-ponyfill";

@Component({
  selector: '[app-webauthn-register-modal]',
  templateUrl: './webAuthRegisterModal.component.html',
  styleUrls: ['./webAuthRegisterModal.component.scss'],
})
export class WebAuthRegisterModalComponent implements OnInit, OnDestroy {
  @Output()
  close = new EventEmitter<string | undefined>();

  private component$: Subscription[] = [];

  @Input()
  loginHidden = false;

  register$: Subscription;



  @Input()
  visible = false;
  countries: any;

  constructor(
    private userRequestService: UserRequestService,
    private comboNotificationService: ComboNotificationService,
    private navigationService: NavigationService,
    private userService: UserService,
    private translate: TranslateService,
    private langService: AppLanguageService,
    private platformService: PlatformService,
    private accessToken: AccessTokenService,
    private webauthnService: WebauthnRequestService,
  ) {}

 

  ngOnDestroy() {
    this.component$.forEach(it => it.unsubscribe());
  }

  ngOnInit() {
    if (this.platformService.server) return;

    //this.component$ = [this.form.controls.email.valueChanges.subscribe(it => this.form.controls.username.setValue(it))];

  }

  webauthRegister(){
    const self = this;
    const parametersMap={};
    commonPagesHelper.loader('show');
    this.webauthnService.getRegisterOptions(parametersMap).subscribe(
      data => {
        this.createWeauthnRegisterOptions(data);
      },
      () => {
        commonPagesHelper.loader('hide');
      }
    );
    
  }
  async createWeauthnRegisterOptions (data){
      commonPagesHelper.loader('show');
      const cco = parseCreationOptionsFromJSON({
      publicKey: data.publickey});
      const response = await create(cco);
      const dataLogin = response.toJSON();
      dataLogin["PHPSESSID"]=data.PHPSESSID;
      this.webauthnService.saveRegisterBiometric(dataLogin).subscribe(data =>{
        commonPagesHelper.loader('hide');
       if (data.status=="ok"){
          this.translate
          .get(['USER_ACCOUNT.BIOMETRIC_REGISTRATION_SUCCESS_LABEL'])
          .subscribe((translatedValue: string) => {
            this.comboNotificationService.notification(
              translatedValue['USER_ACCOUNT.BIOMETRIC_REGISTRATION_SUCCESS_LABEL'],
              ComboNotificationType.Success
            );
          })
          //get authenticator list
          console.log("clossing tab");
          //close modal
          this.close.emit();
        }
      }),() =>{
        commonPagesHelper.loader('hide');
      }
  }


  register() {
/*
    if (!this.form.valid || this.register$) {
      return;
    }

    const model: RegisterFormModel = this.form.value;
    model.acceptTermsAndConditions = model.acceptTermsAndConditions === true ? 1 : 0;

    if (model.hasOwnProperty('country')) {
      model.address = {
        firstCountry: model.country,
      };
      delete model.country;
    }
    commonPagesHelper.loader('show');

    this.register$ = this.userRequestService.createRequest(model).subscribe(this.registerSuccess.bind(this), this.registerError.bind(this));
  */
  }

  private registerError(error: any) {
    this.register$.unsubscribe();
    this.register$ = undefined;
    commonPagesHelper.loader('hide');

    // * Show Snackbar Error
    this.comboNotificationService.notification(`${error.error_description}.`, ComboNotificationType.Error);
  }

  private registerSuccess(response: ResponseModel) {
    this.register$.unsubscribe();
    this.register$ = undefined;
    commonPagesHelper.loader('hide');

    if (response.result === 'ok') {
      this.close.emit();
      this.navigationService.goToDefaultPage();
      this.translate.get(['GLOBAL.ALERTS.THANKS_FOR_REGISTRATION_LABEL']).subscribe((translatedValue: string) => {
        // * Show SnackBar Notification
        this.comboNotificationService.notification(
          translatedValue['GLOBAL.ALERTS.THANKS_FOR_REGISTRATION_LABEL'],
          ComboNotificationType.Info
        );
      });
    } else {
      // * Shows SnackBar Error
      this.comboNotificationService.notification(response.message, ComboNotificationType.Error, response.errors);
    }
  }
}

