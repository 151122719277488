export const licenseBarVendorLogos = [
  {
    img: 'assets/images/vendor-logos/playngo.png',
    url: '',
    pageUrl: 'ALL_GAMES_VENDOR',
    pageUrlData: {
      filter: 'all-games',
      vendor: 'playngodirect',
    },
    allowedCountries: ['de', 'en', 'fi', 'no', 'pl', 'se'],
  },
  {
    img: 'assets/images/vendor-logos/thunderkick.png',
    url: '',
    pageUrl: 'ALL_GAMES_VENDOR',
    pageUrlData: {
      filter: 'all-games',
      vendor: 'thunderkick',
    },
    allowedCountries: ['de', 'en', 'fi', 'no', 'pl', 'se'],
  },
  {
    img: 'assets/images/vendor-logos/evolution.png',
    url: '',
    pageUrl: 'ALL_GAMES_VENDOR',
    pageUrlData: {
      filter: 'all-games',
      vendor: 'evolutiondirect',
    },
    allowedCountries: ['de', 'en', 'fi', 'no', 'pl', 'se'],
  },
  {
    img: 'assets/images/vendor-logos/ballywulff.png',
    url: '',
    pageUrl: 'ALL_GAMES_VENDOR',
    pageUrlData: {
      filter: 'all-games',
      vendor: 'ballywulff',
    },
    allowedCountries: ['de', 'en', 'fi', 'no', 'pl', 'se'],
  },
  {
    img: 'assets/images/vendor-logos/pragmaticplay.png',
    url: '',
    pageUrl: 'ALL_GAMES_VENDOR',
    pageUrlData: {
      filter: 'all-games',
      vendor: 'gamehubpragmatic',
    },
    allowedCountries: ['de', 'en', 'fi', 'no', 'pl', 'se'],
  },
  {
    img: 'assets/images/vendor-logos/endorphina.png',
    url: '',
    pageUrl: 'ALL_GAMES_VENDOR',
    pageUrlData: {
      filter: 'all-games',
      vendor: 'endorphina',
    },
    allowedCountries: ['de', 'en', 'fi', 'no', 'pl', 'se'],
  },
  {
    img: 'assets/images/vendor-logos/yggdrasil.png',
    url: '',
    pageUrl: 'ALL_GAMES_VENDOR',
    pageUrlData: {
      filter: 'all-games',
      vendor: 'yggdrasil',
    },
    allowedCountries: ['de', 'en', 'fi', 'no', 'pl', 'se'],
  },
  {
    img: 'assets/images/vendor-logos/bigtimegaming.png',
    url: '',
    pageUrl: 'ALL_GAMES_VENDOR',
    pageUrlData: {
      filter: 'all-games',
      vendor: 'nyxbigtimegaming',
    },
    allowedCountries: ['de', 'en', 'fi', 'no', 'pl', 'se'],
  },
  {
    img: 'assets/images/vendor-logos/netent_w.png',
    url: '',
    pageUrl: 'ALL_GAMES_VENDOR',
    pageUrlData: {
      filter: 'all-games',
      vendor: 'netentertainment',
    },
    allowedCountries: ['de', 'en', 'fi', 'no', 'pl', 'se'],
  },
  {
    img: 'assets/images/vendor-logos/gamomat.png',
    url: '',
    pageUrl: 'ALL_GAMES_VENDOR',
    pageUrlData: {
      filter: 'all-games',
      vendor: 'oryxgamomat',
    },
    allowedCountries: ['de', 'en', 'fi', 'no', 'pl', 'se'],
  },
  {
    img: 'assets/images/vendor-logos/wms.png',
    url: '',
    pageUrl: 'ALL_GAMES_VENDOR',
    pageUrlData: {
      filter: 'all-games',
      vendor: 'nyxwms',
    },
    allowedCountries: ['de', 'en', 'fi', 'no', 'pl', 'se'],
  },
  {
    img: 'assets/images/vendor-logos/microgaming.png',
    url: '',
    pageUrl: 'ALL_GAMES_VENDOR',
    pageUrlData: {
      filter: 'all-games',
      vendor: 'microgaming',
    },
    allowedCountries: ['de', 'en', 'fi', 'no', 'pl', 'se'],
  },
  {
    img: 'assets/images/vendor-logos/amatic.png',
    url: '',
    pageUrl: 'ALL_GAMES_VENDOR',
    pageUrlData: {
      filter: 'all-games',
      vendor: 'amatic',
    },
    allowedCountries: ['de', 'en', 'fi', 'no', 'pl', 'se'],
  },
];
