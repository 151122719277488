/**
 * Language switcher.
 * @TODO: Sets selected language to storage!
 */
import { Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { Subscription } from 'rxjs';
import { globalConstants } from '../../constants/index';
import { commonPagesHelper } from '../../helpers/index';
import { AppLanguageService, ComboNotificationService, LanguageRequestService } from '../../services/index';
import { ComboNotificationType } from '../../enums/alert-type.enum';

@Component({
  selector: 'language-switcher',
  templateUrl: './languageSwitcher.component.html',
  styleUrls: ['./languageSwitcher.component.scss'],
})
export class LanguageSwitcherComponent implements OnDestroy {
  currentLanguage = globalConstants['DEFAULT_LANGUAGE'];
  availableLanguages = globalConstants['LANGUAGES'];
  languageChangeSubscribe$: Subscription;

  /**
   * @costructor
   */
  constructor(
    private appLanguageService: AppLanguageService,
    private languageRequestService: LanguageRequestService,
    private comboNotificationService: ComboNotificationService,
  ) {
    this.currentLanguage = this.appLanguageService.getLanguageFromStorage();
    this.languageChangeSubscribe$ = this.appLanguageService.languageChange$.subscribe(
      language => {
        this.currentLanguage = language;
      }
    );
  }

  private _isActive = false;

  get isActive() { return this._isActive; }

  @Input()
  set isActive(value: boolean) {
    if (this._isActive === value)
      return;

    commonPagesHelper.headerMenu(value ? 'show' : 'hide');
    this._isActive = value;
    this.isActiveChange.emit(value);
  }

  @Output()
  isActiveChange = new EventEmitter<boolean>();

  /**
   * Toggles dropdown.
   */
  toggleDropdown() {
    this.isActive = !this.isActive;
  }

  /**
   * Opens dropdown.
   */
  openDropdown() {
    this.isActive = true;
  }

  /**
   * Closes dropdown.
   */
  closeDropdown() {
    this.isActive = false;
  }

  /**
   * Sets language by hand.
   * @param {String} language
   */
  setLanguageByHand(language) {
    const withReload = true;
    const withChangeLanguageInUrl = true;

    if (commonPagesHelper.userIsLogged()) {
      this.languageRequestService.setCageLocaleRequest(language)
        .subscribe(
          data => {
            if (data.result === 'ok') { return; }
            this.comboNotificationService.notification(data['message'], ComboNotificationType.Error, data['errors']);

            return;
          },
          error => {
            this.comboNotificationService.notification(error, ComboNotificationType.Error);

            return;
          }
        );
    }

    this.appLanguageService.setLanguageByHand(
      language,
      withReload,
      withChangeLanguageInUrl
    );
  }

  ngOnDestroy() {
    this.languageChangeSubscribe$.unsubscribe();
  }
}
