/* Change Password Form Model */
export class ChangePasswordFormModel {
  constructor(
    public currentPassword?: any,
    public newPassword?: any,
    public newPasswordRepeat?: any,
    public changePasswordValidationMessages = {
      currentPassword: {
        required: 'GLOBAL.VALIDATION_MESSAGES.REQUIRED',
        minlength: 'GLOBAL.VALIDATION_MESSAGES.LEAST_SIX_CHARACTERS_LONG',
        maxlength: 'GLOBAL.VALIDATION_MESSAGES.MORE_THAN_NINETYSIX_CHARACTERS_LONG'
      },
      newPassword: {
        required: 'GLOBAL.VALIDATION_MESSAGES.REQUIRED',
        minlength: 'GLOBAL.VALIDATION_MESSAGES.LEAST_SIX_CHARACTERS_LONG',
        maxlength: 'GLOBAL.VALIDATION_MESSAGES.MORE_THAN_NINETYSIX_CHARACTERS_LONG',
        pattern: 'GLOBAL.VALIDATION_MESSAGES.WRONG_PASSWORD_FORMAT',
      },
      newPasswordRepeat: {
        required: 'GLOBAL.VALIDATION_MESSAGES.REQUIRED',
        validateEqual: 'GLOBAL.VALIDATION_MESSAGES.MISMATCH_PASSWORD',
        minlength: 'GLOBAL.VALIDATION_MESSAGES.LEAST_SIX_CHARACTERS_LONG',
        maxlength: 'GLOBAL.VALIDATION_MESSAGES.MORE_THAN_NINETYSIX_CHARACTERS_LONG'
      }
    },
    public changePasswordFormErrors = {
      currentPassword: [],
      newPassword: [],
      newPasswordRepeat: []
    }) {  }
}