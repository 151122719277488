<div class="container container--noVerticalPadding container--darker">
    <h1 class="big withoutStar">{{ 'RESPONSIBLE_GAMBLING.RESPONSIBLE_GAMBLING_HEADER' | translate }}</h1>
</div>

<div class="container">
    <div class="staticPagesContainer">
        <div class="staticPagesContainer__leftColumn">
            <div class="staticPagesContainer__leftColumn-item">

                <div [innerHTML]="'RESPONSIBLE_GAMBLING.RESPONSIBLE_GAMBLING_CONTENT' | translate"></div>

            </div>
        </div>

        <div class="staticPagesContainer__rightColumn">
            <static-pages-right-column></static-pages-right-column>
        </div>
    </div>
</div>

