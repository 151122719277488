import { Router } from '@angular/router';
import { CoreLayout } from '../../layouts/index';
import { Component, Host } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { regExpValidationConstants } from '../../constants/index';
import { UserRequestService, NavigationService, ComboNotificationService } from '../../services/index';
import { UserModel } from '../../models/index';
import { commonPagesHelper, formHelper } from '../../helpers/index';
import { TranslateService } from '@ngx-translate/core';
import { ComboNotificationType } from '../../enums/alert-type.enum';

@Component({
  selector: 'register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})

export class RegisterComponent {
  layout: any;
  userModel: UserModel;
  success = false;
  sending = false;
  facebookLogin: any;
  registrationAcceptanceText: string;

  validationMessages = {
    'email': {
      'required': 'GLOBAL.VALIDATION_MESSAGES.REQUIRED',
      'pattern': 'GLOBAL.VALIDATION_MESSAGES.WRONG_FORMAT_EMAIL'
    },
    'password': {
      'required': 'GLOBAL.VALIDATION_MESSAGES.REQUIRED',
      'minlength': 'GLOBAL.VALIDATION_MESSAGES.LEAST_SIX_CHARACTERS_LONG',
      'maxlength': 'GLOBAL.VALIDATION_MESSAGES.MORE_THAN_NINETYSIX_CHARACTERS_LONG',
      'pattern': 'GLOBAL.VALIDATION_MESSAGES.WRONG_PASSWORD_FORMAT',
    },
    'passwordRepeat': {
      'required': 'GLOBAL.VALIDATION_MESSAGES.REQUIRED',
      'minlength': 'GLOBAL.VALIDATION_MESSAGES.LEAST_SIX_CHARACTERS_LONG',
      'maxlength': 'GLOBAL.VALIDATION_MESSAGES.MORE_THAN_NINETYSIX_CHARACTERS_LONG',
      'validateEqual': 'GLOBAL.VALIDATION_MESSAGES.MISMATCH_PASSWORD'
    },
    'acceptTermsAndConditions': {
      'required': 'GLOBAL.VALIDATION_MESSAGES.REQUIRED',
    }
  };

  formErrors = {
    'email': [],
    'password': [],
    'passwordRepeat': [],
    'acceptTermsAndConditions': []
  };
  form: FormGroup;

  /**
	 * @Consturctor
	 */
  constructor(
  parent: CoreLayout,
    private _translate: TranslateService,
    private comboNotificationService: ComboNotificationService,
    private router: Router,
    private formBuilder: FormBuilder,
    private userRequestService: UserRequestService,
    public navigationService: NavigationService
  ) {
    this.layout = parent;
    this.userModel = new UserModel();
    this.getRegistrationAcceptanceText();
    this.facebookLogin = this.layout.facebookLogin;
  }

  /**
   * It's native Angular 'on initiation' method.
   * Fired after constructor.
   */
  ngOnInit(): void {
    this.buildForm();
  }

  /**
	 * Gets registration acceptance text with dynamically assigned URLs
	 */
  getRegistrationAcceptanceText() {
    this._translate.get('REGISTER.ACCEPTANCE_LABEL', {
      tacUrl: this.navigationService.constructUrl('TAC'),
      privacyPolicyUrl: this.navigationService.constructUrl('PRIVACY_POLICY')
    }).subscribe((translatedValue: string) => {
      this.registrationAcceptanceText = translatedValue;
    });
  }

  /**
	 * Builds form.
	 */
  buildForm(): void {
    const formBuilderOptions = {
      email: [
        this.userModel.email,
        [
          Validators.required,
          Validators.pattern(regExpValidationConstants['EMAIL'])
        ]
      ],
      password: [
        this.userModel.password,
        [
          Validators.required,
          Validators.minLength(6),
          Validators.maxLength(96),
          Validators.pattern(regExpValidationConstants['PASSWORD'])
        ]
      ],
      passwordRepeat: [
        this.userModel.passwordRepeat,
        [
          Validators.required,
          Validators.minLength(6),
          Validators.maxLength(96)
        ]
      ],
      acceptTermsAndConditions: [
        this.userModel.acceptTermsAndConditions,
        Validators.required
      ],
      isSubscribedToNewsletter: [
        this.userModel.isSubscribedToNewsletter
      ]
    };

    this.form = this.formBuilder.group(formBuilderOptions);
    this.form.valueChanges.subscribe(() => {
      formHelper.onValueChanged(
        this.form,
        this.formErrors,
        this.validationMessages
      );
    });
    formHelper.onValueChanged(
      this.form,
      this.formErrors,
      this.validationMessages
    ); // reset validation messages
  }

  /**
	 * Submit preprocess.
	 * @param {Object} data
	 * @returns {object}
	 */
  submitPreprocess(data) {
    data['acceptTermsAndConditions'] = (data['acceptTermsAndConditions'] ? 1 : 0);
    data['isSubscribedToNewsletter'] = (data['isSubscribedToNewsletter'] ? true : false);
    data['username'] = data['email'].toLowerCase();
    data['plainPassword'] = {
      first: data['password'],
      second: data['passwordRepeat']
    };

    delete data['password'];
    delete data['passwordRepeat'];

    return data;
  }

  /**
	 * On submit handler
	 * @handler
	 */
  onSubmit() {
    const parameters = this.submitPreprocess(this.form.value);
    this.createNewUser(parameters);
  }

  /**
	 * Creates new user.
	 * @param {Object} event
	 */
  createNewUser(parametersMap: object) {
    this.sending = true;
    commonPagesHelper.loader('show');

    this.userRequestService.createRequest(parametersMap).subscribe((data) => {
      commonPagesHelper.loader('hide');
      this.sending = false;

      if (data['result'] === 'ok') {
        this._translate.get(['LAYOUT.REGISTER.REGISTER_SUCCESS_LABEL'])
          .subscribe((translatedValue: string) => {
            // * Show SnackBar Notification
            this.comboNotificationService.notification(translatedValue['LAYOUT.REGISTER.REGISTER_SUCCESS_LABEL'], ComboNotificationType.Success);
          });

        this.success = true;
        this.layout.showSuccessAdditionalInformation();

        this.router.navigateByUrl(this.navigationService.constructUrl('LOGIN'));
        this.form.patchValue({
          username: '',
          email: '',
          password: '',
          passwordRepeat: '',
          acceptTermsAndConditions: ''
        });
        
        return;
      }

      // * Show SnackBar Notification
      this.comboNotificationService.notification(data['message'], ComboNotificationType.Error, data['errors']);

      this.success = false;
      this.form.patchValue({
        password: '',
        passwordRepeat: ''
      });

    }, () => {
      commonPagesHelper.loader('hide');
      this.sending = false;
      this.success = false;
      this.form.patchValue({
        password: '',
        passwordRepeat: ''
      });
    });
  }
}
