import { BaseCashierComponent } from '../base.component';
import { Component } from '@angular/core';

@Component({
  selector: 'cashier-pay-out',
  templateUrl: '../base.component.html',
  styleUrls: ['../base.component.scss']
})

export class PayOutComponent extends BaseCashierComponent {
  public iframeId = 'payout'
}