/**
 * Searchbar component.
 */
import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { globalConstants } from '../../constants/index';
import { commonPagesHelper } from '../../helpers/index';
import {
  UserService,
  GameService,
  SearchBarService,
} from '../../services/index';

@Component({
  selector: 'search-bar',
  templateUrl: './searchBar.component.html',
  styleUrls: ['./searchBar.component.scss'],
})
export class SearchBarComponent implements OnInit, OnDestroy {
  // ? { static: true } query results available in ngOnInit, { static: false } query results available in ngAfterViewInit
  @ViewChild('searchInput',  { static: true }) searchInput;
  @ViewChild('autosuggestScrollbar', { static: true }) autosuggestScrollbar;
  @ViewChild('autosuggestScrollbarItem', { static: true }) autosuggestScrollbarItem;

  defaultImages = globalConstants['DEFAULT_IMAGES'];
  autosuggestLimit = 40

  searchSuggest = []
  visibleSearchAutosuggest = false
  visibleSearchBar = false
  imagesPath = globalConstants['IMAGES_DOMAIN']
  searchValue
  cachedGames = []
  subscribes = {
    allGameList: undefined,
  }

  /**
   * @costructor
   */
  constructor(
    private gameService: GameService,
    private searchBarService: SearchBarService,
    private userService: UserService
  ) {}

  /**
   * It's native Angular 'on initiation' method.
   * Fired after constructor.
   */
  ngOnInit() {
    const self = this;

    this.subscribes.allGameList = this.searchBarService
      .getAllGameList()
      .subscribe(
        data => {
          if (data.result === 'ok') {
            self.cachedGames = data['games'];

            return;
          }

          self.cachedGames = [];
        },
        () => {
          self.cachedGames = [];
        }
      );
  }

  /**
   * Selects specific game.
   * @handler
   * @param {String} value
   * @param {Object} currentGame
   */
  selectGame(value, currentGame) {
    if (typeof currentGame === 'undefined') {
      return;
    }

    this.searchInput.nativeElement.blur();
    this.searchInput.nativeElement.value = currentGame['name'];

    this.searchBarService.gameSelected$.emit(true);
    this.searchOnSubmit(value, currentGame);
  }

  /**
   * Gets autosuggest active index.
   */
  getAutosuggestActiveIndex() {
    const autosuggestElementFound = this.searchSuggest.find(element => {
      return element['active'];
    });

    return this.searchSuggest.indexOf(autosuggestElementFound);
  }

  /**
   * Sets autosuggest item as active.
   * @param {String} [position=bottom]
   */
  setAutosuggestItemAsActive(position = 'down') {
    let nextIndex;
    const autosuggestElementFoundIndex = this.getAutosuggestActiveIndex();
    const autosuggestFoundLength = this.searchSuggest.length;

    if (autosuggestFoundLength === 0) {
      return;
    }

    if (position === 'down') {
      nextIndex =
        autosuggestFoundLength > autosuggestElementFoundIndex + 1
          ? autosuggestElementFoundIndex + 1
          : 0;
    } else {
      nextIndex =
        autosuggestElementFoundIndex === 0
          ? autosuggestFoundLength - 1
          : autosuggestElementFoundIndex - 1;
    }

    this.searchSuggest[autosuggestElementFoundIndex]['active'] = false;
    this.searchSuggest[nextIndex]['active'] = true;

    this.autosuggestScrollbar.nativeElement.scrollTop =
      nextIndex * this.autosuggestScrollbarItem.nativeElement.clientHeight;
  }

  /**
   * Search on 'keyup' handler.
   * @handler
   * @param {HTMLElement} inputElement
   * @param {Object} event
   */
  searchOnKeyup(inputElement, event) {
    const value = inputElement.value;
    const key = event.key;
    let currentGame;

    switch (key) {
      case 'Enter':
        currentGame = this.searchSuggest[this.getAutosuggestActiveIndex()];
        this.selectGame(value, currentGame);
        break;

      case 'Escape':
        this.searchOnBlur();
        break;

      case 'ArrowUp':
        event.preventDefault();
        this.setAutosuggestItemAsActive('top');
        break;

      case 'ArrowDown':
        event.preventDefault();
        this.setAutosuggestItemAsActive('down');
        break;

      default:
        this.searchValue = value;

        if (!value) {
          this.searchSuggest = [];

          return;
        }

        if (this.cachedGames.length === 0) {
          this.searchSuggest = [];

          return;
        }

        this.searchSuggest = this.cachedGames.filter(data => {
          return (
            data.name.toLowerCase().indexOf(value.toLowerCase()) > -1 ||
            data.vendorName.toLowerCase().indexOf(value.toLowerCase()) > -1 ||
            data.urlSlug.toLowerCase().indexOf(value.toLowerCase()) > -1 ||
            data.category.toLowerCase().indexOf(value.toLowerCase()) > -1
          );
        });

        this.searchSuggest = this.searchSuggest.slice(0, this.autosuggestLimit);
        this.searchSuggest.forEach((game, iterator) => {
          game['active'] = Boolean(iterator === 0);
        });

        this.visibleSearchAutosuggest = Boolean(this.searchValue);
        break;
    }
  }

  /**
   * Search on 'focus' handler.
   * @handler
   */
  searchOnFocus() {
    if (!this.searchValue) {
      return;
    }

    this.visibleSearchAutosuggest = true;
  }

  /**
   * Search on 'blur' handler.
   * @handler
   */
  searchOnBlur() {
    this.visibleSearchAutosuggest = false;
  }

  /**
   * Search on 'submit' handler.
   * @handler
   * @param {String} value
   * @param {Object} currentGame
   */
  searchOnSubmit(value, currentGame = null) {
    if (!value) {
      return;
    }

    if (!currentGame) {
      currentGame = this.searchSuggest[this.getAutosuggestActiveIndex()];
    }

    this.visibleSearchAutosuggest = false;

    const gameType = this.userService.isLogged()
      ? 'full'
      : (currentGame.isDemoEnabled ? 'demo' : 'info');

    this.gameService.openGame(currentGame.urlSlug, gameType);
  }

  /**
   * Shows search bar (for small resolutions).
   * @param {HTMLElement} searchInput
   * @handler
   */
  showSearchBar(searchInput) {
    searchInput.focus();
    this.visibleSearchBar = true;
  }

  /**
   * Hides search bar (for small resolutions).
   * @handler
   */
  hideSearchBar() {
    this.visibleSearchBar = false;
  }

  /**
   * It's native Angular 'on destroy' method.
   * Destroys component.
   */
  ngOnDestroy() {
    commonPagesHelper.unsubscribeObserves([this.subscribes.allGameList]);
  }
}
