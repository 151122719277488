import { Injectable, Inject } from '@angular/core';
import { PlatformService } from './platform.service';
import { DOCUMENT } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class CommonPagesService {
  constructor(
    private platformService: PlatformService, 
    @Inject(DOCUMENT) private document: Document) {}

  loader(action = 'show', selector = 'body') {
    if (action === 'show' && this.platformService.server) {
      return;
    }
    
    const loadingClassName = 'page--loaded';
    const specificElement = this.document.querySelector(selector);
    const specificMethod = (action === 'show') ? 'remove' : 'add';
    specificElement.classList[specificMethod](loadingClassName);
  }

  
  /**
   * Shows/hides mobile main menu.
   * Toggle HTML class 'mobileMenuLayer--show' from specific (default: body) tag.
   * @param [action='show'] {String} - enum: 'show', 'hide' or 'toggle'
   * @param [selector='body'] {String}
   */
  mobileMainMenu(action = 'show', selector = 'body') {
    const specificClassName = 'mobileMenuLayer--show';
    const specificElement = this.document.querySelector(selector);
    let specificMethod: string;

    if (action === 'toggle') {
      specificMethod = action;
    } else {
      specificMethod = (action === 'show') ? 'add' : 'remove';
    }

    specificElement.classList[specificMethod](specificClassName);
  }

  /**
   * Shows/hides main menu.
   * Toggle HTML class 'mainMenu--show' from specific (default: body) tag.
   * @param [action='show'] {String} - enum: 'show', 'hide' or 'toggle'
   * @param [selector='body'] {String}
   */
  mainMenu(action = 'show', selector = 'body') {
    const specificClassName = 'mainMenu--show';
    const specificElement = this.document.querySelector(selector);
    let specificMethod: string;

    if (action === 'toggle') {
      specificMethod = action;
    } else {
      specificMethod = (action === 'show') ? 'add' : 'remove';
    }

    specificElement.classList[specificMethod](specificClassName);
  }

  /**
   * Shows/hides main menu user interaction.
   * Toggle HTML class 'mainMenu--hide' from specific (default: body) tag.
   * @param [action='hide'] {String} - enum: 'show', 'hide' or 'toggle'
   * @param [selector='body'] {String}
   */
  mainMenuUserInteraction(action = 'hide', selector = 'body') {
    const specificClassName = 'mainMenuUserInteraction--hide';
    const specificElement = this.document.querySelector(selector);
    let specificMethod: string;

    if (action === 'toggle') {
      specificMethod = action;
    } else {
      specificMethod = (action === 'hide') ? 'add' : 'remove';
    }

    specificElement.classList[specificMethod](specificClassName);
  }

  registerPanel(action = 'show', selector = 'body') {
    const specificClassName = 'registerPanel--show';
    const specificElement = this.document.querySelector(selector);
    const specificMethod = (action === 'show') ? 'add' : 'remove';

    specificElement.classList[specificMethod](specificClassName);
  }
}