<div class="container container--noVerticalPadding container--darker">
  <h1 class="big withoutStar">{{ 'USER_ACCOUNT.MY_PROFILE_HEADER' | translate }}</h1>
</div>

<div class="container">
  <ul class="userAccountComponentTabs">
    <ng-template ngFor let-tabItem [ngForOf]="tabs.data">
      <li
        class="userAccountComponentTabs__item"
        [class.userAccountComponentTabs__item--active]="tabs.activeValue === tabItem.value"
        (click)="tabChange(tabItem.value)"
        *ngIf="tabItem.visible"
      >
        <a class="userAccountComponentTabs__item-anchor">{{ tabItem.nameTranslationKey | translate }}</a>
      </li>
    </ng-template>
  </ul>
  <div class="userAccountComponent">
    <div class="userAccountComponent__item" [class.userAccountComponent__item--active]="tabs.activeValue === 'my-profile'">
      <div class="paragraphsContainer">
        <p [innerHTML]="'USER_ACCOUNT.MY_PROFILE_PARAGRAPH' | translate"></p>
        <p
          class="notFullyRegistered"
          *ngIf="!layout.loggedUserData?.isFullyRegistered"
          [innerHTML]="'USER_ACCOUNT.MY_PROFILE_PARAGRAPH_NOT_FULLY_REGISTERED' | translate"
        ></p>
      </div>

      <form
        class="form"
        [formGroup]="myProfileForm"
        (ngSubmit)="myProfileOnSubmit(myProfileForm.value)"
        (keyup.enter)="myProfileOnSubmit(myProfileForm.value)"
        *ngIf="userDataLoaded"
      >
        <div class="columns">
          <div class="columns__half">
            <h3>{{ 'USER_ACCOUNT.PERSONAL_DATA_HEADER' | translate }}</h3>

            <div class="form-element inputWrapper">
              <label for="accountEmail"
                >{{ 'USER_ACCOUNT.EMAIL_LABEL' | translate }}
                <span class="required">*</span>
              </label>
              <input id="accountEmail" formControlName="email" required />
              <span class="errorMessage" *ngIf="userAccountDataFormModel.myProfileFormErrors.email.length">
                <span class="errorMessage__inside">
                  <span *ngFor="let emailMessage of userAccountDataFormModel.myProfileFormErrors.email">{{
                    emailMessage | translate
                  }}</span>
                </span>
              </span>
            </div>

            <div class="form-element inputWrapper">
              <label for="accountSalutation"
                >{{ 'USER_ACCOUNT.SALUTATION_LABEL' | translate }}
                <span class="required">*</span>
              </label>
              <select id="accountSalutation" formControlName="salutation" required *ngIf="!userData?.isFullyRegistered">
                <option *ngFor="let salutation of inputs.salutation" value="{{ salutation.value }}">
                  {{ salutation.optionTranslationKey | translate }}
                </option>
              </select>
              <select id="accountSalutation" formControlName="salutation" readonly disabled *ngIf="userData?.isFullyRegistered">
                <option
                  *ngFor="let salutation of inputs.salutation"
                  value="{{ salutation.value }}"
                  [selected]="salutation.value === userData['sex']"
                >
                  {{ salutation.optionTranslationKey | translate }}
                </option>
              </select>
              <span class="errorMessage" *ngIf="userAccountDataFormModel.myProfileFormErrors.salutation.length">
                <span class="errorMessage__inside">
                  <span *ngFor="let salutationMessage of userAccountDataFormModel.myProfileFormErrors.salutation">{{
                    salutationMessage | translate
                  }}</span>
                </span>
              </span>
            </div>

            <div class="form-element inputWrapper">
              <label for="accountFirstName"
                >{{ 'USER_ACCOUNT.FIRST_NAME_LABEL' | translate }}
                <span class="required">*</span>
              </label>
              <input id="accountFirstName" formControlName="firstName" required *ngIf="!userData?.isFullyRegistered" />
              <input id="accountFirstName" formControlName="firstName" readonly disabled *ngIf="userData?.isFullyRegistered" />
              <span class="errorMessage" *ngIf="userAccountDataFormModel.myProfileFormErrors.firstName.length">
                <span class="errorMessage__inside">
                  <span *ngFor="let firstNameMessage of userAccountDataFormModel.myProfileFormErrors.firstName">{{
                    firstNameMessage | translate
                  }}</span>
                </span>
              </span>
            </div>

            <div class="form-element inputWrapper">
              <label for="accountLastName"
                >{{ 'USER_ACCOUNT.LAST_NAME_LABEL' | translate }}
                <span class="required">*</span>
              </label>
              <input id="accountLastName" formControlName="lastName" required *ngIf="!userData?.isFullyRegistered" />
              <input id="accountLastName" formControlName="lastName" readonly disabled *ngIf="userData?.isFullyRegistered" />
              <span class="errorMessage" *ngIf="userAccountDataFormModel.myProfileFormErrors.lastName.length">
                <span class="errorMessage__inside">
                  <span *ngFor="let lastNameMessage of userAccountDataFormModel.myProfileFormErrors.lastName">{{
                    lastNameMessage | translate
                  }}</span>
                </span>
              </span>
            </div>

            <div class="inputWrapper">
              <label for="accountBirthDateDay"
                >{{ 'USER_ACCOUNT.BIRTH_DATE_LABEL' | translate }}
                <span class="required">*</span>
              </label>

              <div class="threeColumns">
                <div class="form-element threeColumns__item">
                  <select id="accountBirthDateDay" formControlName="birthDateDay" required *ngIf="!userData?.isFullyRegistered">
                    <option *ngFor="let day of inputs.birthDate.days" value="{{ day }}">{{ day }}</option>
                  </select>
                  <select id="accountBirthDateDay" formControlName="birthDateDay" readonly disabled *ngIf="userData?.isFullyRegistered">
                    <option *ngFor="let day of inputs.birthDate.days" value="{{ day }}">{{ day }}</option>
                  </select>
                  <span class="errorMessage" *ngIf="userAccountDataFormModel.myProfileFormErrors.birthDateDay.length">
                    <span class="errorMessage__inside">
                      <span *ngFor="let birthDateDayMessage of userAccountDataFormModel.myProfileFormErrors.birthDateDay">{{
                        birthDateDayMessage | translate
                      }}</span>
                    </span>
                  </span>
                </div>

                <div class="form-element threeColumns__item">
                  <select id="birthDateMonth" formControlName="birthDateMonth" required *ngIf="!userData?.isFullyRegistered">
                    <option *ngFor="let month of inputs.birthDate.months" value="{{ month.value }}">
                      {{ month.optionTranslationKey | translate }}
                    </option>
                  </select>
                  <select id="birthDateMonth" formControlName="birthDateMonth" readonly disabled *ngIf="userData?.isFullyRegistered">
                    <option *ngFor="let month of inputs.birthDate.months" value="{{ month.value }}">
                      {{ month.optionTranslationKey | translate }}
                    </option>
                  </select>
                  <span class="errorMessage" *ngIf="userAccountDataFormModel.myProfileFormErrors.birthDateMonth.length">
                    <span class="errorMessage__inside">
                      <span *ngFor="let birthDateMonthMessage of userAccountDataFormModel.myProfileFormErrors.birthDateMonth">{{
                        birthDateMonthMessage | translate
                      }}</span>
                    </span>
                  </span>
                </div>

                <div class="form-element threeColumns__item">
                  <select id="birthDateYear" formControlName="birthDateYear" required *ngIf="!userData?.isFullyRegistered">
                    <option *ngFor="let year of inputs.birthDate.years" value="{{ year }}">{{ year }}</option>
                  </select>
                  <select id="birthDateYear" formControlName="birthDateYear" readonly disabled *ngIf="userData?.isFullyRegistered">
                    <option *ngFor="let year of inputs.birthDate.years" value="{{ year }}">{{ year }}</option>
                  </select>
                  <span class="errorMessage" *ngIf="userAccountDataFormModel.myProfileFormErrors.birthDateYear.length">
                    <span class="errorMessage__inside">
                      <span *ngFor="let birthDateYearMessage of userAccountDataFormModel.myProfileFormErrors.birthDateYear">{{
                        birthDateYearMessage | translate
                      }}</span>
                    </span>
                  </span>
                </div>
              </div>
            </div>

            <div class="form-element inputWrapper" *ngIf="hasIDNumberField">
              <label for="accountidNumber"
                >{{ 'USER_ACCOUNT.CPF' | translate }}
                <span class="required">*</span>
              </label>
              <input id="accountidNumber" formControlName="idNumber" required cpfMask *ngIf="!userData?.isFullyRegistered" />
              <input id="accountidNumber" formControlName="idNumber" readonly disabled *ngIf="userData?.isFullyRegistered" />
              <span class="errorMessage" *ngIf="userAccountDataFormModel.myProfileFormErrors.idNumber.length">
                <span class="errorMessage__inside">
                  <span *ngFor="let idNumberMessage of userAccountDataFormModel.myProfileFormErrors.idNumber">{{
                    idNumberMessage | translate
                  }}</span>
                </span>
              </span>
            </div>

            <div class="form-element inputWrapper">
              <label for="accountCitizenship"
                >{{ 'USER_ACCOUNT.CITIZENSHIP_LABEL' | translate }}
                <span class="required">*</span>
              </label>
              <select id="accountCitizenship" formControlName="citizenship" required *ngIf="!userData?.isFullyRegistered">
                <option *ngFor="let country of citizenships" value="{{ country.value }}">{{ country.name }}</option>
              </select>
              <select id="accountCitizenship" formControlName="citizenship" readonly disabled *ngIf="userData?.isFullyRegistered">
                <option *ngFor="let country of citizenships" value="{{ country.value }}">{{ country.name }}</option>
              </select>
              <span class="errorMessage" *ngIf="userAccountDataFormModel.myProfileFormErrors.citizenship.length">
                <span class="errorMessage__inside">
                  <span *ngFor="let citizenshipMessage of userAccountDataFormModel.myProfileFormErrors.citizenship">{{
                    citizenshipMessage | translate
                  }}</span>
                </span>
              </span>
            </div>

            <div class="form-element inputWrapper">
              <label for="accountBirthCountry"
                >{{ 'USER_ACCOUNT.NATIONALITY_LABEL' | translate }}
                <span class="required">*</span>
              </label>
              <select id="accountBirthCountry" formControlName="birthCountry" required *ngIf="!userData.birthCountry">
                <option *ngFor="let country of citizenships" value="{{ country.value }}">{{ country.name }}</option>
              </select>
              <select id="accountBirthCountry" formControlName="birthCountry" readonly disabled *ngIf="userData.birthCountry">
                <option *ngFor="let country of citizenships" value="{{ country.value }}">{{ country.name }}</option>
              </select>
              <span class="errorMessage" *ngIf="userAccountDataFormModel.myProfileFormErrors.birthCountry.length">
                <span class="errorMessage__inside">
                  <span *ngFor="let birthCountryMessage of userAccountDataFormModel.myProfileFormErrors.birthCountry">{{
                    birthCountryMessage | translate
                  }}</span>
                </span>
              </span>
            </div>

            <div class="form-element inputWrapper">
              <label for="accountPhone"
                >{{ 'USER_ACCOUNT.PHONE_LABEL' | translate }}
                <span class="required">*</span>
              </label>
              <input id="accountPhone" formControlName="phone" required />
              <span class="errorMessage" *ngIf="userAccountDataFormModel.myProfileFormErrors.phone.length">
                <span class="errorMessage__inside">
                  <span *ngFor="let phoneMessage of userAccountDataFormModel.myProfileFormErrors.phone">{{
                    phoneMessage | translate
                  }}</span>
                </span>
              </span>
            </div>

            <div class="form-element inputWrapper">
              <label for="accountOccupation"
                >{{ 'USER_ACCOUNT.OCCUPATION_LABEL' | translate }}
                <span class="required">*</span>
              </label>
              <!--              <input id="accountOccupation" formControlName="occupation" required>-->
              <select id="accountOccupation" formControlName="occupation" required>
                <option value="Teacher">Teacher</option>
                <option value="Web designers">Web designers</option>
                <option value="Software developer">Software developer</option>
                <option value="Software engineers">Software engineers</option>
                <option value="Programmers">Programmers</option>
                <option value="Product Analysts">Product Analysts</option>
                <option value="Data Engineers">Data Engineers</option>
                <option value="Webmaster">Webmaster</option>
                <option value="Data Managers">Data Managers</option>
                <option value="Digital Marketers">Digital Marketers</option>
                <option value="Physician Assistants">Physician Assistants</option>
                <option value="Pharmacy Technicians">Pharmacy Technicians</option>
                <option value="General Nurse">General Nurse</option>
                <option value="Nurse Midwife">Nurse Midwife</option>
                <option value="Healthcare Workers">Healthcare Workers</option>
                <option value="Laboratory Technicians">Laboratory Technicians</option>
                <option value="Physiotherapists">Physiotherapists</option>
                <option value="Medical Doctors">Medical Doctors</option>
                <option value="Mechanics">Mechanics</option>
                <option value="Plumbers">Plumbers</option>
                <option value="Service Technicians">Service Technicians</option>
                <option value="Electricians">Electricians</option>
                <option value="Truck Driver">Truck Driver</option>
                <option value="Mechanical Engineer">Mechanical Engineer</option>
                <option value="Project Manager">Project Manager</option>
                <option value="Customer Solutions Expert">Customer Solutions Expert</option>
                <option value="Road Construction Worker">Road Construction Worker</option>
                <option value="Production Assistant">Production Assistant</option>
                <option value="Customer Service Agent">Customer Service Agent</option>
                <option value="HR">HR</option>
                <option value="Hotel Assistant">Hotel Assistant</option>
                <option value="Hotels Manager">Hotels Manager</option>
                <option value="Kitchen Assistants">Kitchen Assistants</option>
                <option value="Kitchen Porter">Kitchen Porter</option>
                <option value="Auditors">Auditors</option>
                <option value="Maid">Maid</option>
                <option value="Cleaner">Cleaner</option>
                <option value="Office worker">Office worker</option>
                <option value="Supervisor">Supervisor</option>
                <option value="Shop assistant">Shop assistant</option>
                <option value="Factory worker">Factory worker</option>
                <option value="Logisticians">Logisticians</option>
                <option value="Sales Worker">Sales Worker</option>
                <option value="Waiter">Waiter</option>
                <option value="Cook">Cook</option>
                <option value="Treasurer">Treasurer</option>
                <option value="Financial Analyst">Financial Analyst</option>
                <option value="Insurance">Insurance</option>
                <option value="Online Casino">Online Casino</option>
                <option value="Laborer">Laborer</option>
              </select>
              <span class="errorMessage" *ngIf="userAccountDataFormModel.myProfileFormErrors.occupation.length">
                <span class="errorMessage__inside">
                  <span *ngFor="let occupationMessage of userAccountDataFormModel.myProfileFormErrors.occupation">{{
                    occupationMessage | translate
                  }}</span>
                </span>
              </span>
            </div>
          </div>

          <div class="columns__half">
            <h3>{{ 'USER_ACCOUNT.ADDRESS_HEADER' | translate }}</h3>

            <div class="form-element inputWrapper">
              <label for="accountStreetAndHouseNumber"
                >{{ 'USER_ACCOUNT.STREET_AND_HOUSE_NUMBER_LABEL' | translate }}
                <span class="required">*</span>
              </label>
              <input
                id="accountStreetAndHouseNumber"
                formControlName="streetAndHouseNumber"
                required
                *ngIf="!userData?.isFullyRegistered"
              />
              <input
                id="accountStreetAndHouseNumber"
                formControlName="streetAndHouseNumber"
                readonly
                disabled
                *ngIf="userData?.isFullyRegistered"
              />
              <span class="errorMessage" *ngIf="userAccountDataFormModel.myProfileFormErrors.streetAndHouseNumber.length">
                <span class="errorMessage__inside">
                  <span *ngFor="let streetAndHouseNumberMessage of userAccountDataFormModel.myProfileFormErrors.streetAndHouseNumber">{{
                    streetAndHouseNumberMessage | translate
                  }}</span>
                </span>
              </span>
            </div>

            <div class="form-element inputWrapper">
              <label for="accountZipCode"
                >{{ 'USER_ACCOUNT.ZIP_CODE_LABEL' | translate }}
                <span class="required">*</span>
              </label>
              <input id="accountZipCode" formControlName="zipCode" required *ngIf="!userData?.isFullyRegistered" />
              <input id="accountZipCode" formControlName="zipCode" readonly disabled *ngIf="userData?.isFullyRegistered" />
              <span class="errorMessage" *ngIf="userAccountDataFormModel.myProfileFormErrors.zipCode.length">
                <span class="errorMessage__inside">
                  <span *ngFor="let zipCodeMessage of userAccountDataFormModel.myProfileFormErrors.zipCode">{{
                    zipCodeMessage | translate
                  }}</span>
                </span>
              </span>
            </div>

            <div class="form-element inputWrapper">
              <label for="accountCity"
                >{{ 'USER_ACCOUNT.CITY_LABEL' | translate }}
                <span class="required">*</span>
              </label>
              <input id="accountCity" formControlName="city" required *ngIf="!userData?.isFullyRegistered" />
              <input id="accountCity" formControlName="city" readonly disabled *ngIf="userData?.isFullyRegistered" />
              <span class="errorMessage" *ngIf="userAccountDataFormModel.myProfileFormErrors.city.length">
                <span class="errorMessage__inside">
                  <span *ngFor="let cityMessage of userAccountDataFormModel.myProfileFormErrors.city">{{ cityMessage | translate }}</span>
                </span>
              </span>
            </div>

            <div class="form-element inputWrapper">
              <label for="accountCountry"
                >{{ 'USER_ACCOUNT.COUNTRY_LABEL' | translate }}
                <span class="required">*</span>
              </label>
              <select id="accountCountry" formControlName="country" readonly disabled>
                <option *ngFor="let country of inputs.country" value="{{ country.value }}">{{ country.name }}</option>
              </select>
              <span class="errorMessage" *ngIf="userAccountDataFormModel.myProfileFormErrors.country.length">
                <span class="errorMessage__inside">
                  <span *ngFor="let countryMessage of userAccountDataFormModel.myProfileFormErrors.country">{{
                    countryMessage | translate
                  }}</span>
                </span>
              </span>
            </div>
          </div>
        </div>

        <div class="buttonWrapper">
          <a class="button button--red" *ngIf="myProfileForm.valid || myProfileSending" (click)="myProfileOnSubmit(myProfileForm.value)"
            >{{ 'USER_ACCOUNT.SAVE_DATA_BUTTON_LABEL' | translate }}
            <i class="fa fa-angle-right" aria-hidden="true"></i>
          </a>
          <a
            class="button button--red button--disabled"
            hide-delay="0"
            tooltip="{{ 'GLOBAL.INVALID_VALIDATION_FORM_FIELDS_TOOLTIP' | translate }}"
            *ngIf="!(myProfileForm.valid || myProfileSending)"
            >{{ 'USER_ACCOUNT.SAVE_DATA_BUTTON_LABEL' | translate }}
            <i class="fa fa-angle-right" aria-hidden="true"></i>
          </a>
        </div>
      </form>
    </div>

    <div
      *ngIf="!layout.loggedUserData?.isFacebook"
      class="userAccountComponent__item userAccountComponent__item--oneColumn"
      [class.userAccountComponent__item--active]="tabs.activeValue === 'change-password'"
    >
      <div class="paragraphsContainer">
        <p [innerHTML]="'USER_ACCOUNT.CURRENT_PASSWORD_PARAGRAPH' | translate"></p>
      </div>

      <form
        class="form"
        [formGroup]="changePasswordForm"
        (ngSubmit)="changePasswordOnSubmit(changePasswordForm.value)"
        (keyup.enter)="changePasswordOnSubmit(changePasswordForm.value)"
        *ngIf="isUserLogged"
      >
        <div class="oneColumn">
          <div class="form-element inputWrapper">
            <label for="changePasswordCurrentPassword"
              >{{ 'USER_ACCOUNT.CURRENT_PASSWORD_LABEL' | translate }}
              <span class="required">*</span>
            </label>
            <input type="password" id="changePasswordCurrentPassword" formControlName="currentPassword" required />
            <span class="errorMessage" *ngIf="changePasswordFormModel.changePasswordFormErrors.currentPassword.length">
              <span class="errorMessage__inside">
                <span *ngFor="let currentPasswordMessage of changePasswordFormModel.changePasswordFormErrors.currentPassword">{{
                  currentPasswordMessage | translate
                }}</span>
              </span>
            </span>
          </div>

          <div class="form-element inputWrapper">
            <label for="changePasswordNewPassword"
              >{{ 'USER_ACCOUNT.NEW_PASSWORD_LABEL' | translate }}
              <span class="required">*</span>
            </label>
            <input
              type="password"
              id="changePasswordNewPassword"
              formControlName="newPassword"
              validateEqual="newPasswordRepeat"
              reverse="true"
              required
            />
            <span class="errorMessage" *ngIf="changePasswordFormModel.changePasswordFormErrors.newPassword.length">
              <span class="errorMessage__inside">
                <span *ngFor="let newPasswordMessage of changePasswordFormModel.changePasswordFormErrors.newPassword">{{
                  newPasswordMessage | translate
                }}</span>
              </span>
            </span>
            <span class="hints">{{ 'GLOBAL.VALIDATION_MESSAGES.PASSWORD_FORMAT_HINT' | translate }}</span>
          </div>

          <div class="form-element inputWrapper">
            <label for="changePasswordNewPasswordRepeat"
              >{{ 'USER_ACCOUNT.NEW_PASSWORD_REPEAT_LABEL' | translate }}
              <span class="required">*</span>
            </label>
            <input
              type="password"
              id="changePasswordNewPasswordRepeat"
              formControlName="newPasswordRepeat"
              validateEqual="newPassword"
              reverse="false"
              required
            />
            <span class="errorMessage" *ngIf="changePasswordFormModel.changePasswordFormErrors.newPasswordRepeat.length">
              <span class="errorMessage__inside">
                <span *ngFor="let newPasswordRepeatMessage of changePasswordFormModel.changePasswordFormErrors.newPasswordRepeat">{{
                  newPasswordRepeatMessage | translate
                }}</span>
              </span>
            </span>
          </div>

          <div class="buttonWrapper">
            <a
              class="button button--red"
              *ngIf="changePasswordForm.valid || changePasswordSending"
              (click)="changePasswordOnSubmit(changePasswordForm.value)"
              >{{ 'USER_ACCOUNT.SUBMIT_BUTTON_LABEL' | translate }}
              <i class="fa fa-angle-right" aria-hidden="true"></i>
            </a>
            <a
              class="button button--red button--disabled"
              hide-delay="0"
              tooltip="{{ 'GLOBAL.INVALID_VALIDATION_FORM_FIELDS_TOOLTIP' | translate }}"
              *ngIf="!(changePasswordForm.valid || changePasswordSending)"
              >{{ 'USER_ACCOUNT.SUBMIT_BUTTON_LABEL' | translate }}
              <i class="fa fa-angle-right" aria-hidden="true"></i>
            </a>
          </div>
        </div>
      </form>
    </div>

    <div
      class="userAccountComponent__item userAccountComponent__item--oneColumn"
      [class.userAccountComponent__item--active]="tabs.activeValue === 'newsletter'"
    >
      <div class="paragraphsContainer">
        <p [innerHTML]="'USER_ACCOUNT.NEWSLETTER_PARAGRAPH' | translate"></p>
      </div>

      <form
        class="form"
        [formGroup]="newsletterForm"
        (ngSubmit)="newsletterOnSubmit()"
        (keyup.enter)="newsletterOnSubmit()"
        *ngIf="isUserLogged"
      >
        <div class="oneColumn">
          <div class="form-element inputWrapper">
            <label for="newsletterSubscribe"
              >{{ 'USER_ACCOUNT.SUBSCRIBE_TO_NEWSLETTER_LABEL' | translate }}
              <span class="required">*</span>
            </label>
            <select id="newsletterSubscribe" formControlName="subscribe" required>
              <option value="n">{{ 'USER_ACCOUNT.NO_LABEL' | translate }}</option>
              <option value="y">{{ 'USER_ACCOUNT.YES_LABEL' | translate }}</option>
            </select>
            <span class="errorMessage" *ngIf="userAccountNewsletterFormModel.newsletterFormErrors.subscribe.length">
              <span class="errorMessage__inside">
                <span *ngFor="let subscribeMessage of userAccountNewsletterFormModel.newsletterFormErrors.subscribe">{{
                  subscribeMessage | translate
                }}</span>
              </span>
            </span>
          </div>

          <div class="buttonWrapper">
            <a
              class="button button--red"
              *ngIf="newsletterForm.valid || newsletterSending"
              (click)="newsletterOnSubmit(newsletterForm.value)"
              >{{ 'USER_ACCOUNT.SUBMIT_BUTTON_LABEL' | translate }}
              <i class="fa fa-angle-right" aria-hidden="true"></i>
            </a>
            <a
              class="button button--red button--disabled"
              hide-delay="0"
              tooltip="{{ 'GLOBAL.INVALID_VALIDATION_FORM_FIELDS_TOOLTIP' | translate }}"
              *ngIf="!(newsletterForm.valid || newsletterSending)"
              >{{ 'USER_ACCOUNT.SUBMIT_BUTTON_LABEL' | translate }}
              <i class="fa fa-angle-right" aria-hidden="true"></i>
            </a>
          </div>
        </div>
      </form>
    </div>


    <div
    class="userAccountComponent__item userAccountComponent__item--oneColumn"
    [class.userAccountComponent__item--active]="tabs.activeValue === 'biometric-login'"
  >
    <div 
      *ngIf="disableWebauthRegistration != '1' && isWebAuthnAvailable" 
    >
      <div class="paragraphsContainer" >
      <p [innerHTML]="'USER_ACCOUNT.WEBAUTH.SETTINGS_TEXT' | translate"></p>
      </div>

        <form
          class="form"
          [formGroup]="newsletterForm"
          (ngSubmit)="webauthRegister()"
          (keyup.enter)="webauthRegister()"
          *ngIf="isUserLogged"
        >
          <div class="oneColumn">
            <div class="buttonWrapper">
              <a
                class="button button--red dont-break-out mb35"
                (click) ="webauthRegister()"
                >{{ 'USER_ACCOUNT.WEBAUTH.REGISTER_BROWSER' | translate}}
                <i class="fa fa-angle-right" aria-hidden="true"></i>
              </a>
              
            </div>
          </div>
        </form>
    </div>


    <div 
    *ngIf="!isWebAuthnAvailable" 
  >
    <div class="paragraphsContainer" >
    <p [innerHTML]="'USER_ACCOUNT.WEBAUTH.NON_SUPPORT_BROWSER' | translate"></p>
    </div>

     
  </div>


    <div 
      *ngIf="authenticators && authenticators.length > 0 " 
    >
      <h3 [innerHTML]="'USER_ACCOUNT.WEBAUTH.BROWSER_LIST' | translate"></h3>
        <table class="table">
          <thead>
            <tr>
              <th scope="col">{{ 'USER_ACCOUNT.WEBAUTH.BROWSER' | translate }}</th>
              <th scope="col">{{ 'USER_ACCOUNT.WEBAUTH.REGISTER_AT' | translate  }}</th>
              <th scope="col">{{ 'USER_ACCOUNT.WEBAUTH.REMOVE_HEADER' | translate  }}</th>
            </tr>
          </thead>
        <tbody>
          <tr *ngFor="let authenticator of authenticators">
            <td>{{authenticator.description}}</td>
            <td>{{authenticator.createdAt.date | date}}</td>
            <td>
              <a  
              (click) ="removeAuthenticator(authenticator.id)"
              >{{ 'USER_ACCOUNT.WEBAUTH.REMOVE' | translate }}</a>
            </td>
          </tr>
        </tbody>
      </table>
     
      <br/>
  </div>




</div>




    
  </div>
  

</div>

