import { NgModule } from '@angular/core';
import { SliderComponent } from './slider/slider.component';
import { SlideItemDirective } from './slide-item/slide-item.directive';
import { CommonModule } from '@angular/common';
import { SliderPaginatorComponent } from './slider-paginator/slider-paginator.component';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [
    SlideItemDirective,
    SliderComponent,
    SliderPaginatorComponent
  ],
  exports: [
    SlideItemDirective,
    SliderComponent,
    SliderPaginatorComponent
  ]
})
export class SliderModule {}