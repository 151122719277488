import { UserService } from '../user.service';
import { AppLanguageService } from '../language.service';
import { Injectable, OnDestroy } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';
import { requestConstants, storageConstants, globalConstants } from '../../constants/index';
import { requestHelper } from '../../helpers/index';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Cacheable } from '../../cache/cacheable';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { PlatformService } from '../platform.service';
import { AccessTokenService } from '../access-token.service';
import { AccessToken } from '../../models/accessToken.model';

@Injectable() export class SliderRequestService implements OnDestroy {
  data
  observable
  userLanguage
  languageChangeSubscribe
  userDataChangeSubscribe

  protected headers: HttpHeaders = new HttpHeaders()
  private sliderImages: Cacheable<any> = new Cacheable<any>()

  constructor(
    private http: HttpClient,
    private deviceService: DeviceDetectorService,
    private appLanguageService: AppLanguageService,
    private userService: UserService,
    private platformService: PlatformService,
    private accessToken: AccessTokenService) {
    this.refreshUrlProperties();
    this.sliderImages.dataFactory = () => {
      return this.http.get(this.formatRequestUrl(), { observe: 'response', headers: this.getRequestHeaders(true), withCredentials: true })
        .pipe(
          map(response =>  {
            this.observable = null;
            if (response.status !== 200) {
              this.refreshUrlProperties();
              this.refresh();
            } else if (response.status === 200) {
              this.data = response.body;
              
              return this.data;
            }
          })
        );
    };

    this.languageChangeSubscribe = this.appLanguageService.languageHasBeenChanged$.subscribe(() => {
      this.refreshUrlProperties();
      this.refresh();
    });

    this.userDataChangeSubscribe = this.userService.userDataChange.subscribe(() => {
      this.refreshUrlProperties();
      this.refresh();
    });
  }

  formatRequestUrl() {
    let url = requestConstants['API_URL'] + requestConstants['HOMEPAGE_SLIDER']['URL'];
    url = requestHelper.urlReplace(url, {
      locale: this.userLanguage,
      deviceType: (this.deviceService.isMobile() || this.deviceService.isTablet()) ? 'mobile' : 'desktop'
    });

    return url;
  }

  dataExists() {
    const dataInfo = this.sliderImages.get();
    if (typeof dataInfo !== 'undefined' && dataInfo !== null) {
      return true;
    }
    
    return false;
  }

  refreshUrlProperties() {
    this.userLanguage = this.platformService.browser 
      ? localStorage.getItem(storageConstants['USER_LANGUAGE'])
      : globalConstants.DEFAULT_LANGUAGE;
  }

  // * Get the Access Token from the Auth Service
  private getAccessToken(): AccessToken {
    return this.accessToken.getToken();
  }

  // * Get Http Headers, with Authentication or not
  getRequestHeaders(isJsonRequest = false): HttpHeaders {
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', isJsonRequest ? 'application/json' : 'x-www-form-urlencoded');
    headers = headers.set('Access-Control-Allow-Headers', 'Content-Type,Accept');
    headers = headers.set('Access-Control-Allow-Methods', 'GET, POST, OPTIONS, PUT, DELETE, PATCH');
    headers = headers.set('Client', requestConstants['APPLICATION_NAME']);

    // * Add Authorization Header
    if (this.getAccessToken()) {
      headers = headers.set('Authorization', `Bearer ${ this.getAccessToken().access_token }`);
    }
    
    return headers;
  }

  public getList(): Observable<any> {
    this.refreshUrlProperties();
    
    return this.sliderImages.resolve();
  }

  public refresh(): Observable<any> {
    this.refreshUrlProperties();
    
    return this.sliderImages.refresh();
  }

  ngOnDestroy() {
    this.languageChangeSubscribe.unsubscribe();
    this.userDataChangeSubscribe.unsubscribe();
  }

}
