import { Injectable } from '@angular/core';
import { requestConstants } from '../../constants/index';
import { RequestService } from './request.service';

@Injectable() export class VendorRequestService {

  /**
   * @constructor
   */
  constructor(private requestService: RequestService) {}

  /**
   * Makes 'winner list' request.
   * @returns <promise>
   */
  listRequest() {
    const withToken = false;
    const url = requestConstants['API_URL'] + requestConstants['VENDOR_LIST']['URL'];
    
    return this.requestService.listRequest(url, {}, withToken);
  }
}
