import { TranslateService } from '@ngx-translate/core';
import { Component } from '@angular/core';
import { NavigationService } from '../../services';

@Component({
  selector: 'account-frozen',
  templateUrl: './accountFrozen.component.html'
})

export class AccountFrozenComponent {

  content;
  /**
	 * @constructor
	 */
  constructor(
    private translate: TranslateService,
    private navigationService: NavigationService
  ) {
    const url = `${this.navigationService.constructUrl('CASHIER')  }?tab=documents`;
    this.translate.get('ACCOUNT_FROZEN.CONTENT', {
      documentsUrl: url
    }).subscribe((translatedText: string) => {
      this.content = translatedText;
    });
  }
}
