import { Injectable, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { globalConstants, storageConstants } from '../constants/index';
import { TranslateService } from '@ngx-translate/core';
import { PlatformService } from './platform.service';

@Injectable()
export class AppLanguageService {
  public currentLanguage = globalConstants['DEFAULT_LANGUAGE'];
  public defaultLanguage = globalConstants['DEFAULT_LANGUAGE'];
  public languageChange$ = new EventEmitter();
  public languageHasBeenChanged$ = new EventEmitter();

  /**
   * @constructor
   */
  constructor(private translate: TranslateService, private _router: Router, private platformService: PlatformService) {
    if (!translate.getLangs().length) {
      this.setLanguages();
    }
  }

  /**
   * Gets language from local storage.
   * @returns {String}
   */
  getLanguageFromStorage() {
    return this.platformService.browser ? localStorage.getItem(storageConstants['USER_LANGUAGE']) : undefined;
  }

  /**
   * Sets languages.
   */
  setLanguages() {
    const languageFromStorage = this.getLanguageFromStorage();
    const onlyValueLangages = globalConstants['LANGUAGES'].map(element => {
      return element.value;
    });

    this.translate.addLangs(onlyValueLangages);
    this.translate.setDefaultLang(this.defaultLanguage);

    const browserLang = this.platformService.browser ? this.translate.getBrowserLang() : undefined;
    const foundLanguage =
      typeof onlyValueLangages.find(languageItem => {
        return languageItem === browserLang;
      }) !== 'undefined';

    const firstUrlPathSegment = this._router.url.substring(1, (this._router.url.substring(1) || '').indexOf('/') + 1);

    const urlLanguage = globalConstants.LANGUAGES.some(it => it.value === firstUrlPathSegment) ? firstUrlPathSegment : undefined;

    this.currentLanguage = foundLanguage ? browserLang : this.defaultLanguage;

    if (languageFromStorage) {
      this.currentLanguage = languageFromStorage;
    } else if (urlLanguage) {
      this.currentLanguage = urlLanguage;
    }

    if (this.platformService.browser) {
      localStorage.setItem(storageConstants['USER_LANGUAGE'], this.currentLanguage);
    }

    this.translate.use(this.currentLanguage);
    this.languageChange$.emit(this.currentLanguage);
  }

  /**
   * Sets language by hand.
   * @param {String} language
   * @param {Boolean} [withReload=false]
   * @param {Boolean} [withChangeLanguageInUrl=true]
   */
  setLanguageByHand(language, withReload = true, withChangeLanguageInUrl = true) {
    const oldLanguage = this.currentLanguage;

    this.currentLanguage = language;

    if (this.platformService.browser) {
      localStorage.setItem(storageConstants['USER_LANGUAGE'], language);
    }

    if (oldLanguage && oldLanguage === language) {
      return;
    }

    this.translate.use(language);
    this.languageChange$.emit(language);
    this.languageHasBeenChanged$.emit(language);

    if (withChangeLanguageInUrl) {
      this.changeLanguageInUrl(language);
    }

    if (this.platformService.browser && withReload) {
      setTimeout(function () {
        window.location.href = `/${language}`;
      }, 3000);

      return;
    }
  }

  /**
   * Changes language in URL
   * @param newLanguage {String}
   */
  changeLanguageInUrl(newLanguage) {
    this._router.navigateByUrl(`/${newLanguage}`);
  }
}

