import {
  storageConstants,
  routingConstants,
  globalConstants,
  envConstants
} from '../constants/index';
import { Subject } from 'rxjs';

let visibleBonusPanel = new Subject<boolean>();

export const commonPagesHelper = {
  visibleBonusPanel: false,

  get bonusPanelState() {
    return this.visibleBonusPanel;
  },

  set bonusPanelState(state) {
    this.visibleBonusPanel = state;
  },

  /**
   * Gets environment constant if exists, gets it from a global file if needed
   */
  getEnvConstant(key, fallback) {
    if (envConstants[key]) {
      return envConstants[key];
    } else {
      return fallback;
    }
  },

  /**
   * Shows/hides loader decorator.
   * Toggle HTML class 'page--loading' from specific (default: body) tag.
   * @param [action='show'] {String} - enum: 'show' or 'hide'
   * @param [selector='body'] {String}
   */
  loader(action = 'show', selector = 'body') {
    if (typeof document === 'undefined' || !document.querySelector) return;
    const loadingClassName = 'page--loaded';
    const specificElement = document.querySelector(selector);
    const specificMethod = (action === 'show') ? 'remove' : 'add';

    specificElement.classList[specificMethod](loadingClassName);
  },

  /**
   * Shows/hides login panel.
   * Toggle HTML class 'loginPanel--show' from specific (default: body) tag.
   * @param [action='shiw'] {String} - enum: 'show' or 'hide'
   * @param [selector='body'] {String}
   */
  loginPanel(action = 'show', selector = 'body') {
    const specificClassName = 'loginPanel--show';
    const loginDropdownClassName = 'active';
    const specificElement = document.querySelector(selector);
    const specificMethod = (action === 'show') ? 'add' : 'remove';

    document.querySelector('.js--login__dropdown').classList[specificMethod](loginDropdownClassName);
    specificElement.classList[specificMethod](specificClassName);
  },

  /**
   * Shows/hides webauthnSuggessModalPanel.
   * Toggle HTML class 'webauthnSuggessModalPanel--show' from specific (default: body) tag.
   * @param [action='shiw'] {String} - enum: 'show' or 'hide'
   * @param [selector='body'] {String}
   */
  webauthnSuggessModalPanel(action = 'show', selector = 'body') {
    const specificClassName = 'webauthnSuggessModalPanel--show';
    const loginDropdownClassName = 'active';
    const specificElement = document.querySelector(selector);
    const specificMethod = (action === 'show') ? 'add' : 'remove';

    //document.querySelector('.js--login__dropdown').classList[specificMethod](loginDropdownClassName);
    specificElement.classList[specificMethod](specificClassName);
  },

  /**
   * Shows/hides user panel.
   * Toggle HTML class 'userPanel--show' from specific (default: body) tag.
   * @param [action='shiw'] {String} - enum: 'show' or 'hide'
   * @param [selector='body'] {String}
   */
  userPanel(action = 'show', selector = 'body') {
    const specificClassName = 'userPanel--show';
    const specificElement = document.querySelector(selector);
    const specificMethod = (action === 'show') ? 'add' : 'remove';

    specificElement.classList[specificMethod](specificClassName);
  },

    /**
   * Shows/hides webauthn register suggess panel.
   * Toggle HTML class 'registerPanel--show' from specific (default: body) tag.
   * @param [action='shiw'] {String} - enum: 'show' or 'hide'
   * @param [selector='body'] {String}
   */
    webauthnPanel(action = 'show', selector = 'body') {
      const specificClassName = 'webauthnPanel--show';
      const specificElement = document.querySelector(selector);
      const specificMethod = (action === 'show') ? 'add' : 'remove';
  
      specificElement.classList[specificMethod](specificClassName);
    },

    
  /**
   * Shows/hides register panel.
   * Toggle HTML class 'registerPanel--show' from specific (default: body) tag.
   * @param [action='shiw'] {String} - enum: 'show' or 'hide'
   * @param [selector='body'] {String}
   */
  registerPanel(action = 'show', selector = 'body') {
    const specificClassName = 'registerPanel--show';
    const specificElement = document.querySelector(selector);
    const specificMethod = (action === 'show') ? 'add' : 'remove';

    specificElement.classList[specificMethod](specificClassName);
  },

  /**
   * Shows/hides TAC modal.
   * Toggle HTML class 'tacModal--show' from specific (default: body) tag.
   * @param [action='shiw'] {String} - enum: 'show' or 'hide'
   * @param [selector='body'] {String}
   */
  tacModal(action = 'show', selector = 'body') {
    const specificClassName = 'tacModal--show';
    const specificElement = document.querySelector(selector);
    const specificMethod = (action === 'show') ? 'add' : 'remove';

    specificElement.classList[specificMethod](specificClassName);
  },

  /**
   * Enables/disables fullscreen.
   * Toggle HTML class 'fullscreen' from specific (default: body) tag.
   * @param [action='enable'] {String} - enum: 'enable' or 'disable'
   * @param [selector='body'] {String}
   */
  fullscreen(action = 'enable', selector = 'body') {
    const specificClassName = 'fullscreen';
    const specificElement = document.querySelector(selector);
    const specificMethod = (action === 'enable') ? 'add' : 'remove';

    specificElement.classList[specificMethod](specificClassName);
  },

  /**
   * Expands/collapses tab modals.
   * Toggle HTML class 'headerMenu--show' from specific (default: body) tag.
   * @param [action='expand'] {String} - enum: 'expand' or 'collapse'
   * @param [selector='body'] {String}
   */
  headerMenu(action = 'show', selector = 'body') {
    const specificClassName = 'headerMenu--show';
    const specificElement = document.querySelector(selector);
    let specificMethod: string;

    if (action === 'toggle') {
      specificMethod = action;
    } else {
      specificMethod = (action === 'show') ? 'add' : 'remove';
    }

    specificElement.classList[specificMethod](specificClassName);
  },

  /**
   * Unsubscribe multi observes.
   * @param {Array} observes
   */
  unsubscribeObserves(observes) {
    if (!observes.length) {
      return;
    }

    observes.forEach((observe) => {
      if (typeof (observe) !== 'undefined') {
        observe.unsubscribe();
      }
    });
  },


  /**
   * Gets user data from storage.
   * @return {Object|false}
   */
  userIsLogged() {
    const currentUserData = localStorage.getItem(storageConstants['CURRENT_USER']);
    if (!currentUserData) {
      return false;
    }

    return Boolean(JSON.parse(currentUserData));
  },

  /**
   * Goes to specific page
   * @param {String} url
   * @param {Object} parameters
   * @param {String} language
   * @returns {String}
   */
  constructUrl(slug, parameters = {}, language = undefined) {
    if (!language) {
      language = typeof localStorage !== 'undefined'
        ? localStorage.getItem(storageConstants['USER_LANGUAGE'])
        : globalConstants.DEFAULT_LANGUAGE;
    }
    const languagesData = routingConstants[language.toUpperCase()];
    if (typeof (languagesData[slug]['slug']) === 'undefined') {
      console.error('language error (routingConstants[language][slug][\'slug\'])');

      return '';
    }

    let localeUrl = `/${language}/${languagesData[slug]['slug']}`;
    const parameterKeys = Object.keys(parameters);
    const haveParameters = (parameterKeys.length > 0);

    if (haveParameters) {
      parameterKeys.forEach((parameterKey) => {
        localeUrl = localeUrl.replace(`:${parameterKey}`, parameters[parameterKey]);
      });
    }

    return localeUrl;
  },

  removeUrlParameter(url, parameter) {
    const urlParts = url.split('?');

    if (urlParts.length >= 2) {
      // Get first part, and remove from array
      const urlBase = urlParts.shift();

      // Join it back up
      const queryString = urlParts.join('?');

      const prefix = `${encodeURIComponent(parameter)}=`;
      const parts = queryString.split(/[&;]/g);

      // Reverse iteration as may be destructive
      // eslint-disable-next-line no-plusplus
      for (let i = parts.length; i-- > 0;) {
        // Idiom for string.startsWith
        if (parts[i].lastIndexOf(prefix, 0) !== -1) {
          parts.splice(i, 1);
        }
      }

      url = `${urlBase}?${parts.join('&')}`;
    }

    return url;
  }
};

