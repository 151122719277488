import { Injectable, EventEmitter } from '@angular/core';
import {
  storageConstants,
} from '../constants/index';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { PlatformService } from './platform.service';
import { Router } from '@angular/router';
import { commonPagesHelper } from '../helpers';
import { delay } from 'rxjs/operators';
import { AccessTokenService } from './access-token.service';

@Injectable()
export class UserService {
  private _isLogged = false
  private _isFullyRegistered = false
  private _userDataChange = new BehaviorSubject<any>(undefined)
  private _favoriteGamesChange = new BehaviorSubject<any>(undefined)
  public userBalanceChange$ = new EventEmitter()

  /**
   * @constructor
   */
  constructor(platformService: PlatformService, private router: Router, private accessToken: AccessTokenService) {
    this._userDataChange.subscribe(user => {
      this._isLogged = user !== null && user !== undefined;
      this._isFullyRegistered = this._isLogged && user.isFullyRegistered;
    });
    try {
      const currentUser = platformService.browser
        ? localStorage.getItem(storageConstants.CURRENT_USER)
        : undefined;
      if (currentUser && currentUser.length) {
        this._userDataChange.next(JSON.parse(currentUser));
      }
    } catch (e) {
      console.error(e);
    }

    try {
      const favorites = platformService.browser
        ? localStorage.getItem(storageConstants['FAVORITE_GAMES'])
        : undefined;
      if (favorites && favorites.length) {
        this._favoriteGamesChange.next(JSON.parse(favorites));
      }
    } catch (e) {
      console.error(e);
    }
  }

  /**
   * Returns true if user is logged.
   * @returns {Boolean}
   */
  isLogged() {
    return this._isLogged;
  }

  /**
   * Returns true if user is fully registered.
   * @returns {Boolean}
   */
  isFullyRegistered() {
    return this._isFullyRegistered;
  }

  /**
   * Stores user data.
   * @param {Object} data
   */
  storeUserData(data) {
    localStorage.setItem(
      storageConstants['CURRENT_USER'],
      JSON.stringify(data)
    );

    localStorage.setItem(storageConstants['IS_AUTHENTICATED'], 'true');
    this._userDataChange.next(data);

    if (data.isAccountFrozen && localStorage.getItem('accountFrozenInformed') !== 'true') {
      localStorage.setItem('accountFrozenInformed', 'true');
      of({}).pipe(delay(1000)).subscribe(() => this.router.navigateByUrl(commonPagesHelper.constructUrl('ACCOUNT_FROZEN')));
    }
  }

  /**
   * Stores user data without emitting changes.
   * @param {Object} data
   */
  storeUserDataWithBalanceRefresh(data) {
    localStorage.setItem(
      storageConstants['CURRENT_USER'],
      JSON.stringify(data)
    );

    this.userBalanceChange$.emit(data);
  }

  storeUserBalanceRefresh(balance) {
    const currentUserString = localStorage.getItem(storageConstants.CURRENT_USER);
    const currentUser = currentUserString ? JSON.parse(currentUserString) : undefined;

    if (currentUser) {
      currentUser.balance = balance;
      localStorage.setItem(
        storageConstants.CURRENT_USER,
        JSON.stringify(currentUser)
      );
    }

    this.userBalanceChange$.emit(currentUser);
  }

  /**
   * Gets user data from storage.
   * @return {Object|false}
   */
  getUserData() {
    return this._userDataChange.value;
  }

  get userDataChange(): Observable<any> { return this._userDataChange; }

  /**
   * Destroys stored user and token data.
   * @param {Boolean} [withUserData=true]
   */
  destroyStoredData(withUserData = true) {
    if (withUserData) {
      localStorage.removeItem(storageConstants['CURRENT_USER']);
      sessionStorage.clear();
      this._userDataChange.next(undefined);
      this.accessToken.clearAccessToken();
    }
    localStorage.removeItem(storageConstants['VISITED_PAGES']);
    localStorage.removeItem(storageConstants['FAVORITE_GAMES']);
    localStorage.removeItem(storageConstants['IS_AUTHENTICATED']);
    localStorage.removeItem('accountFrozenInformed');
    this._favoriteGamesChange.next(undefined);
  }

  /**
   * Stores user favorites games to local storeg in friendly format.
   * @param {Object} data
   */
  setFavoriteGames(data) {
    const games = data.games;
    const favorites = games.map(game => {
      return game.urlSlug;
    });

    localStorage.setItem(
      storageConstants['FAVORITE_GAMES'],
      JSON.stringify(favorites)
    );
    this._favoriteGamesChange.next(favorites);
  }

  /**
   * Gets user favorite games from storage.
   * @return {Object|false}
   */
  getFavoriteGamesFromStorage() {
    return this._favoriteGamesChange.value;
  }
}
