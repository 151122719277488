/* User Account Data Form Model */
export class UserAccountDataFormModel {
  constructor(
    public email?: string,
    public salutation?: string,
    public firstName?: string,
    public lastName?: string,
    public birthDateDay?: string,
    public birthDateMonth?: string,
    public birthDateYear?: string,
    public citizenship?: string,
    public birthCountry?: string,
    public country?: string,
    public occupation?: string,
    public phone?: string,
    public idNumber?: string,
    public streetAndHouseNumber?: string,
    public zipCode?: string,
    public city?: string,
    public myProfileValidationMessages = {
      email: {
        required: 'GLOBAL.VALIDATION_MESSAGES.REQUIRED',
        pattern: 'GLOBAL.VALIDATION_MESSAGES.WRONG_FORMAT_EMAIL'
      },
      salutation: {
        required: 'GLOBAL.VALIDATION_MESSAGES.REQUIRED'
      },
      firstName: {
        required: 'GLOBAL.VALIDATION_MESSAGES.REQUIRED',
        minlength: 'GLOBAL.VALIDATION_MESSAGES.LEAST_TWO_CHARACTERS_LONG',
        maxlength: 'GLOBAL.VALIDATION_MESSAGES.MORE_THAN_NINETYSIX_CHARACTERS_LONG'
      },
      lastName: {
        required: 'GLOBAL.VALIDATION_MESSAGES.REQUIRED',
        minlength: 'GLOBAL.VALIDATION_MESSAGES.LEAST_TWO_CHARACTERS_LONG',
        maxlength: 'GLOBAL.VALIDATION_MESSAGES.MORE_THAN_NINETYSIX_CHARACTERS_LONG'
      },
      birthDateDay: {
        required: 'GLOBAL.VALIDATION_MESSAGES.REQUIRED'
      },
      birthDateMonth: {
        required: 'GLOBAL.VALIDATION_MESSAGES.REQUIRED'
      },
      birthDateYear: {
        required: 'GLOBAL.VALIDATION_MESSAGES.REQUIRED'
      },
      citizenship: {
        required: 'GLOBAL.VALIDATION_MESSAGES.REQUIRED'
      },
      birthCountry: {
        required: 'GLOBAL.VALIDATION_MESSAGES.REQUIRED'
      },
      country: {
        required: 'GLOBAL.VALIDATION_MESSAGES.REQUIRED'
      },
      idNumber: {
        required: 'GLOBAL.VALIDATION_MESSAGES.REQUIRED',
        pattern: 'GLOBAL.VALIDATION_MESSAGES.WRONG_FORMAT_CPF'
      },
      occupation: {
        required: 'GLOBAL.VALIDATION_MESSAGES.REQUIRED',
        minlength: 'GLOBAL.VALIDATION_MESSAGES.LEAST_TWO_CHARACTERS_LONG',
        maxlength: 'GLOBAL.VALIDATION_MESSAGES.MORE_THAN_NINETYSIX_CHARACTERS_LONG'
      },
      phone: {
        required: 'GLOBAL.VALIDATION_MESSAGES.REQUIRED',
        minlength: 'GLOBAL.VALIDATION_MESSAGES.LEAST_SEVEN_CHARACTERS_LONG',
        maxlength: 'GLOBAL.VALIDATION_MESSAGES.MORE_THAN_NINETYSIX_CHARACTERS_LONG',
        pattern: 'GLOBAL.VALIDATION_MESSAGES.INVALID_PHONE'
      },
      streetAndHouseNumber: {
        required: 'GLOBAL.VALIDATION_MESSAGES.REQUIRED',
        minlength: 'GLOBAL.VALIDATION_MESSAGES.LEAST_SIX_CHARACTERS_LONG',
        maxlength: 'GLOBAL.VALIDATION_MESSAGES.MORE_THAN_NINETYSIX_CHARACTERS_LONG'
      },
      zipCode: {
        required: 'GLOBAL.VALIDATION_MESSAGES.REQUIRED',
        minlength: 'GLOBAL.VALIDATION_MESSAGES.LEAST_THREE_CHARACTERS_LONG',
        maxlength: 'GLOBAL.VALIDATION_MESSAGES.MORE_THAN_THIRTYSIX_CHARACTERS_LONG'
      },
      city: {
        required: 'GLOBAL.VALIDATION_MESSAGES.REQUIRED',
        minlength: 'GLOBAL.VALIDATION_MESSAGES.LEAST_THREE_CHARACTERS_LONG',
        maxlength: 'GLOBAL.VALIDATION_MESSAGES.MORE_THAN_NINETYSIX_CHARACTERS_LONG'
      }
    },
    public myProfileFormErrors = {
      email: [],
      salutation: [],
      firstName: [],
      lastName: [],
      birthDateDay: [],
      birthDateMonth: [],
      birthDateYear: [],
      citizenship: [],
      birthCountry: [],
      idNumber: [],
      country: [],
      occupation: [],
      phone: [],
      streetAndHouseNumber: [],
      zipCode: [],
      city: []
    }) {  }
}
