import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { of } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { requestConstants } from '../../constants';
import { requestHelper } from '../../helpers';
import { AppLanguageService, RequestService } from '../../services';
import { StaticData } from './static.model';

@Injectable({
  providedIn: 'root'
})
export class StaticPageService {

  private staticBySlug: { [slug: string]: StaticData } = {}

  constructor(
    private langService: AppLanguageService,
    private requestService: RequestService,
    private router: Router
  ) { }

  get(slug: string) {
    const cacheKey = `${this.langService.currentLanguage}/${slug}`;
    if (cacheKey in this.staticBySlug)
      return of(this.staticBySlug[cacheKey]);
 
    const url = requestHelper.urlReplace(requestConstants.API_URL + requestConstants.STATIC_PAGE.URL, { slug });
    const request = this.requestService.standardRequest(url, requestConstants.STATIC_PAGE.METHOD, {});
    
    return request.pipe(
      map(it => { return it.pageContent; }),
      tap(it => this.staticBySlug[slug] = it, () => this.router.navigate([this.langService.currentLanguage, 'error', '404']))
    );
  }
}
