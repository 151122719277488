import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { StaticData } from './static.model';
import { StaticPageService } from './static.service';

@Injectable({
  providedIn: 'root'
})
export class StaticResolver implements Resolve<StaticData> {

  constructor(private staticService: StaticPageService) {}

  resolve(route: ActivatedRouteSnapshot): StaticData | Observable<StaticData> | Promise<StaticData> {
    if (!route.params.slug) throw new Error('Slug is required.');
    
    return this.staticService.get(route.params.slug);
  }
}
