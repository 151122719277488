import { Injectable } from '@angular/core';
import { requestConstants } from '../../constants/index';
import { requestHelper } from '../../helpers/index';
import { RequestService } from './request.service';
import { AuthenticationService } from '../authentication.service';

@Injectable() export class GameRequestService {

  constructor(private auth: AuthenticationService, private requestService: RequestService) { }

  /**
     * Makes 'game list' request.
     * @param {Object} [parametersMap = {}]
     * @param {Number} [offset = 0]
     * @param {Number} [limit = 20]
     * @returns <promise>
     */
  listRequest(parametersMap = {}, offset = 0, limit = 20) {
    const withToken = true;
    const url = requestHelper.urlReplace(
      requestConstants['API_URL'] + requestConstants['GAME_LIST']['URL'],
      {
        offset: offset,
        limit: limit
      }
    );

    return this.requestService.listRequest(url, parametersMap, withToken);
  }

  /**
     * Makes 'game list with free spins' request.
     * @param {Object} [parametersMap = {}]
     * @param {Number} [offset = 0]
     * @param {Number} [limit = 20]
     * @returns <promise>
     */
  withFreeSpinsListRequest(parametersMap = {}, offset = 0, limit = 20) {
    const withToken = true;
    const url = requestHelper.urlReplace(
      requestConstants['API_URL'] + requestConstants['GAME_LIST_WITH_FREE_SPINS']['URL'],
      {
        offset: offset,
        limit: limit
      }
    );

    return this.requestService.listRequest(url, parametersMap, withToken);
  }

  /**
     * Makes 'game list without free spins' request.
     * @param {Object} [parametersMap = {}]
     * @param {Number} [offset = 0]
     * @param {Number} [limit = 20]
     * @returns <promise>
     */
  withoutFreeSpinsListRequest(parametersMap = {}, offset = 0, limit = 20) {
    const withToken = true;
    const url = requestHelper.urlReplace(
      requestConstants['API_URL'] + requestConstants['GAME_LIST_WITHOUT_FREE_SPINS']['URL'],
      {
        offset: offset,
        limit: limit
      }
    );

    return this.requestService.listRequest(url, parametersMap, withToken);
  }

  /**
     * Makes 'last played game list' request.
     * @param {Object} [parametersMap = {}]
     * @param {Number} [offset = 0]
     * @param {Number} [limit = 20]
     * @returns <promise>
     */
  lastPlayedListRequest(parametersMap = {}, offset = 0, limit = 20) {
    const withToken = true;
    const url = requestHelper.urlReplace(
      requestConstants['API_URL'] + requestConstants['GAME_LIST_LAST_PLAYED']['URL'],
      {
        offset: offset,
        limit: limit
      }
    );

    return this.requestService.listRequest(url, parametersMap, withToken);
  }

  /**
     * Makes 'all game list' request.
     * @param {Object} [parametersMap = {}]
     * @param {Number} [offset = 0]
     * @param {Number} [limit = 20]
     * @returns <promise>
     */
  allGameListRequest() {
    const withToken = false;
    const url = requestConstants['API_URL'] + requestConstants['AUTOSUGGEST_GAME_LIST']['URL'];

    return this.requestService.listRequest(url, {}, withToken);
  }

  /**
     * Makes 'favourite game list' request.
     * @param {Object} [parametersMap = {}]
     * @param {Number} [offset = 0]
     * @param {Number} [limit = 20]
     * @returns <promise>
     */
  favouriteListRequest(parametersMap = {}, offset = 0, limit = 20) {
    const withToken = true;
    const url = requestHelper.urlReplace(
      requestConstants['API_URL'] + requestConstants['FAVOURITE_GAME_LIST']['URL'],
      {
        offset: offset,
        limit: limit
      }
    );

    return this.requestService.listRequest(url, parametersMap, withToken);
  }

  /**
     * Makes 'add game to favourite' request.
     * @param {String} urlSlug
     * @returns <promise>
     */
  addToFavourites(urlSlug) {
    const withToken = true;
    const requestServiceMethod = requestConstants['ADD_TO_FAVOURITE_LIST']['METHOD'].toUpperCase();
    const url = requestHelper.urlReplace(
      requestConstants['API_URL'] + requestConstants['ADD_TO_FAVOURITE_LIST']['URL'],
      {
        urlSlug: urlSlug
      }
    );

    return this.requestService[requestServiceMethod](url, {}, withToken);
  }

  /**
     * Makes 'remove game from favourite' request.
     * @param {String} urlSlug
     * @returns <promise>
     */
  removeFromFavourites(urlSlug) {
    const withToken = true;
    const requestServiceMethod = requestConstants['REMOVE_FROM_FAVOURITE_LIST']['METHOD'].toUpperCase();
    const url = requestHelper.urlReplace(
      requestConstants['API_URL'] + requestConstants['REMOVE_FROM_FAVOURITE_LIST']['URL'],
      {
        urlSlug: urlSlug
      }
    );

    return this.requestService[requestServiceMethod](url, {}, withToken);
  }

  /**
     * Makes 'reality checker' request.
     * @param {Object} urlSlug
     * @returns <promise>
     */
  realityChecker(urlSlug) {
    const withToken = true;

    if (typeof(urlSlug) === 'undefined') {
      console.info('realityChecker: urlSlug parameter is not provided!');

      return;
    }

    const url = requestHelper.urlReplace(
      requestConstants['API_URL'] + requestConstants['REALITY_CHECKER']['URL'],
      {
        urlSlug: urlSlug
      }
    );

    return this.requestService.detailsRequest(url, withToken);
  }

  /**
     * Makes 'confirm reality checker' request.
     * @param {Object} urlSlug
     * @returns <promise>
     */
  confirmRealityChecker(urlSlug, status = 'failure') {
    const withToken = true;

    if (typeof(urlSlug) === 'undefined') {
      console.info('confirmRealityChecker: urlSlug parameter is not provided!');

      return;
    }

    const url = requestHelper.urlReplace(
      requestConstants['API_URL'] + requestConstants['CONFIRM_REALITY_CHECKER']['URL'],
      {
        urlSlug: urlSlug,
        status: status
      }
    );

    return this.requestService.detailsRequest(url, withToken);
  }

  /**
     * Makes 'game details' request.
     * @param {Object} urlSlug
     * @returns <promise>
     */
  gameDetailsRequest(urlSlug) {
    const withToken = false;
    if (typeof(urlSlug) === 'undefined') {
      console.info('gameDetailsRequest: urlSlug parameter is not provided!');

      return;
    }

    const url = requestHelper.urlReplace(
      requestConstants['API_URL'] + requestConstants['GAME_DETAILS']['URL'],
      {
        urlSlug: urlSlug
      }
    );

    return this.requestService.detailsRequest(url, withToken);
  }

  /**
     * Makes 'demo game details' request.
     * @param {Object} urlSlug
     * @returns <promise>
     */
  demoGameDetailsRequest(urlSlug) {
    const withToken = false;
    if (typeof(urlSlug) === 'undefined') {
      console.info('demoGameDetailsRequest: urlSlug parameter is not provided!');

      return;
    }

    const url = requestHelper.urlReplace(
      requestConstants['API_URL'] + requestConstants['DEMO_GAME_DETAILS']['URL'],
      {
        urlSlug: urlSlug
      }
    );

    return this.requestService.detailsRequest(url, withToken);
  }

  /**
     * Makes 'full game details' request.
     * @param {String} urlSlug
     * @returns <promise>
     */
  fullGameDetailsRequest(urlSlug) {
    const withToken = true;
    if (typeof(urlSlug) === 'undefined') {
      console.info('fullGameDetailsRequest: urlSlug parameter is not provided!');

      return;
    }

    const url = requestHelper.urlReplace(
      requestConstants['API_URL'] + requestConstants['DEMO_GAME_DETAILS']['URL'],
      {
        urlSlug: urlSlug
      }
    );

    return this.requestService.detailsRequest(url, withToken);
  }

  /**
     * Makes 'send game rating' request.
     * @param {String} urlSlug
     * @param {Number} rating - from 1 to 5.
     */
  sendGameRatingRequest(urlSlug, rating) {
    const withToken = true;
    if (typeof(urlSlug) === 'undefined') {
      console.info('sendGameRatingRequest: urlSlug parameter is not provided!');

      return;
    }

    const url = requestHelper.urlReplace(
      requestConstants['API_URL'] + requestConstants['SEND_GAME_RATING']['URL'],
      {
        urlSlug: urlSlug
      }
    );
    const requestServiceMethod = requestConstants['SEND_GAME_RATING']['METHOD'].toUpperCase();

    return this.requestService[requestServiceMethod](url, {
      rating: rating
    }, withToken);
  }

  /**
     * Makes 'send game comment' request.
     * @param {String} urlSlug
     * @param {String} comment.
     */
  sendGameCommentRequest(urlSlug, comment) {
    const withToken = true;
    if (typeof(urlSlug) === 'undefined') {
      console.info('sendGameRatingRequest: urlSlug parameter is not provided!');

      return;
    }

    const url = requestHelper.urlReplace(
      requestConstants['API_URL'] + requestConstants['SEND_GAME_COMMENT']['URL'],
      {
        urlSlug: urlSlug
      }
    );
    const requestServiceMethod = requestConstants['SEND_GAME_COMMENT']['METHOD'].toUpperCase();

    return this.requestService[requestServiceMethod](url, {
      comment: comment
    }, withToken);
  }
}
