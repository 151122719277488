import { Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';
import { commonPagesHelper } from '../helpers/commonPages.helper';

export class Cacheable<T> {

  private data: T = undefined

  dataFactory: () => Observable<T>

  resolve(): Observable<T> {
    if (!this.dataFactory) {
      throw new Error('getHandler is not defined');
    }
    if (this.data !== undefined)
      return of(this.data);

    return this.dataFactory().pipe(tap(data => {
      this.data = data;
    }));
  }

  get(): T {
    return this.data;
  }

  reset(): any {
    this.data = undefined;
  }

  refresh(): Observable<T> {
    this.reset();
    
    return this.resolve();
  }
}
