import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import {
  RequestService
} from './request/request.service';

@Injectable() export class GeoLocationService {
  private infoRequest: Observable<any>;
  private _geoInfo = null;
  private _geoInfoChange = new BehaviorSubject<any>(undefined)

  /**
     * @constructor
     */
  constructor(private requestService: RequestService) {
    this._geoInfoChange.subscribe(geoInfo => {
      this._geoInfo = geoInfo;
    });

    this.getGeoLocationInformation().subscribe(data => {
      this._geoInfoChange.next(data);
    });
  }

  getGeoInfo() {
    return this._geoInfoChange.value;
  }

  get geoInfoChange(): Observable<any> { return this._geoInfoChange; }

  onGeoInfoReady(callback) {
    const geoInfo = this.getGeoInfo();
    
    if(!geoInfo) {
      this.geoInfoChange.subscribe(_geoInfo => {
        if(_geoInfo) {
          callback(_geoInfo);
        }
      });
    }
    else {
      callback(geoInfo);
    }
  }

  /**
     * Gets all game list.
     */
  getGeoLocationInformation() {
    const protocol = location.protocol;
    const service = protocol.startsWith('https:') ? 'freegeoip.app' : 'ip-api.com';

    return this.requestService.GET(`${protocol}//${service}/json`, {}, false);
  }
}
