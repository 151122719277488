import { Injectable } from '@angular/core';
import { requestConstants } from '../../constants/index';
import { RequestService } from './request.service';

@Injectable() export class ContactRequestService {

  /**
   * @constructor
   */
  constructor(private requestService: RequestService) {}

  /**
   * Makes 'send message' request.
   * @param {Object} parametersMap
   * @returns <promise>
   */
  sendMessage(parametersMap) {
    const withToken = false;
    const url = requestConstants['API_URL'] + requestConstants['SEND_CONTACT_MESSAGE']['URL'];

    return this.requestService.standardRequest(
      url,
      requestConstants['SEND_CONTACT_MESSAGE']['METHOD'],
      parametersMap,
      withToken
    );
  }
}
