import { Component, Output, EventEmitter, Input, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { regExpValidationConstants, requestConstants } from '../../constants';
import { RegisterFormModel } from '../../models';
import { Subscription, Observable, from } from 'rxjs';
import { commonPagesHelper } from '../../helpers';
import { UserRequestService, ComboNotificationService, NavigationService, UserService, AppLanguageService } from '../../services';
import { ResponseModel, FacebookRegisterResponse } from '../../models/request.model';
import { SocialAuthService, FacebookLoginProvider } from '@abacritt/angularx-social-login';
import { TranslateService } from '@ngx-translate/core';
import { startWith, switchMap } from 'rxjs/operators';
import { PlatformService } from '../../services/platform.service';
import { AccessTokenService } from '../../services/access-token.service';
import { ComboNotificationType } from '../../enums/alert-type.enum';
import { countriesTranslated } from '../../data/index';

@Component({
  selector: '[app-register-modal]',
  templateUrl: './registerModal.component.html',
  styleUrls: ['./registerModal.component.scss'],
})
export class RegisterModalComponent implements OnInit, OnDestroy {
  @Output()
  close = new EventEmitter<string | undefined>();

  private component$: Subscription[] = [];

  facebookLogin$: Subscription;
  facebookRegister$: Subscription;

  form: FormGroup = this.formBuilder.group({
    acceptTermsAndConditions: [false, [Validators.required]],
    email: [undefined, [Validators.required, Validators.pattern(regExpValidationConstants['EMAIL'])]],
    isSubscribedToNewsletter: [0, [Validators.required, Validators.min(0), Validators.max(0)]],
    plainPassword: this.formBuilder.group({
      first: [
        undefined,
        [Validators.required, Validators.minLength(6), Validators.maxLength(96), Validators.pattern(regExpValidationConstants['PASSWORD'])],
      ],
      second: [undefined, [Validators.required, Validators.minLength(6), Validators.maxLength(96)]],
    }),
    country: [undefined, [Validators.required]],
    username: [undefined, [Validators.required, Validators.pattern(regExpValidationConstants['EMAIL'])]],
  });

  @Input()
  loginHidden = false;

  register$: Subscription;

  get registerAcceptanceText(): Observable<string> {
    return this.langService.languageChange$.pipe(
      startWith(''),
      switchMap(() =>
        this.translate.get('REGISTER.ACCEPTANCE_LABEL', {
          tacUrl: this.navigationService.constructUrl('TAC'),
          privacyPolicyUrl: this.navigationService.constructUrl('PRIVACY_POLICY'),
        })
      )
    );
  }

  registerValidationMessages = {
    email: {
      required: 'GLOBAL.VALIDATION_MESSAGES.REQUIRED',
      pattern: 'GLOBAL.VALIDATION_MESSAGES.WRONG_FORMAT_EMAIL',
    },
    password: {
      required: 'GLOBAL.VALIDATION_MESSAGES.REQUIRED',
      minlength: 'GLOBAL.VALIDATION_MESSAGES.LEAST_SIX_CHARACTERS_LONG',
      maxlength: 'GLOBAL.VALIDATION_MESSAGES.MORE_THAN_NINETYSIX_CHARACTERS_LONG',
      pattern: 'GLOBAL.VALIDATION_MESSAGES.WRONG_PASSWORD_FORMAT',
    },
    repeatPassword: {
      required: 'GLOBAL.VALIDATION_MESSAGES.REQUIRED',
      validateEqual: 'GLOBAL.VALIDATION_MESSAGES.MISMATCH_PASSWORD',
      minlength: 'GLOBAL.VALIDATION_MESSAGES.LEAST_SIX_CHARACTERS_LONG',
      maxlength: 'GLOBAL.VALIDATION_MESSAGES.MORE_THAN_NINETYSIX_CHARACTERS_LONG',
    },
    country: {
      required: 'GLOBAL.VALIDATION_MESSAGES.REQUIRED',
    },
    acceptTermsAndConditions: {
      required: 'GLOBAL.VALIDATION_MESSAGES.REQUIRED',
    },
  };

  @Input()
  visible = false;
  countries: any;

  constructor(
    private formBuilder: FormBuilder,
    private userRequestService: UserRequestService,
    private comboNotificationService: ComboNotificationService,
    private navigationService: NavigationService,
    private authService: SocialAuthService,
    private userService: UserService,
    private translate: TranslateService,
    private langService: AppLanguageService,
    private platformService: PlatformService,
    private accessToken: AccessTokenService
  ) {}

  private facebookLoginSuccess(facebookData) {
    this.facebookLogin$.unsubscribe();
    this.facebookLogin$ = undefined;

    // eslint-disable-next-line @typescript-eslint/camelcase
    facebookData.client_id = requestConstants.OAUTH_AUTHENTICATE.CLIENT_ID;
    // eslint-disable-next-line @typescript-eslint/camelcase
    facebookData.client_secret = requestConstants.OAUTH_AUTHENTICATE.CLIENT_SECRET;

    this.facebookRegister$ = this.userRequestService
      .createUserViaFacebook(facebookData)
      .subscribe(this.facebookRegisterSuccess.bind(this), () => {
        commonPagesHelper.loader('hide');
      });
  }

  private facebookLoginError() {
    this.facebookLogin$.unsubscribe();
    this.facebookLogin$ = undefined;
    commonPagesHelper.loader('hide');
  }

  facebookRegister() {
    commonPagesHelper.loader('show');

    this.facebookLogin$ = from(this.authService.signIn(FacebookLoginProvider.PROVIDER_ID))
        .subscribe(
          this.facebookLoginSuccess.bind(this),
          this.facebookLoginError.bind(this)
        );

  }

  private facebookRegisterSuccess(response: FacebookRegisterResponse) {
    this.facebookRegister$.unsubscribe();
    this.facebookRegister$ = undefined;
    commonPagesHelper.loader('hide');

    if (!response || !response.access_token) {
      return;
    }

    // * Set Access Token
    this.accessToken.setAccessToken(response);

    this.userRequestService.detailsRequest().subscribe(
      userData => {
        if (userData.result !== 'ok') {
          return;
        }
        if (!(typeof userData.user === 'object' && userData.user.locale)) {
          return;
        }

        this.userService.storeUserData(userData.user);

        commonPagesHelper.registerPanel('hide');
        this.close.emit();
        this.navigationService.goToDefaultPage();
      },
      error => {
        // * Show Error SnackBar
        this.comboNotificationService.notification(`${error.error_description}.`, ComboNotificationType.Error);
      }
    );
  }

  ngOnDestroy() {
    this.component$.forEach(it => it.unsubscribe());
  }

  ngOnInit() {
    if (this.platformService.server) return;

    this.component$ = [this.form.controls.email.valueChanges.subscribe(it => this.form.controls.username.setValue(it))];

    this.countries = this.getCountries();
  }

  /**
   * Gets countries names by current locale
   */
  getCountries() {
    const locale = this.langService.getLanguageFromStorage();

    return countriesTranslated.hasOwnProperty(locale) ? countriesTranslated[locale] : countriesTranslated['en'];
  }

  register() {
    if (!this.form.valid || this.register$) {
      return;
    }

    const model: RegisterFormModel = this.form.value;
    model.acceptTermsAndConditions = model.acceptTermsAndConditions === true ? 1 : 0;

    if (model.hasOwnProperty('country')) {
      model.address = {
        firstCountry: model.country,
      };
      delete model.country;
    }
    commonPagesHelper.loader('show');

    this.register$ = this.userRequestService.createRequest(model).subscribe(this.registerSuccess.bind(this), this.registerError.bind(this));
  }

  private registerError(error: any) {
    this.register$.unsubscribe();
    this.register$ = undefined;
    commonPagesHelper.loader('hide');

    this.form.controls.plainPassword.reset();
    // * Show Snackbar Error
    this.comboNotificationService.notification(`${error.error_description}.`, ComboNotificationType.Error);
  }

  private registerSuccess(response: ResponseModel) {
    this.register$.unsubscribe();
    this.register$ = undefined;
    commonPagesHelper.loader('hide');

    if (response.result === 'ok') {
      this.close.emit();
      this.form.reset({ isSubscribedToNewsletter: 0 });
      this.form.markAsPristine();
      this.navigationService.goToDefaultPage();
      this.translate.get(['GLOBAL.ALERTS.THANKS_FOR_REGISTRATION_LABEL']).subscribe((translatedValue: string) => {
        // * Show SnackBar Notification
        this.comboNotificationService.notification(
          translatedValue['GLOBAL.ALERTS.THANKS_FOR_REGISTRATION_LABEL'],
          ComboNotificationType.Info
        );
      });
    } else {
      // * Shows SnackBar Error
      this.comboNotificationService.notification(response.message, ComboNotificationType.Error, response.errors);
      this.form.controls.plainPassword.reset();
    }
  }
}

