import { BaseCashierComponent } from '../base.component';
import { Component } from '@angular/core';

@Component({
  selector: 'cashier-transactions',
  templateUrl: '../base.component.html',
  styleUrls: ['../base.component.scss']
})

export class TransactionsComponent extends BaseCashierComponent {
  public iframeId = 'transactions'
}