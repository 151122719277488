import { Component, Host, OnInit, AfterContentChecked, ViewEncapsulation, AfterViewInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CoreLayout } from '../../layouts/index';
import { globalConstants } from '../../constants/index';

import { AppLanguageService } from '../../services/language.service';

import { routingConstants } from '../../constants/index';
import { partnerLogos } from '../../data/partnerLogos.data';
import { licenseBarPaymentLogos } from '../../data/licenseBarPaymentLogos.data';
import { licenseBarVendorLogos } from '../../data/licenseBarVendorLogos.data';
import { PlatformService } from '../../services/platform.service';
import { SliderOptions, sliderResponsiveOptions } from '../../components/slider/slider.model';
import { StartComponentData } from './start.model';
import { Subscription } from 'rxjs';
import { NavigationService, NewsletterRequestService, ComboNotificationService } from '../../services';
import { GameService } from '../../services/index';
import { TranslateService } from '@ngx-translate/core';
import { ComboNotificationType } from '../../enums/alert-type.enum';
import Splide from '@splidejs/splide';

@Component({
  selector: 'start-component',
  templateUrl: './start.component.html',
  styleUrls: ['./start.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class StartComponent implements OnInit, AfterContentChecked, AfterViewInit {
  private component$: Subscription[] = []

  layout
  routingConstants = routingConstants
  imagesPath = globalConstants['IMAGES_DOMAIN']

  cyclicJackpotsSetTimeout
  cyclicJackpotsTimeout = 300000 // 5 minutes

  currentLanguage

  private slidersPlayMode: 'auto' | 'manual' = 'manual'

  mainSliderOptions: SliderOptions = {
    autoInterval: 5000,
    autoPauseOnHover: true,
    loop: true,
    paginatorVisible: true,
    paginatorVisibleSingle: false,
    play: 'auto',
    rowCount: 1,
    slideSize: 1,
    transitionDuration: 200,
  }

  private winnerSliderOptions: SliderOptions = {
    autoInterval: 2000,
    autoPauseOnHover: true,
    loop: true,
    paginatorVisible: false,
    play: 'auto',
    rowCount: 1,
    slideSize: 8,
    transitionDuration: 200
  }

  winnerSliderOptionsResolver = sliderResponsiveOptions({
    '(min-width: 1px) and (max-width: 539px)': {
      ...this.winnerSliderOptions,
      slideSize: 2,
    },
    '(min-width: 540px) and (max-width: 799px)': {
      ...this.winnerSliderOptions,
      slideSize: 3,
    },
    '(min-width: 800px) and (max-width: 1219px)': {
      ...this.winnerSliderOptions,
      slideSize: 4,
    },
    '(min-width: 1220px) and (max-width: 1379px)': {
      ...this.winnerSliderOptions,
      slideSize: 5,
    },
    '(min-width: 1380px) and (max-width: 1579px)': {
      ...this.winnerSliderOptions,
      slideSize: 6,
    },
    '(min-width: 1580px)': this.winnerSliderOptions
  })

  logoSliderOptions: SliderOptions = {
    autoInterval: 2500,
    autoPauseOnHover: false,
    loop: true,
    paginatorVisible: false,
    play: 'auto',
    rowCount: 1,
    slideSize: 7,
    transitionDuration: 200
  }

  logoSliderOptionsResolver = sliderResponsiveOptions({
    '(min-width: 1px) and (max-width: 479px)': {
      ...this.logoSliderOptions,
      slideSize: 1,
    },
    '(min-width: 480px) and (max-width: 519px)': {
      ...this.logoSliderOptions,
      slideSize: 2,
    },
    '(min-width: 520px) and (max-width: 979px)': {
      ...this.logoSliderOptions,
      slideSize: 3,
    },
    '(min-width: 980px) and (max-width: 1219px)': {
      ...this.logoSliderOptions,
      slideSize: 4,
    },
    '(min-width: 1220px) and (max-width: 1279px)': {
      ...this.logoSliderOptions,
      slideSize: 5,
    },
    '(min-width: 1280px)': this.logoSliderOptions
  })

  paymentsSliderOptionsResolver = sliderResponsiveOptions({
    '(max-width: 979px)': {
      autoInterval: 4500,
      autoPauseOnHover: true,
      loop: true,
      paginatorVisible: false,
      play: 'auto',
      rowCount: 1,
      slideSize: 2,
      transitionDuration: 3900,
    },
    '(min-width: 980px)': {
      autoInterval: 0,
      autoPauseOnHover: true,
      loop: true,
      paginatorVisible: false,
      play: 'manual',
      rowCount: 1,
      slideSize: 20,
      transitionDuration: 0,
    }
  })

  vendorsSliderOptionsResolver = sliderResponsiveOptions({
    '(max-width: 979px)': {
      autoInterval: 4500,
      autoPauseOnHover: true,
      loop: true,
      paginatorVisible: false,
      play: 'auto',
      rowCount: 1,
      slideSize: 2,
      transitionDuration: 3900,
    },
    '(min-width: 980px)': {
      autoInterval: 0,
      autoPauseOnHover: true,
      loop: true,
      paginatorVisible: false,
      play: 'manual',
      rowCount: 2,
      slideSize: 20,
      transitionDuration: 0,
    }
  })

  model = {
    rating: 2.35
  }

  logos = partnerLogos

  get licenseBarPaymentLogos() {
    return licenseBarPaymentLogos.filter(it => it.allowedCountries.some(c => c === (this.currentLanguage || globalConstants.DEFAULT_LANGUAGE)));
  }

  get licenseBarVendorLogos() {
    return licenseBarVendorLogos.filter(it => it.allowedCountries.some(c => c === (this.currentLanguage || globalConstants.DEFAULT_LANGUAGE)));
  }

  gameList: {
    forYou: {
      loading: boolean;
      data: any[];
    };
    trending: {
      loading: boolean;
      data: any[];
    };
    new: {
      loading: boolean;
      data: any[];
    };
    winners: {
      loading: boolean;
      data: any[];
    };
    jackpots: {
      loading: boolean;
      data: any[];
    };
  }

  slider = undefined
  sliderSubscriber = undefined

  /**
   * @constructor
   */
  constructor(
    parent: CoreLayout,
    private route: ActivatedRoute,
    private router: Router,
    private appLanguageService: AppLanguageService,
    private platformService: PlatformService,
    public navigationService: NavigationService,
    private newsletterService: NewsletterRequestService,
    private comboNotificationService: ComboNotificationService,
    private translate: TranslateService,
    private gameService: GameService
  ) {
    this.layout = parent;

    if (this.platformService.browser) {
      this.layout.startRecordingScrollPosition = true;

      this.currentLanguage = this.appLanguageService.currentLanguage;

      document.querySelector('html').setAttribute('lang', this.currentLanguage);
    }
  }

  ngOnInit() {
    this.component$ = [this.route.data.subscribe(this.dataInit.bind(this))];


  }

  ngOnDestroy() {
    this.component$.forEach(it => it.unsubscribe());

    if (this.platformService.browser) {
      this.layout.saveWindowScrollPosition();
      this.layout.startRecordingScrollPosition = false;
      if (typeof this.cyclicJackpotsSetTimeout !== 'undefined') {
        clearTimeout(this.cyclicJackpotsSetTimeout);
      }
    }
  }

  ngAfterContentChecked() {
    this.newsletterNotify();
  }

  private newsletterNotify() {
    if (!this.newsletterService.unsubscribeResponse) return;

    const response = this.newsletterService.unsubscribeResponse;
    this.newsletterService.unsubscribeResponse = undefined;

    this.translate
      .get(['USER_ACCOUNT.NEWSLETTER_UNSUBSCRIBE_SUCCESS', 'USER_ACCOUNT.NEWSLETTER_UNSUBSCRIBE_ERROR'])
      .subscribe((messages: any) => {
        if (response.result === 'ok') {
          const message = response.message || messages['USER_ACCOUNT.NEWSLETTER_UNSUBSCRIBE_SUCCESS'];
          // * Show SnackBar Notification
          this.comboNotificationService.notification(message, ComboNotificationType.Success);
        } else {
          const message = response.message || messages['USER_ACCOUNT.NEWSLETTER_UNSUBSCRIBE_ERROR'];
          // * Show SnackBar Notification
          this.comboNotificationService.notification(message, ComboNotificationType.Error);
        }
      });
  }

  dataInit(data: { start: StartComponentData }) {
    this.slider = data.start.slider;
    console.log(data.start.gameList);
    this.gameList = data.start.gameList;
  }

  replaceGameUrl(gameURL){
    return gameURL.replace(/\/assets\/vendors/g, '/elcarado_com/assets/vendors');
  }

  /**
   * Rating on change.
   * @param {Float} value
   * @param {String} target
   */
  ratingChange(value, target) {
    console.log(`ratingChange -> ${target}: `, value);
  }

  navigateTo(url: string) {
    window.location.replace(url);
  }

  goToTop() {
    this.layout.goToTop();
  }

  ngAfterViewInit() {
    new Splide('#sliderGamesForYou',
    {
      updateOnMove: true,
      type: 'slide',
      start: 0,
      perPage: 7,
      autoplay: false,
      perMove: 1,
      breakpoints: {
          912: {
              perPage: 5,
          },
          640: {
              perPage: 3,
          },
          280: {
              perPage: 2,
          },
      },
      rewind: false,
      width: '100%',
      height: 'auto',
      arrows: true,
      interval: 6000,
      pagination: false,
      pauseOnHover: true,
      wheel: false,
      padding: 0,
      drag: true,
      direction: 'ltr',
      clones: 0,
      cloneStatus: false,
      }
    ).mount();

    new Splide('#sliderTrending',
    {
      updateOnMove: true,
      type: 'slide',
      start: 0,
      perPage: 7,
      autoplay: false,
      perMove: 1,
      breakpoints: {
          912: {
              perPage: 5,
          },
          640: {
              perPage: 3,
          },
          280: {
              perPage: 2,
          },
      },
      rewind: false,
      width: '100%',
      height: 'auto',
      arrows: true,
      interval: 6000,
      pagination: false,
      pauseOnHover: true,
      wheel: false,
      padding: 0,
      drag: true,
      direction: 'ltr',
      clones: 0,
      cloneStatus: false,
      }
    ).mount();

    new Splide('#sliderNew',
    {
      updateOnMove: true,
      type: 'slide',
      start: 0,
      perPage: 7,
      autoplay: false,
      perMove: 1,
      breakpoints: {
          912: {
              perPage: 5,
          },
          640: {
              perPage: 3,
          },
          280: {
              perPage: 2,
          },
      },
      rewind: false,
      width: '100%',
      height: 'auto',
      arrows: true,
      interval: 6000,
      pagination: false,
      pauseOnHover: true,
      wheel: false,
      padding: 0,
      drag: true,
      direction: 'ltr',
      clones: 0,
      cloneStatus: false,
      }
    ).mount();

    new Splide('#sliderJackpot',
    {
      updateOnMove: true,
      type: 'slide',
      start: 0,
      perPage: 7,
      autoplay: false,
      perMove: 1,
      breakpoints: {
          912: {
              perPage: 5,
          },
          640: {
              perPage: 3,
          },
          280: {
              perPage: 2,
          },
      },
      rewind: false,
      width: '100%',
      height: 'auto',
      arrows: true,
      interval: 6000,
      pagination: false,
      pauseOnHover: true,
      wheel: false,
      padding: 0,
      drag: true,
      direction: 'ltr',
      clones: 0,
      cloneStatus: false,
      }
    ).mount();

  }
}
