import { CanActivate, ActivatedRouteSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { AppLanguageService } from '../../services';
import { StaticPageService } from './static.service';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class StaticGuard implements CanActivate {
  constructor(
    private langService: AppLanguageService,
    private router: Router,
    private staticService: StaticPageService) {
  }

  canActivate(route: ActivatedRouteSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    if (!route.params.slug) throw new Error('Slug is required.');
    
    return this.staticService.get(route.params.slug).pipe(
      map(it => it ? true : this.router.createUrlTree([`/${this.langService.currentLanguage}/error/404`]))
    );
  }
}