import { RequestService } from '../../services/request/request.service';
import { Component, OnDestroy, Host } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { regExpValidationConstants } from '../../constants/index';
import { UserAccountDataFormModel, ChangePasswordFormModel, UserAccountNewsletterFormModel } from '../../models/index';
import {
  UserRequestService,
  UserService,
  NewsletterRequestService,
  ComboNotificationService,
  AppLanguageService,
  WebauthnRequestService
} from '../../services/index';
import { TranslateService } from '@ngx-translate/core';
import { commonPagesHelper, formHelper } from '../../helpers/index';
import { countriesTranslated } from '../../data/index';
import { CoreLayout } from '../../layouts/index';
import { ComboNotificationType } from '../../enums/alert-type.enum';
import {
  create,
  parseCreationOptionsFromJSON,
} from "@github/webauthn-json/browser-ponyfill";
import { CookieService } from 'ngx-cookie-service';
import {requestConstants} from '../../constants/index';
@Component({
  selector: 'user-account',
  templateUrl: './userAccount.component.html',
  styleUrls: ['./userAccount.component.scss'],
})
export class UserAccountComponent implements OnDestroy {
  layout: any;

  public subscribes = {
    userRequestDetails: undefined,
    userRequestDetailsFailureTranslations: undefined,
    userRequestDetailsErrorTranslations: undefined,
    myProfileFormValueChanges: undefined,
    changePasswordFormValueChanges: undefined,
    newsletterFormValueChanges: undefined,
    userRequestServiceUpdate: undefined,
    userRequestServiceUpdateErrorTranslations: undefined,
    userRequestServiceChangePassword: undefined,
    userRequestServiceChangePasswordSuccessTranslations: undefined,
    userRequestServiceChangePasswordErrorTranslations: undefined,
    newsletterRequestService: undefined,
    newsletterRequestServiceSuccessTranslations: undefined,
    newsletterRequestServiceErrorTranslations: undefined,
    citizenshipsLoader: undefined,
    cpfValueChanges: undefined,
  };
  inputs = {
    birthDate: {
      days: this.getDays(),
      months: [
        {
          optionTranslationKey: 'GLOBAL.MONTHS.JANUARY',
          value: '01',
        },
        {
          optionTranslationKey: 'GLOBAL.MONTHS.FEBRUARY',
          value: '02',
        },
        {
          optionTranslationKey: 'GLOBAL.MONTHS.MARCH',
          value: '03',
        },
        {
          optionTranslationKey: 'GLOBAL.MONTHS.APRIL',
          value: '04',
        },
        {
          optionTranslationKey: 'GLOBAL.MONTHS.MAY',
          value: '05',
        },
        {
          optionTranslationKey: 'GLOBAL.MONTHS.JUNE',
          value: '06',
        },
        {
          optionTranslationKey: 'GLOBAL.MONTHS.JULY',
          value: '07',
        },
        {
          optionTranslationKey: 'GLOBAL.MONTHS.AUGUST',
          value: '08',
        },
        {
          optionTranslationKey: 'GLOBAL.MONTHS.SEPTEMBER',
          value: '09',
        },
        {
          optionTranslationKey: 'GLOBAL.MONTHS.OCTOBER',
          value: '10',
        },
        {
          optionTranslationKey: 'GLOBAL.MONTHS.NOVEMBER',
          value: '11',
        },
        {
          optionTranslationKey: 'GLOBAL.MONTHS.DECEMBER',
          value: '12',
        },
      ],
      years: this.getYears(),
    },
    citizenship: this.getCitizenships(),
    country: this.getCountries(),
    salutation: [
      {
        optionTranslationKey: 'GLOBAL.SALUTATION.MR',
        value: 'm',
      },
      {
        optionTranslationKey: 'GLOBAL.SALUTATION.MRS',
        value: 'f',
      },
    ],
  };

  newsletterEligible = true;
  isWebAuthnAvailable=false;

  tabs = {
    activeValue: 'my-profile',
    data: [
      {
        value: 'my-profile',
        nameTranslationKey: 'USER_ACCOUNT.TABS.MY_PROFILE_LABEL',
        visible: true,
      },
      {
        value: 'change-password',
        nameTranslationKey: 'USER_ACCOUNT.TABS.CHANGE_PASSWORD_LABEL',
        visible: false,
      },
      {
        value: 'newsletter',
        nameTranslationKey: 'USER_ACCOUNT.TABS.NEWSLETTER_LABEL',
        visible: this.newsletterEligible,
        // (!!this.newsletterEligible ? true : this.newsletterEligible)
      },
      {
        value: 'biometric-login',
        nameTranslationKey: 'USER_ACCOUNT.TABS.BIOMETRICAL_LABEL',
        visible: true,
        // (!!this.newsletterEligible ? true : this.newsletterEligible)
      },
    ],
  };

  userAccountDataFormModel = new UserAccountDataFormModel();
  changePasswordFormModel = new ChangePasswordFormModel();
  userAccountNewsletterFormModel = new UserAccountNewsletterFormModel();

  userData: any;

  // * Form Groups
  changePasswordForm: FormGroup;
  newsletterForm: FormGroup;
  myProfileForm: FormGroup;

  citizenships = undefined;

  // * Booleans
  userDataLoaded = false;
  isUserLogged = false;
  changePasswordSending = false;
  newsletterSending = false;
  myProfileSending = false;
  hasIDNumberField = false;
  disableWebauthRegistration= "";
  authenticators=[];

  constructor(
    parent: CoreLayout,
    private userRequestService: UserRequestService,
    private userService: UserService,
    private newsletterRequestService: NewsletterRequestService,
    private _translate: TranslateService,
    private formBuilder: FormBuilder,
    private comboNotificationService: ComboNotificationService,
    public appLanguageService: AppLanguageService,
    private requestService: RequestService,
    private webauthnService: WebauthnRequestService,
    private cookieService: CookieService,
  ) {
    this.layout = parent;
    this.isUserLogged = this.layout.logged;
    if (this.isUserLogged) {
      this.tabs.data[1].visible = Boolean(!this.layout.loggedUserData.isFacebook);
      this.buildForm();
    }
  }

  ngOnInit(): void {
    this.isWebauthnSupported();
    // * Start with the Loader Hidden
    commonPagesHelper.loader('false');

    this.getUserData();

    this.subscribes.citizenshipsLoader = this.loadCitizenships().subscribe(data => {
      this.setCitizenships(data[this.appLanguageService.getLanguageFromStorage()]);
    });
    this.disableWebauthRegistration = this.cookieService.get('disable_webauth_registration');
  }

  loadCitizenships() {
    return this.requestService.GET('assets/i18n/citizenships.json', {});
  }

  validateNewsletterVisibility(): void {
    this.tabs.data.find(x => x.value === 'newsletter').visible = this.newsletterEligible;
  }

  /**
   * Gets countries names by current locale
   */
  getCountries() {
    const locale = this.appLanguageService.getLanguageFromStorage();

    return countriesTranslated[locale];
  }

  /**
   * Format json file to proper array of citizenships
   * @param data loaded json file with all citizenships
   */
  setCitizenships(data) {
    const citizenships = [];
    Object.keys(data)
      .sort()
      .forEach(key => {
        citizenships.push({
          name: data[key],
          value: key,
        });
      });
    citizenships.sort(function (a, b) {
      return a.name.localeCompare(b.name);
    });
    this.citizenships = citizenships;
  }

  /**
   * Returns formatted array for a citizenships json
   */
  getCitizenships() {
    return this.citizenships;
  }

  /**
   * Gets user data.
   */
  getUserData() {
    commonPagesHelper.loader('show');

    this.subscribes.userRequestDetails = this.userRequestService.detailsRequest().subscribe(
      data => {
        if (data['result'] !== 'ok') {
          this.subscribes.userRequestDetailsFailureTranslations = this._translate
            .get(['USER_ACCOUNT.GET_USER_DATA_FAILURE_LABEL'])
            .subscribe((translatedValue: string) => {
              // * Show SnackBar Notification
              this.comboNotificationService.notification(
                translatedValue['USER_ACCOUNT.GET_USER_DATA_FAILURE_LABEL'],
                ComboNotificationType.Error,
                data['errors']
              );
              commonPagesHelper.loader('hide');
            });
             //get authenticator list
            console.log("getting user data");
            this.webauthnService.getBiometricUserBrowserList().subscribe(data =>{
              this.authenticators=data;
            });
        } else {
          this.newsletterEligible = data['user']['newsletterEligible'];
          this.updateAndStoreUserData(data['user']);
        }
      },
      error => {
        if (typeof error === 'object') {
          error = JSON.stringify(error);
        }
        // * Show SnackBar Notification
        this.comboNotificationService.notification(error, ComboNotificationType.Error);
        commonPagesHelper.loader('hide');
      }
    );
    
  }

  /**
   * Updates and store user data based on userData object.
   * @param {Object} userData
   */
  updateAndStoreUserData(userData) {
    if (typeof userData === 'undefined') {
      return;
    }

    this.userService.storeUserData(userData);
    this.userData = userData;

    let birthDate;
    let birthDateDay;
    let birthDateMonth;
    let birthDateYear;
    const isSetBirthDate =
      this.userData['dateOfBirth'] && Object.keys(this.userData['dateOfBirth']).length > 0 && this.userData['dateOfBirth']['date'];

    if (isSetBirthDate) {
      birthDate = this.userData['dateOfBirth']['date'];

      const birthDateSplitted = birthDate.split('-');
      birthDateDay = parseInt(birthDateSplitted[2]);
      birthDateMonth = birthDateSplitted[1];
      birthDateYear = birthDateSplitted[0];
    }

    this.myProfileForm.setValue({
      email: this.userData['email'] || '',
      salutation: this.userData['sex'] || '',
      firstName: this.userData['firstName'] || '',
      lastName: this.userData['lastName'] || '',
      birthDateDay: birthDateDay || '',
      birthDateMonth: birthDateMonth || '',
      birthDateYear: birthDateYear || '',
      citizenship: this.userData['citizenship'] || '',
      birthCountry: this.userData['birthCountry'] || '',
      country: this.userData['address']['firstCountry'] || '',
      idNumber: this.userData['idNumber'] || '',
      occupation: this.userData['occupation'] || '',
      phone: this.userData['address']['telPersonal'] || '',
      streetAndHouseNumber: this.userData['address']['firstAddress'] || '',
      zipCode: this.userData['address']['firstZipCode'] || '',
      city: this.userData['address']['firstCity'] || '',
    });
    this.updateFormValidation(this.myProfileForm);

    if (this.newsletterEligible) {
      this.newsletterForm.setValue({
        subscribe: (this.userData['isSubscribedToNewsletter'] ? 'y' : 'n') || 'n',
      });
      this.updateFormValidation(this.newsletterForm);
    } else {
      this.validateNewsletterVisibility();
    }

    this.userDataLoaded = true;
    commonPagesHelper.loader('false');
  }

  /**
   * Changes active tab.
   * @param {String} value
   */
  tabChange(value) {
    this.tabs.activeValue = value;
    if (value=="biometric-login"){
      this.webauthnService.getBiometricUserBrowserList().subscribe(data =>{
        this.authenticators=data;
      });
    }
  }

  /**
   * Gets days for select field.
   * @handler
   */
  getDays() {
    return Array(31)
      .fill(0)
      .map((x, i) => {
        return i + 1;
      });
  }

  /**
   * Gets years for select field.
   * @handler
   */
  getYears() {
    return Array(100 - 17)
      .fill(0)
      .map((x, i) => {
        return i + (new Date().getFullYear() - 100);
      })
      .reverse();
  }

  /**
   * Builds form.
   */
  buildForm(): void {
    this.myProfileForm = new FormGroup({
      email: new FormControl('', [Validators.required, Validators.pattern(regExpValidationConstants['EMAIL'])]),
      salutation: new FormControl('', Validators.required),
      firstName: new FormControl('', [Validators.required, Validators.minLength(2), Validators.maxLength(96)]),
      lastName: new FormControl('', [Validators.required, Validators.minLength(2), Validators.maxLength(96)]),
      birthDateDay: new FormControl('', Validators.required),
      birthDateMonth: new FormControl('', Validators.required),
      birthDateYear: new FormControl('', Validators.required),
      citizenship: new FormControl('', Validators.required),
      birthCountry: new FormControl('', Validators.required),
      phone: new FormControl('', [
        Validators.required,
        Validators.minLength(7),
        Validators.maxLength(96),
        Validators.pattern(regExpValidationConstants['PHONE']),
      ]),
      streetAndHouseNumber: new FormControl('', [Validators.required, Validators.minLength(6), Validators.maxLength(96)]),
      zipCode: new FormControl('', [Validators.required, Validators.minLength(3), Validators.maxLength(36)]),
      city: new FormControl('', [Validators.required, Validators.minLength(3), Validators.maxLength(96)]),
      country: new FormControl('', Validators.required),
      occupation: new FormControl('', [Validators.required, Validators.minLength(2), Validators.maxLength(96)]),
      idNumber: new FormControl('', []),
    });
    this.subscribes.myProfileFormValueChanges = this.myProfileForm.valueChanges.subscribe(() => {
      formHelper.onValueChanged(
        this.myProfileForm,
        this.userAccountDataFormModel.myProfileFormErrors,
        this.userAccountDataFormModel.myProfileValidationMessages
      );
    });
    formHelper.onValueChanged(
      this.myProfileForm,
      this.userAccountDataFormModel.myProfileFormErrors,
      this.userAccountDataFormModel.myProfileValidationMessages
    );

    //Validate CPF
    this.subscribes.cpfValueChanges = this.myProfileForm.get('country').valueChanges.subscribe(val => {
      if (val === 'BR') {
        this.hasIDNumberField = true;
        this.myProfileForm.controls['idNumber'].setValidators([Validators.required, Validators.pattern(regExpValidationConstants['CPF'])]);
      } else {
        this.hasIDNumberField = false;
        this.myProfileForm.controls['idNumber'].clearValidators();
      }
      this.myProfileForm.controls['idNumber'].updateValueAndValidity();
    });

    this.changePasswordForm = new FormGroup({
      currentPassword: new FormControl('', [
        Validators.required,
        Validators.minLength(6),
        Validators.maxLength(96),
        Validators.pattern(regExpValidationConstants['PASSWORD']),
      ]),
      newPassword: new FormControl('', [
        Validators.required,
        Validators.minLength(6),
        Validators.maxLength(96),
        Validators.pattern(regExpValidationConstants['PASSWORD']),
      ]),
      newPasswordRepeat: new FormControl('', [
        Validators.required,
        Validators.minLength(6),
        Validators.maxLength(96),
        Validators.pattern(regExpValidationConstants['PASSWORD']),
      ]),
    });
    this.subscribes.changePasswordFormValueChanges = this.changePasswordForm.valueChanges.subscribe(() => {
      formHelper.onValueChanged(
        this.changePasswordForm,
        this.changePasswordFormModel.changePasswordFormErrors,
        this.changePasswordFormModel.changePasswordValidationMessages
      );
    });
    formHelper.onValueChanged(
      this.changePasswordForm,
      this.changePasswordFormModel.changePasswordFormErrors,
      this.changePasswordFormModel.changePasswordValidationMessages
    );

    this.newsletterForm = new FormGroup({
      subscribe: new FormControl('', Validators.required),
    });
    this.subscribes.newsletterFormValueChanges = this.newsletterForm.valueChanges.subscribe(() => {
      formHelper.onValueChanged(
        this.newsletterForm,
        this.userAccountNewsletterFormModel.newsletterFormErrors,
        this.userAccountNewsletterFormModel.newsletterValidationMessages
      );
    });
    formHelper.onValueChanged(
      this.newsletterForm,
      this.userAccountNewsletterFormModel.newsletterFormErrors,
      this.userAccountNewsletterFormModel.newsletterValidationMessages
    );
  }

  /**
   * Updates form validation.
   * @param {Object} specificForm
   */
  updateFormValidation(specificForm) {
    for (const i in specificForm.controls) {
      specificForm.controls[i].markAsTouched();
    }
  }

  /**
   * My profile on submit handler.
   * @handler
   */
  myProfileOnSubmit() {
    this.saveUserData(this.myProfileForm.value);
  }

  /**
   * Save user data.
   * @param {Object} parametersMap
   */
  saveUserData(parametersMap: object) {
    const newParametersMap = {};

    if (!parametersMap['email']) {
      return;
    }

    this.myProfileSending = true;
    commonPagesHelper.loader('show');
    parametersMap['email'] = parametersMap['email'].toLowerCase();

    newParametersMap['email'] = parametersMap['email'];
    newParametersMap['sex'] = parametersMap['salutation'];
    newParametersMap['firstName'] = parametersMap['firstName'];
    newParametersMap['lastName'] = parametersMap['lastName'];
    newParametersMap['dateOfBirth'] = {
      day: parseInt(parametersMap['birthDateDay']).toString(),
      month: parseInt(parametersMap['birthDateMonth']).toString(),
      year: parseInt(parametersMap['birthDateYear']).toString(),
    };
    newParametersMap['citizenship'] = parametersMap['citizenship'];
    newParametersMap['birthCountry'] = parametersMap['birthCountry'];
    if (parametersMap['country'] === 'BR') {
      newParametersMap['idNumber'] = parametersMap['idNumber'];
    }
    newParametersMap['address'] = {
      telPersonal: parametersMap['phone'],
      firstAddress: parametersMap['streetAndHouseNumber'],
      firstZipcode: parametersMap['zipCode'],
      firstCity: parametersMap['city'],
      firstCountry: parametersMap['country'],
    };
    newParametersMap['occupation'] = parametersMap['occupation'];

    this.subscribes.userRequestServiceUpdate = this.userRequestService.updateRequest(newParametersMap).subscribe(
      data => {
        if (data['result'] === 'ok') {
          if (typeof data['user'] !== 'undefined') {
            this.updateAndStoreUserData(data['user']);
          }
          // * Show SnackBar Notification
          this.comboNotificationService.notification(data.message, ComboNotificationType.Success);
        } else {
          // * Show SnackBar Notification
          this.comboNotificationService.notification(data['message'], ComboNotificationType.Error, data['errors']);
        }

        this.myProfileSending = false;
        commonPagesHelper.loader('false');
      },
      () => {
        this.subscribes.userRequestServiceUpdateErrorTranslations = this._translate
          .get(['USER_ACCOUNT.PROFILE_NOT_UPDATED_LABEL'])
          .subscribe((translatedValue: string) => {
            // * Show SnackBar Notification
            this.comboNotificationService.notification(
              translatedValue['USER_ACCOUNT.PROFILE_NOT_UPDATED_LABEL'],
              ComboNotificationType.Error
            );
          });

        this.myProfileSending = false;
        commonPagesHelper.loader('false');
      }
    );
  }

  /**
   * Change password on submit handler.
   * @handler
   */
  changePasswordOnSubmit() {
    const parametersMap = {
      currentPassword: this.changePasswordForm.value.currentPassword,
      plainPassword: {
        first: this.changePasswordForm.value.newPassword,
        second: this.changePasswordForm.value.newPasswordRepeat,
      },
    };

    this.changePassword(parametersMap);
  }

  /**
   * Changes password.
   * @param {Object} parametersMap
   */
  changePassword(parametersMap: object) {
    this.changePasswordSending = true;
    commonPagesHelper.loader('show');

    this.subscribes.userRequestServiceChangePassword = this.userRequestService.changePasswordRequest(parametersMap).subscribe(
      data => {
        if (data.result === 'ok') {
          this.subscribes.userRequestServiceChangePasswordSuccessTranslations = this._translate
            .get(['USER_ACCOUNT.CHANGE_PASSWORD_CHANGED_SUCCESSFULLY_LABEL'])
            .subscribe((translatedValue: string) => {
              // * Show SnackBar Notification
              this.comboNotificationService.notification(
                translatedValue['USER_ACCOUNT.CHANGE_PASSWORD_CHANGED_SUCCESSFULLY_LABEL'],
                ComboNotificationType.Success
              );
            });

          this.changePasswordForm.reset();
        } else {
          this.subscribes.userRequestServiceChangePasswordSuccessTranslations = this._translate
            .get(['USER_ACCOUNT.CHANGE_PASSWORD_CHANGED_SUCCESSFULLY_LABEL'])
            .subscribe(() => {
              // * Show SnackBar Notification
              this.comboNotificationService.notification(data.message || '?', ComboNotificationType.Error, data['errors']);
            });
        }

        this.changePasswordSending = false;
        commonPagesHelper.loader('false');
      },
      () => {
        this.subscribes.userRequestServiceChangePasswordErrorTranslations = this._translate
          .get(['USER_ACCOUNT.CHANGE_PASSWORD_NOT_CHANGED_LABEL'])
          .subscribe((translatedValue: string) => {
            // * Show SnackBar Notification
            this.comboNotificationService.notification(
              translatedValue['USER_ACCOUNT.CHANGE_PASSWORD_NOT_CHANGED_LABEL'],
              ComboNotificationType.Error
            );
          });

        this.changePasswordSending = false;
        commonPagesHelper.loader('false');
      }
    );
  }

  /**
   * Newsletter on submit handler.
   * @handler
   */
  newsletterOnSubmit() {
    if (typeof this.userData['email'] === 'undefined') {
      return;
    }
    this.newsletter({
      email: this.userData['email'],
    });
  }

  /**
   * Updates newsletter.
   * @param {Object} parametersMap
   */
  newsletter(parametersMap: object) {
    const newsletterServiceMethod = this.newsletterForm.value.subscribe === 'y' ? 'subscribeToNewsletter' : 'unsubscribeFromNewsletter';
    this.newsletterSending = true;
    commonPagesHelper.loader('show');

    const newsletterSuccessMessageTranslationKey =
      this.newsletterForm.value.subscribe === 'y'
        ? 'USER_ACCOUNT.NEWSLETTER_SUBSCRIBED_SUCCESSFULLY_LABEL'
        : 'USER_ACCOUNT.NEWSLETTER_UNSUBSCRIBED_SUCCESSFULLY_LABEL';

    const newsletterFailureMessageTranslationKey =
      this.newsletterForm.value.subscribe === 'y'
        ? 'USER_ACCOUNT.NEWSLETTER_NOT_SUBSCRIBED_LABEL'
        : 'USER_ACCOUNT.NEWSLETTER_NOT_UNSUBSCRIBED_LABEL';

    this.subscribes.newsletterRequestService = this.newsletterRequestService[newsletterServiceMethod](parametersMap).subscribe(
      data => {
        this.subscribes.newsletterRequestServiceSuccessTranslations = this._translate
          .get([newsletterSuccessMessageTranslationKey])
          .subscribe(() => {
            // * Show SnackBar Notification
            this.comboNotificationService.notification(data.message, ComboNotificationType.Success);
          });

        this.newsletterSending = false;
        commonPagesHelper.loader('false');
      },
      () => {
        this.subscribes.newsletterRequestServiceErrorTranslations = this._translate
          .get([newsletterFailureMessageTranslationKey])
          .subscribe((translatedValue: string) => {
            // * Show SnackBar Notification
            this.comboNotificationService.notification(
              translatedValue[newsletterFailureMessageTranslationKey],
              ComboNotificationType.Error
            );
          });

        this.newsletterSending = false;
        commonPagesHelper.loader('false');
      }
    );
  }

  /**
   * It's native Angular 'on destroy' method.
   * Destroys component.
   */
  ngOnDestroy() {
    commonPagesHelper.unsubscribeObserves([
      this.subscribes.userRequestDetails,
      this.subscribes.userRequestDetailsFailureTranslations,
      this.subscribes.userRequestDetailsErrorTranslations,
      this.subscribes.myProfileFormValueChanges,
      this.subscribes.changePasswordFormValueChanges,
      this.subscribes.newsletterFormValueChanges,
      this.subscribes.userRequestServiceUpdate,
      this.subscribes.userRequestServiceUpdateErrorTranslations,
      this.subscribes.userRequestServiceChangePassword,
      this.subscribes.userRequestServiceChangePasswordSuccessTranslations,
      this.subscribes.userRequestServiceChangePasswordErrorTranslations,
      this.subscribes.newsletterRequestService,
      this.subscribes.newsletterRequestServiceSuccessTranslations,
      this.subscribes.newsletterRequestServiceErrorTranslations,
      this.subscribes.citizenshipsLoader,
      this.subscribes.cpfValueChanges,
    ]);
  }

  webauthRegister(){
    const self = this;
    const parametersMap={};
    commonPagesHelper.loader('show');
    this.webauthnService.getRegisterOptions(parametersMap).subscribe(
      data => {
        this.createWeauthnRegisterOptions(data);
      },
      () => {
        commonPagesHelper.loader('hide');
      }
    );
    
  }
  async createWeauthnRegisterOptions (data){
      commonPagesHelper.loader('show');
      const cco = parseCreationOptionsFromJSON({
      publicKey: data.publickey});
      const response = await create(cco);
      const dataLogin = response.toJSON();
      //dataLogin["client_id"]= requestConstants['OAUTH_AUTHENTICATE']['CLIENT_ID'];
      //dataLogin["client_secret"]=requestConstants['OAUTH_AUTHENTICATE']['CLIENT_SECRET'];
      dataLogin["PHPSESSID"]=data.PHPSESSID;
      this.webauthnService.saveRegisterBiometric(dataLogin).subscribe(data =>{
        commonPagesHelper.loader('hide');
       if (data.status=="ok"){
          //Cookies are not being set by the different subdomain. Need to do it manually
          const claves = Object.keys(data.cookies);
          claves.map(cookie_index =>{
              this.cookieService.set(cookie_index, data.cookies[cookie_index].value, new Date(data.cookies[cookie_index].valid),'/');
          });
          this.cookieService.set('disable_webauth_registration', "1",365,'/');
          this._translate
          .get(['USER_ACCOUNT.BIOMETRIC_REGISTRATION_SUCCESS_LABEL'])
          .subscribe((translatedValue: string) => {
            this.comboNotificationService.notification(
              translatedValue['USER_ACCOUNT.BIOMETRIC_REGISTRATION_SUCCESS_LABEL'],
              ComboNotificationType.Success
            );
          })
          //get authenticator list
          this.webauthnService.getBiometricUserBrowserList().subscribe(data =>{
            this.authenticators=data;
          });
        }
      }),() =>{
        commonPagesHelper.loader('hide');
      }
  }

  removeAuthenticator(id){
    commonPagesHelper.loader('show');
    this.webauthnService.deleteBiometricBrowser(id).subscribe(data =>{
        if (data.status=="ok"){
          this.authenticators=data.authenticator;
          //If deleted authenticator is on cookie, remove it
          const webAuthnIdCookie = this.cookieService.get('allowWebautn_browserID');
          if (webAuthnIdCookie==id){
            this.cookieService.set('allowWebautn_browserID', "", 0,'/');
            this.cookieService.set('is-browser-checked', "", 0,'/');
          }
        }
        commonPagesHelper.loader('hide');
    }),()=>{
      commonPagesHelper.loader('hide');
    }

  }



  isWebauthnSupported(){
    if (window.PublicKeyCredential && window.PublicKeyCredential.isUserVerifyingPlatformAuthenticatorAvailable) {
      // El navegador admite WebAuthn y autenticadores verificables por el usuario
      window.PublicKeyCredential.isUserVerifyingPlatformAuthenticatorAvailable()
        .then((result) => {
          if (result) {
            this.isWebAuthnAvailable=true;
          } else {
            this.isWebAuthnAvailable=false;
            this.cookieService.set('disable_webauth_registration', "1",365,'/');
          }
        })
        .catch((error) => {
          this.isWebAuthnAvailable=false;
          this.cookieService.set('disable_webauth_registration', "1",365,'/');

        });
    } else {
      this.isWebAuthnAvailable=false;
      this.cookieService.set('disable_webauth_registration', "1",365,'/');

    }
    
  }




}

