/* User Account Newsletter Form Model */
export class UserAccountNewsletterFormModel {
  constructor(
    public subscribe?: any,
    public newsletterValidationMessages = {
      subscribe: {
        required: 'GLOBAL.VALIDATION_MESSAGES.REQUIRED',
        pattern: 'GLOBAL.VALIDATION_MESSAGES.WRONG_FORMAT_EMAIL'
      }
    },
    public newsletterFormErrors = {
      subscribe: []
    }) {  }
}