import { Component, Inject } from '@angular/core';

import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { DialogMessageConfig } from '../../models/dialogMessageConfig.model';

@Component({
  selector: 'app-confirmation-dialog',
  template: ` 
      <h1 mat-dialog-title>
        <mat-toolbar color="primary">
          {{ config.data.title }}
        </mat-toolbar>
      </h1>
    <div mat-dialog-content>
      <p>{{ config.data.body }}</p>
    </div>
    <div mat-dialog-actions>
      <button mat-raised-button color="accent" (click)="onNoClick()">
        {{ config.data.confirmText }}</button>
      <button mat-raised-button color="primary" [mat-dialog-close]="true" cdkFocusInitial>
        {{ config.data.declineText }}</button>
    </div>
  `,
  styleUrls: ['./confirmation-dialog.component.scss']
})

export class ConfirmDialogComponent {
  config: DialogMessageConfig;

  constructor(
    public dialogRef: MatDialogRef<ConfirmDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogMessageConfig) { 

    this.config = data;
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

}