<div class="container container--noVerticalPadding container--darker">
    <h1 class="big withoutStar">{{ 'ABOUT_US.ABOUT_US_HEADER' | translate }}</h1>
</div>

<div class="container">
    <div class="staticPagesContainer">
        <div class="staticPagesContainer__leftColumn">
            <div class="staticPagesContainer__leftColumn-item">
                <h2 class="bottomDecorator title">
                    <span class="decorator"><i class="fa fa-info-circle" aria-hidden="true"></i></span>
                    {{ 'ABOUT_US.SUBHEADER1' | translate }}
                </h2>

                <p class="paragraph" [innerHtml]="'ABOUT_US.SUBCONTENT1' | translate"></p>
            </div>

            <div class="staticPagesContainer__leftColumn-item">
                <h2 class="bottomDecorator title">
                    <span class="decorator"><i class="fa fa-info-circle" aria-hidden="true"></i></span>
                    {{ 'ABOUT_US.SUBHEADER2' | translate }}
                </h2>

                <p class="paragraph" [innerHtml]="'ABOUT_US.SUBCONTENT2' | translate"></p>
            </div>

            <!--div class="staticPagesContainer__leftColumn-item">
                <h2 class="bottomDecorator title">
                    <span class="decorator"><i class="fa fa-info-circle" aria-hidden="true"></i></span>
                    {{ 'ABOUT_US.SUBHEADER3' | translate }}
                </h2>

                <p class="paragraph" [innerHtml]="'ABOUT_US.SUBCONTENT3' | translate"></p>
            </div-->
        </div>

        <div class="staticPagesContainer__rightColumn">
            <static-pages-right-column></static-pages-right-column>
        </div>
    </div>
</div>

