import { Component } from '@angular/core';
import {
  Router,
} from '@angular/router';
import {
  FormGroup,
  FormBuilder,
  Validators,
} from '@angular/forms';
import {
  regExpValidationConstants,
} from '../../constants/index';
import { AccountActivationFormModel } from '../../models/index';
import { UserRequestService, ComboNotificationService, UserService } from '../../services/index';
import { TranslateService } from '@ngx-translate/core';
import { commonPagesHelper, formHelper } from '../../helpers/index';
import { ComboNotificationType } from '../../enums/alert-type.enum';

@Component({
  selector: 'account-activation',
  templateUrl: './accountActivation.component.html',
  styleUrls: ['./accountActivation.component.scss']
})

export class AccountActivationComponent {

  successEmailTranslation = { value: '' }
  accountActivationFormModel
  sending = false

  validationMessages = {
    'email': {
      'required': 'GLOBAL.VALIDATION_MESSAGES.REQUIRED',
      'pattern': 'GLOBAL.VALIDATION_MESSAGES.WRONG_FORMAT_EMAIL'
    }
  }

  formErrors = {
    'email': ''
  }

  form: FormGroup

  /**
   * @Consturctor
   */
  constructor(
    private userRequestService: UserRequestService,
    private _translate: TranslateService,
    private formBuilder: FormBuilder,
    private comboNotificationService: ComboNotificationService,
    private userService: UserService,
    private router: Router
  ) {
    this.accountActivationFormModel = new AccountActivationFormModel();
  }

  /**
   * It's native Angular 'on initiation' method.
   * Fired after constructor.
   */
  ngOnInit(): void {

    if (this.userService.isLogged()) {
      this.router.navigateByUrl('/');
    }

    this.successEmailTranslation = { value: '' };
    this.buildForm();
  }

  /**
   * Builds form.
   */
  buildForm(): void {
    const formBuilderOptions = {
      email: [
        this.accountActivationFormModel.email,
        [
          Validators.required,
          Validators.pattern(regExpValidationConstants['EMAIL'])
        ]
      ]
    };

    this.form = this.formBuilder.group(formBuilderOptions);
    this.form.valueChanges.subscribe(() => {
      formHelper.onValueChanged(
        this.form,
        this.formErrors,
        this.validationMessages
      );
    });
    formHelper.onValueChanged(
      this.form,
      this.formErrors,
      this.validationMessages
    ); // reset validation messages
  }

  /**
   * On submit handler
   * @handler
   */
  onSubmit() {
    if (this.sending) {
      return;
    }
    const parametersMap = this.onSubmitPreprocess();
    this.sendActivationEmail(parametersMap);
  }

  /**
   * On submit preprocess.
   * @returns {Object}
   */
  onSubmitPreprocess() {
    const parametersMap = Object.assign({}, this.form.value);
    if (! parametersMap['email']) {
      return;
    }

    return parametersMap;
  }

  /**
   * Sends activation e-mail once again.
   * @param {Object} event
   */
  sendActivationEmail(parametersMap: object) {
    const self = this;
    this.sending = true;
    commonPagesHelper.loader('show');
    this.userRequestService.accountActivation(parametersMap).subscribe((data) => {
      const isSuccess = (data.result === 'ok');
      if (isSuccess) {
        self._translate.get(['ACCOUNT_ACTIVATION.MAIL_SENT_SUCCESSFULLY_LABEL'])
          .subscribe((translatedValue: string) => {
          // * Show SnackBar Notification
            self.comboNotificationService.notification(translatedValue['ACCOUNT_ACTIVATION.MAIL_SENT_SUCCESSFULLY_LABEL'], ComboNotificationType.Success);
          });

        self.successEmailTranslation = { value: self.form.value.email };
        self.form.patchValue({
          email: ''
        });
        self.sending = false;
        commonPagesHelper.loader('false'); 

        return;
      }

      // * Show SnackBar Notification
      self.comboNotificationService.notification(data.message, ComboNotificationType.Error);

      self.successEmailTranslation = { value: '' };
      self.sending = false;
      commonPagesHelper.loader('false');
    }, () => {
      self._translate.get(['ACCOUNT_ACTIVATION.CANNOT_SEND_MAIL_LABEL'])
        .subscribe((translatedValue: string) => {
          // * Show SnackBar Notification
          self.comboNotificationService.notification(translatedValue['ACCOUNT_ACTIVATION.CANNOT_SEND_MAIL_LABEL'], ComboNotificationType.Error);
        });

      self.successEmailTranslation = { value: '' };
      self.sending = false;
      commonPagesHelper.loader('false');
    });
  }

}
