import { SliderComponent } from './slider/slider.component';

export interface SliderOptions {
  autoInterval: number;
  autoPauseOnHover: boolean;
  loop: boolean;
  paginatorVisible: boolean;
  paginatorVisibleSingle?: boolean;
  play: 'auto' | 'manual';
  rowCount: number;
  slideSize: number;
  transitionDuration: number;
}

export function sliderResponsiveOptions(optionsMap: { [breakpoint: string]: SliderOptions }) {
  return (slider: SliderComponent) => {
    let matched: SliderOptions = undefined;
    for (const breakpoint in optionsMap) {
      slider.optionsAddBreakpoint(breakpoint, optionsMap[breakpoint]);
      matched = optionsMap[breakpoint];
    }
    return matched;
  };
}
