import { Directive, ElementRef, HostListener } from '@angular/core';
import { Router } from '@angular/router';

@Directive({
  selector: '[routeTransformer]'
})
export class RouteTransformerDirective {

  constructor(private el: ElementRef, private router: Router) { }

  @HostListener('click', ['$event'])
  public onClick(event) {
    if (event.target.tagName === 'A') {
      const isExternalLink = event.target.getAttribute('target') == '_blank';
      const link = event.target.getAttribute('href');
      const isMailLink = link.startsWith('mailto:');

      if(!(isExternalLink || isMailLink)) {
        this.router.navigate([link]);
        event.preventDefault();
      }
      else if(isMailLink && !isExternalLink) {
        event.target.setAttribute('target', '_blank');
      }
    }
    
    return;
  }
};