import {
    storageConstants,
    routingConstants,
    globalConstants,
    envConstants
  } from '../constants/index';
  import { Injectable } from '@angular/core';
  import {
    WebauthnRequestService
  } from '../services/index';
  import { Observable, of, Subject } from 'rxjs';
  import { take } from 'rxjs/operators';
  import { CookieService } from 'ngx-cookie-service';
  @Injectable({
    providedIn: 'root'
  }) export class HandleAllowWebauthnGlobalHelper{
  
    constructor(
        private webauthnService: WebauthnRequestService,
        private cookieService: CookieService
        ) { 

        }


    getCheckWebAuthnDisplay(): Observable<boolean> {
        var cookieValue = document.cookie.replace(/(?:(?:^|.*;\s*)allowWebautn\s*\=\s*([^;]*).*$)|^.*$/, "$1");
        var isBrowserChecked = document.cookie.replace(/(?:(?:^|.*;\s*)is-browser-checked\s*\=\s*([^;]*).*$)|^.*$/, "$1");
        //user is allow to login via webauthn. Check if registration exists
        let allowLoginWebauth=false;
        var isNewMethod = document.cookie.replace(/(?:(?:^|.*;\s*)new_webauth_login_revision\s*\=\s*([^;]*).*$)|^.*$/, "$1");
        if (isNewMethod=='1'){
            let isAuthenticated= false;
            if (isAuthenticated){
                //user is authenticate
                allowLoginWebauth=true;
                return of(allowLoginWebauth);
            }else{
                let browserId = document.cookie.replace(/(?:(?:^|.*;\s*)allowWebautn_browserID\s*\=\s*([^;]*).*$)|^.*$/, "$1");
                if (isBrowserChecked){
                    allowLoginWebauth=true;
                    return of(allowLoginWebauth);
                }else{
                    const subject = new Subject<boolean>();
                    this.webauthnService.checkAuthenticator(browserId)
                    .pipe(take(1))
                    .subscribe(
                      result => {
                            let allowLoginWebauth = false;
                            if (result.state === "OK"){
                                //set a session cookie to ovoid checking every request
                                const expirationDate = new Date();
                                expirationDate.setFullYear(expirationDate.getFullYear() + 1);
                                this.cookieService.set('is-browser-checked', 'true', expirationDate,'/');
                                allowLoginWebauth=true;                                 
                            }else{
                                allowLoginWebauth=false;
                                this.cookieService.set('is-browser-checked', 'false', 0,'/');
                            }

                            subject.next(allowLoginWebauth);
                            subject.complete();
                        },
                        () => {
                            // Maneja los errores aquí si es necesario
                            subject.next(false);
                            subject.complete();
                          }
                      );
                      return subject.asObservable();
                }
            }
        }else{
            allowLoginWebauth = cookieValue==="yes";
            return of(allowLoginWebauth);
        }
    }
  
    
  };
  
  