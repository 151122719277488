import { Injectable } from '@angular/core';

import { requestConstants } from '../../constants/index';
import { requestHelper } from '../../helpers/index';
import { RequestService } from './request.service';

@Injectable() export class BonusRequestService {

  /**
     * @constructor
     */
  constructor(
    private requestService: RequestService
  ) {
    //...
  }

  /**
     * Makes 'bonus activate' request.
     * @param bonusId {Number}
     * @param urlSlug {String}
     * @returns <promise>
     */
  bonusActivateRequest(bonusId) {
    const withToken = true;
    const url = requestHelper.urlReplace(
      requestConstants['API_URL'] + requestConstants['BONUS_ACTIVATE']['URL'],
      {
        bonusId: bonusId
      }
    );

    return this.requestService.GET(url, {}, withToken);
  }

  getUserBonuses() {
    const url = requestConstants['API_URL'] + requestConstants['USER_BONUS_LIST']['URL'];

    return this.requestService.GET(url, {}, true);
  }

  getBonuses() {
    const url = requestHelper.urlReplace(
      requestConstants['API_URL'] + requestConstants['BONUS_LIST']['URL'],
      {
        //bonusId: bonusId,
      }
    );

    return this.requestService.GET(url, {}, true);
  }

  /**
     * Makes 'bonus list' request.
     * @param {Object} [parametersMap = {}]
     * @returns <promise>
     */
  bonusListForUserRequest() {
    const withToken = true;
    const url = requestConstants['API_URL'] + requestConstants['USER_BONUS_LIST']['URL'];
    
    return this.requestService.listRequest(url, {}, withToken);
  }
}
