<div class="searchBar" [class.autosuggest--show]="visibleSearchAutosuggest" [class.searchBar--show]="visibleSearchBar">
	<div class="searchBar__mask" (click)="searchOnBlur(); hideSearchBar()"></div>
	<perfect-scrollbar #autosuggestScrollbar class="searchBar__suggestWrapper">
		<ul class="searchBar__suggest{{ visibleSearchAutosuggest ? ' searchBar__suggest--show' : '' }}">
			<li #autosuggestScrollbarItem class="searchBar__suggest-item" [class.searchBar__suggest-item--active]="(suggestItem?.active)" *ngFor="let suggestItem of searchSuggest" (click)="selectGame(suggestItem.urlSlug, suggestItem)">
				<div class="searchBar__suggest-item_image"><img class="searchBar__suggest-item_image" [defaultImage]="defaultImages.SMALL" [lazyLoad]="suggestItem.gameThumbImageUrl" [errorImage]="defaultImages.SMALL"></div>
				<div class="searchBar__suggest-item_text">
					<span class="searchBar__suggest-item_textName">{{ suggestItem.name }}</span>
					<span class="searchBar__suggest-item_textVendorName">{{ suggestItem.vendorName }}</span>
				</div>
			</li>
		</ul>
	</perfect-scrollbar>
	<input class="searchBar__search" name="search" [(ngModel)]="searchValue" placeholder="{{ 'LAYOUT.SEARCH_BAR_SEARCH_PLACEHOLDER' | translate }}" autocomplete="off" [attr.autofocus]="visibleSearchBar" #searchInput (keyup)="searchOnKeyup(searchInput, $event)" (focus)="searchOnFocus()">
	<a class="searchBar__fakeSubmit" (click)="showSearchBar(searchInput)"></a>
	<a class="searchBar__submit" (click)="searchOnSubmit(searchInput.value, null)"></a>
</div>
