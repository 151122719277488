import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { StaticComponentData } from './static.model';
import { ActivatedRoute } from '@angular/router';
//import { MetaService } from '@ngx-meta/core';
import { Meta, MetaDefinition, Title } from '@angular/platform-browser';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { PlatformService } from '../../services/platform.service';

@Component({
  selector: 'static',
  templateUrl: './static.component.html',
  styleUrls: ['./static.component.scss'],
})
export class StaticComponent implements OnInit, OnDestroy {
  private component$: Subscription[]

  constructor(
    private readonly route: ActivatedRoute,
    //private metaService: MetaService,
    private metaService: Meta,
    private titleService:Title,
    private readonly sanitizer: DomSanitizer,
    private readonly platformService: PlatformService) { }

  content: SafeHtml

  dataInit(data: StaticComponentData) {
    if (!data || !data.static) return;

    this.content = this.sanitizer.bypassSecurityTrustHtml(data.static.content);
    this.titleService.setTitle(data.static.metaTitle);
    this.metaService.addTag({name:'description',content: data.static.metaDescription});
    this.metaService.addTag({name:'keywords',content: data.static.metaKewords});
  }

  ngOnDestroy() {
    this.component$.forEach(it => it.unsubscribe());
    this.component$ = [];
  }

  ngOnInit() {
    this.component$ = [
      this.route.data.subscribe(this.dataInit.bind(this))
    ];
    if (this.platformService.browser) {
      setTimeout(() => {
        window.scroll(0, 0);
      }, 500);
    }
  }
}
