import { Component } from '@angular/core';
import {
  FormGroup,
  FormBuilder,
  Validators,
} from '@angular/forms';
import {
  regExpValidationConstants,
} from '../../constants/index';
import { ForgotPasswordFormModel } from '../../models/index';
import { UserRequestService, ComboNotificationService } from '../../services/index';
import { TranslateService } from '@ngx-translate/core';
import { commonPagesHelper, formHelper } from '../../helpers/index';
import { ComboNotificationType } from '../../enums/alert-type.enum';

@Component({
  selector: 'password-reset',
  templateUrl: './passwordReset.component.html',
  styleUrls: ['./passwordReset.component.scss']
})

export class PasswordResetComponent {

  successEmailTranslation = { value: '' }
  forgotPasswordFormModel
  sending = false

  validationMessages = {
    'email': {
      'required': 'GLOBAL.VALIDATION_MESSAGES.REQUIRED',
      'pattern': 'GLOBAL.VALIDATION_MESSAGES.WRONG_FORMAT_EMAIL'
    }
  }

  formErrors = {
    'email': ''
  }

  form: FormGroup

  /**
   * @Consturctor
   */
  constructor(
    private userRequestService: UserRequestService,
    private _translate: TranslateService,
    private formBuilder: FormBuilder,
    private comboNotificationService: ComboNotificationService
  ) {
    this.forgotPasswordFormModel = new ForgotPasswordFormModel();
  }

  /**
   * It's native Angular 'on initiation' method.
   * Fired after constructor.
   */
  ngOnInit(): void {
    this.successEmailTranslation = { value: '' };
    this.buildForm();
  }

  /**
   * Builds form.
   */
  buildForm(): void {
    const formBuilderOptions = {
      email: [
        this.forgotPasswordFormModel.email,
        [
          Validators.required,
          Validators.pattern(regExpValidationConstants['EMAIL'])
        ]
      ]
    };

    this.form = this.formBuilder.group(formBuilderOptions);
    this.form.valueChanges.subscribe(() => {
      formHelper.onValueChanged(
        this.form,
        this.formErrors,
        this.validationMessages
      );
    });
    formHelper.onValueChanged(
      this.form,
      this.formErrors,
      this.validationMessages
    ); // reset validation messages
  }

  /**
   * On submit handler
   * @handler
   */
  onSubmit() {
    if (this.sending) {
      return;
    }
    const parametersMap = this.onSubmitPreprocess();
    this.passwordReset(parametersMap);
  }

  /**
   * On submit preprocess.
   * @returns {Object}
   */
  onSubmitPreprocess() {
    const parametersMap = Object.assign({}, this.form.value);
    if (!parametersMap['email']) {
      return;
    }

    parametersMap['username'] = parametersMap['email'].toLowerCase();
    delete parametersMap['email'];

    return parametersMap;
  }

  /**
   * Password reset.
   * @param {Object} event
   */
  passwordReset(parametersMap: object) {
    this.sending = true;
    commonPagesHelper.loader('show');

    this.userRequestService.passwordReset(parametersMap).subscribe((data) => {
      const isSuccess = (data.result === 'ok');

      if (isSuccess) {
        this._translate.get(['PASSWORD_RESET.MAIL_SENT_SUCCESSFULLY_LABEL'])
          .subscribe((translatedValue: string) => {
            // * Show SnackBar Notification
            this.comboNotificationService.notification(translatedValue['PASSWORD_RESET.MAIL_SENT_SUCCESSFULLY_LABEL'], ComboNotificationType.Success);
          });

        this.successEmailTranslation = { value: this.form.value.email };
        this.form.patchValue({
          email: ''
        });
        this.sending = false;
        commonPagesHelper.loader('false');

        return;
      }
      // * Show SnackBar Notification
      this.comboNotificationService.notification(data.message, ComboNotificationType.Error);

      this.successEmailTranslation = { value: '' };
      this.sending = false;
      commonPagesHelper.loader('false');

    }, () => {
      this._translate.get(['PASSWORD_RESET.CANNOT_SEND_MAIL_LABEL'])
        .subscribe((translatedValue: string) => {
          // * Show SnackBar Notification
          this.comboNotificationService.notification(translatedValue['PASSWORD_RESET.CANNOT_SEND_MAIL_LABEL'], ComboNotificationType.Error);
        });

      this.successEmailTranslation = { value: '' };
      this.sending = false;
      commonPagesHelper.loader('false');
    });
  }

}
