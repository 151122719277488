import { Component } from '@angular/core';
import { NavigationService } from '../../services/index';

@Component({
  selector: 'static-pages-right-column',
  templateUrl: './staticPagesRightColumn.component.html',
  styleUrls: ['./staticPagesRightColumn.component.scss'],
})
export class StaticPagesRightColumnComponent {
  menuData

  /**
   * @costructor
   */
  constructor(private navigationService: NavigationService) {
    this.menuData = [
      {
        translationKey: 'STATIC_PAGES.PAYMENT_PROVIDERS_LABEL',
        link: this.navigationService.constructUrl('PAYMENT_PROVIDERS'),
      },
      {
        translationKey: 'STATIC_PAGES.ABOUT_US_LABEL',
        link: this.navigationService.constructUrl('ABOUT_US'),
      },
      {
        translationKey: 'STATIC_PAGES.CONTACT_LABEL',
        link: this.navigationService.constructUrl('CONTACT'),
      },
      {
        translationKey: 'STATIC_PAGES.FAQ_LABEL',
        link: this.navigationService.constructUrl('FAQ'),
      },
      {
        translationKey: 'STATIC_PAGES.SAFETY_LABEL',
        link: this.navigationService.constructUrl('SAFETY'),
      },
      {
        translationKey: 'STATIC_PAGES.RESPONSIBLE_GAMBLING_LABEL',
        link: this.navigationService.constructUrl('RESPONSIBLE_GAMBLING'),
      },
      {
        translationKey: 'STATIC_PAGES.YOUTH_PROTECTION_LABEL',
        link: this.navigationService.constructUrl('YOUTH_PROTECTION'),
      },
      {
        translationKey: 'STATIC_PAGES.TAC_LABEL',
        link: this.navigationService.constructUrl('TAC'),
      },
      {
        translationKey: 'STATIC_PAGES.GENERAL_BONUS_TERMS_LABEL',
        link: this.navigationService.constructUrl('GENERAL_BONUS_TERMS'),
      },
      {
        translationKey: 'STATIC_PAGES.TERMS_OF_USE_LABEL',
        link: this.navigationService.constructUrl('TERMS_OF_USE'),
      },
      {
        translationKey: 'STATIC_PAGES.PRIVACY_POLICY_LABEL',
        link: this.navigationService.constructUrl('PRIVACY_POLICY'),
      },
      {
        translationKey: 'STATIC_PAGES.COOKIE_POLICY_LABEL',
        link: this.navigationService.constructUrl('COOKIE_POLICY'),
      }
    ];
  }
}
