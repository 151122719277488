import { stringHelper } from '../helpers';

export const affiliateParamConstants = [
  'ref_id',
  'btag',
  'ttClickId',
  's1',
  'affid',
  'ma_token',
  'ma_affiliate_id'
];

export const affiliateParamNormalizedMapConstants = {};
affiliateParamConstants.forEach(key => affiliateParamNormalizedMapConstants[stringHelper.normalize(key)] = key);
