import { BaseCashierComponent } from '../base.component';
import { Component } from '@angular/core';

@Component({
  selector: 'cashier-block-account',
  templateUrl: '../base.component.html',
  styleUrls: ['../base.component.scss']
})

export class BlockAccountComponent extends BaseCashierComponent {
  public iframeId = 'deactivateAccount'
}