import { BaseCashierComponent } from './components/base.component';
import { Router, ActivatedRoute } from '@angular/router';
import { Component, ViewChild } from '@angular/core';
import { ScrollToConfigOptions, ScrollToService } from '@nicky-lenaers/ngx-scroll-to';
import {
  FormBuilder,
} from '@angular/forms';
import { UserRequestService } from '../../services/index';
import { TranslateService } from '@ngx-translate/core';
import { NavigationService } from '../../services/index';
import { CasinoService } from '../../services/index';

import {
  PayInComponent,
  PayOutComponent,
  PromoCodeComponent,
  TransactionsComponent,
  GameRoundsComponent,
  LimitsComponent,
  BlockAccountComponent,
  DocumentsComponent
} from './components/index';
import { PlatformService } from '../../services/platform.service';

@Component({
  selector: 'cashier',
  templateUrl: './cashier.component.html',
  styleUrls: ['./cashier.component.scss']
})

export class CashierComponent {
  // ? { static: true } query results available in ngOnInit, { static: false } query results available in ngAfterViewInit
  @ViewChild('scrollContent', { static: true }) scrollContent;
  @ViewChild('overviewTab', { static: true }) private overviewTab: BaseCashierComponent;
  @ViewChild('payInTab', { static: true }) private payInTab: PayInComponent;
  @ViewChild('payOutTab', { static: true }) private payOutTab: PayOutComponent;
  @ViewChild('promoCodeTab', { static: true }) private promoCodeTab: PromoCodeComponent;
  @ViewChild('transactionsTab', { static: true }) private transactionsTab: TransactionsComponent;
  @ViewChild('gameRoundsTab', { static: true }) private gameRoundsTab: GameRoundsComponent;
  @ViewChild('limitsTab', { static: true }) private limitsTab: LimitsComponent;
  @ViewChild('blockAccountTab', { static: true }) private blockAccountTab: BlockAccountComponent;
  @ViewChild('documentsTab', { static: true } ) private documentsTab: DocumentsComponent;

  inputs = {}

  startRecordingScrollPosition = false
  scrollYPosition = 0
  scrollDelay
  isFrozen = localStorage.getItem('accountFrozenInformed') === 'true' ? true : false;

  tabs = {
    activeValue: 'overview',
    depositBonusCode: null,
    data: [
      {
        value: 'overview',
        nameTranslationKey: 'CASHIER.TABS.OVERVIEW.LABEL',
        hide: this.isFrozen
      },
      {
        value: 'payIn',
        nameTranslationKey: 'CASHIER.TABS.PAY_IN.LABEL',
        hide: this.isFrozen
      },
      {
        value: 'payOut',
        nameTranslationKey: 'CASHIER.TABS.PAY_OUT.LABEL',
        hide: this.isFrozen
      },
      {
        value: 'promoCode',
        nameTranslationKey: 'CASHIER.TABS.PROMO_CODE.LABEL',
        hide: this.isFrozen
      },
      {
        value: 'transactions',
        nameTranslationKey: 'CASHIER.TABS.TRANSACTIONS.LABEL',
        hide: this.isFrozen
      },
      {
        value: 'gameRounds',
        nameTranslationKey: 'CASHIER.TABS.GAME_ROUNDS.LABEL',
        hide: this.isFrozen
      },
      {
        value: 'limits',
        nameTranslationKey: 'CASHIER.TABS.LIMITS.LABEL',
        hide: this.isFrozen
      },
      {
        value: 'blockAccount',
        nameTranslationKey: 'CASHIER.TABS.BLOCK_ACCOUNT.LABEL',
        hide: this.isFrozen
      },
      {
        value: 'documents',
        nameTranslationKey: 'CASHIER.TABS.DOCUMENTS.LABEL',
        hide: false
      }
    ]
  }

  isMobile = false

  /**
   * Constructor.
   */
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private userRequestService: UserRequestService,
    private _translate: TranslateService,
    private formBuilder: FormBuilder,
    public _scrollToService: ScrollToService,
    private casinoService: CasinoService,
    private navigationService: NavigationService,
    private platformService: PlatformService
  ) {
    route.queryParams.subscribe((data) => {
      this.tabs.activeValue = data.tab ? data.tab : 'overview';
      this.tabs.depositBonusCode = data.depositBonusCode ? data.depositBonusCode : null;
      this.refreshSpecificTabContent();
      this.isMobile = this.casinoService.isMobile();
    });
  }

  /**
   * It's native Angular 'on initiation' method.
   * Fired after constructor.
   */
  ngOnInit(): void {
    if (this.platformService.browser) {
      this.refreshSpecificTabContent();

      this.scrollDelay = setTimeout(()=>{
        this.scrollToElement();
      }, 1000);
    }

  }



  /**
   * Scrolls to target html element
   */
  scrollToElement() {
    const config: ScrollToConfigOptions = {
      target: 'cashierComponent1',
      duration: 600,
      offset: 70
    };
    this._scrollToService.scrollTo(config);
  }

  /**
   * Changes active tab.
   * @param {String} value
   */
  tabChange(value) {
    this.router.navigate([this.navigationService.constructUrl('CASHIER')], {
      queryParams: {
        tab: value
      }
    });
    this.scrollDelay = setTimeout(()=>{
      this.scrollToElement();
    }, 1000);
  }

  /**
   * Refreshes specific tab content.
   */
  refreshSpecificTabContent() {

    const specificComponentName = `${this.tabs.activeValue  }Tab`;

    if (typeof (this[specificComponentName]) !== 'undefined') {
      this[specificComponentName].refreshView(this.tabs.activeValue, this.tabs.depositBonusCode);
    }
  }

  ngOnDestroy() {
    clearTimeout(this.scrollDelay);
  }

}
