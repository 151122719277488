import {PipeTransform, Pipe} from '@angular/core';
import { AppLanguageService } from '../services';

@Pipe({ name: 'currency_format'})
export class CurrencyFormatPipe implements PipeTransform {
  constructor(private appLanguageService: AppLanguageService) {}
  transform(value: any, currency?: string) {
    const lang = this.appLanguageService.getLanguageFromStorage();
    return new Intl.NumberFormat(lang, {
      maximumFractionDigits: 2,
      style: 'currency',
      currency: currency || 'EUR'
    }).format(value);
  }
}
