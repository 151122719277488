import { Component, Inject } from '@angular/core';
import { MAT_SNACK_BAR_DATA, MatSnackBarRef } from '@angular/material/snack-bar';

@Component({
  selector: 'snackbar-notification',
  template: ` <span [className]="className">
                <p [innerHTML]="text"></p>
                <button mat-raised-button class="mat-button" [innerHTML]="dismissText" (click)="_snackRef.dismiss()" [innerHTML]="dismissText"></button>
              </span>
            `,
  styleUrls: ['./snackbar-notification.component.scss']
})

export class SnackbarNotificationComponent {
  text = 'Snackbar Text Here';
  dismissText = 'Dismiss';
  className: string;

  constructor( @Inject(MAT_SNACK_BAR_DATA) public _data: any, public _snackRef: MatSnackBarRef<SnackbarNotificationComponent>) {
    this.text = _data.message;
    this.dismissText = _data.action;
    this.className = _data.class;
  }
  
}