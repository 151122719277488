import { UserRequestService } from './request/userRequest.service';
import { UserService } from './user.service';
import { DeviceDetectorService } from 'ngx-device-detector';
import { Injectable, OnDestroy } from '@angular/core';
import { globalConstants } from '../constants/index';
import { commonPagesHelper } from '../helpers/index';
import { Router, NavigationEnd } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { RequestService } from './request';

@Injectable() export class CasinoService implements OnDestroy {

  private showingSidebar: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false)

  changePage$ = undefined
  userInformations$ = undefined
  userBalance$ = undefined
  trackingPixel$
  appTrackingPixel

  /**
     * @constructor
     */
  constructor(
    private deviceService: DeviceDetectorService,
    private router: Router,
    public userRequestService: UserRequestService,
    public userService: UserService,
    public requestService: RequestService
  ) {
    this.changePage$ = this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd && typeof document !== 'undefined') {
        const tooltips = document.querySelectorAll('.tooltip.tooltip-show');
        Array.prototype.forEach.call(tooltips, function(element){
          element.parentNode.removeChild(element);
        });
      }
    });

    this.trackingPixel$ = this.requestService.renderTrackingPixel$.subscribe((data) => {
      if (data.track_event && data.event_params) {
        this.renderTrackingPixel(data.track_event, data.event_params);
      }
    });
  }

  public isSidebarVisible(){
    return this.showingSidebar.asObservable();
  }

  public toggleSidebarValue(status) {
    this.showingSidebar.next(status);
  }

  public isMobile() {
    return this.deviceService.isMobile() || this.deviceService.isTablet();
  }

  public isDesktop() {
    return this.deviceService.isDesktop();
  }

  /**
   * Checks whether Facebook login feature is enabled
   * If not, facebook login buttons remain hidden
   */
  public isFacebookLoginEnabled() {
    return commonPagesHelper.getEnvConstant('FACEBOOK_LOGIN', globalConstants['FACEBOOK_LOGIN']);
  }

  /**
   * Refresh user informations (eg. balance etc.)
   */
  refreshUserInformations() {
    if (!this.userService.isLogged()) {
      this.userService.destroyStoredData();
      
      return;
    }

    this.userInformations$ = this.userRequestService.detailsRequest().subscribe((userData) => {
      this.userService.storeUserDataWithBalanceRefresh(userData.user);
    });
  }

  refreshUserBalance() {
    if (!this.userService.isLogged()) {
      this.userService.destroyStoredData();
      
      return;
    }

    this.userBalance$ = this.userRequestService.balanceRequest().subscribe((response) => {
      if (response.result === 'ok')
        this.userService.storeUserBalanceRefresh(response.balance);
    });
  }

  /**
   * Adds tracking pixel by params from request
   */
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  renderTrackingPixel(trackEvent, eventParams) {
    switch (trackEvent) {
      case 'registration.completed':
        // n/a
        // this.appTrackingPixel = '<img src="https://www.imcounting.com/script/track_image.php?idform=4572085&campagne=42812844&variable=INSERT_USER_ID_OR_EMAIL_HERE" border="0" width="1" height="1" />';
        break;

      case 'registration.confirmed':
        // n/a
        break;

      case 'registration_full.completed':
        // n/a
        break;
      default:
        break;
    }

    if (this.appTrackingPixel) {
      const trackingPixelWrapper = document.getElementById('conversion-tracking-pixel');
      trackingPixelWrapper.innerHTML = this.appTrackingPixel;
    }
  }

  ngOnDestroy() {
    if (this.changePage$)
      this.changePage$.unsubscribe();

    if (this.userInformations$)
      this.userInformations$.unsubscribe();

    if (this.userBalance$)
      this.userBalance$.unsubscribe();

    if (this.trackingPixel$)
      this.trackingPixel$.unsubscribe();
  }
}
