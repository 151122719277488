import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser, isPlatformServer } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class PlatformService {
  private _browser: boolean = undefined
  private _server: boolean = undefined

  constructor(@Inject(PLATFORM_ID) platformId: Record<string, any>) {
    this._browser = isPlatformBrowser(platformId);
    this._server = isPlatformServer(platformId);
  }

  get browser() { return this._browser; }

  get name() {
    if (this._browser) return 'browser';
    else if (this._server) return 'server';
    
    return undefined;
  }

  get server() { return this._server; }
}