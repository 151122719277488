import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { ResizableModule } from 'angular-resizable-element';
import {
  TranslateModule,
  TranslateLoader,
} from '@ngx-translate/core';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { TooltipModule } from 'ng2-tooltip-directive';
import { BrowserAnimationsModule, NoopAnimationsModule } from '@angular/platform-browser/animations';

import {
  TableComponent,
  GameItemComponent,
  ModalComponent,
  LanguageSwitcherComponent,
  SearchBarComponent,
  StaticPagesRightColumnComponent,
} from './index';
import {
  EqualValidator,
} from '../directives/index';

// * Import Material Module
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { MatToolbarModule } from '@angular/material/toolbar';

import { ConfirmDialogComponent } from './confirmation-dialog/confirmation-dialog.component';

import { SnackbarNotificationComponent } from './snackbar-notification/snackbar-notification.component';

import {
  PerfectScrollbarModule,
} from 'ngx-perfect-scrollbar';

import { RegisterModalComponent } from './registerModal/registerModal.component';
import { KeyValuePipe } from '../pipes/keyValue.pipe';
import { SliderModule } from './slider/slider.module';
import { TranslateInlineLoader } from './translation/translation-class.component';
import { WebAuthRegisterModalComponent } from './webAuthRegisterModal/webAuthRegisterModal.component';

@NgModule({
  imports: [
    TooltipModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    BrowserAnimationsModule,
    NoopAnimationsModule,
    ResizableModule,
    LazyLoadImageModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useClass: TranslateInlineLoader
      }
    }),
    PerfectScrollbarModule,
    SliderModule,
    MatDialogModule,
    MatButtonModule,
    MatToolbarModule
  ],
  exports: [
    CommonModule,
    TranslateModule,
    FormsModule,
    ReactiveFormsModule,
    TableComponent,
    GameItemComponent,
    ConfirmDialogComponent,
    SnackbarNotificationComponent,
    ModalComponent,
    LanguageSwitcherComponent,
    SearchBarComponent,
    StaticPagesRightColumnComponent,
    BrowserAnimationsModule,
    NoopAnimationsModule,
    ResizableModule,
    LazyLoadImageModule,
    RegisterModalComponent,
    WebAuthRegisterModalComponent,
    KeyValuePipe,
    EqualValidator,
    SliderModule,
  ],
  declarations: [
    TableComponent,
    GameItemComponent,
    ConfirmDialogComponent,
    SnackbarNotificationComponent,
    ModalComponent,
    LanguageSwitcherComponent,
    SearchBarComponent,
    StaticPagesRightColumnComponent,
    RegisterModalComponent,
    WebAuthRegisterModalComponent,
    KeyValuePipe,
    EqualValidator,
  ],
})
export class SharedModule {}
