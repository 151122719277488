import { CasinoService } from '../../../services/casino.service';
import { Component, ElementRef, ViewChild, Inject } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { UserService, UserRequestService, WINDOW } from '../../../services/index';
import { TranslateService } from '@ngx-translate/core';
import { DomSanitizer } from '@angular/platform-browser';
import { PlatformService } from '../../../services/platform.service';

@Component({
  selector: 'cashier-overview',
  templateUrl: './base.component.html',
  styleUrls: ['./base.component.scss']
})

export class BaseCashierComponent {
  public iframeURL;
  public iframeLoaded;
  public iframeHeight = 0;
  public iframeId = 'index';

  // ? { static: true } query results available in ngOnInit, { static: false } query results available in ngAfterViewInit
  @ViewChild('iframeGame', { static: true }) iframeGame: ElementRef;

  constructor(
    private sanitizer: DomSanitizer,
    private userService: UserService,
    private userRequestService: UserRequestService,
    private _translate: TranslateService,
    private formBuilder: FormBuilder,
    private casinoService: CasinoService,
    @Inject(WINDOW) public window: Window,
    private platformService: PlatformService
  ) {
    // ...
  }

  /**
   * It's native Angular 'on initiation' method.
   * Fired after constructor.
   */
  ngOnInit(): void {
    if (this.platformService.browser) {
      this.iframeGame.nativeElement.contentWindow.postMessage('initialized.casino', '*');
    }
  }

  /**
   * Refreshes view.
   * @param {Boolean} depositBonusCode
   */
  refreshView(value, depositBonusCode = null) {
    this.userRequestService.getCashierUrl(this.iframeId, depositBonusCode).subscribe((result) => {
      if (result.result === 'ok') {
        this.iframeURL = this.sanitizer.bypassSecurityTrustResourceUrl(result.cashierIframeUrl);
      }
    });
  }

  refreshIframeHeight() {
    const self = this;
    window.addEventListener('message', function (e) {
      switch (e.data) {
        case 'closePopup':
          // close iframe popup - n/a in Elcarado
          break;
        case 'showCashier':
          // TODO: show cashier
          break;
        case 'refresh':
          self.casinoService.refreshUserInformations();
          break;
        default:
          // eslint-disable-next-line no-case-declarations
          const h = Number(e.data.if_height);
          if (!isNaN(h) && h > 0) {
            self.iframeHeight = h;
          }
      }
    }, false);
  }

  /**
   * Iframe on load handler.
   * @param iframeGame {DOMElement}
   * @handler
   */
  iframeOnLoad(iframeGame) {
    if (!(
      iframeGame['src']
    )) {
      return;
    }
    this.refreshIframeHeight();
    this.iframeLoaded = true;
  }
}
