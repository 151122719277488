<div class="container container--noVerticalPadding container--darker">
  <h1 class="big withoutStar">{{ 'PASSWORD_RESET_CONFIRM.HEADER' | translate }}</h1>
</div>

<div class="container">
  <div class="passwordResetConfirmComponent">

    <div class="paragraphsContainer">
      <p [innerHTML]="'PASSWORD_RESET_CONFIRM.PARAGRAPH' | translate"></p>
    </div>
    <!-- TODO Move to new Sharable Component, Passing Type and Message -->
    <div class="alert alert--success" *ngIf="tokenIsValid">
      <p class="alert__header">{{ 'PASSWORD_RESET_CONFIRM.SUCCESS_HEADER' | translate }}</p>
      <p class="alert__paragraph"
        [innerHTML]="'PASSWORD_RESET_CONFIRM.SUCCESS_PARAGRAPH' | translate:successEmailTranslation"></p>
    </div>

    <form class="passwordResetConfirmComponentInside" [formGroup]="formOptions.forgottenPasswordValidation.form"
      (ngSubmit)="tokenVerification()" (keyup.enter)="tokenVerification()">
      <div class="passwordResetConfirmComponentInside__item">
        <label for="first">{{ 'PASSWORD_RESET_CONFIRM.PASSWORD_LABEL' | translate }}</label>
        <input type="password" id="first" class="first" formControlName="first" validateEqual="second" reverse="true"
          required>
        <span class="errorMessage" *ngIf="formOptions.forgottenPasswordValidation.errors">
          <span class="errorMessage__inside">
            <span
              *ngFor="let firstMessage of formOptions.forgottenPasswordValidation.errors">{{ firstMessage | translate }}</span>
          </span>
        </span>
        <span class="hints">{{ 'GLOBAL.VALIDATION_MESSAGES.PASSWORD_FORMAT_HINT' | translate }}</span>
      </div>

      <div class="passwordResetConfirmComponentInside__item">
        <label for="second">{{ 'PASSWORD_RESET_CONFIRM.PASSWORD_REPEAT_LABEL' | translate }}</label>
        <input type="password" id="second" class="first" formControlName="second" validateEqual="first" reverse="false"
          required>
        <span class="errorMessage" *ngIf="formOptions.forgottenPasswordValidation.errors">
          <span class="errorMessage__inside">
            <span
              *ngFor="let secondMessage of formOptions.forgottenPasswordValidation.errors">{{ secondMessage | translate }}</span>
          </span>
        </span>
      </div>

      <div class="passwordResetConfirmComponentInside__item">
        <a class="button button--red button--disabled" *ngIf="!(formOptions.forgottenPasswordValidation.form.valid)"
          tooltip="{{ 'GLOBAL.INVALID_VALIDATION_FORM_FIELDS_TOOLTIP' | translate }}">{{ 'PASSWORD_RESET_CONFIRM.CHANGE_PASSWORD_BUTTON_LABEL' | translate }}
          <i class="fa fa-angle-right" aria-hidden="true"></i></a>
          <a class="button button--red" *ngIf="(formOptions.forgottenPasswordValidation.form.valid)" (click)="tokenVerification($event)">{{ 'PASSWORD_RESET_CONFIRM.CHANGE_PASSWORD_BUTTON_LABEL' | translate }} <i class="fa fa-angle-right" aria-hidden="true"></i></a>
      </div>
    </form>
  </div>
</div>