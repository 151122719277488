import { GameRequestService } from '../../services';
import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { GameDetailsComponentData } from './gameDetails.model';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class GameDetailsResolver implements Resolve<GameDetailsComponentData> {
  constructor(private gameRequestService: GameRequestService) { }

  resolve(route: ActivatedRouteSnapshot): GameDetailsComponentData | Observable<GameDetailsComponentData> | Promise<GameDetailsComponentData> {
    return this.gameRequestService
      .gameDetailsRequest(route.params.slug)
      .pipe(
        map(data => ({
          game: data.game
        }))
      );
  }
}