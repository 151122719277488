import { Injectable } from '@angular/core';
import {
  Router,
  ActivatedRoute
} from '@angular/router';

import { storageConstants, routingConstants } from '../constants/index';
import { requestHelper } from '../helpers/index';
import * as uuid from 'uuid';
import { PlatformService } from './platform.service';

@Injectable() export class NavigationService {
  /**
   * @constructor
   */
  constructor(
    private _router: Router,
    private route: ActivatedRoute,
    private platformService: PlatformService) { }

  /**
 * Gets current language
 * @returns {String}
 */
  getCurrentLanguage() {
    return this.platformService.browser
      ? (localStorage.getItem(storageConstants['USER_LANGUAGE']) || 'en')
      : 'en';
  }

  /**
   * Changes language.
   * @param language {String}
   */
  changeLanguage(language) {
    this._router.navigateByUrl(`/${language}`);
  }

  /**
   * Goes to default page.
   */
  goToDefaultPage() {
    this._router.navigateByUrl(this.constructUrl('START'));
  }

  /**
   * Goes to specific page
   * @param {String} url
   * @param {Object} parameters
   * @param {String} language
   */
  goTo(slug, parameters = {}, language = this.getCurrentLanguage()) {
    const languagesData = routingConstants[language.toUpperCase()];

    if (typeof (languagesData[slug]['slug']) === 'undefined') {
      console.error('language error (routingConstants[language][slug][\'slug\'])');
      
      return '';
    }

    let localeUrl = `/${language}/${languagesData[slug]['slug']}`;
    const parameterKeys = Object.keys(parameters);
    const haveParameters = (parameterKeys.length > 0);

    if (haveParameters) {
      parameterKeys.forEach((parameterKey) => {
        localeUrl = localeUrl.replace(`:${parameterKey}`, parameters[parameterKey]);
      });
    }

    this._router.navigateByUrl(localeUrl);
  }

  /**
   * Goes to specific page
   * @param {String} url
   * @param {Object} parameters
   * @param {String} language
   * @returns {String}
   */
  constructUrl(slug, parameters = {}, language = this.getCurrentLanguage()) {
    const languagesData = routingConstants[language.toUpperCase()];
    if (typeof (languagesData[slug]['slug']) === 'undefined') {
      console.error('language error (routingConstants[language][slug][\'slug\'])');
      
      return '';
    }

    let localeUrl = `/${language}/${languagesData[slug]['slug']}`;
    const parameterKeys = Object.keys(parameters);
    const haveParameters = (parameterKeys.length > 0);

    if (haveParameters) {
      parameterKeys.forEach((parameterKey) => {
        localeUrl = localeUrl.replace(`:${parameterKey}`, parameters[parameterKey]);
      });
    }

    return localeUrl;
  }

  constructUrlWithQueryParams(slug, queryParams, parameters = {}, language = this.getCurrentLanguage()) {
    const languagesData = routingConstants[language.toUpperCase()];
    if (typeof (languagesData[slug]['slug']) === 'undefined') {
      console.error('language error (routingConstants[language][slug][\'slug\'])');
      
      return '';
    }

    let localeUrl = `/${language}/${languagesData[slug]['slug']}`;
    const parameterKeys = Object.keys(parameters);
    const haveParameters = (parameterKeys.length > 0);

    if (haveParameters) {
      parameterKeys.forEach((parameterKey) => {
        localeUrl = localeUrl.replace(`:${parameterKey}`, parameters[parameterKey]);
      });
    }

    if (queryParams) {
      localeUrl += `?${  Object.keys(queryParams)
        .map(key => `${encodeURIComponent(key)  }=${  encodeURIComponent(queryParams[key])}`)
        .join('&')}`;
    }

    return localeUrl;
  }

  /**
   * Redirect user to profile page with optional follow url
   * @param redirect string
   */
  goToUserAccount() {
    this._router.navigateByUrl(this.constructUrl('USER_ACCOUNT'));
  }

  /**
   * Redirect user to profile page with optional follow url
   * @param redirect string
   */
  goToPage(url: string) {
    this._router.navigateByUrl(url);
  }

  /**
   * Goes to back route
   * @returns {String}
   */
  goToBackRoute() {
    window.history.back();
  }

  /**
   * Goes to saved (in local storage) route.
   */
  goToSavedRoute() {
    if (this.platformService.server) return;
    this._router.navigateByUrl(localStorage.getItem(
      storageConstants['PREVIOUS_URL']));
  }

  /**
   * Adds forceRefresh query string parameter (refreshes view).
   */
  forceRefresh() {
    if (this.platformService.server) {
      return;
    }
    const currentURL = window.location.pathname;
    const newURL = requestHelper.createQueryStringURL(
      currentURL,
      {
        forceRefresh: uuid.v4(),
        ...this.route.snapshot.queryParams
      }
    );
    this._router.navigateByUrl(newURL);
  }
}
