import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService, NavigationService } from '../../services/index';

@Component({
  // moduleId: module.id,
  selector: 'logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.scss']
})

export class LogoutComponent implements OnInit {

  /**
     * @constructor
     */
  constructor(
    private router: Router,
    private authenticationService: AuthenticationService,
    private navigationService: NavigationService
  ) {
    // ...
  }

  /**
     * It's native Angular 'on initiation' method.
     * Fired after constructor.
     */
  ngOnInit() {
    this.authenticationService.logout().subscribe(() => {
      //
    });
    this.router.navigate([this.navigationService.constructUrl('LOGIN')]);
  }
}
