import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'keyValue', pure: false })
export class KeyValuePipe implements PipeTransform {
  transform(input: any): any {
    const keys = [];
    for (const key in input) {
      if (Object.prototype.hasOwnProperty.call(input, key)) {
        keys.push({ key: key, value: input[key] });
      }
    }
    
    return keys;
  }
}
