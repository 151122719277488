<div class="container container--noVerticalPadding container--darker">
  <h1 class="big withoutStar">{{ 'REGISTER.REGISTER_HEADER' | translate }}</h1>
</div>

<div class="container">
  <div class="registerComponent">
    <form class="form" [formGroup]="form" (ngSubmit)="onSubmit()" (keyup.enter)="onSubmit()">
      <div class="columns">
        <div class="columns__half">
          <h3>{{ 'REGISTER.EMAIL_REGISTER_HEADER' | translate }}</h3>
          <div class="form-element inputWrapper">
            <label for="registerEmail">{{ 'REGISTER.EMAIL_PLACEHOLDER' | translate }}</label>
            <input id="registerEmail" formControlName="email" required>
            <span class="errorMessage" *ngIf="formErrors.email.length">
              <span class="errorMessage__inside">
                <span *ngFor="let emailMessage of formErrors.email">{{ emailMessage | translate }}</span>
              </span>
            </span>
          </div>

          <div class="form-element inputWrapper">
            <label for="registerPassword">{{ 'REGISTER.PASSWORD_PLACEHOLDER' | translate }}</label>
            <input id="registerPassword" type="password" formControlName="password" validateEqual="passwordRepeat"
              reverse="true" required>
            <span class="errorMessage" *ngIf="formErrors.password.length">
              <span class="errorMessage__inside">
                <span *ngFor="let passwordMessage of formErrors.password">{{ passwordMessage | translate }}</span>
              </span>
            </span>
            <span class="hints">{{ 'GLOBAL.VALIDATION_MESSAGES.PASSWORD_FORMAT_HINT' | translate }}</span>
          </div>

          <div class="form-element inputWrapper">
            <label for="registerPasswordRepeat">{{ 'REGISTER.PASSWORD_REPEAT_LABEL' | translate }}</label>
            <input id="registerPasswordRepeat" type="password" formControlName="passwordRepeat" validateEqual="password"
              reverse="false" required>
            <span class="errorMessage" *ngIf="formErrors.passwordRepeat.length">
              <span class="errorMessage__inside">
                <span
                  *ngFor="let passwordRepeatMessage of formErrors.passwordRepeat">{{ passwordRepeatMessage | translate }}</span>
              </span>
            </span>
          </div>

			    	<div class="inputWrapper inputWrapperRelative">
			    		<label class="acceptance" for="registerAcceptTermsAndConditions" [innerHTML]="registrationAcceptanceText"></label>
				        <input id="registerAcceptTermsAndConditions" type="checkbox" formControlName="acceptTermsAndConditions" required>
				        <label class="akaCheckbox" for="registerAcceptTermsAndConditions" [class.error]="formErrors.acceptTermsAndConditions.length"></label>
			    	</div>

			    	<div class="inputWrapper inputWrapperRelative">
			    		<label class="acceptance" for="registerisSubscribedToNewsletter" [innerHtml]="'REGISTER.SUBSCRIBE_TO_NEWSLETTER_GDPR' | translate"></label>
				        <input id="registerisSubscribedToNewsletter" type="checkbox" formControlName="isSubscribedToNewsletter">
				        <label class="akaCheckbox" for="registerisSubscribedToNewsletter"></label>
			    	</div>

			    	<div class="inputWrapper">
			    		<a class="button button--gray iconRightPosition button--disabled" hide-delay="0" tooltip="{{ 'GLOBAL.INVALID_VALIDATION_FORM_FIELDS_TOOLTIP' | translate }}" *ngIf="!form.valid || sending"><span class="textInside">{{ 'REGISTER.REGISTER_LABEL' | translate }}</span><i class="fa fa-angle-right" aria-hidden="true"></i></a>
			    		<a class="button button--gray iconRightPosition" *ngIf="(! (!form.valid || sending))" (click)="onSubmit(form.value)"><span class="textInside">{{ 'REGISTER.REGISTER_LABEL' | translate }}</span><i class="fa fa-angle-right" aria-hidden="true"></i></a>
			    	</div>
			    </div>
          <div class="columns__half">
            <ng-template [ngIf]="facebookLogin">
              <div class="inputWrapper">
                  <h3>{{ 'REGISTER.FACEBOOK_REGISTER_HEADER' | translate }}</h3>
                  <a class="button button&#45;&#45;blue" (click)="layout.loginViaFacebook()"><i class="fa fa-facebook-official" aria-hidden="true"></i><span class="textInside">{{ 'REGISTER.REGISTER_LABEL' | translate }}</span></a>
              </div>
            </ng-template>
            <div class="inputWrapper">
              <h4>{{ 'REGISTER.ALREADY_REGISTERED_LABEL' | translate }}</h4>
              <a class="button button--gray iconRightPosition"
                [routerLink]="navigationService.constructUrl('LOGIN')"><span
                  class="textInside">{{ 'REGISTER.LOGIN_LABEL' | translate }}</span><i class="fa fa-angle-right"
                  aria-hidden="true"></i></a>
            </div>

          <div class="inputWrapper">
            <div class="imageBackground">
              <img src="/assets/images/secure-100-percent-big.png" alt="100% Secure">
            </div>
          </div>
          </div>
        </div>
    </form>
  </div>
</div>