export const environment = {
    production: true,
    API_URL: 'https://api.elcarado.com',
    CLIENT_ID: '1_27sashk2oplw8cogssc44owwo8o4k44gwcccwkcog448kg8woo',
    APPLICATION_NAME: 'elcarado.com',
    CLIENT_SECRET: '5vm1toa1ag4kk00ck4k0o8kswcgc8goo4gckck8044g0cowoc0',
    API_AVAILABILITY_TIMEOUT: 10000,
    USER_IDLE_INTERVAL: 10000,
    CACHE_DURATION: 60,
    COOKIE_DOMAIN: 'elcarado.com',
    ENV: 'prod',
    FACEBOOK_CLIENT_ID: '2065065290390012',
    GOOGLE_GA_KEY: 'UA-132913071-1',
    GOOGLE_MAPS_KEY: 'AIzaSyDhukYvfw6St-uhu-TkbaqNFqiRo0PsaVY'
};

