/* @TODO: config! */
import { environment } from '../../environments/environment';

export const globalConstants = {
  APPLICATION_NAME: environment.APPLICATION_NAME,
  APPLICATION_VERSION: '1.0.108',
  LANGUAGES: [
    {
      value: 'en',
      text: 'EN',
      alternativeText: 'English',
      flag: 'assets/images/flags/UnitedKingdom.png',
    },
    {
      value: 'de',
      text: 'DE',
      alternativeText: 'Deutsch',
      flag: 'assets/images/flags/Germany.png',
    },
    {
      value: 'fi',
      text: 'Fi',
      alternativeText: 'Suomi',
      flag: 'assets/images/flags/Finnland.png',
    },
    {
      value: 'br',
      text: 'BR',
      alternativeText: 'Brazil',
      flag: 'assets/images/flags/Brazil.png',
    },
  ],
  DEFAULT_LANGUAGE: 'en',
  AUTENTICATION: {
    GRANT_TYPE: 'password',
    CLIENT_ID: environment.CLIENT_ID,
    CLIENT_SECRET: environment.CLIENT_SECRET,
  },
  REFRESH_TOKEN: {
    GRANT_TYPE: 'refresh_token',
  },
  IMAGES_DOMAIN: '',
  DEFAULT_IMAGES: {
    BIG: '/assets/images/logo.png',
    SMALL: '/assets/images/logo.png',
  },
  SOCIAL_PROVIDERS: {
    'facebook': {
      'clientId': environment.FACEBOOK_CLIENT_ID,
      'apiVersion': 'v2.4'
    },
  },
  GOOGLE_MAPS_KEY: environment.GOOGLE_MAPS_KEY,
  GOOGLE_GA_KEY: environment.GOOGLE_GA_KEY,
  // DEMO_CHECK_INTERVAL: '900000', // 15 minutes
  DEMO_CHECK_INTERVAL: 900000, // 15 minutes
  RUNTIME_ERROR_TIMEOUT: 10000, // 10 sec
  COOKIE_DOMAIN: environment.COOKIE_DOMAIN,
  SESSION_EXPIRE: 900000, // 15 minutes
  // Note coming from Symfony:
  // to prevent infinite session refresh, the parameter SESSION_EXPIRE must be at least twice higher than USER_IDLE_INTERVAL
  USER_IDLE_INTERVAL: 450000, // 7.5 minutes,
  FACEBOOK_LOGIN: 0,
  BONUS_REFRESH_INTERVAL: 120000, // 2 minutes for bonus list refresh

  LANDING_PAGE: {
    DEFAULT_BONUS_AMOUNT: '200',
    DEFAULT_FREE_SPIN_AMOUNT: '575',
  },
};

if (typeof window !== 'undefined' && window.location.hostname === 'localhost') {
  globalConstants.COOKIE_DOMAIN = 'localhost';

  globalConstants.AUTENTICATION.CLIENT_ID = environment.CLIENT_ID;
  globalConstants.AUTENTICATION.CLIENT_SECRET = environment.CLIENT_SECRET;

  globalConstants.REFRESH_TOKEN.GRANT_TYPE = environment.CLIENT_ID;

  globalConstants.SOCIAL_PROVIDERS.facebook.clientId = '2065065290390012';
  globalConstants.GOOGLE_MAPS_KEY = 'AIzaSyDhukYvfw6St-uhu-TkbaqNFqiRo0PsaVY';
}
