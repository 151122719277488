import { Injectable } from '@angular/core';

import { requestConstants } from '../../constants/index';
import { requestHelper } from '../../helpers/index';
import { RequestService } from './request.service';

@Injectable() export class WinnerRequestService {

  /**
     * @constructor
     */
  constructor(
    private requestService: RequestService
  ) {
    //...
  }

  /**
     * Makes 'winner list' request.
     * @param {Number} [limit = 12]
     * @returns <promise>
     */
  listRequest(limit = 12) {
    const withToken = false;
    const url = requestHelper.urlReplace(
      requestConstants['API_URL'] + requestConstants['WINNER_LIST']['URL'],
      {
        limit: limit
      }
    );
    
    return this.requestService.listRequest(url, {}, withToken);
  }

}
