<div class="webauthn__dropdown-wrapper">
    
    <div id="webauthn-sugess-modal" >
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="webauthn__dropdown-inside first">
                <a class="closeRegisterDropdownButton" (click)="close.emit()"><i class="fa fa-times" aria-hidden="true"></i></a>
                <h2>{{ 'LAYOUT.USE_BIOMETRIC' | translate }}</h2>
                <img src="/assets/images/webauthn/face-scan-small.png" alt="" id="image_webauthn_register" />
                <p>{{ 'LAYOUT.WEBAUTHN_SUGESS_MODAL_REGISTER_SUGESS' | translate }}</p>
                <p>{{ 'LAYOUT.WEBAUTHN_SUGESS_MODAL_REGISTER_SUGESS_TEXT' | translate }}</p>
                        <div class="form-group">
                             <form class="webautn-form-register login-form-widget">
                                <div class="webauthn__dropdown-insideElement withoutMargin">
                                        <button class="button button--green iconRightPosition center-button" type="button"  id="webauthn-register-button-suggess"
                                        (click) ="webauthRegister()"
                                        ><i class="load-animate fa fa-spinner fa-spin" style="font-size:30px"></i> {{ 'LAYOUT.WEBAUTHN_SUGESS_MODAL_REGISTER' | translate }}</button>
                                    </div>
                                    </form>

                        </div>


                        <div class="or"></div>

                        <div class="form-group">
                            <div class="webauthn__dropdown-insideElement withoutMargin">
                            <button class="button button--red iconRightPosition  center-button" type="submit" data-dismiss="modal" aria-label="Close"  (click)="close.emit()">{{ 'LAYOUT.WEBAUTHN_SUGESS_MODAL_CONTINUE' | translate }}</button>
                           </div>
                           </div>

            </div>
        </div>
    </div>
</div>
          

</div>

