import { Component } from '@angular/core';
import {
  Router,
  NavigationEnd,
  ActivatedRoute
} from '@angular/router';
import { NavigationService } from '../../services/index';

@Component({
  selector: 'maintenance-mode',
  templateUrl: './maintenanceMode.component.html',
  styleUrls: ['./maintenanceMode.component.scss']
})

export class MaintenanceModeComponent {
  private currentRoute

  /**
   * @Consturctor
   */
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    public navigationService: NavigationService
  ) {
    this.onRouterChanged();
  }

  /**
   * It's native Angular 'on initiation' method.
   * Fired after constructor.
   */
  ngOnInit() {
    // ...
  }

  /**
   * On router changed.
   */
  onRouterChanged() {
    /**
     * On router subscribe handler.
     * @handler
     * @param value {Object}
     * @param value.id {Number}
     * @param value.url {String}
     * @param value.urlAfterRedirects? {String}
     */
    const _routerSubscribeHandler = (value) => {
      const navigationEndInstance = (value instanceof NavigationEnd);
      if (!navigationEndInstance) {
        return;
      }
      this.currentRoute = this.router.url;
    };

    this.router.events.subscribe(_routerSubscribeHandler);
  }

}
