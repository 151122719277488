/* Contact Form Model */
export class ContactFormModel {
  /**
	 * @constructor
	 */
  constructor(
    public email?: any,
    public subject?: any,
    public firstName?: any,
    public lastName?: any,
    public phone?: any,
    public message?: any
  ) {  }
}