<a class="closeRegisterDropdownButton" (click)="close.emit()"><i class="fa fa-times" aria-hidden="true"></i></a>
<div class="register__dropdown-wrapper">
  <form class="register__dropdown-inside first" [formGroup]="form" (ngSubmit)="register()" (keyup.enter)="register()">
    <h2>
      <span>{{ 'LAYOUT.REGISTER_LABEL' | translate }}</span>
    </h2>

    <div class="form-element register__dropdown-insideElement moreMarginBottom">
      <label for="email">{{ 'LAYOUT.LOG_IN_BAR_EMAIL_PLACEHOLDER' | translate }}</label>
      <input id="email" formControlName="email" required [class.error]="form.get('email').dirty && form.get('email').invalid" />
      <span class="errorMessage" *ngIf="form.get('email').dirty && form.get('email').invalid">
        <span class="errorMessage__inside">
          <span *ngFor="let error of form.get('email').errors | keyValue">{{
            registerValidationMessages.email[error.key] | translate
          }}</span>
        </span>
      </span>
    </div>
    <ng-container formGroupName="plainPassword">
      <div class="form-element register__dropdown-insideElement moreMarginBottom">
        <label for="password">{{ 'LAYOUT.LOG_IN_BAR_PASSWORD_PLACEHOLDER' | translate }}</label>
        <input
          id="password"
          type="password"
          formControlName="first"
          validateEqual="plainPassword.second"
          reverse="true"
          required
          [class.error]="form.get('plainPassword.first').dirty && form.get('plainPassword.first').invalid"
        />
        <span class="errorMessage" *ngIf="form.get('plainPassword.first').dirty && form.get('plainPassword.first').invalid">
          <span class="errorMessage__inside">
            <span *ngFor="let error of form.get('plainPassword.first').errors | keyValue">{{
              registerValidationMessages.password[error.key] | translate
            }}</span>
          </span>
        </span>
        <span class="hints">{{ 'GLOBAL.VALIDATION_MESSAGES.PASSWORD_FORMAT_HINT' | translate }}</span>
      </div>
      <div class="form-element register__dropdown-insideElement moreMarginBottom">
        <label for="repeatPassword">{{ 'LAYOUT.REGISTER_BAR_PASSWORD_REPEAT_PLACEHOLDER' | translate }}</label>
        <input
          id="repeatPassword"
          type="password"
          formControlName="second"
          validateEqual="plainPassword.first"
          reverse="false"
          required
          [class.error]="form.get('plainPassword.second').dirty && form.get('plainPassword.second').invalid"
        />
        <span class="errorMessage" *ngIf="form.get('plainPassword.second').dirty && form.get('plainPassword.second').invalid">
          <span class="errorMessage__inside">
            <span *ngFor="let error of form.get('plainPassword.second').errors | keyValue">{{
              registerValidationMessages.repeatPassword[error.key] | translate
            }}</span>
          </span>
        </span>
      </div>
    </ng-container>

    <div class="form-element register__dropdown-insideElement moreMarginBottom">
      <label for="accountCountry">{{ 'USER_ACCOUNT.COUNTRY_LABEL' | translate }}</label>
      <select id="accountCountry" formControlName="country" required>
        <option *ngFor="let country of countries" value="{{ country.value }}">{{ country.name }}</option>
      </select>
      <span class="errorMessage" *ngIf="form.get('country').dirty && form.get('country').invalid">
        <span class="errorMessage__inside">
          <span *ngFor="let error of form.get('country').errors | keyValue">{{
            registerValidationMessages.country[error.key] | translate
          }}</span>
        </span>
      </span>
    </div>

    <div class="register__dropdown-insideElement moreMarginBottom centeredText">
      <div class="inputWrapperRelative">
        <label class="acceptance" for="acceptTermsAndConditions" [innerHtml]="registerAcceptanceText | async"></label>
        <input
          id="acceptTermsAndConditions"
          type="checkbox"
          formControlName="acceptTermsAndConditions"
          required
          [class.error]="form.get('acceptTermsAndConditions').dirty && form.get('acceptTermsAndConditions').invalid"
        />
        <label
          for="acceptTermsAndConditions"
          class="akaCheckbox"
          [class.error]="form.get('acceptTermsAndConditions').dirty && form.get('acceptTermsAndConditions').invalid"
        ></label>
      </div>
      <div class="inputWrapperRelative">
        <label class="acceptance" for="isSubscribedToNewsletter" [innerHtml]="'REGISTER.SUBSCRIBE_TO_NEWSLETTER_GDPR' | translate"></label>
        <input id="isSubscribedToNewsletter" type="checkbox" formControlName="isSubscribedToNewsletter" />
        <label for="isSubscribedToNewsletter" class="akaCheckbox"></label>
      </div>
    </div>

    <div class="register__dropdown-insideElement withoutMargin">
      <a
        class="button button--green button--disabled"
        hide-delay="0"
        tooltip="{{ 'GLOBAL.INVALID_VALIDATION_FORM_FIELDS_TOOLTIP' | translate }}"
        *ngIf="!form.valid || register$"
      >
        <i class="fa fa-user-plus" aria-hidden="true"></i
        ><span class="textInside">{{ 'LAYOUT.REGISTER_BAR_REGISTER_LABEL' | translate }}</span>
      </a>
      <a class="button button--green" *ngIf="form.valid && !register$" (click)="register()">
        <i class="fa fa-user-plus" aria-hidden="true"></i
        ><span class="textInside">{{ 'LAYOUT.REGISTER_BAR_REGISTER_LABEL' | translate }}</span>
      </a>
    </div>
  </form>
  <div class="register__dropdown-inside withoutVerticalPadding">
    <hr attr.data-text="{{ 'LAYOUT.OR_LABEL' | translate }}" />
  </div>
  <div class="register__dropdown-inside">
    <div class="register__dropdown-insideElement withoutMargin">
      <a class="button button--blue" (click)="facebookRegister()"
        ><i class="fa fa-facebook-official" aria-hidden="true"></i
        ><span class="textInside">{{ 'LAYOUT.FACEBOOK_REGISTER_LABEL' | translate }}</span></a
      >
    </div>
  </div>
  <div *ngIf="!loginHidden" class="register__dropdown-inside darkBackground paddingForDecorator">
    <div class="register__dropdown-insideElement withoutMargin">
      <h3>{{ 'LAYOUT.ALREADY_REGISTERED_LABEL' | translate }}</h3>
      <a class="button button--gray iconRightPosition" (click)="close.emit('login')"
        ><span class="textInside">{{ 'LAYOUT.LOGIN_LABEL' | translate }}</span
        ><i class="fa fa-angle-right" aria-hidden="true"></i
      ></a>
    </div>
  </div>
</div>

