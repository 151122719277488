import { Injectable } from '@angular/core';
import { requestConstants } from '../../constants/index';
import { requestHelper } from '../../helpers/index';
import { RequestService } from './request.service';

import { Observable } from 'rxjs';

@Injectable() export class NewsletterRequestService {

  /**
   * @constructor
   */
  constructor(private requestService: RequestService) { }

  /**
   * Makes 'Subscribe to newsletter' request.
   * @param {Object} parameters
   * @param {String} parameters.email
   * @returns <http:post>
   */
  subscribeToNewsletter(parameters) {
    const withToken = false;
    const method = requestConstants['SUBSCRIBE_TO_NEWSLETTER']['METHOD'].toUpperCase();
    const url = requestConstants['API_URL'] + requestConstants['SUBSCRIBE_TO_NEWSLETTER']['URL'];

    return this.requestService[method](url, parameters, withToken);
  }

  /**
   * Makes 'Unsubscribe from newsletter' request.
   * @param {Object} parameters
   * @param {String} parameters.email
   * @returns <http:post>
   */
  unsubscribeFromNewsletter(parameters) {
    const withToken = true;
    const method = requestConstants['UNSUBSCRIBE_FROM_NEWSLETTER']['METHOD'].toUpperCase();
    const url = requestConstants['API_URL'] + requestConstants['UNSUBSCRIBE_FROM_NEWSLETTER']['URL'];

    return this.requestService[method](url, parameters, withToken);
  }

  unsubscribeByPublicId(publicId: string): Observable<any> {
    const method = requestConstants.UNSUBSCRIBE_FROM_NEWSLETTER_BY_PUBLIC_ID.METHOD;
    const url = requestHelper.urlReplace(
      requestConstants.API_URL + requestConstants.UNSUBSCRIBE_FROM_NEWSLETTER_BY_PUBLIC_ID.URL,
      { publicId }
    );

    return this.requestService[method](url);
  }

  unsubscribeResponse: NewsletterUnsubscribeResponse
}

export interface NewsletterUnsubscribeResponse {
  message?: string;
  result: string;
}
