import { BaseCashierComponent } from '../base.component';
import { Component } from '@angular/core';

@Component({
  selector: 'cashier-pay-in',
  templateUrl: './payIn.component.html',
  styleUrls: ['../base.component.scss']
})

export class PayInComponent extends BaseCashierComponent {
  public iframeId = 'payin'
}