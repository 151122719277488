import { BreakpointObserver } from '@angular/cdk/layout';
import { AfterContentChecked, AfterContentInit, Component, ContentChildren, ElementRef, EmbeddedViewRef, HostListener, Input, OnDestroy, OnInit, QueryList, TemplateRef, ViewChild, ViewContainerRef, NgZone } from '@angular/core';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import { interval, Subscription, timer } from 'rxjs';
import { PlatformService } from '../../../services/platform.service';
import { SlideItemDirective } from '../slide-item/slide-item.directive';
import { SliderOptions } from '../slider.model';

@Component({
  selector: 'slider',
  templateUrl: './slider.component.html',
  styleUrls: ['./slider.component.scss'],
})
export class SliderComponent implements AfterContentChecked, AfterContentInit, OnInit, OnDestroy {
  // ? { static: true } query results available in ngOnInit, { static: false } query results available in ngAfterViewInit
  @ViewChild('slide', { static: true }) slideRef: ElementRef;
  @ViewChild('slider', { static: true }) sliderRef: ElementRef;
  @ViewChild('viewContainerRef', { read: ViewContainerRef, static: true }) viewContainerRef: ViewContainerRef;
  private slideUpdateTimeout: any;
  private component$: Subscription[] = [];
  private slideStyleTransformLeft: number;
  private slideToTransformCustomDuration: number;
  slidesWidth = 0
  slideStyle: {
    transform?: SafeStyle;
    transition?: string;
    width?: string;
  } = { };

  constructor(
    public breakpointObserver: BreakpointObserver,
    private platformService: PlatformService,
    private sanitizer: DomSanitizer,
    private zone: NgZone) {
    this.platformBrowser = platformService.browser;
    this.platformServer = platformService.server;
  }

  initializing = false
  initialized = false

  loopInfiniteOverlap$: Subscription

  ngAfterContentChecked() {
    if (this.platformService.server) return;
    this.slideUpdate();
  }

  ngAfterContentInit() {
    if (!this.slideItems) {
      this.slideItemsBefore = this.slideItemTemplates.map(it => this.viewContainerRef.createEmbeddedView(it));
      this.slideItems = this.slideItemTemplates.map(it => this.viewContainerRef.createEmbeddedView(it));
      this.slideItemsAfter = this.slideItemTemplates.map(it => this.viewContainerRef.createEmbeddedView(it));
      this.slideItemsAll = this.slideItemsBefore.concat(this.slideItems).concat(this.slideItemsAfter);

      this.slideItemsBefore.forEach(it => {
        it.rootNodes[0].classList.add('clone');
        it.rootNodes[0].classList.add('before');
      });
      this.slideItemsAfter.forEach(it => {
        it.rootNodes[0].classList.add('clone');
        it.rootNodes[0].classList.add('after');
      });
      this.slideItemsAll.forEach(it => {
        if (!it.rootNodes[0].classList.contains('slide-item'))
          it.rootNodes[0].classList.add('slide-item');
      });
    }
  }

  ngOnDestroy() {
    if (this.platformService.server) return;
    this.component$.forEach(it => it.unsubscribe());
  }

  ngOnInit() {
    if (this.platformService.server) return;

    this.initializing = true;

    this.optionsCurrent = this.options
      || (this.optionsResolver ? this.optionsResolver(this) : undefined)
      || this.optionsDefault;
    this.playAutoInit();
    this.slideCurrent = 0;

  }

  optionsAddBreakpoint(breakpoint: string, options: SliderOptions) {
    if (this.platformService.server) return;
    this.component$.push(this.breakpointObserver.observe(breakpoint).subscribe((result) => {
      if (!result.matches) return;
      this.optionsCurrent = options;
      this.slideSize = options.slideSize;
      this.windowResized();
    }));
  }

  @Input()
  options: SliderOptions

  optionsCurrent: SliderOptions

  slideSize: number

  private optionsDefault: SliderOptions = {
    autoInterval: 5000,
    autoPauseOnHover: true,
    loop: true,
    paginatorVisible: true,
    paginatorVisibleSingle: false,
    play: 'auto',
    rowCount: 1,
    slideSize: 1,
    transitionDuration: 200
  }

  @Input()
  optionsResolver: ((slider: SliderComponent) => SliderOptions)

  private playAuto$: Subscription

  private playAutoInit() {
    if (this.playAuto$) {
      this.playAuto$.unsubscribe();
      this.playAuto$ = undefined;
    }

    if (this.optionsCurrent.play !== 'auto')
      return;

    this.playAuto$ = interval(this.optionsCurrent.autoInterval || this.optionsDefault.autoInterval).subscribe(() => {
      if (!this.playAutoPaused && this.initialized && !this.initializing)
        this.slideNext();
    });
  }

  playAutoPause() {
    if (this.optionsCurrent.autoPauseOnHover)
      this.playAutoPaused = true;
  }

  playAutoUnpause() {
    this.playAutoPaused = false;
  }

  private playAutoPaused = false

  pointerClicked(event: MouseEvent) {
    const now = new Date().getTime();

    if (!this.pointerMoved || this.pointerMoveEnded.getTime() > (now + 500))
      return;

    event.preventDefault();
    event.stopImmediatePropagation();
    event.stopPropagation();
    event.returnValue = false;
  }

  private pointerMoveBegin(x: number, y: number) {
    if (this.loopInfiniteOverlap$) return;
    this.pointerMoved = false;
    event.preventDefault();
    event.returnValue = false;
    this.pointerMovePositionX = x;
    this.pointerMovePositionY = y;
    this.pointerMoveLeft = this.slideStyleTransformLeft || 0;
    this.slideStyle.transition = 'none';
  }

  pointerMoveBeginMouse(event: MouseEvent) {
    if (this.loopInfiniteOverlap$) return;
    event.preventDefault();
    event.returnValue = false;
    this.pointerMoveBegin(event.screenX, event.screenY);
  }

  pointerMoveBeginTouchStarted: Touch

  pointerMoveBeginTouch(event: TouchEvent) {
    if (this.loopInfiniteOverlap$ || this.pointerMoveBeginTouchStarted) return;
    this.pointerMoveBeginTouchStarted = event.touches[0];
    event.preventDefault();
    event.returnValue = false;
    this.pointerMoveBegin(this.pointerMoveBeginTouchStarted.screenX, this.pointerMoveBeginTouchStarted.screenY);
  }

  private pointerMoveEnded: Date = undefined
  private pointerMoved: boolean = undefined
  pointerMovePositionX: number = undefined
  private pointerMovePositionY: number = undefined
  private pointerMoveLeft: number = undefined

  private pointerMoveEnd(x: number) {
    if (this.pointerMovePositionX === undefined)
      return;

    let closestSlideItemDiff = undefined;
    let closestSlideItemIndex = undefined;

    this.slideItemsAll.forEach((slideItem, slideItemIndex) => {
      const offsetLeft = slideItem.rootNodes[0].offsetLeft;
      const diff = Math.abs(-this.slideStyleTransformLeft - offsetLeft);
      if (closestSlideItemDiff === undefined || diff < closestSlideItemDiff) {
        closestSlideItemDiff = diff;
        closestSlideItemIndex = slideItemIndex;
      }
    });

    let slideToIndex = closestSlideItemIndex;
    if (closestSlideItemDiff > 50)
      slideToIndex = x > this.pointerMovePositionX ? closestSlideItemIndex - 1 : closestSlideItemIndex + 1;

    this.pointerMovePositionX = undefined;
    this.pointerMovePositionY = undefined;
    this.slideTo(slideToIndex, false, 200);
    this.playAutoInit();
    this.pointerMoveEnded = new Date();
  }

  pointerMoveEndMouse(event: MouseEvent) {
    if (this.sliderRef.nativeElement !== event.currentTarget) return;
    this.pointerMoveEnd(event.screenX);
  }

  pointerMoveEndTouch(event: TouchEvent) {
    if (!this.pointerMoveBeginTouchStarted) return;
    let touch: Touch = undefined;
    for (let i = 0; i < event.changedTouches.length; i++) {
      if (event.changedTouches[i].identifier === this.pointerMoveBeginTouchStarted.identifier)
        touch = event.changedTouches[i];
    }
    this.pointerMoveBeginTouchStarted = undefined;
    if (touch) this.pointerMoveEnd(touch.screenX);
  }

  pointerMovePositionUpdate(x: number) {
    if (this.pointerMovePositionX === undefined) return;

    const offsetX = x - this.pointerMovePositionX;
    this.slideStyleTransformLeft = this.pointerMoveLeft + offsetX;
    this.slideStyle.transform = this.sanitizer.bypassSecurityTrustStyle(`translate3d(${this.slideStyleTransformLeft}px, 0px, 0px)`);
    this.pointerMoved = true;
  }

  pointerMovePositionUpdateMouse(event: MouseEvent) {
    this.pointerMovePositionUpdate(event.screenX);
  }

  pointerMovePositionUpdateTouch(event: TouchEvent) {
    if (!this.pointerMoveBeginTouchStarted) return;
    let touch: Touch = undefined;
    for (let i = 0; i < event.changedTouches.length; i++) {
      if (event.changedTouches[i].identifier === this.pointerMoveBeginTouchStarted.identifier)
        touch = event.changedTouches[i];
    }
    if (touch) this.pointerMovePositionUpdate(touch.screenX);
  }

  platformBrowser = false
  platformServer = false

  _slideCurrent: number
  get slideCurrent(): number { return this._slideCurrent; }
  set slideCurrent(value: number) {
    this._slideCurrent = value;
  }

  private slideItemsAfter: EmbeddedViewRef<{}>[]

  private slideItemsAll: EmbeddedViewRef<{}>[]

  private slideItemsBefore: EmbeddedViewRef<{}>[]

  slideItems: EmbeddedViewRef<{}>[]

  @ContentChildren(SlideItemDirective, { read: TemplateRef }) slideItemTemplates: QueryList<TemplateRef<{}>>

  slideNext() {
    this.slideTo(this.slideCurrent + 1);
  }

  slidePrev() {
    this.slideTo(this.slideCurrent - 1);
  }

  slideTo(slideIndex: number, initial = false, duration: number = undefined) {
    if (!this.slidesWidth || this.pointerMovePositionX !== undefined) return;

    if (slideIndex < this.slideItems.length || slideIndex >= this.slideItems.length * 2) {
      if (!this.optionsCurrent.loop) return;
      this.slideCurrent = slideIndex;
      if (initial) {
        this.slideToTransform(true, duration);
      } else {
        if (this.loopInfiniteOverlap$)
          this.loopInfiniteOverlap$.unsubscribe();
        this.loopInfiniteOverlap$ =
          timer((this.optionsCurrent.transitionDuration || this.optionsDefault.transitionDuration) + 50).subscribe(() => {
            this.loopInfiniteOverlap$.unsubscribe();
            this.loopInfiniteOverlap$ = undefined;
            this.slideCurrent = this.slideItems.length + (slideIndex % this.slideItems.length);
            this.slideToTransform(true, duration);
          });
      }
    } else if (slideIndex >= this.slideItems.length) {
      this.slideCurrent = slideIndex;
    }

    this.slideToTransform(initial, duration);
  }

  private slideToTransform(initial: boolean, duration: number = undefined) {
    if (this.slideCurrent === undefined
      || !this.slideItemsAll
      || this.slideCurrent < 0
      || this.slideCurrent >= this.slideItemsAll.length)
      return;

    if (!this.initialized || this.initializing || initial) {
      this.slideStyle.transition = 'none';
    }
    else if (!this.slideStyle.transition || this.slideStyle.transition === 'none' || this.slideToTransformCustomDuration) {
      this.slideStyle.transition = `all ${  (duration || this.optionsCurrent.transitionDuration || this.optionsDefault.transitionDuration) / 1000  }s`;
      this.slideToTransformCustomDuration = duration;
    }

    this.slideStyleTransformLeft = -this.slideItemsAll[this.slideCurrent].rootNodes[0].offsetLeft;
    this.slideStyle.transform = this.sanitizer.bypassSecurityTrustStyle(`translate3d(${this.slideStyleTransformLeft}px, 0px, 0px)`);
  }

  slideUpdate(initialSlideTo = undefined) {
    if (this.slideCurrent > 0
      && (this.slideStyleTransformLeft === undefined || this.slideStyleTransformLeft === 0)
      && this.slideItemsAll
      && this.slideItemsAll.length
      && this.slideItemsAll[this.slideCurrent].rootNodes
      && this.slideItemsAll[this.slideCurrent].rootNodes.length
      && this.slideItemsAll[this.slideCurrent].rootNodes[0].offsetLeft) {
      this.slideToTransform(true);
    }

    if (this.pointerMovePositionX !== undefined || !this.slideRef || !this.slideRef.nativeElement) return;

    if (this.slideUpdateTimeout) return;

    this.slideUpdateTimeout = setTimeout(() => {
      this.zone.run(() => {
        const sliderEl = this.sliderRef.nativeElement as HTMLDivElement;
        if (this.pointerMovePositionX !== undefined || !sliderEl.clientWidth) return;
        let sizeSlide = this.optionsCurrent.slideSize;
        if ( this.slideSize !== undefined){
          sizeSlide=this.slideSize;
        }
        //const slideItemWidth = sliderEl.clientWidth / Math.min(this.optionsCurrent.slideSize, this.slideItems.length);
        const slideItemWidth = sliderEl.clientWidth / Math.min(sizeSlide, this.slideItems.length);
        this.slidesWidth = slideItemWidth * this.slideItems.length + 2;

        [this.slideItemsBefore, this.slideItems, this.slideItemsAfter].forEach(currentItems => {
          currentItems.forEach((slideItem: any) => {
            slideItem.rootNodes[0].style.width = `${slideItemWidth  }px`;
          });
        });

        this.initializing = !this.slidesWidth;
        this.slideStyle.width = `${(this.slidesWidth * 3).toString()  }px`;
        if (!this.initializing && !this.initialized) {
          this.slideTo(this.slideItems.length, true);
          this.initialized = true;
          this.playAutoInit();
        } else if (initialSlideTo !== undefined) {
          this.slideTo(initialSlideTo);
        }
        this.slideUpdateTimeout = undefined;
      });
    }, 500);
  }

  @HostListener('window:resize', ['$event'])
  windowResized() {
    if (this.platformService.server) return;
    this.initialized = false;
    this.initializing = true;
    this.slideUpdate(this.slideItems.length);
  }

}
