import { Component, OnInit, OnDestroy } from '@angular/core';
import { NewsletterRequestService, NewsletterUnsubscribeResponse, AppLanguageService } from '../../services';
import { Subscription } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';
import { routingConstants } from '../../constants';

@Component({
  selector: 'newsletter-unsubscribe',
  template: '<div></div>'
})
export class NewsletterUnsubscribeComponent implements OnInit, OnDestroy {

  private component$: Subscription[] = []

  constructor(
    private langService: AppLanguageService,
    private newsletterService: NewsletterRequestService,
    private route: ActivatedRoute,
    private router: Router) { }

  ngOnInit(): void {
    this.component$ = [
      this.route.params.subscribe((params: any) => this.unsubscribe(params.publicId))
    ];
  }

  ngOnDestroy(): void {
    this.component$.forEach(it => it.unsubscribe());
    this.component$ = [];
  }

  redirectToStart(result: NewsletterUnsubscribeResponse): void {
    const startUrl = routingConstants[this.langService.currentLanguage.toUpperCase()].START.slug;
    this.newsletterService.unsubscribeResponse = result;
    this.router.navigate([this.langService.currentLanguage, startUrl], { replaceUrl: true });
  }

  unsubscribe(publicId: string) {
    const error = { result: 'error' };
    if (!publicId || !publicId.length) {
      this.redirectToStart(error);
      
      return;
    }

    this.newsletterService.unsubscribeByPublicId(publicId).subscribe(
      (response: any) => this.redirectToStart(response),
      () => this.redirectToStart(error)
    );
  }
}