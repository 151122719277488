/* eslint-disable @typescript-eslint/camelcase */
import { UserService } from '../user.service';
import { Injectable } from '@angular/core';

import { requestConstants } from '../../constants/index';
import { requestHelper } from '../../helpers/request.helper';
import { RequestService } from './request.service';
import { Observable } from 'rxjs';
import { ResponseModel } from '../../models/request.model';
import { AccessTokenService } from '../access-token.service';

@Injectable() export class UserRequestService {

  constructor(
    private userService: UserService,
    private requestService: RequestService,
    private accessToken: AccessTokenService) { }

  /**
     * Login method.
     * @param {String} username
     * @param {String} password
     * @returns <http:post>
  */
  login(username: string, password: string) {
    const withToken = false;
    const method = requestConstants['OAUTH_AUTHENTICATE']['METHOD'].toUpperCase();

    const url = requestConstants['OAUTH_AUTHENTICATE']['URL'];
    const requestParameters = {
      grant_type: requestConstants['OAUTH_AUTHENTICATE']['GRANT_TYPE'],
      client_id: requestConstants['OAUTH_AUTHENTICATE']['CLIENT_ID'],
      client_secret: requestConstants['OAUTH_AUTHENTICATE']['CLIENT_SECRET'],
      username: username,
      password: password
    };

    return this.requestService[method](url, requestParameters, withToken);
  }

  /**
     * Login as user.
     * @param {String} token
     * @returns <http:post>
     */
  loginAsUser(token: string) {
    const withToken = false;
    const method = requestConstants['OAUTH_AUTHENTICATE_AS_USER']['METHOD'].toUpperCase();
    const url = requestHelper.urlReplace(
      requestConstants['OAUTH_AUTHENTICATE_AS_USER']['URL'],
      {
        token: token
      }
    );

    const requestParameters = {
      clientId: requestConstants['OAUTH_AUTHENTICATE_AS_USER']['CLIENT_ID'],
      clientSecret: requestConstants['OAUTH_AUTHENTICATE_AS_USER']['CLIENT_SECRET']
    };

    return this.requestService[method](url, requestParameters, withToken);
  }

  /**
     * Refreshes user session
     * @returns <http:get>
     */
  refreshUserSession() {
    const method = requestConstants['USER_SESSION_EXTEND']['METHOD'].toUpperCase();
    const url = requestConstants['API_URL'] + requestConstants['USER_SESSION_EXTEND']['URL'];

    return this.requestService[method](url);
  }

  /**
     * Checks user session
     * @returns <http:get>
     */
  checkUserSession() {
    const method = requestConstants['USER_SESSION_CHECK']['METHOD'].toUpperCase();
    const url = requestConstants['API_URL'] + requestConstants['USER_SESSION_CHECK']['URL'];

    return this.requestService[method](url);
  }

  /**
     * Refreshes token (session) method.
     * @returns <http:post>
     */
  refreshToken() {
    const withToken = false;
    const method = requestConstants['REFRESH_TOKEN']['METHOD'].toUpperCase();
    const token = this.accessToken.getToken();
    const url = requestHelper.urlReplace(
      requestConstants['REFRESH_TOKEN']['URL'],
      {
        grantType: requestConstants['REFRESH_TOKEN']['GRANT_TYPE'],
        clientID: requestConstants['REFRESH_TOKEN']['CLIENT_ID'],
        clientSecret: requestConstants['REFRESH_TOKEN']['CLIENT_SECRET'],
        refreshToken: token && token.refresh_token ? token.refresh_token : 'invalid_token'
      }
    );

    return this.requestService[method](url, {}, withToken);
  }

  /**
     * Makes 'Login via Facebook' request.
     * @param {Object} facebookData
     * @param {String} facebookData.name
     * @param {String} facebookData.email
     * @param {String} facebookData.uid
     * @param {String} facebookData.provider
     * @param {String} facebookData.image
     * @param {String} facebookData.token
     * @returns <http:post>
     */
  loginViaFacebook(facebookData) {
    const withToken = false;
    const method = requestConstants['FACEBOOK_AUTHENTICATE']['METHOD'].toUpperCase();
    const url = requestConstants['FACEBOOK_AUTHENTICATE']['URL'];

    return this.requestService[method](url, facebookData, withToken);
  }

  /**
     * Makes 'logout' request.
     * @returns <http:post>
     */
  logout() {
    const withToken = true;
    const method = requestConstants['LOGOUT']['METHOD'].toUpperCase();
    const url = requestConstants['LOGOUT']['URL'];

    return this.requestService[method](url, {}, withToken);
  }

  /**
     * Makes 'password reset' request.
     * @param {Object} data
     * @param {String} data.email
     * @returns <promise>
     */
  passwordReset(data) {
    const withToken = false;
    const method = requestConstants['USER_PASSWORD_RESET']['METHOD'];
    const url = requestConstants['API_URL'] + requestConstants['USER_PASSWORD_RESET']['URL'];

    return this.requestService[method](url, data, withToken);
  }

  /**
     * Makes 'password reset confirmation' request.
     * @param {Object} parametersMap
     * @param {Object} parametersMap.plainPassword
     * @param {String} parametersMap.plainPassword.first
     * @param {String} parametersMap.plainPassword.second
     * @param {String} temporaryToken
     * @returns <promise>
     */
  passwordResetConfirmation(parametersMap, temporaryToken) {
    const withToken = false;
    const method = requestConstants['USER_PASSWORD_RESET_VERIFICATION']['METHOD'];
    const url = requestHelper.urlReplace(
      requestConstants['API_URL'] + requestConstants['USER_PASSWORD_RESET_VERIFICATION']['URL'],
      {
        temporaryToken: temporaryToken,
      }
    );

    return this.requestService[method](url, parametersMap, withToken);
  }

  /**
     * Makes 'email change confirmation' request.
     * @param {String} temporaryToken
     * @returns <promise>
     */
  emailChangeConfirmation(temporaryToken) {
    const withToken = true;
    const method = requestConstants['USER_CONFIRM_EMAILE_CHANGE']['METHOD'];
    const url = requestHelper.urlReplace(
      requestConstants['API_URL'] + requestConstants['USER_CONFIRM_EMAILE_CHANGE']['URL'],
      {
        temporaryToken: temporaryToken,
      }
    );

    return this.requestService[method](url, {}, withToken);
  }


  /**
     * Makes 'confirm email change confirm' request.
     * @param {String} temporaryToken
     * @returns <promise>
     */
  confirmEmailChange(temporaryToken) {
    const withToken = true;
    const method = requestConstants['USER_CONFIRM_EMAIL_CHANGE']['METHOD'];
    const url = requestHelper.urlReplace(
      requestConstants['API_URL'] + requestConstants['USER_CONFIRM_EMAIL_CHANGE']['URL'],
      {
        temporaryToken: temporaryToken,
      }
    );

    return this.requestService[method](url, {}, withToken);
  }

  /**
     * Makes 'details user' request.
     * @returns <promise>
     */
  detailsRequest() {
    const url = requestConstants.API_URL + requestConstants.USER_DETAILS.URL;

    return this.requestService.detailsRequest(url);
  }

  balanceRequest() {
    const url = requestConstants.API_URL + requestConstants.USER_BALANCE.URL;

    return this.requestService.detailsRequest(url);
  }

  /**
     * Makes 'create user' request.
     * @param {Object} parametersMap
     * @returns <promise>
     * @TODO edit in urls useless.
     */
  createRequest(parametersMap): Observable<ResponseModel> {
    const withToken = false;
    const isEditView = false;
    const urls = {
      edit: requestConstants['USER_UPDATE']['URL'],
      create: requestConstants['USER_CREATE']['URL']
    };

    return this.requestService.updateRequest(
      urls,
      parametersMap,
      isEditView,
      withToken
    );
  }

  /**
     * Makes 'register confirmation' request.
     * @param {String} temporaryToken
     * @returns <promise>
     */
  registerConfirmation(temporaryToken) {
    const withToken = false;
    const method = requestConstants['USER_CREATE_VERIFICATION']['METHOD'];
    const url = requestHelper.urlReplace(
      requestConstants['API_URL'] + requestConstants['USER_CREATE_VERIFICATION']['URL'],
      {
        temporaryToken: temporaryToken,
      }
    );
    const requestParameters = {
      client_id: requestConstants['OAUTH_AUTHENTICATE_AS_USER']['CLIENT_ID'],
      client_secret: requestConstants['OAUTH_AUTHENTICATE_AS_USER']['CLIENT_SECRET']
    };

    return this.requestService[method](url, requestParameters, withToken);
  }

  /**
     * Makes 'Create user via Facebook' request.
     * @param {Object} facebookData
     * @param {String} facebookData.name
     * @param {String} facebookData.email
     * @param {String} facebookData.uid
     * @param {String} facebookData.provider
     * @param {String} facebookData.image
     * @param {String} facebookData.token
     * @returns <http:post>
     */
  createUserViaFacebook(facebookData): Observable<any> {
    const withToken = false;
    const method = requestConstants['USER_CREATE_VIA_FACEBOOK']['METHOD'].toUpperCase();
    const url = requestConstants['USER_CREATE_VIA_FACEBOOK']['URL'];

    return this.requestService[method](url, facebookData, withToken);
  }

  /**
     * Makes 'update user' request.
     * @param {Object} parametersMap
     * @returns <promise>
     */
  updateRequest(parametersMap) {
    const withToken = true;
    const method = requestConstants['USER_UPDATE']['METHOD'].toUpperCase();
    const url = requestConstants['USER_UPDATE']['URL'];

    return this.requestService[method](url, parametersMap, withToken);
  }

  /**
     * Makes 'check user' request.
     * @param {String} email
     * @returns <promise>
     */
  checkRequest(email) {
    const method = requestConstants['USER_CHECK']['METHOD'];
    const url = requestConstants['API_URL'] + requestConstants['USER_CHECK']['URL'];
    const parameters = {
      email: email
    };
    const withToken = false;

    return this.requestService[method](
      url,
      parameters,
      withToken
    );
  }

  /**
     * Makes 'change user password' request.
     * @param {Object} parametersMap
     * @TODO: parameters transform.
     * @returns <promise>
     */
  changePasswordRequest(parametersMap) {
    const method = requestConstants['USER_CHANGE_PASSWORD']['METHOD'];
    const withToken = true;
    const newParameters = parametersMap;

    const url = requestHelper.urlReplace(
      requestConstants['API_URL'] + requestConstants['USER_CHANGE_PASSWORD']['URL'],
      {}
    );

    return this.requestService[method](
      url,
      newParameters,
      withToken
    );
  }

  /**
     * Makes 'change user password as guest' request.
     * @param {String} temporaryToken
     * @param {String} currentPassword
     * @param {String} password
     * @param {String} passwordRepeat
     * @returns <promise>
     */
  changePasswordAsGuestRequest(temporaryToken, password, passwordRepeat) {
    const newParameters = {
      plainPassword: {
        first: password,
        second: passwordRepeat
      }
    };
    const method = requestConstants['USER_CHANGE_PASSWORD_AS_GUEST']['METHOD'];
    const withToken = false;

    const url = requestHelper.urlReplace(
      requestConstants['API_URL'] + requestConstants['USER_CHANGE_PASSWORD_AS_GUEST']['URL'],
      {
        temporaryToken: temporaryToken
      }
    );

    return this.requestService[method](
      url,
      newParameters,
      withToken
    );
  }

  /**
     * Makes 'accept tac' request.
     * @param {String} email
     * @returns <promise>
     */
  acceptTAC(type) {
    const method = requestConstants['TAC_ACCEPTATION']['METHOD'];
    const url = requestHelper.urlReplace(
      requestConstants['API_URL'] + requestConstants['TAC_ACCEPTATION']['URL'],
      {
        type: type
      }
    );

    return this.requestService[method](
      url,
      {}
    );
  }

  /**
     * Makes request to re-send user activation e-mail.
     * @param {Object} data
     * @param {String} data.email
     * @returns <promise>
     */
  accountActivation(data) {
    const withToken = false;
    const method = requestConstants['USER_ACCOUNT_ACTIVATION']['METHOD'];

    const url = requestHelper.urlReplace(
      requestConstants['API_URL'] + requestConstants['USER_ACCOUNT_ACTIVATION']['URL'],
      {}
    );

    return this.requestService[method](url, data, withToken);
  }

  /**
     * Makes request to refresh user favorite games.
     * @returns <promise>
     */
  refreshFavorites() {
    this.detailsRequest().subscribe((userData) => { this.userService.setFavoriteGames(userData.favourites); });
  }

  /**
     * Gets the URL to cashier iframe used on Cashier component
     * @param redirect cashier tab
     */
  getCashierUrl(redirect = 'index', depositBonusCode = null) {

    let url;
    if (redirect === 'payin' && depositBonusCode !== null) {
      url = requestHelper.urlReplace(
        requestConstants['CASHIER_PAYIN_DEPOSIT_BONUS_CODE']['URL'],
        {
          depositBonusCode: depositBonusCode
        }
      );
    } else {
      url = requestHelper.urlReplace(
        requestConstants['CASHIER_IFRAME']['URL'],
        {
          redirect: redirect
        }
      );
    }

    return this.requestService.detailsRequest(
      requestConstants['API_URL'] + url,
      true
    );
  }
}
