import { commonPagesHelper } from '../helpers/commonPages.helper';
import { CookieService } from 'ngx-cookie-service';
import { Injectable } from '@angular/core';
import { globalConstants, requestConstants, envConstants, storageConstants } from '../constants';
import { affiliateParamNormalizedMapConstants } from '../constants/affiliate.constants';
import { stringHelper } from '../helpers';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { timeout, tap, map } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { of } from 'rxjs';
import { PlatformService } from './platform.service';

@Injectable()
export class AppLoadService {
  static checkWebApiAvailabilityTimeoutDefault = 10000

  private initUrl: URL

  private static showDialog(doc: Document, message: string) {
    const dialogEl = doc.querySelector('.app-web-api-unavailable-dialog');
    const messageEl = doc.querySelector('.app-web-api-unavailable-dialog-message');
    if (dialogEl && messageEl) {
      messageEl.innerHTML = message;
      dialogEl.classList.add('app-web-api-unavailable-dialog--visible');
    }
  }

  private static hideLoadingMessage(doc: Document) {
    const messageEl = doc.querySelector('.loadingText');
    if (messageEl && messageEl.parentElement) {
      messageEl.parentElement.removeChild(messageEl);
    }
  }

  static checkWebApiAvailability(http: HttpClient, translateService: TranslateService, platformService: PlatformService, doc: Document): Promise<any> {
    const firstUrlPathSegment = platformService.browser
      ? location.pathname.substring(
        1,
        (location.pathname.substring(1) || '').indexOf('/') + 1)
      : '';
    const locale = globalConstants.LANGUAGES.some(it => it.value === firstUrlPathSegment)
      ? firstUrlPathSegment
      : globalConstants.DEFAULT_LANGUAGE;
    const url = `${envConstants.API_URL}${requestConstants.USER_SESSION_CHECK.URL.replace('${locale}', locale)}`;

    const headers = this.checkWebApiAvailabilityHeaders(platformService);

    return http
      .get(url, { headers, withCredentials: true }).pipe(
        timeout(envConstants['API_AVAILABILITY_TIMEOUT'] || AppLoadService.checkWebApiAvailabilityTimeoutDefault)
      )
      .toPromise()
      .catch((response: any) => {
        if (response && (response.status === 0 || response.name === 'TimeoutError' || response.status === undefined)) {
          return translateService.get('GLOBAL.SERVICE_DOWN').pipe(
            map((message: string) => message === 'GLOBAL.SERVICE_DOWN'
              ? 'Elcarado is currently not available, please try again later.'
              : message
            ),
            tap((message: string) => {
              AppLoadService.hideLoadingMessage(doc);
              AppLoadService.showDialog(doc, message);
              if (platformService.browser)
                throw message;
            })
          ).toPromise();
        }
        if (response && response.error && response.error.error) {
          localStorage.removeItem(storageConstants['CURRENT_TOKEN']);
          localStorage.removeItem(storageConstants['CURRENT_USER']);
          localStorage.removeItem(storageConstants['FAVORITE_GAMES']);
        }
        
        return of({}).toPromise();
      });
  }

  static checkWebApiAvailabilityFactory(http: HttpClient, translateService: TranslateService, platformService: PlatformService, document: Document) {
    return () => AppLoadService.checkWebApiAvailability(http, translateService, platformService, document);
  }

  static checkWebApiAvailabilityHeaders(platformService: PlatformService): { [key: string]: string } {
    let authToken: { access_token?: string; token_type?: string } = {};
    try {
      const authTokenString = platformService.browser
        ? localStorage.getItem(storageConstants.CURRENT_TOKEN)
        : undefined;
      if (authTokenString && authTokenString.length) {
        authToken = JSON.parse(authTokenString);
      }
    } catch (e) {
      console.error(e);
    }

    const headers = {};
    if (authToken.access_token && authToken.token_type === 'bearer') {
      headers['Authorization'] = `Bearer ${authToken.access_token}`;
    }

    return headers;
  }

  constructor(
    private router: Router,
    private cookieService: CookieService,
    private platformService: PlatformService
  ) { }

  initializeApp() {
    if (this.platformService.server) {
      return;
    }

    if (!this.initUrl) {
      this.initUrl = new URL(location.href);
      this.initAffiliateCookies();
    }
  }

  initAffiliateCookies() {
    const nextQueryParamMap = {};
    (this.initUrl.searchParams as any).forEach((value: string, key: string) => {
      const normalizedKey = stringHelper.normalize(key);
      if (normalizedKey in affiliateParamNormalizedMapConstants) {
        const cookieName = affiliateParamNormalizedMapConstants[normalizedKey];
        this.setCookie(cookieName, value);
      } else {
        nextQueryParamMap[key] = value;
      }
    });

    const nextUrl = this.router.createUrlTree(
      [this.initUrl.pathname], { queryParams: nextQueryParamMap }
    );
    this.router.navigateByUrl(nextUrl);
  }

  setCookie(name: string, value: string) {
    const expires = 180;
    const path = '/';
    const domain = commonPagesHelper.getEnvConstant('COOKIE_DOMAIN', globalConstants['COOKIE_DOMAIN']);
    this.cookieService.set(name, value, expires, path, domain);
  }
}
