import { BaseCashierComponent } from '../base.component';
import { Component } from '@angular/core';

@Component({
  selector: 'cashier-documents',
  templateUrl: '../base.component.html',
  styleUrls: ['../base.component.scss']
})

export class DocumentsComponent extends BaseCashierComponent {
  public iframeId = 'documents'
}