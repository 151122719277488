export const storageConstants = {
  CURRENT_TOKEN: 'currentToken',
  CURRENT_USER: 'currentUser',
  USER_LANGUAGE: 'userLanguage',
  LIST_TYPE: 'listType',
  LATEST_GAMES: 'latestGames',
  PREVIOUS_URL: 'previousUrl',
  PREVIOUS_URL_DATA: 'previousUrlData',
  FOLLOW_PREVIOUS_URL: 'followPreviousUrl',
  FOLLOW_PREVIOUS_URL_POSITION: 'followPreviousUrlPosition',
  FAVORITE_GAMES: 'favoriteGames',
  CINEMA_MODE: 'cinemaMode',
  VISITED_PAGES: 'visitedPages',
  IS_AUTHENTICATED: 'isAuthenticated',
};
