import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';
import { NavigationService } from '../services/index';
import { AccessTokenService } from '../services/access-token.service';


@Injectable() export class NotLoggedIn implements CanActivate {

  constructor(private router: Router, private navigationService: NavigationService, private accessToken: AccessTokenService) { }

  canActivate() {
    if (this.accessToken.isAccessTokenSet()) {
      this.router.navigateByUrl(this.navigationService.constructUrl('USER_ACCOUNT'));
      
      return false;
    }

    return true;
  }
}