<div class="container container--noVerticalPadding container--darker">
	<h1 class="big withoutStar">{{ 'CONFIRM_EMAIL_CHANGE.CONFIRM_EMAIL_CHANGE_HEADER' | translate }}</h1>
</div>

<div class="container">
	<div class="container-inside">
        <!-- TODO Move to new Sharable Component, Passing Type and Message -->
        <div class="alert alert--information" *ngIf="!isRequestFlag">
          <p class="alert__header">{{ 'GLOBAL.INLINE_ALERTS.INFORMATION' | translate }}</p>
          <p class="alert__paragraph">{{ 'CONFIRM_EMAIL_CHANGE.CONFIRM_EMAIL_CHANGE_IN_PROGRESS_LABEL' | translate }}</p>
        </div>

        <div class="alert alert--success" *ngIf="isRequestFlag && tokenIsValid">
          <p class="alert__header">{{ 'GLOBAL.INLINE_ALERTS.SUCCESS' | translate }}</p>
          <p class="alert__paragraph">{{ 'CONFIRM_EMAIL_CHANGE.CONFIRM_EMAIL_CHANGE_SUCCESS_PARAGRAPH' | translate }}</p>
        </div>

        <div class="alert alert--error" *ngIf="isRequestFlag && !tokenIsValid">
          <p class="alert__header">{{ 'GLOBAL.INLINE_ALERTS.ERROR' | translate }}</p>
          <p class="alert__paragraph">{{ 'CONFIRM_EMAIL_CHANGE.CONFIRM_EMAIL_CHANGE_FAILURE_PARAGRAPH' | translate }}</p>
        </div>

	</div>
</div>

