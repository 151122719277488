import { CasinoService } from './casino.service';
import { Injectable, EventEmitter, OnDestroy } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { requestHelper, commonPagesHelper } from '../helpers/index';
import { GameRequestService } from './request/gameRequest.service';
import { ComboNotificationService } from './comboNotification.service';
import { globalConstants, storageConstants, routingConstants } from '../constants/index';
import { Router } from '@angular/router';
import { ComboNotificationType } from '../enums/alert-type.enum';
import { ConfirmDialogComponent } from '../components/confirmation-dialog/confirmation-dialog.component';
import { DialogMessageConfig } from '../models/dialogMessageConfig.model';

@Injectable() export class GameService implements OnDestroy {
  public jackpotUpdateHandles = []
  public favoritesHasChanged$ = new EventEmitter()
  public sidebarHasBeenOpened$ = new EventEmitter()
  public sidebarHasBeenClosed$ = new EventEmitter()
  public openGame$ = new EventEmitter()
  public subscribes = {
    gameRequestServiceType: undefined,
    gameRequestServiceAddToFavourite: undefined,
    gameRequestServiceAddToFavouriteSuccessTranslations: undefined,
    gameRequestServiceAddToFavouriteFailureTranslations: undefined,
    gameRequestServiceAddToFavouriteErrorTranslations: undefined,
    gameRequestServiceRemoveFromFavourites: undefined,
    realityChecker: undefined,
    gameRequestDetails: undefined,
    router: undefined
  }

  public realityCheckerTimeout
  /**
     * @constructor
     */
  constructor(
    private _router: Router,
    private gameRequestService: GameRequestService,
    private _translate: TranslateService,
    private comboNotificationService: ComboNotificationService,
    private casinoService: CasinoService
  ) {
  }

  /**
   * This method checks if we need or not display reality checker modal
   * @param data data
   * @param urlSlug
   */
  handleRealityChecker(data, urlSlug, urlToRedirect = '') {
    const isRealityCheckWrapped = (typeof (data['realityCheck']) !== undefined);
    if (data['result'] === 'ok') {
      // 'due' value can equal: 'true', 'false' or 'none'
      if (isRealityCheckWrapped && data['realityCheck']['due'] === 'true'
        ||
        !isRealityCheckWrapped && data['due'] === 'true') {

        this._translate.get([
          'GAME_DETAILS.REALITY_CHECKER.CONFIRM',
          'GAME_DETAILS.REALITY_CHECKER.DECLINE'
        ]).subscribe((translatedValue: string) => {
          const confirmationMessage = isRealityCheckWrapped ? data['realityCheck']['message'] : data['message'];
          const confirmationSettings = {
            confirmText: translatedValue['GAME_DETAILS.REALITY_CHECKER.CONFIRM'],
            declineText: translatedValue['GAME_DETAILS.REALITY_CHECKER.DECLINE'],
            overlayClickToClose: false
          };

        // * Set the Setting for the Dialog
        const config: DialogMessageConfig = {
          data: {
            title: 'Dialog Confirmation',
            body: confirmationMessage
          },
          width: '400px',
          template: ConfirmDialogComponent
        };
        // * The Dailog Return True if the player accepted, else returns false/undefined
        this.comboNotificationService.openDialog(config).afterClosed()
        .subscribe((accepted: boolean) => {
            if (accepted) {
              if(this.casinoService.isMobile()){
                this.confirmRealityCheckerWithRedirect(urlSlug, urlToRedirect);
              } else {
                this.confirmRealityChecker(urlSlug, 'success');
              }
            } else {
              this.confirmRealityChecker(urlSlug, 'failure');
            }
          });
        });
      } else if(this.casinoService.isMobile()){
        // Start mobile game if reality check is not required right now ('due' !== 'true')
        window.location.href = urlToRedirect;
      } else {
        this.cycleRealityChecker(data, urlSlug);
      }
    } else {
      console.error('Something went wrong while checking reality');
    }
  }

  /**
   * Sends request with reality checker status and redirect to game if success
   * @param {String} urlSlug
   * @param {String} urlToRedirect
   * @param {String} [status=success]
   */
  confirmRealityCheckerWithRedirect(urlSlug, urlToRedirect, status = 'success') {
    commonPagesHelper.loader('show');

    this.subscribes.gameRequestServiceType = this.gameRequestService['confirmRealityChecker'](urlSlug, status).subscribe((data) => {
      commonPagesHelper.loader('hide');
      if (data['result'] === 'ok') {
        window.location.href = urlToRedirect;
      }
    }, (error) => {
      commonPagesHelper.loader('hide');
      // * Show SnackBar Notification
      this.comboNotificationService.notification(error.error_description, ComboNotificationType.Error);
    });
  }

  /**
   * Sends request with reality checker stats
   * @param {String} urlSlug
   * @param {String} [status=failure]
   */
  confirmRealityChecker(urlSlug, status = 'failure') {
    commonPagesHelper.loader('show');

    this.subscribes.gameRequestServiceType = this.gameRequestService['confirmRealityChecker'](urlSlug, status).subscribe((data) => {
      commonPagesHelper.loader('hide');
      if (data['result'] === 'ok') {
        this.cycleRealityChecker(data, urlSlug);
      }
    }, (error) => {
      commonPagesHelper.loader('hide');
      // * Show SnackBar Notification
      this.comboNotificationService.notification(error.error_description, ComboNotificationType.Error);
    });
  }

  /**
   * Returns reality checker details
   * @param {String} urlSlug
   */
  requestRealityChecker(urlSlug) {



    this.subscribes.realityChecker = this.gameRequestService.realityChecker(urlSlug).subscribe((rcData) => {
      this.handleRealityChecker(rcData, urlSlug);
    });
  }

  /**
   * Returns reality checker details for mobile game
   * @param {String} urlSlug
   */
  requestRealityCheckerForMobile(urlSlug, urlToRedirect) {
    this.subscribes.realityChecker = this.gameRequestService.realityChecker(urlSlug).subscribe((rcData) => {
      this.handleRealityChecker(rcData, urlSlug, urlToRedirect);
    });
  }

  /**
   * Displays reality checker modal after proper period of time
   * @param data data retrived from reality checker endpoint
   * @param urlSlug url of the game
   */
  cycleRealityChecker(data, urlSlug) {
    const isRealityCheckWrapped = (typeof (data['realityCheck']) !== undefined);
    const timeLeft = (isRealityCheckWrapped ? data['realityCheck']['timeLeft'] : data['timeLeft']) * 1000;

    this.realityCheckerTimeout = setTimeout(() => {
      this.requestRealityChecker(urlSlug);
    }, timeLeft);
  }

  /**
   * Turn off Reality Checker
   */
  turnOffRealityChecker() {
    clearTimeout(this.realityCheckerTimeout);
  }

  /**
   * Opens game.
   * @param {String} urlSlug
   * @param {String} [type=demo]
   */
  openGame(urlSlug, type = 'demo') {
    if (type === 'full' && !commonPagesHelper.userIsLogged()) {
      this._router.navigateByUrl(
        `${commonPagesHelper.constructUrl('LOGIN')  }?follow=1&toFullVersion=1`
      );

      return;
    }

    let specificMethod: string = undefined;
    switch (type) {
      case 'demo':
        specificMethod = 'demoGameDetailsRequest';
        break;
      case 'full':
        specificMethod = 'fullGameDetailsRequest';
        break;
      case 'info':
        specificMethod = 'gameDetailsRequest';
        break;
      default:
        throw new Error(`Unsupported game mode ${type}.`);
    }
    commonPagesHelper.loader('show');

    sessionStorage.setItem(
      storageConstants['PREVIOUS_URL_DATA'],
      (window.scrollY-window.innerHeight).toString()
    );

    this.subscribes.gameRequestServiceType = this.gameRequestService[specificMethod](urlSlug).subscribe((data) => {
      commonPagesHelper.loader('hide');
      if (data['result'] === 'ok') {

        if (this.casinoService.isMobile() && data.url.gameStartUrl) {
          // Close sidebar, no need to show it while starting a game
          this.sidebarHasBeenClosed$.emit(true);

          // Play demo
          if (type === 'demo' || type === 'info') {
            window.location.href = data.url.gameStartUrl;

            return;
          }

          if (data.hasOwnRealityChecker) {
            window.location.href = data.url.gameStartUrl;
          } else  {
            this.requestRealityCheckerForMobile(urlSlug, data.url.gameStartUrl);
          }

          return;
        }

        this.goToGameDetails(data.game, true, type);

        // Open game on desktop
        this.openGame$.emit({
          url: data.url,
          slug: urlSlug,
          type: type,
          jurisdictionWarning: data.jurisdictionWarning,
          vendor: data.game.vendorName,
          vendorSlug: data.game.vendorSlug,
          config: data.config || []
        });

        // We only need to fire our Reality Checker if the game doesn't have its own one
        if(type === 'full' && !data.hasOwnRealityChecker) {
          this.requestRealityChecker(urlSlug);
        }

        return;
      }

      // * Show SnackBar Notification
      this.comboNotificationService.notification(data['message'], ComboNotificationType.Error, data['errors']);
    }, (error) => {
      commonPagesHelper.loader('hide');

      switch(error.result) {
        case 'not_found':
          this._router.navigateByUrl(`${localStorage.getItem(storageConstants['USER_LANGUAGE'])}/404`);
          break;
        default:
          // * Show SnackBar Notification
          this.comboNotificationService.notification(error.message || error.error_description, ComboNotificationType.Error);
          break;
      }

    });
  }

  /**
   * Adds game to favourite list.
   * @param {Object} event
   * @param {Object} game
   */
  addToFavourites(event, game) {
    event.preventDefault();
    event.stopPropagation();
    const urlSlug = game.urlSlug;
    commonPagesHelper.loader('show');

    this.subscribes.gameRequestServiceAddToFavourite = this.gameRequestService.addToFavourites(urlSlug).subscribe((data) => {
      commonPagesHelper.loader('hide');

      if (data['result'] === 'ok') {
        // * Show SnackBar Notification
        this.comboNotificationService.notification(data['message'], ComboNotificationType.Success);
        game.isFavourite = true;
        this.favoritesHasChanged$.emit(true);

        return;
      }

      // * Show SnackBar Notification
      this.comboNotificationService.notification(data['message'], ComboNotificationType.Error);

      return;
    }, (error) => {
      commonPagesHelper.loader('hide');
      // * Show SnackBar Notification
      this.comboNotificationService.notification(`${error.error_description}.`, ComboNotificationType.Error);
    });
  }

  /**
   * Removes game from favourite list.
   * @param {Object} event
   * @param {Object} game
   */
  removeFromFavourites(event, game) {
    event.preventDefault();
    event.stopPropagation();
    const urlSlug = game.urlSlug;
    commonPagesHelper.loader('show');

    this.subscribes.gameRequestServiceRemoveFromFavourites = this.gameRequestService.removeFromFavourites(urlSlug).subscribe((data) => {
      commonPagesHelper.loader('hide');

      if (data['result'] === 'ok') {
        // * Show SnackBar Notification
        this.comboNotificationService.notification(data['message'], ComboNotificationType.Success);
        game.isFavourite = false;

        this.favoritesHasChanged$.emit(true);

        return;
      }
      // * Show SnackBar Notification
      this.comboNotificationService.notification(data['message'], ComboNotificationType.Error);

      return;
    }, (error) => {
      commonPagesHelper.loader('hide');
      // * Show SnackBar Notification
      this.comboNotificationService.notification(`${error.error_description}.`, ComboNotificationType.Error);
    });
  }

  /**
     * Goes to game details.
     * @param {Object} gameData
     * @param {Boolean} withRedirect - flag.
     * @param {String} automaticGame (full or demo).
     */
  goToGameDetails(gameData, withRedirect = false, automaticGame = '') {
    let userLanguage = localStorage.getItem(storageConstants['USER_LANGUAGE']);
    const urlSlug = gameData['urlSlug'];

    if (!userLanguage) {
      userLanguage = globalConstants['DEFAULT_LANGUAGE'];
    }

    let routeURL = `/${userLanguage}/${routingConstants[userLanguage.toUpperCase()]['GAME_DETAILS']['slug']}`.replace(':slug', urlSlug);

    if (automaticGame !== '') {
      routeURL = requestHelper.createQueryStringURL(routeURL, {
        type: automaticGame
      });
    }

    // Hide game sidebar on mobile (when we only want to show description)
    if (this.casinoService.isMobile()) {
      this.sidebarHasBeenClosed$.emit(true);
    }

    if (withRedirect) {
      this._router.navigateByUrl(routeURL);

      return;
    }

    return routeURL;
  }

  /**
     * Goes to game details with play.
     * @param {Object} gameData
     * @param {Boolean} withRedirect - flag.
     */
  goToGameDetailsWithPlay(gameData, withRedirect = false) {
    if (this.casinoService.isMobile()) {
      return this.loadGameSidebar(gameData['urlSlug']);
    }

    return this.goToGameDetails(gameData, withRedirect, 'full');
  }

  /**
     * Goes to game details with demo.
     * @param {Object} gameData
     * @param {Boolean} withRedirect - flag.
     */
  goToGameDetailsWithDemo(gameData, withRedirect = false) {
    if (this.casinoService.isMobile()) {
      return this.loadGameSidebar(gameData['urlSlug']);
    }

    return this.goToGameDetails(gameData, withRedirect, 'demo');
  }

  /**
   * Loads game side panel for mobile devices
   * @param game game slug
   */
  loadGameSidebar(game) {
    this.casinoService.toggleSidebarValue(false);
    commonPagesHelper.loader('show');

    this.subscribes.gameRequestDetails = this.gameRequestService['gameDetailsRequest'](game).subscribe((data) => {
      this.sidebarHasBeenOpened$.emit(data);
      commonPagesHelper.loader('hide');
    });

    return;
  }

  /**
   * Checks if we want to show game page as an information page or not
   * @param type Type of the game page (demo, full, info)
   */
  isGameMode(type) {
    return (type !== 'info');
  }

  /**
   * It's native Angular 'on destroy' method.
   * Destroys component.
   */
  ngOnDestroy() {
    commonPagesHelper.unsubscribeObserves(this.subscribes);
    clearTimeout(this.realityCheckerTimeout);

  }
}
