import {
  Component,
  HostListener,
  Inject,
  OnDestroy,
  OnInit,
  ViewChild,
  NgZone,
  HostBinding,
  Renderer2,
  inject, ElementRef, isDevMode
} from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { ScrollToConfigOptions, ScrollToService } from '@nicky-lenaers/ngx-scroll-to';
import { SocialAuthService, FacebookLoginProvider } from '@abacritt/angularx-social-login';
import { TranslateService } from '@ngx-translate/core';
import { CookieService } from 'ngx-cookie-service';
import {
  eventConstants,
  globalConstants,
  regExpValidationConstants,
  requestConstants,
  routingConstants,
  storageConstants,
} from '../../constants/index';
import { commonPagesHelper, formHelper, HandleAllowWebauthnGlobalHelper } from '../../helpers/index';
import { LoginFormModel } from '../../models/index';
import {
  AppLanguageService,
  AuthenticationService,
  BonusRequestService,
  CasinoService,
  ComboNotificationService,
  CustomCategoryRequestService,
  GameRequestService,
  GameService,
  LatestGamesService,
  NavigationService,
  RequestService,
  SearchBarService,
  UserRequestService,
  UserService,
  WebauthnRequestService,
  WINDOW
} from '../../services/index';
import { environment } from '../../../environments/environment';
import { Subject, Subscription, timer, interval } from 'rxjs';
import { PlatformService } from '../../services/platform.service';
import { CommonPagesService } from '../../services/commonPagesService';
import { noop } from 'rxjs';
import { AccessTokenService } from '../../services/access-token.service';
import { ComboNotificationType } from '../../enums/alert-type.enum';

import {
  //parseCreationOptionsFromJSON,
  //create,
  get,
  parseRequestOptionsFromJSON,
  supported,
  AuthenticationPublicKeyCredential,
  create,
  parseCreationOptionsFromJSON
} from "@github/webauthn-json/browser-ponyfill";


@Component({
  selector: 'app-layout-core',
  templateUrl: './layout.html',
  styleUrls: ['./styles/index.scss']
})
export class CoreLayout implements OnInit, OnDestroy {
  public newsletterEligible: boolean;
  @HostListener('window:resize', ['$event']) _onResize() {
    this.onResize();
  }
  // ? { static: true } query results available in ngOnInit, { static: false } query results available in ngAfterViewInit
  @ViewChild('scrollContent', { static: true }) scrollContent;
  @ViewChild('loaderContainer', { static: true }) loaderContainer;
  @ViewChild('bonusCounter', { static: false }) bonusCounter;
  @ViewChild('mobileLanguageWrapper', { static: true }) mobileLanguageWrapper;
  @ViewChild('sidebar', { static: true }) sidebar;

  startRecordingScrollPosition = false
  scrollYPosition = 0
  subscription: Subscription;
  currentLanguage
  isDevMode: boolean;
  isFrozen = localStorage.getItem('accountFrozenInformed') === 'true' ? true : false;
  chat = null;

  public alertOptions = {
    overlay: true,
    overlayClickToClose: true,
    showCloseButton: true,
    duration: 3000
  }

  public confirmationOptions = {
    overlay: true,
    overlayClickToClose: true,
    showCloseButton: true,
    position: ['top', 'right']
  }

  // TODO Yandrick
  public notificationOptions = {
    position: ['top', 'right'],
    timeOut: 0,
    lastOnBottom: false
  }

  mobile = {
    show: {
      menu: false,
      search: false,
      bonus: false,
      userPanel: false
    }
  }

  bonuses = []

  openedGameRightPanelTab = 'similar'

  commonPagesHelper = commonPagesHelper

  currentUserData
  userPicture
  visibleLoginBar = false
  visibleUserPanel = false
  visibleRegisterBar = false
  visibleTACModal = false
  visibleWebauthnRegister= false
  isWebauthnLoginExec= false
  showUserPassword=false
  visibleWebauthnSuggessModal=false
  isWebAuthnAvailable=false

  // Todo move to a service as subject IVAN
  // visibleBonusPanel = false;

  private _visibleLanguagePanel = false

  get visibleLanguagePanel() { return this._visibleLanguagePanel; }

  set visibleLanguagePanel(value: boolean) {
    this._visibleLanguagePanel = value;
    if (this._visibleLanguagePanel) {
      this.closeAllHeaderPanels('language');
    }
  }

  sendingLogin = false
  loginFormModel
  loginValidationMessages = {
    'username': {
      'required': 'GLOBAL.VALIDATION_MESSAGES.REQUIRED',
      'pattern': 'GLOBAL.VALIDATION_MESSAGES.WRONG_FORMAT_EMAIL'
    },
    'password': {
      'required': 'GLOBAL.VALIDATION_MESSAGES.REQUIRED',
      'minlength': 'GLOBAL.VALIDATION_MESSAGES.LEAST_SIX_CHARACTERS_LONG',
      'maxlength': 'GLOBAL.VALIDATION_MESSAGES.MORE_THAN_NINETYSIX_CHARACTERS_LONG',
    }
  }
  loginFormErrors = {
    'username': [],
    'password': []
  }
  loginForm: FormGroup

  visibleMenu = true
  visibleMenuUserInteraction = undefined

  fullscreen = false
  latestOpenedGames = []
  latestOpenedGamesLoggedUser = []

  logged = false
  loggedUserData

  imagesPath = globalConstants['IMAGES_DOMAIN']
  applicationName = globalConstants['APPLICATION_NAME']
  applicationVersion = globalConstants['APPLICATION_VERSION']
  routingConstants = routingConstants

  mainMenu
  footerMenu

  submenu = []

  firstBonusRequest = false
  cyclicBonusListSetTimeout

  cyclicTokenSetTimeout
  cyclicBonusTimeout

  routerEventsSubscribe
  activatedRouteParamsSubscribe
  requestServiceOnchangeSubscribe
  userServiceDataChangeSubscribe
  favoritesChangeSubscribe
  translateSubscribe
  bonusActivateRequestDetailsSubscribe
  languageChangeSubscribe
  languageHasBeenChangedSubscribe
  sideBarDataSubscribe
  customCategoriesSubscribe


  previousUrl = undefined
  visitedPages: string[] = JSON.parse(localStorage.getItem(storageConstants['VISITED_PAGES'])) || [];

  registrationAcceptanceText = undefined

  isMobile = false

  public sideBarOpened = false
  public sideBarContent
  public sideBarContentLoaded = false
  public sidebarLatestOpenedGame = null

  cookieValue = 'UNKNOWN'

  showLiveChatLink = false
  liveChatIconTimeout

  customCategories = []
  customCategoriesProceeded = false

  // Terms And Conditions modal
  tacTitle
  tacType
  tacText
  tacButtonText

  extendingUserSessionSubscribe
  checkingUserStateSubscribe

  sessionExtendTimer
  userActivity
  userActivitySubscriber
  userLoginSubscriber
  userInactive: Subject<any> = new Subject()

  userIsBeingTracked = false

  userServiceBalanceChangeSubscribe

  facebookLogin = false
  isAuthenticated = false

  /**
   * @constructor
   * @param router {Router}
   */
  constructor(
    public _socialAuthentication: SocialAuthService,
    private _router: Router,
    private _translate: TranslateService,
    private gameRequestService: GameRequestService,
    private gameService: GameService,
    private authenticationService: AuthenticationService,
    private userRequestService: UserRequestService,
    private bonusRequestService: BonusRequestService,
    private userService: UserService,
    private requestService: RequestService,
    public navigationService: NavigationService,
    public appLanguageService: AppLanguageService,
    private latestGamesService: LatestGamesService,
    private comboNotificationService: ComboNotificationService,
    private formBuilder: FormBuilder,
    public _scrollToService: ScrollToService,
    public searchBarService: SearchBarService,
    public casinoService: CasinoService,
    private cookieService: CookieService,
    private customCategoryRequestService: CustomCategoryRequestService,
    private zone: NgZone,
    @Inject(WINDOW) public window: Window,
    private platformService: PlatformService,
    private commonPagesService: CommonPagesService,
    private _renderer2: Renderer2,
    @Inject(DOCUMENT) private _document: Document,
    private webauthnService: WebauthnRequestService,
    private accessToken: AccessTokenService,
    private handleAllowWebauthnGlobalHelper: HandleAllowWebauthnGlobalHelper

    ) {
    const self = this;

    this.appLanguageService.setLanguages();

    this.currentLanguage = this.appLanguageService.currentLanguage;
    this.isDevMode = isDevMode();

    this.refreshCyclicToken();

    this.cyclicBonusTimeout = commonPagesHelper.getEnvConstant('BONUS_REFRESH_INTERVAL', globalConstants['BONUS_REFRESH_INTERVAL']);

    this.latestOpenedGames = this.latestGamesService.getList();

    this.latestGamesService.latestGamesChange$.subscribe((data) => {
      this.latestOpenedGames = data;
      self.setLatestOpenedGamesLoggedUser();
    });

    // this.visibleBonusPanel = commonPagesHelper.bonusPanelState;
    // console.log(this.visibleBonusPanel);

    this.loggedUserData = this.getLoggedUserData();
    this.loginFormModel = new LoginFormModel();
    this.newsletterEligible = this.getNewsletterEligible();

    this.onResize();

    requestService.requestChange$.subscribe((message) => {
      if (message === eventConstants['SHOW_LOGIN_MODAL']) {
        self.toggleLoginBar();
      }
    });

    requestService.changedTAC$.subscribe((data) => {
      this.tacTitle = data.message;
      this.tacType = data.type;
      self._translate.get([
        'TAC.TAC_CONTENT',
        'TAC.ACCEPTATION_LABEL',
        'PRIVACY_POLICY.PRIVACY_POLICY_CONTENT',
        'PRIVACY_POLICY.ACCEPTATION_LABEL',
      ]).subscribe((translatedValue: string) => {
        if (this.tacType === 0) {
          this.tacText = translatedValue['TAC.TAC_CONTENT'];
          this.tacButtonText = translatedValue['TAC.ACCEPTATION_LABEL'];
        }
        if (this.tacType === 2) {
          this.tacText = translatedValue['PRIVACY_POLICY.PRIVACY_POLICY_CONTENT'];
          this.tacButtonText = translatedValue['PRIVACY_POLICY.ACCEPTATION_LABEL'];
        }
      });
      this.showTACModal();
    });



    this.userServiceBalanceChangeSubscribe = this.userService.userBalanceChange$.subscribe((data) => {
      if (typeof (data) !== 'undefined') {
        this.loggedUserData = data;
      }
    });

    this.userServiceDataChangeSubscribe = this.userService.userDataChange.subscribe((data) => {
      if (typeof (data) === 'undefined') {
        this.logged = false;
        this.loggedUserData = undefined;
        clearTimeout(this.cyclicBonusListSetTimeout);
        this.stopWatchingUser();

        return;
      }

      this.logged = true;
      this.loggedUserData = data;

      if (!data.isFullyRegistered) {
        commonPagesHelper.loader('false');
        // * Go to User Account to Update Details
        self.navigationService.goToUserAccount();
      } else if (data.firstTime) {
        // * First Time Login
        this.userLoggedFirstTime();
      }
    });

    // Get ToS links with registration text
    this.getRegistrationAcceptanceText();
    this.languageChangeSubscribe = appLanguageService.languageChange$.subscribe(() => {
      this.getRegistrationAcceptanceText();
    });

    this.languageHasBeenChangedSubscribe = appLanguageService.languageHasBeenChanged$.subscribe(() => {
      this.setMenusData();
    });

    this.favoritesChangeSubscribe = gameService.favoritesHasChanged$.subscribe(() => {
      this.userRequestService.refreshFavorites();
    });

    if (this.previousUrl === undefined) {
      this.previousUrl = this._router.url;
    }

    this.isMobile = (this.requestService.deviceType === 'mobile');

    this.sideBarDataSubscribe = gameService.sidebarHasBeenOpened$.subscribe((data) => {
      this.sideBarLoadGameData(data);
    });

    this.sideBarDataSubscribe = gameService.sidebarHasBeenClosed$.subscribe(() => {
      this.sideBarClose();
    });

    this.startRecordingScrollPosition = true;

    this.facebookLogin = this.casinoService.isFacebookLoginEnabled();

    this.setMenusData();
    this.isAuthenticated = this.getIsAuthenticated()

    if (this.platformService.browser) {
      if (this.loggedUserData) {
        this.startWatchingUser();
        this.getCyclicBonusList();
      }

      this.liveChatIconTimeout = setTimeout(() => {
        this.showLiveChatLink = true;
      }, 2000);

      this._router.events.subscribe((event) => {
        if (event instanceof NavigationEnd) {
          if (event.url !== '/') {
            localStorage.setItem(
              storageConstants['PREVIOUS_URL'],
              self.previousUrl
            );
            if(event.url !== self.previousUrl) {
              self.previousUrl = event.url;
              this.visitedPages.push(event.url);
              localStorage.setItem(
                storageConstants['VISITED_PAGES'],
                JSON.stringify(this.visitedPages)
              );
            }
            // Analytics
            if (environment.production) {
              (window as any).ga('set', 'page', event.urlAfterRedirects);
              (window as any).ga('send', 'pageview');
            }
          }
        }
        if (this.isMobile) {
          this.sideBarClose();
        }
      });
    }
  }

  @HostBinding('class.platform-browser')
  platformBrowser: boolean

  @HostBinding('class.platform-server')
  platformServer: boolean

  showWebAuthn= false
  userIsActive = false

  /**
   * Extends not idle user session
   */
  extendUserSession(firstTime = false) {
    if ((!this.userService.isLogged() || !this.userIsActive || !this.userIsBeingTracked) && !firstTime) {
      // User is not active or logged out, we don't need to extend session
      return;
    }

    // Extending session
    this.extendingUserSessionSubscribe = this.userRequestService.refreshUserSession().subscribe(() => noop);
  }

  /**
   * Checks user state
   */
  checkUserState() {
    this.checkingUserStateSubscribe = this.userRequestService.checkUserSession().subscribe(() => noop);
  }

  /**
   * Start watching user activity
   */
  startWatchingUser() {
    this.userIsBeingTracked = true;

    this.setUserTimeout();
    this.extendUserSession(true);
    this.setTimeToSessionExtend();
    this.userActivitySubscriber = this.userInactive.subscribe(() => {
      // User is not active anymore, we don't need to extend session, user state will be checked while refreshing token
      this.userIsActive = false;
    });
  }

  getNewsletterEligible() {
    const parseUserData = this.loggedUserData;

    return parseUserData && parseUserData['newsletterEligibile'] ? parseUserData['newsletterEligibile'] : false;
  }

  /**
   * User is not logged in anymore, we don't need to watch him anymore
   */
  stopWatchingUser() {
    if (!this.userIsBeingTracked) {
      return true;
    }

    this.userIsBeingTracked = false;
    this.userActivitySubscriber.unsubscribe();
    this.extendingUserSessionSubscribe.unsubscribe();
  }

  getIsAuthenticated() {
    const getLocalAuthenticated = localStorage.getItem(storageConstants['IS_AUTHENTICATED']);
    return getLocalAuthenticated == 'true';
  }

  @HostListener('window:storage', ['$event'])
  handleParalelTab(event: StorageEvent) {
    if (event.key !== storageConstants['IS_AUTHENTICATED']) {
      return false;
    }
    const isAuthenticated = this.getIsAuthenticated()
    if (this.isAuthenticated != isAuthenticated) {
      this.isAuthenticated = isAuthenticated;
      window.location.reload();
    }
  }

  /**
   * Checks if user is active
   */
  setUserTimeout() {
    this.userActivity = setTimeout(
      () => this.userInactive.next(undefined),
      commonPagesHelper.getEnvConstant('USER_IDLE_INTERVAL', globalConstants['USER_IDLE_INTERVAL'])
    );
  }

  /**
   * Set cyclic request to extend user session
   */
  setTimeToSessionExtend() {
    this.sessionExtendTimer = setInterval(() => {
      this.extendUserSession();
    }, commonPagesHelper.getEnvConstant('USER_IDLE_INTERVAL', globalConstants['USER_IDLE_INTERVAL']));
  }

  @HostListener('document:click')
  @HostListener('window:mousemove')
  refreshUserState() {
    this.zone.runOutsideAngular(() => {
      clearTimeout(this.userActivity);
      this.setUserTimeout();
      this.userIsActive = true;
    });
  }

  sideBarLoadGameData(data) {
    if (typeof data.game !== 'undefined') {
      this.sideBarOpened = true;
      this.sideBarContent = data.game;
      this.sideBarContentLoaded = true;
      this.sidebarLatestOpenedGame = data;
    } else {
      // * Show SnackBar Notification Data
      this.comboNotificationService.notification('Cannot load game data', ComboNotificationType.Error);
    }
  }

  replaceSidebarGameImage() {
    this.sideBarContent.gameImageUrl = this.sideBarContent.gameThumbImageUrl;
  }

  sideBarClose() {
    this.sideBarOpened = false;
  }

  /**
   * Gets registration acceptance text with dynamically assigned URLs
   */
  getRegistrationAcceptanceText() {
    this._translate.get('REGISTER.ACCEPTANCE_LABEL', {
      tacUrl: this.navigationService.constructUrl('TAC'),
      privacyPolicyUrl: this.navigationService.constructUrl('PRIVACY_POLICY')
    }).subscribe((translatedValue: string) => {
      this.registrationAcceptanceText = translatedValue;
    });
  }

  /**
   * Sets latest opened games for logged user.
   */
  setLatestOpenedGamesLoggedUser() {
    this.gameRequestService.lastPlayedListRequest({
      orderField: 'id',
      orderDirection: 'desc'
    }).subscribe((data) => {
      if (data.result === 'ok') {
        this.latestOpenedGamesLoggedUser = data.games;

        return;
      }
    }, () => {
      this.latestOpenedGamesLoggedUser = [];
    });
  }

  userLoggedFirstTime(): void {
    this._translate.get([
      'GLOBAL.NOTIFICATIONS.SUCCESS_LABEL',
      'GLOBAL.NOTIFICATIONS.SUCCESS_YOU_LOGGED_IN'
    ]).subscribe((translatedValue: string) => {
      // * Show the SnackBar Notification
      this.comboNotificationService.notification(translatedValue['GLOBAL.NOTIFICATIONS.SUCCESS_YOU_LOGGED_IN'], ComboNotificationType.Success);
    });

    this.userRequestService.refreshFavorites();
    this.setMenusData();
    this.setLatestOpenedGamesLoggedUser();
    this.getCyclicBonusList();

    this.startWatchingUser();
  }

  /**
   * It's native Angular 'on initiation' method.
   * Fired after constructor.
   */
  ngOnInit(): void {
    this.isWebauthnSupported();
    this.platformBrowser = this.platformService.browser;
    this.platformServer = this.platformService.server;

    this.windowScrolledInit();

    if (this.platformService.server)
      this.mainMenuStandard();

    this.searchBarService.gameSelected$.subscribe(() => {
      this.setMobileHeaderMenu('all', 'hide');
    });

    this.buildForm();

    if (this.platformService.browser && this.cookieService.get('gdpr') === '1') {
      document.getElementById('gdpr-cookie-bar').setAttribute('class', 'gdprClass');
      this.appendGoogleAnalytics();
    }
    this.handleAllowWebauthnGlobalHelper.getCheckWebAuthnDisplay().subscribe((valor) => {
      this.showWebAuthn=valor;
    });
  }

  /**
   *
   * Check if is need to show the webauthn suggess modal
   *
   */
  needToShowWebauthnSuggessModal(){
    //const isSuport=supported();
    const isSuport=this.isWebAuthnAvailable;
    if (isSuport===undefined || !isSuport){
      return;
    }
    if (this.isLogged()){
      var cookieValue = document.cookie.replace(/(?:(?:^|.*;\s*)webauthn_popup\s*\=\s*([^;]*).*$)|^.*$/, "$1");
      let current=new Date().getTime();
      if (!(cookieValue!="" && parseInt(cookieValue + 30*24*60*60*1000) > current)){
        //this.toggleWebauthRegister();
        this.toggleWebauthSuggessPanel();
        this.cookieService.set("webauthn_popup", `${new Date().getTime()}`, 30,'/');
      }
    }
  }

  isWebauthnSupported(){
    if (window.PublicKeyCredential && window.PublicKeyCredential.isUserVerifyingPlatformAuthenticatorAvailable) {
      // El navegador admite WebAuthn y autenticadores verificables por el usuario
      window.PublicKeyCredential.isUserVerifyingPlatformAuthenticatorAvailable()
        .then((result) => {
          if (result) {
            this.isWebAuthnAvailable=true;
            /* ----- Show suggess webathn browser register? --- */
            this.needToShowWebauthnSuggessModal();
          } else {
            this.isWebAuthnAvailable=false;
            this.cookieService.set('disable_webauth_registration', "1",365,'/');
          }
        })
        .catch((error) => {
          this.isWebAuthnAvailable=false;
          this.cookieService.set('disable_webauth_registration', "1",365,'/');

        });
    } else {
      this.isWebAuthnAvailable=false;
      this.cookieService.set('disable_webauth_registration', "1",365,'/');

    }

  }

  /**
   * Set GDPR Cookie.
   * @param element
   */
  setGdprCookie() {
    if (this.cookieService.get('gdpr') !== '1') {
      const expiredDate = new Date();
      expiredDate.setDate(expiredDate.getFullYear() + 1);
      this.cookieService.set('gdpr', '1', expiredDate, '/');
      document.getElementById('gdpr-cookie-bar').setAttribute('class', 'gdprClass');
      this.appendGoogleAnalytics();
    }
  }

  appendGoogleAnalytics() {
    const gaCode = globalConstants['GOOGLE_GA_KEY']
    let script = this._renderer2.createElement('script');
    script.type = `text/javascript`;
    script.text = `
			(function (i, s, o, g, r, a, m) {
				i['GoogleAnalyticsObject'] = r;
				i[r] = i[r] || function () {
					(i[r].q = i[r].q || []).push(arguments)
				}, i[r].l = 1 * new Date();
				a = s.createElement(o),
					m = s.getElementsByTagName(o)[0];
				a.async = 1;
				a.src = g;
				m.parentNode.insertBefore(a, m)
			})(window, document, 'script', '//www.google-analytics.com/analytics.js', 'ga');

			ga('create', '${gaCode}', 'auto');
    `;
    this._renderer2.appendChild(this._document.head, script);
  }

  /**
   * Sets (mobile) header menu element.
   * @param element
   */
  setMobileHeaderMenu(element, option = 'show') {
    this.scrollContent.nativeElement.scrollTo(0, 0);

    this.mobile.show = {
      menu: false,
      search: false,
      bonus: false,
      userPanel: false
    };

    if (typeof (this.mobile.show[element]) === 'undefined') {
      this.commonPagesService.mobileMainMenu('hide');

      return;
    }

    switch (option) {
      case 'toggle':
        this.mobile.show[element] = !this.mobile.show[element];
        this.commonPagesService.mobileMainMenu(this.mobile.show[element] ? 'hide' : 'show');
        break;
      case 'show':
        this.mobile.show[element] = true;
        this.commonPagesService.mobileMainMenu('show');
        break;
      case 'hide':
        this.mobile.show[element] = false;
        this.commonPagesService.mobileMainMenu('hide');
        this.goToTop();
        break;
      default:
        break;
    }
  }

  getBonusesList(refresh = false) {
    if (!this.bonusCounter) return;

    this.bonusCounter.nativeElement.classList.add('loading');
    this.bonusRequestService.bonusListForUserRequest().subscribe((data) => {
      this.firstBonusRequest = true;
      if (data.result !== 'ok') {
        this.bonuses = [];
        this.removeBonusCounterClass('loading');
        this.getCyclicBonusList();

        return;
      }
      if (data.result === 'ok') {
        data.bonuses.forEach((element) => {
          switch (element.type) {
            case 'free_spin':
              // eslint-disable-next-line @typescript-eslint/camelcase
              element.own_type = 'Free spin';
              break;
            case 'free_spin_per_deposit':
              // eslint-disable-next-line @typescript-eslint/camelcase
              element.own_type = 'Free spin per deposit';
              break;
            case 'bonus':
              // eslint-disable-next-line @typescript-eslint/camelcase
              element.own_type = 'Bonus';
              break;
            default:
              // eslint-disable-next-line @typescript-eslint/camelcase
              element.own_type = '?';
              break;
          }

        });

        let eligibilityCheck = this.getNewsletterEligible();

        if (eligibilityCheck === false) {
          let filteredBonuses = data.bonuses.filter(bonusItem => bonusItem.type !== 'deposit_bonus');
          this.bonuses = filteredBonuses;
        } else {
          this.bonuses = data.bonuses;
        }
      }
      this.removeBonusCounterClass('loading', refresh === false);
    }, (error) => {
      this.firstBonusRequest = true;
      this.removeBonusCounterClass('loading', true)
    });
  }

  removeBonusCounterClass(className: string, refresh = false) {
    if (this.bonusCounter) {
      setTimeout(() => {
        this.bonusCounter.nativeElement.classList.remove(className);
      }, 500);
    }
    if (refresh) {
      this.getCyclicBonusList();
    }
  }

  /**
   * Gets bonus list (cyclic).
   */
  getCyclicBonusList() {
    this.cyclicBonusListSetTimeout = setTimeout(() => {
      this.getBonusesList();
    }, (!this.firstBonusRequest ? 0 : this.cyclicBonusTimeout));
  }

  /**
   * Refreshes token (cyclic).
   */
  refreshCyclicToken() {
    if (this.platformService.server) return;
    const self = this;

    this.cyclicTokenSetTimeout = setTimeout(() => {
      if (this.accessToken.isAccessTokenSet) {
        self.userRequestService.refreshToken().subscribe((data) => {
          if (data && data['access_token']) {
            this.accessToken.setAccessToken(data);
          }
          self.refreshCyclicToken();
        }, (error) => {
          if (error.error === 'unauthorized_client') {
            const withUserData = true;
            self.userService.destroyStoredData(withUserData);
          }
          self.refreshCyclicToken();
        });
      } else {
        self.refreshCyclicToken();
      }
    }, (commonPagesHelper.getEnvConstant('SESSION_EXPIRE', globalConstants['SESSION_EXPIRE']) ? (commonPagesHelper.getEnvConstant('SESSION_EXPIRE', globalConstants['SESSION_EXPIRE']) * 0.8) : 60000));
  }

  openGame(slug, type) {
    this.goToTop();
    this.gameService.openGame(slug, type);
  }

  /**
   * Builds form.
   */
  buildForm(): void {
    const loginFormBuilderOptions = {
      username: [
        this.loginFormModel.username,
        [
          Validators.required,
          Validators.pattern(regExpValidationConstants['EMAIL'])
        ]
      ],
      password: [
        this.loginFormModel.password,
        [
          Validators.required,
          Validators.minLength(6),
          Validators.maxLength(96)
        ]
      ]
    };

    this.loginForm = this.formBuilder.group(loginFormBuilderOptions);
    this.loginForm.valueChanges.subscribe(() => {
      formHelper.onValueChanged(
        this.loginForm,
        this.loginFormErrors,
        this.loginValidationMessages
      );
    });
    formHelper.onValueChanged(
      this.loginForm,
      this.loginFormErrors,
      this.loginValidationMessages
    ); // reset validation messages
  }

  /**
   * Login.
   * @param {Object} data
   * @param {Boolean} [isWidget=false]
   */
  login(data) {
    if (!this.loginForm.valid) {
      return;
    }
    const self = this;

    if (this.accessToken.isAccessTokenSet()) {
      this.authenticationService.logout();
    }

    commonPagesHelper.loader('show');

    this.sendingLogin = true;

    this.authenticationService.login(
      data.username,
      data.password
    ).subscribe(() => {
      this.userLoginSubscriber = this.userRequestService.detailsRequest().subscribe(userData => {
        if (userData.result !== 'ok') {
          return;
        }

        if (!(typeof userData.user === 'object' && userData.user.locale)) {
          return;
        }

        userData.user.firstTime = true;
        this.userService.storeUserData(userData.user);
        if (userData.favourites) {
          this.userService.setFavoriteGames(userData.favourites);
        }
        self.sendingLogin = false;
        self.clearLoginForm();
        self.latestOpenedGames = self.latestGamesService.clearList();

        // Show mobile layer after login
        if (self.casinoService.isMobile() && self.sidebarLatestOpenedGame !== null) {
          self.sideBarLoadGameData(self.sidebarLatestOpenedGame);
        }
        commonPagesHelper.loginPanel('hide');
        commonPagesHelper.loader('hide');
        setTimeout(() => {
          // Redirect after login
          // @TODO: Login and redirect for My Account to fill up user profile
          const urlToFollow = localStorage.getItem(storageConstants['PREVIOUS_URL']);
          if (self.casinoService.isDesktop() && urlToFollow !== '/') {
            this._router.navigateByUrl(urlToFollow);
          }
          this.needToShowWebauthnSuggessModal();
        }, 1000);
      }, noop);
    }, (error) => {
      commonPagesHelper.loader('hide');

      self.sendingLogin = false;
      self._translate.get(['GLOBAL.NOTIFICATIONS.WARNING_LABEL'])
        .subscribe(() => {
        // * Show SnackBar Notification
          self.comboNotificationService.notification(`${error.error_description}.`, ComboNotificationType.Error);
        });
    });
  }

  /**
   * Login via Facebook.
   * @param {Boolean} [isWidget=false]
   */
  loginViaFacebook(isWidget = false) {
    const self = this;
    commonPagesHelper.loader('show');
    this._socialAuthentication.signIn(FacebookLoginProvider.PROVIDER_ID).then((facebookData) => {
      const facebookLoginData = facebookData;

      facebookLoginData['client_id'] = requestConstants['OAUTH_AUTHENTICATE']['CLIENT_ID'];
      facebookLoginData['client_secret'] = requestConstants['OAUTH_AUTHENTICATE']['CLIENT_SECRET'];

      self.userRequestService.loginViaFacebook(facebookLoginData).subscribe((data) => {
        commonPagesHelper.loader('hide');

        if (data && data['access_token']) {
          this.accessToken.setAccessToken(data);

          self.userPicture = facebookLoginData['image'] ? facebookLoginData['image'] : '';

          self.userRequestService.detailsRequest().subscribe((userData) => {
            if (userData.result !== 'ok' || (!(typeof (userData.user) === 'object' && userData.user.locale))) {
              return;
            }

            userData.user.firstTime = true;
            self.isAuthenticated = true;
            self.userService.storeUserData(userData.user);
            self._translate.get(['GLOBAL.NOTIFICATIONS.SUCCESS_LABEL', 'GLOBAL.NOTIFICATIONS.SUCCESS_YOU_LOGGED_IN'])
              .subscribe((translatedValue: string) => {
                // * SnackBar Notifications
                self.comboNotificationService.notification(translatedValue['GLOBAL.NOTIFICATIONS.SUCCESS_YOU_LOGGED_IN'], ComboNotificationType.Success);
              });

            if (isWidget) {
              self.toggleLoginBar();
            }
            self.clearLoginForm();
            self.latestOpenedGames = self.latestGamesService.clearList();
            self.navigationService.goToDefaultPage();

          }, (error) => { self._translate.get(['GLOBAL.NOTIFICATIONS.ERROR_LABEL'])
            .subscribe(() => {
              // * SnackBar Notifications
              self.comboNotificationService.notification(`${error.error_description}.`, ComboNotificationType.Error);
            });
          });
        } else {
          console.error(JSON.stringify(data));
        }
      }, () => {
        commonPagesHelper.loader('hide');
      });
    }, () => {
      commonPagesHelper.loader('hide');
    });
  }

  /**
   * Clears login form inputs (username, password).
   */
  clearLoginForm() {
    this.loginForm.reset();
    this.loginForm.patchValue({
      username: '',
      password: ''
    });

    this.loginFormErrors.username = [];
    this.loginFormErrors.password = [];
  }

  /**
   * Logout.
   */
  logout() {
    commonPagesHelper.loader('show');
    this.authenticationService.logout().subscribe(() => {
      this._translate.get(['GLOBAL.NOTIFICATIONS.SUCCESS_LABEL', 'GLOBAL.NOTIFICATIONS.SUCCESS_YOU_LOGGED_OUT'])
        .subscribe((translatedValue: string) => {
          // * Show SnackBar Notification
          this.comboNotificationService.notification(translatedValue['GLOBAL.NOTIFICATIONS.SUCCESS_YOU_LOGGED_OUT'], ComboNotificationType.Success);
        });
      commonPagesHelper.loader('hide');
      this.userPicture = '';
      this.isAuthenticated = false;
      this.bonuses = [];
      this.clearLoginForm();
      this.latestOpenedGames = this.latestGamesService.clearList();
      this.latestOpenedGamesLoggedUser = [];
      this.navigationService.goToDefaultPage();
    });
  }

  /**
   * Shows right panel tab by tabName.
   * @param {String} tabName
   */
  showRightPanelTab(tabName) {
    this.openedGameRightPanelTab = tabName;
  }

  /**
   * Saves window scroll position to local storage.
   * @param {String} [position = undefined]
   */
  saveWindowScrollPosition(position = undefined) {
    position = position || this.scrollYPosition;

    localStorage.setItem(
      storageConstants['PREVIOUS_URL_DATA'],
      position
    );
  }

  private windowScrolledTimer$: Subscription

  windowScrolledInit() {
    if (this.platformServer || this.windowScrolledTimer$) return;
    this.windowScrolledTimer$ = timer(1000, 2000).subscribe(this.windowScrolledCheck.bind(this));
  }

  windowScrolledCheck() {
    const windowHeight = Math.max(document.documentElement.clientHeight, window.innerHeight || 0);
    const specificMethod = (window.pageYOffset > (windowHeight * 0.9)) ? 'add' : 'remove';
    document.querySelector('body').classList[specificMethod]('goToTop--show');
    if (this.startRecordingScrollPosition) {
      this.scrollYPosition = window.pageYOffset;
    }
  }

  /**
   * On window resize handler.
   * @param {Object} event
   */
  onResize(event = undefined) {
    if (typeof window === 'undefined') return;

    const viewportWidth = window.innerWidth;

    if (viewportWidth < 1280) {
      this.visibleMenu = false;
      this.commonPagesService.mainMenu('hide');
    } else {

      if (this.visibleMenuUserInteraction === false) {
        return;
      }

      this.visibleMenu = true;
      this.commonPagesService.mainMenu('show');
    }
  }

  /**
   * Goes to top position.
   */
  goToTop() {
    const config: ScrollToConfigOptions = {
      target: 'top',
      duration: 400,
      offset: 0
    };
    this._scrollToService.scrollTo(config);
  }

  /**
   * Scrolls to target html element
   */
  scrollToElement(target) {
    const config: ScrollToConfigOptions = {
      target: target,
      duration: 400,
      offset: 0
    };
    this._scrollToService.scrollTo(config);
  }

  /**
   * Router subscribe handler.
   * @handler
   * @param {Object} value
   */
  _routerSubscribeHandler(value) {
    const navigationStartInstance = (value instanceof NavigationStart);
    const navigationEndInstance = (value instanceof NavigationEnd);
    if (navigationStartInstance) {
      this.submenu = [];
    }

    if (navigationEndInstance) {
      this.logged = this.isLogged();
      this.goToTop();
    }
  }

  /**
   * Sets menu data.
   */
  setMenusData() {
    this.footerMenu = {
      gamesSummary: [
        {
          translationKey: 'LAYOUT.FOOTER_MENU.ALL_LABEL',
          link: this.navigationService.constructUrl('ALL_GAMES', { filter: 'all' })
        },
        {
          translationKey: 'LAYOUT.FOOTER_MENU.SLOTS_LABEL',
          link: this.navigationService.constructUrl('ALL_GAMES', { filter: 'slots' }),
          queryStringParameters: {}
        },
        {
          translationKey: 'LAYOUT.FOOTER_MENU.TABLE_GAMES_LABEL',
          link: this.navigationService.constructUrl('ALL_GAMES', { filter: 'table' }),
          queryStringParameters: {}
        }
      ],
      service: [
        /*{
          translationKey: 'LAYOUT.FOOTER_MENU.SUPPORT_LABEL',
          link: this.navigationService.constructUrl('SUPPORT')
        },*/
        {
          translationKey: 'LAYOUT.FOOTER_MENU.WELCOME_BONUS_LABEL',
          link: this.navigationService.constructUrl('BONUS')
        },
        /*{
          translationKey: 'LAYOUT.FOOTER_MENU.BONUS_CONDITIONS_LABEL',
          link: this.navigationService.constructUrl('BONUS_CONDITION')
        },*/
        {
          translationKey: 'LAYOUT.FOOTER_MENU.FAQ_LABEL',
          link: this.navigationService.constructUrl('FAQ')
        },
        {
          translationKey: 'LAYOUT.FOOTER_MENU.CONTACT_LABEL',
          link: this.navigationService.constructUrl('CONTACT')
        },
        {
          translationKey: 'LAYOUT.FOOTER_MENU.PAYMENT_METHODS_LABEL',
          link: this.navigationService.constructUrl('PAYMENT_PROVIDERS')
        },
        {
          translationKey: 'LAYOUT.FOOTER_MENU.RESPONSIBLE_GAMBLING_LABEL',
          link: this.navigationService.constructUrl('RESPONSIBLE_GAMBLING')
        },
        {
          translationKey: 'LAYOUT.FOOTER_MENU.PROTECTION_OF_MINORS_LABEL',
          link: this.navigationService.constructUrl('YOUTH_PROTECTION')
        },
        {
          translationKey: 'LAYOUT.FOOTER_MENU.SAFETY_LABEL',
          link: this.navigationService.constructUrl('SAFETY')
        }
      ],
      about: [
        {
          translationKey: 'LAYOUT.FOOTER_MENU.ABOUT_US_LABEL',
          link: this.navigationService.constructUrl('ABOUT_US')
        },
        {
          translationKey: 'LAYOUT.FOOTER_MENU.TAC_LABEL',
          link: this.navigationService.constructUrl('TAC')
        },
        {
          translationKey: 'LAYOUT.FOOTER_MENU.GENERAL_BONUS_TERMS_LABEL',
          link: this.navigationService.constructUrl('GENERAL_BONUS_TERMS')
        },
        {
          translationKey: 'LAYOUT.FOOTER_MENU.PRIVACY_POLICY_LABEL',
          link: this.navigationService.constructUrl('PRIVACY_POLICY')
        },
        {
          translationKey: 'LAYOUT.FOOTER_MENU.COOKIE_POLICY_LABEL',
          link: this.navigationService.constructUrl('COOKIE_POLICY')
        }
      ]
    };

    // * Re-Build Menu Again
    this.initMainMenu([]);

    this.customCategoriesSubscribe = this.customCategoryRequestService
      .listRequest()
      .subscribe((data) => {
        commonPagesHelper.loader('hide');
        this.customCategories = [];
        if (data['result'] === 'ok' && data.gameCategories.length) {
          Object.keys(data.gameCategories).forEach((key) => {
            this.customCategories.push({
              isVisible: true,
              iconName: 'icon-icon12',
              label: data.gameCategories[key]['name'],
              imgSrc: data.gameCategories[key]['image'],
              link: this.navigationService.constructUrl('ALL_GAMES_CATEGORY', {
                filter: 'games-category',
                category: data.gameCategories[key]['slug']
              }),
              queryStringParameters: {},
              translationKey: false
            });
          });
          this.customCategories.push({
            separator: true,
            isVisible: true
          });

          this.initMainMenu(this.customCategories);
          this.footerMenu.gamesSummary.push(...this.customCategories);

          this.customCategoriesProceeded = true;

          this.customCategories = [];
        }
      }, () => {
        commonPagesHelper.loader('hide');
      });
  }

  initMainMenu(customCategories: any[]) {
    this.mainMenu = [
      {
        iconName: 'icon-icon15',
        translationKey: 'LAYOUT.MAIN_MENU.HOME_LABEL',
        link: this.navigationService.constructUrl('START'),
        isVisible: true
      },
      {
        iconName: 'icon-icon12',
        translationKey: 'LAYOUT.MAIN_MENU.LIVE_CASINO_LABEL',
        link: this.navigationService.constructUrl('ALL_GAMES', { filter: 'livecasino' }),
        queryStringParameters: {},
        isVisible: true
      },
      // {
      //   iconName: 'icon-icon16',
      //   translationKey: 'LAYOUT.MAIN_MENU.BONUS_LABEL',
      //   link: this.navigationService.constructUrl('BONUS'),
      //   isVisible: true
      // },
      /*{
        iconName: 'fa fa-envelope-o',
        translationKey: 'LAYOUT.MAIN_MENU.CONTACT_LABEL',
        link: this.navigationService.constructUrl('CONTACT'),
        isVisible: true
      },
      {
        iconName: 'fa fa-comments',
        translationKey: 'LAYOUT.MAIN_MENU.FAQ_LABEL',
        link: this.navigationService.constructUrl('FAQ'),
        isVisible: true
      },*/
      {
        separator: true,
        isVisible: true
      },
      {
        iconName: 'icon-icon22',
        translationKey: 'LAYOUT.MAIN_MENU.YOUR_FAVOURITE_LABEL',
        link: this.navigationService.constructUrl('ALL_GAMES', { filter: 'your-favourite' }),
        queryStringParameters: {},
        isVisible: this.userService.getUserData()
      },
      {
        iconName: 'icon-icon11',
        translationKey: 'LAYOUT.MAIN_MENU.LAST_PLAYED_LABEL',
        link: this.navigationService.constructUrl('ALL_GAMES', { filter: 'last-played' }),
        queryStringParameters: {},
        isVisible: this.userService.getUserData()
      },
      {
        iconName: 'icon-icon10',
        translationKey: 'LAYOUT.MAIN_MENU.HOT_LABEL',
        link: this.navigationService.constructUrl('ALL_GAMES', { filter: 'hot' }),
        queryStringParameters: {},
        isVisible: true
      },
      {
        iconName: 'icon-icon14',
        translationKey: 'LAYOUT.MAIN_MENU.NEW_LABEL',
        link: this.navigationService.constructUrl('ALL_GAMES', { filter: 'new' }),
        queryStringParameters: {},
        isVisible: true
      },
      /*{
        iconName: 'fa fa-paper-plane-o',
        translationKey: 'LAYOUT.MAIN_MENU.LATEST_LABEL',
        link: this.navigationService.constructUrl('ALL_GAMES'),
        queryStringParameters: {
          filter: 'latest'
        },
        isVisible: true
      },*/
      {
        separator: true,
        isVisible: true
      },
      {
        iconName: 'all-games-icon',
        itemClass: 'all-games',
        translationKey: 'LAYOUT.MAIN_MENU.ALL_GAMES_LABEL',
        link: this.navigationService.constructUrl('ALL_GAMES', { filter: 'all' }),
        queryStringParameters: {},
        isVisible: true
      },
      {
        iconName: 'icon-icon18',
        translationKey: 'LAYOUT.MAIN_MENU.SLOT_LABEL',
        link: this.navigationService.constructUrl('ALL_GAMES', { filter: 'slots' }),
        isVisible: true
      },
      {
        iconName: 'icon-icon19',
        translationKey: 'LAYOUT.MAIN_MENU.TABLE_LABEL',
        link: this.navigationService.constructUrl('ALL_GAMES', { filter: 'table' }),
        queryStringParameters: {},
        isVisible: true
      },
      {
        iconName: 'icon-icon20',
        translationKey: 'LAYOUT.MAIN_MENU.VIDEOPOKER_LABEL',
        link: this.navigationService.constructUrl('ALL_GAMES', { filter: 'videopoker' }),
        queryStringParameters: {},
        isVisible: true
      },
      {
        iconName: 'icon-icon17',
        translationKey: 'LAYOUT.MAIN_MENU.SCRATCHCARDS_LABEL',
        link: this.navigationService.constructUrl('ALL_GAMES', { filter: 'scratchcards' }),
        queryStringParameters: {},
        isVisible: true
      },
      {
        iconName: 'icon-icon21',
        translationKey: 'LAYOUT.MAIN_MENU.VIRTUAL_GAMES_LABEL',
        link: this.navigationService.constructUrl('ALL_GAMES', { filter: 'virtual-games' }),
        queryStringParameters: {},
        isVisible: true
      },

      {
        iconName: 'icon-icon8',
        translationKey: 'LAYOUT.MAIN_MENU.OTHER_LABEL',
        link: this.navigationService.constructUrl('ALL_GAMES', { filter: 'other' }),
        queryStringParameters: {},
        isVisible: true
      }
    ];

    if (customCategories) {
      this.mainMenu.splice(4, 0, ...customCategories);
    }
  }

  /**
   * Returns logged state.
   */
  isLogged() {
    return this.userService.isLogged();
  }

  /**
   * Gets user data.
   * @return {False|Object}
   */
  getLoggedUserData() {
    return this.userService.getUserData();
  }

  /**
   * Toggles webauthn bar.
   */
  toggleWebauthRegister() {
    this.visibleWebauthnRegister = !this.visibleWebauthnRegister;
    const webauthnToggleMethod = (this.visibleWebauthnRegister ? 'show' : 'hide');
    commonPagesHelper.webauthnPanel(webauthnToggleMethod);


  }

    /**
   * Toggles webauthn bar.
   */
    toggleWebauthSuggessPanel() {
      this.visibleWebauthnSuggessModal=!this.visibleWebauthnSuggessModal;
      const webauthnToggleMethod = (this.visibleWebauthnSuggessModal ? 'show' : 'hide');
      commonPagesHelper.webauthnSuggessModalPanel(webauthnToggleMethod);

    }
  /**
   * Toggles register bar.
   */
  toggleRegisterBar() {
    this.visibleRegisterBar = !this.visibleRegisterBar;
    const registerToggleMethod = (this.visibleRegisterBar ? 'show' : 'hide');
    commonPagesHelper.registerPanel(registerToggleMethod);
  }

  /**
   * Toggles login bar.
   */
  toggleLoginBar() {
    this.visibleLoginBar = !this.visibleLoginBar;
    if (this.visibleLoginBar){
      this.handleAllowWebauthnGlobalHelper.getCheckWebAuthnDisplay().subscribe((valor) => {
        this.showWebAuthn=valor;
      });
    }
    const loginToggleMethod = (this.visibleLoginBar ? 'show' : 'hide');
    commonPagesHelper.loginPanel(loginToggleMethod);
  }

  /**
   * Toggles user panel bar.
   */
  toggleUserPanel() {
    const userToggleMethod = (this.visibleUserPanel ? 'hide' : 'show');
    this.visibleLanguagePanel = false;
    this.closeAllHeaderPanels('user');
    this.visibleUserPanel = userToggleMethod === 'show';
    commonPagesHelper.userPanel(userToggleMethod);
  }

  /**
 * Toggles user bonus panel.
 */
  toggleBonusPanel() {
    const bonusToggleMethod = (commonPagesHelper.bonusPanelState ? 'hide' : 'show');
    this.visibleLanguagePanel = false;
    this.closeAllHeaderPanels('bonus');
    commonPagesHelper.bonusPanelState = bonusToggleMethod === 'show';
    commonPagesHelper.userPanel(bonusToggleMethod);
  }

  /**
   * Transforms main menu to compact mode.
  */
  mainMenuCompact() {
    this.commonPagesService.mainMenuUserInteraction('hide');
    this.visibleMenu = false;
    this.commonPagesService.mainMenu('hide');
    this.visibleMenuUserInteraction = false;
  }

  /**
   * Transforms main menu to standard mode.
   */
  mainMenuStandard() {
    this.commonPagesService.mainMenuUserInteraction('show');
    this.visibleMenu = true;
    this.commonPagesService.mainMenu('show');
    this.visibleMenuUserInteraction = true;
  }

  /**
   * Toggles main menu.
   */
  mainMenuToggle() {
    const viewportWidth = window.innerWidth;

    if (viewportWidth < 1280) {
      this.commonPagesService.mainMenuUserInteraction('show');
      this.visibleMenu = !this.visibleMenu;
      this.commonPagesService.mainMenu(this.visibleMenu ? 'show' : 'hide');
      this.visibleMenuUserInteraction = this.visibleMenu;

      return;
    }

    if (typeof (this.visibleMenuUserInteraction) === 'undefined') {
      this.visibleMenuUserInteraction = (!this.visibleMenu);
    } else {
      this.visibleMenuUserInteraction = (!this.visibleMenuUserInteraction);
    }

    this.commonPagesService.mainMenuUserInteraction(this.visibleMenuUserInteraction ? 'show' : 'hide');
    this.visibleMenu = this.visibleMenuUserInteraction;
    this.commonPagesService.mainMenu(this.visibleMenu ? 'show' : 'hide');
  }

  closeAllHeaderPanels(except?: string) {
    if (except !== 'language')
      this.visibleLanguagePanel = false;
    if (except !== 'bonus')
      this.closeBonusPanel();
    if (except !== 'user')
      this.closeUserPanel();
  }

  closeBonusPanel() {
    if (!commonPagesHelper.bonusPanelState) return;
    commonPagesHelper.bonusPanelState(false);
    commonPagesHelper.userPanel('hide');
  }

  /**
   * Collapses login panel.
   */
  closeLoginPanel() {
    this.visibleLoginBar = false;
    commonPagesHelper.loginPanel('hide');
  }

  /**
   * Collapses webauthn suggess panel.
   */
    closeWebauthnSuggessPanel() {
      this.visibleWebauthnSuggessModal = false;
      commonPagesHelper.webauthnSuggessModalPanel('hide');
    }

    /**
   * Collapses webauthn panel.
   */
    closeWebauthnPanel(show: string | undefined) {
      this.visibleWebauthnRegister = false;
      commonPagesHelper.webauthnPanel('hide');
    }
  /**
   * Collapses register panel.
   */
  closeRegisterPanel(show: string | undefined) {
    this.visibleRegisterBar = false;
    commonPagesHelper.registerPanel('hide');
    if (show === 'login') {
      this.toggleLoginBar();
    }
  }

  closeUserPanel() {
    if (!this.visibleUserPanel) return;
    this.visibleUserPanel = false;
    commonPagesHelper.userPanel('hide');
  }

  /**
   * Toggles mobile languages.
   */
  toggleMobileLanguages() {
    this.mobileLanguageWrapper.nativeElement.classList.toggle('language-switcher-wrapper--active');
  }

  /**
   * Fullscreen mode on.
   */
  fullscreenModeOn() {
    const element = document.documentElement;

    if (element.requestFullscreen) {
      element.requestFullscreen();
    } else if (element['mozRequestFullScreen']) {
      element['mozRequestFullScreen']();
    } else if (element['webkitRequestFullscreen']) {
      element['webkitRequestFullscreen']();
    } else if (element['msRequestFullscreen']) {
      element['msRequestFullscreen']();
    }

    this.fullscreen = true;
    commonPagesHelper.fullscreen('enable');
  }

  /**
   * Fullscreen mode off.
   */
  fullscreenModeOff(redirectToHomepage = false) {
    if (!this.fullscreen) {
      if (redirectToHomepage) {
        this._router.navigate([this.navigationService.constructUrl('START')]);
      }

      return;
    }

    if (document['exitFullscreen']) {
      document['exitFullscreen']();
    } else if (document['mozCancelFullScreen']) {
      document['mozCancelFullScreen']();
    } else if (document['webkitExitFullscreen']) {
      document['webkitExitFullscreen']();
    }

    this.fullscreen = false;
    commonPagesHelper.fullscreen('disable');

    if (redirectToHomepage) {
      this._router.navigate(['/']);
    }
  }

  /**
   * Toggles fullscreen.
   */
  toggleFullscreenTab() {
    if (this.fullscreen) {
      this.fullscreenModeOff();

      return;
    }

    this.fullscreenModeOn();
  }

  /**
   * Gets translation by key
   * @param {String} key -- translation key.
   * @returns {String}
   */
  getTranslation(key) {
    return this._translate.get(key).subscribe((translation) => {
      return translation;
    });
  }

  /**
   * Redirects to previous page.
   */
  goBack() {
    window.history.back();
  }

  /**
   * Activates bonus.
   * @param {Object} bonus
   */
  bonusActivate(bonus) {
    if (typeof (bonus) === 'undefined') {
      return;
    }

    commonPagesHelper.loader('show');

    this.bonusActivateRequestDetailsSubscribe = this.bonusRequestService
      .bonusActivateRequest(bonus['bonusId'])
      .subscribe((data) => {

        if (data['result'] !== 'ok') {
          // * Show SnackBar Notification
          this.comboNotificationService.notification(data.message, ComboNotificationType.Error, data['errors']);
          commonPagesHelper.loader('hide');

          return;
        }

        switch (bonus['type']) {
          case 'free_spin_per_deposit':
          case 'free_spin':
            this.gameService.goToGameDetailsWithPlay(bonus['game'], true);
            break;

          case 'bonus':
            this.casinoService.refreshUserInformations();
            break;

          case 'cash_back':
            this.casinoService.refreshUserInformations();
            break;

          case 'deposit_bonus':
            // eslint-disable-next-line no-case-declarations
            const queryParams = {
              tab: 'payIn'
            };
            if (bonus['code']) {
              queryParams['depositBonusCode'] = bonus['code'];
            }
            this._router.navigate([this.navigationService.constructUrl('CASHIER')], {
              queryParams: queryParams
            });
            break;
          default:
            break;
        }

        // * Extract Result from Response
        const { result } = data;
        if (result === 'ok') {
          // * Show Message
          const message = (data.message ? data.message : 'Bonus Successfully Added');
          // * Show SnackBar Notification
          this.comboNotificationService.notification(message, ComboNotificationType.Success);
          // * Toggle the Bonus Dropdown Panel
          this.toggleBonusPanel();
        }

        this.getBonusesList(true);

        commonPagesHelper.loader('hide');
      }, (error) => {
        // * Show SnackBar Notification
        this.comboNotificationService.notification(error.error_description, ComboNotificationType.Error);

        commonPagesHelper.loader('hide');
      });
  }

  /**
   * Shows new TAC modal.
   */
  showTACModal() {
    this.visibleTACModal = true;
    commonPagesHelper.tacModal('show');
    commonPagesHelper.loader('hide');
  }

  /**
   * Sends request accepting new TAC
   */
  acceptTAC() {
    commonPagesHelper.loader('show');
    this.userRequestService.acceptTAC(this.tacType).subscribe((data) => {
      if (data.result === 'ok') {
        this.visibleTACModal = false;
        commonPagesHelper.tacModal('hide');
        commonPagesHelper.loader('hide');
        this._router.navigateByUrl(commonPagesHelper.constructUrl('ALL_GAMES', { filter: 'hot' }));
        // * Show SnackBar Notification
        this.comboNotificationService.notification(data.message, ComboNotificationType.Success);
      }
    }, (error) => {
      commonPagesHelper.loader('hide');
      // * Show SnackBar Notification
      this.comboNotificationService.notification(error.error_description, ComboNotificationType.Error);
    });
  }

  ngOnDestroy() {
    commonPagesHelper.unsubscribeObserves([
      this.routerEventsSubscribe,
      this.activatedRouteParamsSubscribe,
      this.requestServiceOnchangeSubscribe,
      this.userServiceDataChangeSubscribe,
      this.translateSubscribe,
      this.bonusActivateRequestDetailsSubscribe,
      this.favoritesChangeSubscribe,
      this.languageChangeSubscribe,
      this.sideBarDataSubscribe,
      this.languageHasBeenChangedSubscribe,
      this.userServiceBalanceChangeSubscribe,
      this.windowScrolledTimer$,
      this.subscription,
      this.userLoginSubscriber
      //this.customCategoriesSubscribe
    ]);
    clearTimeout(this.cyclicBonusListSetTimeout);
    clearTimeout(this.liveChatIconTimeout);
  }

  showUserPasswordForm(){
    const self = this;
    this.showUserPassword=true;
  }
  webauthLogin(){
    const self = this;
    this.isWebauthnLoginExec=true;
    commonPagesHelper.loader('show');

    //need to get username first
    let browserId = document.cookie.replace(/(?:(?:^|.*;\s*)allowWebautn_browserID\s*\=\s*([^;]*).*$)|^.*$/, "$1");
    this.webauthnService.getUserIdByBrowserId(browserId).subscribe(
      data => {
       if (data.state=="OK"){
        const parametersMap={"username": data.email};
        this.webauthnService.getLoginOptions(parametersMap).subscribe(
          data => {
            this.authenticateBiometric(data);
          },
          () => {
            commonPagesHelper.loader('hide');
          }
        );
       }else{
        //If is not OK, cancel the biometric login and remove the cookie
        this.isWebauthnLoginExec=false
        commonPagesHelper.loader('hide');
        this.cookieService.set("allowWebautn_browserID", "allowWebautn_browserID", 0,'/');
        this.cookieService.set('disable_webauth_registration', "",0,'/');

        //Remove also the suggess cookie
        this.cookieService.set("webauthn_popup", "0", 0,'/');
        this.comboNotificationService.notification('There was an error with you Biometric Login. Please review your Biometric Login settings in your profiler', ComboNotificationType.Error);
        this.showWebAuthn=false;
       }
      },
      () => {
      }
    );

  }

  async authenticateBiometric(data, options?: {
    conditionalMediation?: boolean;
  }){
    commonPagesHelper.loader('show');
    const cro = parseRequestOptionsFromJSON({
      publicKey: data.publickey
    });
    try{
    const response = await get(cro);
    const dataLogin = response.toJSON();
    dataLogin["client_id"]= requestConstants['OAUTH_AUTHENTICATE']['CLIENT_ID'];
    dataLogin["client_secret"]=requestConstants['OAUTH_AUTHENTICATE']['CLIENT_SECRET'];
    this.webauthnService.loginBiometricUser(dataLogin).subscribe(data =>{
      this.isWebauthnLoginExec=false;
      if (data.status=="ko"){
        this.comboNotificationService.notification(data.errorMessage, ComboNotificationType.Error);
      }else if(data.status=="ok"){
        // * Set AccessToken
        this.accessToken.setAccessToken(data);
        localStorage.removeItem(storageConstants['VISITED_PAGES']);

        this.userLoginSubscriber = this.userRequestService.detailsRequest().subscribe(userData => {
          if (userData.result !== 'ok') {
            return;
          }

          if (!(typeof userData.user === 'object' && userData.user.locale)) {
            return;
          }

          userData.user.firstTime = true;
          this.userService.storeUserData(userData.user);
          if (userData.favourites) {
            this.userService.setFavoriteGames(userData.favourites);
          }
          this.sendingLogin = false;
          this.clearLoginForm();
          this.latestOpenedGames = this.latestGamesService.clearList();

          // Show mobile layer after login
          if (this.casinoService.isMobile() && this.sidebarLatestOpenedGame !== null) {
            this.sideBarLoadGameData(this.sidebarLatestOpenedGame);
          }
          commonPagesHelper.loginPanel('hide');
          commonPagesHelper.loader('hide');
          setTimeout(() => {
            // Redirect after login
            // @TODO: Login and redirect for My Account to fill up user profile
            const urlToFollow = localStorage.getItem(storageConstants['PREVIOUS_URL']);
            if (this.casinoService.isDesktop() && urlToFollow !== '/') {
              this._router.navigateByUrl(urlToFollow);
            }
          }, 1000);
        }, noop);
      }
      commonPagesHelper.loader('hide');

    },()=>{
      commonPagesHelper.loader('hide');
      this.isWebauthnLoginExec=false;
    });
    }catch(e){
      commonPagesHelper.loader('hide');
      this.isWebauthnLoginExec=false;
      this.comboNotificationService.notification('Biometric login was cancelled by the browser. Please try again', ComboNotificationType.Error);


    }

  }

  skipWebauthRegister(){
    this.visibleWebauthnSuggessModal=false;
    this.commonPagesHelper.webauthnSuggessModalPanel("hide");
  }

  webauthRegister(){
    const self = this;
    const parametersMap={};
    commonPagesHelper.loader('show');
    try{
      this.webauthnService.getRegisterOptions(parametersMap).subscribe(
        data => {
          this.createWeauthnRegisterOptions(data);
        },
        () => {
          commonPagesHelper.loader('hide');
        }
      );
    }catch(e){
      this.isWebauthnLoginExec=false;
      this.comboNotificationService.notification('Biometric login was cancelled by the browser. Please try again', ComboNotificationType.Error);
      this.toggleWebauthSuggessPanel();
    }
  }
  async createWeauthnRegisterOptions (data){
      commonPagesHelper.loader('show');
      const cco = parseCreationOptionsFromJSON({
      publicKey: data.publickey});
      const response = await create(cco);
      const dataLogin = response.toJSON();
      this.webauthnService.saveRegisterBiometric(dataLogin).subscribe(data =>{
        commonPagesHelper.loader('hide');
       if (data.status=="ok"){
          //Cookies are not being set by the different subdomain. Need to do it manually
          const claves = Object.keys(data.cookies);
          claves.map(cookie_index =>{
              this.cookieService.set(cookie_index, data.cookies[cookie_index].value, new Date(data.cookies[cookie_index].valid),'/');
          });
          this.cookieService.set('disable_webauth_registration', "1",365,'/');
          this._translate
          .get(['USER_ACCOUNT.BIOMETRIC_REGISTRATION_SUCCESS_LABEL'])
          .subscribe((translatedValue: string) => {
            this.comboNotificationService.notification(
              translatedValue['USER_ACCOUNT.BIOMETRIC_REGISTRATION_SUCCESS_LABEL'],
              ComboNotificationType.Success
            );
          })
          //Hide the webauthn suggess panel
          this.toggleWebauthSuggessPanel();
        }
      }),() =>{
        commonPagesHelper.loader('hide');
      }
  }

  replaceGameUrl(gameURL){
    return gameURL.replace(/\/assets\/vendors/g, '/elcarado_com/assets/vendors');
  }
}
