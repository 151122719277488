import { Component, OnInit, OnDestroy } from '@angular/core';
import {
  NavigationService,
  UserService,
  AppLanguageService,
  UserRequestService,
  ComboNotificationService,
} from '../../services';
import { globalConstants, requestConstants, storageConstants } from '../../constants';
import { ActivatedRoute } from '@angular/router';
import { Subscription, from } from 'rxjs';
import { LandingPageComponentData, LandingPageData } from './landingPage.model';
import { licenseBarPaymentLogos } from '../../data/licenseBarPaymentLogos.data';
import { licenseBarVendorLogos } from '../../data/licenseBarVendorLogos.data';
import { SocialAuthService } from '@abacritt/angularx-social-login';
import { FacebookRegisterResponse } from '../../models/request.model';
import { CommonPagesService } from '../../services/commonPagesService';
import { sliderResponsiveOptions } from '../../components/slider/slider.model';
import { AccessTokenService } from '../../services/access-token.service';
import { ComboNotificationType } from '../../enums/alert-type.enum';

@Component({
  selector: 'app-landing-page',
  templateUrl: './landingPage.component.html',
  styleUrls: ['./landingPage.component.scss'],
})
export class LandingPageComponent implements OnInit, OnDestroy {
  private component$: Subscription[]

  private data: LandingPageComponentData

  facebookLogin$: Subscription

  facebookRegister$: Subscription

  jasperoConfirmationOptions = {
    overlay: true,
    overlayClickToClose: true,
    showCloseButton: true,
    position: ['top', 'right'],
  }

  jasperoNotificationOptions = {
    position: ['top', 'right'],
    timeOut: 0,
    lastOnBottom: false
  }

  registerModalVisible = false

  userDetails$: Subscription

  paymentsSliderOptionsResolver = sliderResponsiveOptions({
    '(max-width: 979px)': {
      autoInterval: 4500,
      autoPauseOnHover: true,
      loop: true,
      paginatorVisible: false,
      play: 'auto',
      rowCount: 1,
      slideSize: 2,
      transitionDuration: 3900,
    },
    '(min-width: 980px)': {
      autoInterval: 0,
      autoPauseOnHover: true,
      loop: true,
      paginatorVisible: false,
      play: 'manual',
      rowCount: 1,
      slideSize: 20,
      transitionDuration: 0,
    }
  })

  vendorsSliderOptionsResolver = sliderResponsiveOptions({
    '(max-width: 979px)': {
      autoInterval: 4500,
      autoPauseOnHover: true,
      loop: true,
      paginatorVisible: false,
      play: 'auto',
      rowCount: 1,
      slideSize: 2,
      transitionDuration: 3900,
    },
    '(min-width: 980px)': {
      autoInterval: 0,
      autoPauseOnHover: true,
      loop: true,
      paginatorVisible: false,
      play: 'manual',
      rowCount: 2,
      slideSize: 20,
      transitionDuration: 0,
    }
  })

  slider = undefined

  constructor(
    private authService: SocialAuthService,
    private comboNotificationService: ComboNotificationService,
    private languageService: AppLanguageService,
    private navigationService: NavigationService,
    private route: ActivatedRoute,
    private userService: UserService,
    private userRequestService: UserRequestService,
    private commonPagesService: CommonPagesService,
    private accessToken: AccessTokenService) { }

  get applicationVersion(): string {
    return globalConstants.APPLICATION_VERSION;
  }

  dataInit(data: LandingPageComponentData) {
    this.data = data;
  }

  private facebookLoginSuccess(facebookData) {
    this.facebookLogin$.unsubscribe();
    this.facebookLogin$ = undefined;

    // eslint-disable-next-line @typescript-eslint/camelcase
    facebookData.client_id = requestConstants.OAUTH_AUTHENTICATE.CLIENT_ID;
    // eslint-disable-next-line @typescript-eslint/camelcase
    facebookData.client_secret =
      requestConstants.OAUTH_AUTHENTICATE.CLIENT_SECRET;

    this.facebookRegister$ = this.userRequestService
      .createUserViaFacebook(facebookData)
      .subscribe(this.facebookRegisterSuccess.bind(this), () => {
        this.commonPagesService.loader('hide');
      });
  }

  private facebookLoginError() {
    this.facebookLogin$.unsubscribe();
    this.facebookLogin$ = undefined;
    this.commonPagesService.loader('hide');
  }

  facebookRegister() {
    this.commonPagesService.loader('show');

    this.facebookLogin$ =
      from(this.authService.signIn('facebook'))
        .subscribe(
          this.facebookLoginSuccess.bind(this),
          this.facebookLoginError.bind(this)
        );
  }

  private facebookRegisterSuccess(response: FacebookRegisterResponse) {
    this.facebookRegister$.unsubscribe();
    this.facebookRegister$ = undefined;

    if (!response || !response.access_token) {
      this.commonPagesService.loader('hide');
      
      return;
    }

    this.accessToken.setAccessToken(response);

    this.userDetails$ = this.userRequestService.detailsRequest().subscribe(
      userData => {
        this.userDetails$.unsubscribe();
        this.userDetails$ = undefined;
        this.commonPagesService.loader('hide');
        if (userData.result !== 'ok') {
          return;
        }
        if (!(typeof userData.user === 'object' && userData.user.locale)) {
          return;
        }

        this.userService.storeUserData(userData.user);

        this.commonPagesService.registerPanel('hide');
        this.navigationService.goToDefaultPage();
      },
      error => {
        this.userDetails$.unsubscribe();
        this.userDetails$ = undefined;
        this.commonPagesService.loader('hide');
        // * Show SnackBar Notification
        this.comboNotificationService.notification(`${error.error_description}.`, ComboNotificationType.Error);
      }
    );
  }

  get landingPage(): LandingPageData {
    return this.data && this.data.landingPage ? this.data.landingPage : {};
  }

  get languageCurrent(): string {
    return this.languageService.currentLanguage;
  }

  get licenseBarPaymentLogos(): any[] {
    return licenseBarPaymentLogos;
  }

  get licenseBarVendorLogos(): any[] {
    return licenseBarVendorLogos;
  }

  // TODO: pipe
  navigationUrl(key: string): string {
    return this.navigationService.constructUrl(key);
  }

  ngOnDestroy() {
    this.component$.forEach(it => it.unsubscribe());
    this.component$ = [];
  }

  ngOnInit() {
    this.component$ = [this.route.data.subscribe(this.dataInit.bind(this))];
  }

  registerModalOpen() {
    this.commonPagesService.registerPanel('show');
    this.registerModalVisible = true;
  }

  registerModalClose() {
    this.commonPagesService.registerPanel('hide');
    this.registerModalVisible = false;
  }

  get userAuthenticated(): boolean {
    return this.userService.isLogged();
  }
}
