<div class="container container--noVerticalPadding container--darker">
	<h1 class="big withoutStar">{{ 'RESTRICTED_AREA.HEADER' | translate }}</h1>
</div>

<div class="container">
	<div class="staticPagesContainer">
		<div class="staticPagesContainer__leftColumn">
			<div class="staticPagesContainer__leftColumn-item" [innerHtml]="restrictedAreaContent"></div>
		</div>
	</div>
</div>

