<div class="container container--noVerticalPadding container--darker">
	<h1 class="big withoutStar">{{ 'SUPPORT.SUPPORT_HEADER' | translate }}</h1>
</div>

<div class="container">
	<div class="staticPagesContainer">
		<div class="staticPagesContainer__leftColumn">
			<div class="staticPagesContainer__leftColumn-item">
				...
			</div>
		</div>
	</div>
</div>

