/* eslint-disable no-case-declarations */
import { CasinoService } from '../../services/casino.service';
import { Component, ViewChild, Host, OnInit, OnDestroy, Inject } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';
import { DomSanitizer, SafeResourceUrl, Title } from '@angular/platform-browser';
import { CoreLayout } from '../../layouts/index';
import { globalConstants, regExpValidationConstants, storageConstants } from '../../constants/index';
import {
  UserService,
  GameRequestService,
  GameService,
  NavigationService,
  LatestGamesService,
  NewsletterRequestService,
  ComboNotificationService,
  WINDOW,
} from '../../services/index';
import { TranslateService } from '@ngx-translate/core';
import { commonPagesHelper, formHelper } from '../../helpers/index';
import { NewsletterFormModel } from '../../models/index';
import { Subject, noop } from 'rxjs';
import { PlatformService } from '../../services/platform.service';
import { sliderResponsiveOptions } from '../../components/slider/slider.model';
import { GameDetailsComponentData } from './gameDetails.model';
import { ComboNotificationType } from '../../enums/alert-type.enum';
import { DialogMessageConfig } from '../../models/dialogMessageConfig.model';
import { ConfirmDialogComponent } from '../../components/confirmation-dialog/confirmation-dialog.component';

@Component({
  selector: 'game-details',
  templateUrl: './gameDetails.component.html',
  styleUrls: ['./gameDetails.component.scss'],
})
export class GameDetailsComponent implements OnInit, OnDestroy {
  // ? { static: true } query results available in ngOnInit, { static: false } query results available in ngAfterViewInit
  @ViewChild('inputComment', { static: true }) inputComment;

  layout;
  currentUrlSlug;
  slug;
  templateData;
  isDemoMode = false;
  currentGame;

  gameDetailsRequestSubscribe;
  routerSubscribe;
  routerDataSubscribe;
  routerQueryParametersSubscribe;
  gameServiceOpenGameSubscribe;
  sendGameRatingRequestSubscribe;
  ratingTranslateSubscribe;
  sendGameCommentRequestSubscribe;
  commentTranslateSubscribe;
  showErrorTranslateSubscribe;
  jurisdictionTranslateSubscribe;
  runtimeErrorsTranslateSubscribe;

  origin;
  playngoGameCommunicator;

  private iframeURL: SafeResourceUrl;
  iframeLoaded = false;
  iframeRequest = false;
  imagesPath = globalConstants['IMAGES_DOMAIN'];

  rate = 0;
  rateUserHover = false;
  sendingRate = false;

  model = {
    rating: 0,
  };
  disabledRating = false;

  sendingNewsletter = false;
  newsletterFormModel;
  newsletterValidationMessages = {
    email: {
      required: 'GLOBAL.VALIDATION_MESSAGES.REQUIRED',
      pattern: 'GLOBAL.VALIDATION_MESSAGES.WRONG_FORMAT_EMAIL',
    },
  };
  newsletterFormErrors = {
    email: [],
  };
  newsletterForm: FormGroup;

  similarGamesSliderConfig = {
    items: 6,
    dots: false,
    navigation: false,
    loop: true,
    autoplay: true,
    autoplaytimeOut: 2000,
    autoplayHoverPause: true,
    responsive: {
      0: {
        items: 1,
      },
      540: {
        items: 2,
      },
      640: {
        items: 3,
      },
      800: {
        items: 4,
      },
      1024: {
        items: 5,
      },
      1120: {
        items: 6,
      },
      1220: {
        items: 7,
      },
      1280: {
        items: 8,
      },
      1380: {
        items: 9,
      },
      1580: {
        items: 10,
      },
      1720: {
        items: 10,
      },
    },
  };

  similarGameSliderOptionsResolver = sliderResponsiveOptions({
    '(min-width: 1px) and (max-width: 539px)': {
      autoInterval: 2000,
      autoPauseOnHover: true,
      loop: true,
      paginatorVisible: false,
      play: 'auto',
      rowCount: 1,
      slideSize: 1,
      transitionDuration: 200,
    },
    '(min-width: 540px) and (max-width: 639px)': {
      autoInterval: 2000,
      autoPauseOnHover: true,
      loop: true,
      paginatorVisible: false,
      play: 'auto',
      rowCount: 1,
      slideSize: 2,
      transitionDuration: 200,
    },
    '(min-width: 640px) and (max-width: 799px)': {
      autoInterval: 2000,
      autoPauseOnHover: true,
      loop: true,
      paginatorVisible: false,
      play: 'auto',
      rowCount: 1,
      slideSize: 3,
      transitionDuration: 200,
    },
    '(min-width: 800px) and (max-width: 1023px)': {
      autoInterval: 2000,
      autoPauseOnHover: true,
      loop: true,
      paginatorVisible: false,
      play: 'auto',
      rowCount: 1,
      slideSize: 4,
      transitionDuration: 200,
    },
    '(min-width: 1024px) and (max-width: 1119px)': {
      autoInterval: 2000,
      autoPauseOnHover: true,
      loop: true,
      paginatorVisible: false,
      play: 'auto',
      rowCount: 1,
      slideSize: 5,
      transitionDuration: 200,
    },
    '(min-width: 1120px) and (max-width: 1219px)': {
      autoInterval: 2000,
      autoPauseOnHover: true,
      loop: true,
      paginatorVisible: false,
      play: 'auto',
      rowCount: 1,
      slideSize: 6,
      transitionDuration: 200,
    },
    '(min-width: 1220px) and (max-width: 1279px)': {
      autoInterval: 2000,
      autoPauseOnHover: true,
      loop: true,
      paginatorVisible: false,
      play: 'auto',
      rowCount: 1,
      slideSize: 7,
      transitionDuration: 200,
    },
    '(min-width: 1280px) and (max-width: 1379px)': {
      autoInterval: 2000,
      autoPauseOnHover: true,
      loop: true,
      paginatorVisible: false,
      play: 'auto',
      rowCount: 1,
      slideSize: 8,
      transitionDuration: 200,
    },
    '(min-width: 1380px) and (max-width: 1579px)': {
      autoInterval: 2000,
      autoPauseOnHover: true,
      loop: true,
      paginatorVisible: false,
      play: 'auto',
      rowCount: 1,
      slideSize: 9,
      transitionDuration: 200,
    },
    '(min-width: 1580px)': {
      autoInterval: 2000,
      autoPauseOnHover: true,
      loop: true,
      paginatorVisible: false,
      play: 'auto',
      rowCount: 1,
      slideSize: 10,
      transitionDuration: 200,
    },
  });

  cyclicInviteToPlayFullVersion;
  invitationForDemoIsShown = false;
  fullVersionInvitationSubscribe: Subject<any> = new Subject();

  runtimeErrorsTimer;

  runtimeMode = 'iframe';

  isCinemaMode = false;

  gameNotFound = false;

  isInformationMode = false;

  showMobileCover = false;

  cyclicReloadUserInformations;
  userBalanceReloadingSubscribe: Subject<any> = new Subject();

  /**
   * Constructor.
   */
  constructor(
     parent: CoreLayout,
    private translate: TranslateService,
    private route: ActivatedRoute,
    private _router: Router,
    public userService: UserService,
    private gameRequestService: GameRequestService,
    private gameService: GameService,
    private navigationService: NavigationService,
    private latestGamesService: LatestGamesService,
    private newsletterRequestService: NewsletterRequestService,
    private comboNotificationService: ComboNotificationService,
    private sanitizer: DomSanitizer,
    private formBuilder: FormBuilder,
    private titleService: Title,
    public casinoService: CasinoService,
    @Inject(WINDOW) public window: Window,
    private platformService: PlatformService
  ) {
    this.layout = parent;
    this.newsletterFormModel = new NewsletterFormModel();
    this.playngoGameCommunicator = this.getPlayngoGameCommunicator();
  }

  /**
   * It's native Angular 'on initiation' method.
   * Fired after constructor.
   */
  ngOnInit() {
    this.buildForm();
    const self = this;

    this.gameServiceOpenGameSubscribe = this.gameService.openGame$.subscribe(data => {
      this.currentGame = data;
      if (this.platformService.browser) {
        this.renderGameContainer(data);
      }
    });

    this.routerDataSubscribe = this.route.data.subscribe(data => {
      this.setGameData(data.gameDetails);
    });

    if (this.platformService.server) return;

    // Route params
    this.routerSubscribe = this.route.params.subscribe(parameters => {
      const slug = parameters['slug'];
      self.currentUrlSlug = slug;

      self.slug = slug;
      self.disabledRating = false;
    });

    if (this.gameNotFound === false) {
      self.routerQueryParametersSubscribe = self.route.queryParams.subscribe(queryParameters => {
        if (!self.gameService.isGameMode(queryParameters.type)) {
          self.isInformationMode = true;

          return;
        }

        if ((typeof queryParameters.type !== 'undefined' && queryParameters.type === 'full') || queryParameters.type === 'demo') {
          self.openGame(self.currentUrlSlug, queryParameters.type);
        } else {
          const userIsLogged = self.userService.isLogged();
          self.openGame(self.currentUrlSlug, userIsLogged ? 'full' : 'demo');
        }
        self.isDemoMode = queryParameters.type === 'demo';

        if (!self.isDemoMode && (!this.userService.isLogged() || !this.userService.isFullyRegistered())) {
          this._router.navigateByUrl(`${commonPagesHelper.constructUrl('LOGIN')}?follow=1&toFullVersion=1`);
        }

        if (self.isDemoMode) {
          this.runtimeActionsForDemo();
        } else {
          this.runtimeActionsForFullVersion();
        }
      });
    }

    // this.fullVersionInvitationSubscribe.subscribe(() => {
    //   this.showInvitationToPlayFullVersion();
    // });

    this.userBalanceReloadingSubscribe.subscribe(() => {
      this.reloadUserBalance();
    });

    setTimeout(() => {
      this.layout.mainMenuCompact();
    }, 200);

    this.isCinemaMode = localStorage.getItem(storageConstants['CINEMA_MODE']) ? true : false;
  }

  renderGameContainer(game) {
    const self = this;

    switch (game.vendorSlug) {
      case 'netentertainment':
        this.runtimeMode = 'div';

        this.iframeRequest = false;

        const config = game.config;

        const netEntScript = document.createElement('script');
        netEntScript.src = `${config.staticServer}/gameinclusion/library/gameinclusion.js`;
        netEntScript.type = 'text/javascript';
        netEntScript.async = true;
        netEntScript.charset = 'utf-8';

        config.targetElement = 'netentCasinoGame';
        config.language = this.layout.appLanguageService.getLanguageFromStorage();
        config.fullScreen = false;
        config.width = '100%';
        config.height = this.casinoService.isMobile() ? '100%' : '100%';
        config.enforceRatio = false;

        const onError = error => {
          if (error.code === 13) {
            // show flash error
            this.showRuntimeError();
          }
          console.warn(error);
        };

        setTimeout(function () {
          (window as any).netent.launch(config, () => null, onError);

          // Tell the game that the plugin has initialized. Plugin must call pluginReady within 30 seconds, or game will provide error message to player.
          (window as any).netent.plugin.call('pluginReady', [], noop);

          // Tell the game to show the clock in-game
          (window as any).netent.plugin.call('showSystemClock', [], noop, noop);
        }, 2000);
        document.getElementsByTagName('head')[0].appendChild(netEntScript);
        break;

      case 'playngo':
      case 'playngodirect':
        this.runtimeMode = 'iframe';

        clearTimeout(this.runtimeErrorsTimer);

        this.iframeRequest = true;

        var startUrl = `${game.url.gameStartUrl}&origin=${location.origin}`;

        var origin = new URL(startUrl);
        this.origin = origin.origin;

        this.iframeURL = this.sanitizer.bypassSecurityTrustResourceUrl(startUrl);
        break;

      default:
        this.runtimeMode = 'iframe';

        clearTimeout(this.runtimeErrorsTimer);

        this.iframeRequest = true;
        this.iframeURL = this.sanitizer.bypassSecurityTrustResourceUrl(game.url.gameStartUrl);
        this.handleGameCommunication();
        break;
    }

    this.isDemoMode = game.type === 'demo';

    if (game.jurisdictionWarning) {
      this.showWarningAboutJurisdiction();
    }
  }

  runtimeActionsForDemo() {
    this.inviteForFullVersion();
    this.gameService.turnOffRealityChecker();
    this.checkForRuntimeErrors();
  }

  runtimeActionsForFullVersion() {
    this.cycleReloadUserInformations();
    this.checkForRuntimeErrors();
  }

  /**
   * Show notification that game could not be loaded
   */
  showRuntimeError() {
    if (this.currentGame && this.currentGame.vendorSlug === 'microgaming') {
      this.iframeLoaded = true;

      return;
    }

    this.runtimeErrorsTranslateSubscribe = this.translate.get(['GAME_DETAILS.RUNTIME_ERROR']).subscribe((translatedValue: string) => {
      // * Show SnackBar Notification
      this.comboNotificationService.notification(translatedValue['GAME_DETAILS.RUNTIME_ERROR'], ComboNotificationType.Warning);
    });
  }

  /**
   * We're trying to run the game for 10 seconds, if something went wrong we display an error
   */
  checkForRuntimeErrors() {
    this.runtimeErrorsTimer = setTimeout(() => {
      if (this.runtimeMode === 'div') {
        return;
      }
      if (!this.iframeLoaded) {
        this.showRuntimeError();
      }
    }, globalConstants['RUNTIME_ERROR_TIMEOUT']);
  }

  /**
   * Information about leaving Maltese jurisdiction
   */
  showWarningAboutJurisdiction() {
    this.jurisdictionTranslateSubscribe = this.translate.get(['GLOBAL.ALERTS.JURISDICTION']).subscribe((translatedValue: string) => {
      // * Show SnackBar Notification
      this.comboNotificationService.notification(translatedValue['GLOBAL.ALERTS.JURISDICTION'], ComboNotificationType.Info, null, 6000);
    });
  }

  /**
   * Show invitation popup
   */
  showInvitationToPlayFullVersion() {
    if (this.isInformationMode) {
      return;
    }

    this.translate
      .get([
        'GAME_DETAILS.DEMO_CHECK_TITLE',
        'GAME_DETAILS.DEMO_CHECK_CONTENT_LOGGED',
        'GAME_DETAILS.DEMO_CHECK_CONTENT',
        'GAME_DETAILS.DEMO_CHECK_CONTINUE',
        'GAME_DETAILS.DEMO_CHECK_PLAY',
      ])
      .subscribe((translatedValue: string) => {
        const confirmationTitle = translatedValue['GAME_DETAILS.DEMO_CHECK_TITLE'];
        const confirmationMessage =
          translatedValue[this.userService.isLogged() ? 'GAME_DETAILS.DEMO_CHECK_CONTENT_LOGGED' : 'GAME_DETAILS.DEMO_CHECK_CONTENT'];

        this.invitationForDemoIsShown = true;

        // * Set the Setting for the Dialog
        const config: DialogMessageConfig = {
          data: {
            title: confirmationTitle,
            body: confirmationMessage,
            confirmText: translatedValue['GAME_DETAILS.DEMO_CHECK_PLAY'],
            declineText: translatedValue['GAME_DETAILS.DEMO_CHECK_CONTINUE'],
          },
          width: '400px',
          template: ConfirmDialogComponent,
        };
        // * The Dailog Return True if the player accepted, else returns false/undefined
        this.comboNotificationService
          .openDialog(config)
          .afterClosed()
          .subscribe((accepted: boolean) => {
            this.invitationForDemoIsShown = false;
            if (!accepted) {
              return false;
            } else {
              if (this.userService.isLogged()) {
                this.goToGameDetails(this.templateData, true, 'full');
              } else {
                this.layout.toggleLoginBar();
              }
            }
          });
      });
  }

  /**
   * Refresh user balance in app while playing real game
   */
  reloadUserBalance() {
    if (this.isInformationMode) {
      return;
    }

    this.casinoService.refreshUserBalance();
  }

  /**
   * Handle User balance relading in 5s intervals
   */
  cycleReloadUserInformations() {
    this.cyclicReloadUserInformations = setInterval(() => {
      this.userBalanceReloadingSubscribe.next(undefined);
    }, 5000);
  }

  /**
   * Invites user to play full version of the game
   */
  inviteForFullVersion() {
    this.cyclicInviteToPlayFullVersion = setInterval(() => {
      if (this.invitationForDemoIsShown || !this.isDemoMode) {
        return;
      }

      this.fullVersionInvitationSubscribe.next(undefined);
    }, globalConstants['DEMO_CHECK_INTERVAL']);
  }

  /**
   * Builds form.
   */
  buildForm(): void {
    const newsletterFormBuilderOptions = {
      email: [this.newsletterFormModel.username, [Validators.required, Validators.pattern(regExpValidationConstants['EMAIL'])]],
    };

    this.newsletterForm = this.formBuilder.group(newsletterFormBuilderOptions);
    this.newsletterForm.valueChanges.subscribe(() => {
      formHelper.onValueChanged(this.newsletterForm, this.newsletterFormErrors, this.newsletterValidationMessages);
    });
    formHelper.onValueChanged(this.newsletterForm, this.newsletterFormErrors, this.newsletterValidationMessages); // reset validation messages
  }

  /**
   * Iframe on load handler.
   * @param iframeGame {DOMElement}
   * @handler
   */
  iframeOnLoad(iframeGame) {
    if (this.currentGame) {
      if (iframeGame['src'] !== '') {
        if (iframeGame['src'] === this.currentGame.url.gameStartUrl.trim()) {
          this.iframeLoaded = true;
        }

        if (this.currentGame.vendorSlug === 'playngodirect') {
          this.handlePlayngoGameCommunication(iframeGame);
          this.iframeLoaded = true;
        }
      }
    }
  }

  /**
   * Sets game data.
   * @param {String} slug
   */
  setGameData(data: GameDetailsComponentData) {
    this.templateData = data.game;
    const newLatestGame = {
      name: data.game.name,
      vendorName: data.game.vendorName,
      vendorImageUrl: data.game.vendorImageUrl,
      urlSlug: data.game.urlSlug,
      gameThumbImageUrl: data.game.gameThumbImageUrl,
    };
    this.latestGamesService.add(newLatestGame);
    this.model.rating = parseFloat(this.templateData['rating']);
    this.setMetaTags(data.game);
  }

  /**
   * Rating on change.
   * @param {Float} value
   * @param {String} target
   */
  rateChange(value, target) {
    const self = this;
    if (!(target.rate && target.rate > 0 && value && value > 0) || this.disabledRating) {
      return;
    }

    if (!this.rateUserHover || this.sendingRate) {
      return;
    }

    this.rateUserHover = false;

    this.sendingRate = true;
    commonPagesHelper.loader('show');

    this.sendGameRatingRequestSubscribe = this.gameRequestService
      .sendGameRatingRequest(this.templateData['urlSlug'], target.rate)
      .subscribe(
        data => {
          self.sendingRate = false;
          if (data.result !== 'ok') {
            self.showError(data['message']);
            commonPagesHelper.loader('hide');
            self.disabledRating = true;

            return;
          }

          if (typeof data['rating'] !== 'undefined') {
            self.templateData.rating = data['rating'];
            self.templateData.ratingsCount = data['ratingsCount'];

            self.rate = target.rate;
            self.ratingTranslateSubscribe = self.translate
              .get(['GLOBAL.NOTIFICATIONS.THANKS_FOR_RATING_LABEL'])
              .subscribe((translatedValue: string) => {
                // * Show SnackBar Notification
                self.comboNotificationService.notification(
                  translatedValue['GLOBAL.NOTIFICATIONS.THANKS_FOR_RATING_LABEL'],
                  ComboNotificationType.Success
                );
              });
          }

          commonPagesHelper.loader('hide');
          this.disabledRating = true;
        },
        error => {
          self.sendingRate = false;
          commonPagesHelper.loader('hide');
          console.error('error -->', error);
        }
      );
  }

  /**
   * Rating / mouse on hover.
   */
  rateHover() {
    this.rateUserHover = true;
  }

  /**
   * Rating / mouse on leave.
   */
  rateLeave() {
    this.rateUserHover = false;
  }

  /**
   * Adds comment.
   * @param {String} comment
   */
  addComment(comment) {
    const self = this;

    if (!comment) {
      return;
    }

    commonPagesHelper.loader('show');

    this.sendGameCommentRequestSubscribe = this.gameRequestService.sendGameCommentRequest(this.templateData['urlSlug'], comment).subscribe(
      data => {
        commonPagesHelper.loader('hide');
        if (data.result !== 'ok') {
          self.showError(data['message']);
          commonPagesHelper.loader('hide');
          self.disabledRating = true;

          return;
        }

        self.inputComment.nativeElement.value = '';
        // * Show SnackBar Notification
        self.comboNotificationService.notification(data['message'], ComboNotificationType.Success);
      },
      error => {
        commonPagesHelper.loader('hide');
        console.error('error -->', error);
      }
    );
  }

  showError(message) {
    // * Show SnackBar Notification
    this.comboNotificationService.notification(message, ComboNotificationType.Error);
  }

  /**
   * Newsletter sign up handler.
   * @param {Object} parametersMap
   */
  newsletterSignUp(parametersMap) {
    if (!this.newsletterForm.valid) {
      return;
    }

    const self = this;
    this.sendingNewsletter = true;

    this.newsletterRequestService.subscribeToNewsletter(parametersMap).subscribe(
      data => {
        commonPagesHelper.loader('hide');
        self.sendingNewsletter = false;

        if (data['result'] === 'ok') {
          self.translate.get(['LAYOUT.NEWSLETTER.NEWSLETTER_SUCCESS_LABEL']).subscribe((translatedValue: string) => {
            // * Show SnackBar Notification
            self.comboNotificationService.notification(
              translatedValue['LAYOUT.NEWSLETTER.NEWSLETTER_SUCCESS_LABEL'],
              ComboNotificationType.Success
            );
          });

          return;
        }
        // * Show SnackBar Notification
        self.comboNotificationService.notification(data['message'], ComboNotificationType.Error);
      },
      () => {
        commonPagesHelper.loader('hide');
        self.sendingNewsletter = false;
      }
    );
  }

  stringifyParams(obj) {
    var str = [];
    for (var p in obj)
      if (obj.hasOwnProperty(p)) {
        str.push(encodeURIComponent(p) + '=' + encodeURIComponent(obj[p]));
      }
    return str.join('&');
  }

  getAllUrlParams(url) {
    // get query string from url (optional) or window
    var queryString = url ? url.split('?')[1] : '';

    // we'll store the parameters here
    var obj = {};

    // if query string exists
    if (queryString) {
      // stuff after # is not part of query string, so get rid of it
      queryString = queryString.split('#')[0];

      // split our query string into its component parts
      var arr = queryString.split('&');

      for (var i = 0; i < arr.length; i++) {
        // separate the keys and the values
        var a = arr[i].split('=');

        // set parameter name and value (use 'true' if empty)
        var paramName = a[0];
        var paramValue = typeof a[1] === 'undefined' ? true : a[1];

        // (optional) keep case consistent
        paramName = paramName.toLowerCase();
        if (typeof paramValue === 'string') paramValue = paramValue.toLowerCase();

        // if the paramName ends with square brackets, e.g. colors[] or colors[2]
        if (paramName.match(/\[(\d+)?\]$/)) {
          // create key if it doesn't exist
          var key = paramName.replace(/\[(\d+)?\]/, '');
          if (!obj[key]) obj[key] = [];

          // if it's an indexed array e.g. colors[2]
          if (paramName.match(/\[\d+\]$/)) {
            // get the index value and add the entry at the appropriate position
            var index = /\[(\d+)\]/.exec(paramName)[1];
            obj[key][index] = paramValue;
          } else {
            // otherwise add the value to the end of the array
            obj[key].push(paramValue);
          }
        } else {
          // we're dealing with a string
          if (!obj[paramName]) {
            // if it doesn't exist, create property
            obj[paramName] = paramValue;
          } else if (obj[paramName] && typeof obj[paramName] === 'string') {
            // if property does exist and it's a string, convert it to an array
            obj[paramName] = [obj[paramName]];
            obj[paramName].push(paramValue);
          } else {
            // otherwise add the property
            obj[paramName].push(paramValue);
          }
        }
      }
    }

    return obj;
  }

  /**
   * Redirects to referer link.
   */
  closeGame() {
    this.casinoService.refreshUserInformations();

    const redirectBackToGameList = localStorage.getItem(storageConstants['FOLLOW_PREVIOUS_URL']);

    if (redirectBackToGameList) {
      sessionStorage.setItem(storageConstants['FOLLOW_PREVIOUS_URL_POSITION'], '1');
      localStorage.removeItem(storageConstants['FOLLOW_PREVIOUS_URL']);
      const previousUrl = localStorage.getItem(storageConstants['PREVIOUS_URL']);
      if (typeof previousUrl !== 'undefined' || previousUrl !== '/') {
        let queryStringToAdd = {
          scrollTo: 1,
        };
        let urlQueryString = Object.assign({}, this.getAllUrlParams(previousUrl), queryStringToAdd);
        this._router.navigateByUrl(`${previousUrl}?${this.stringifyParams(urlQueryString)}`);
      } else {
        this._router.navigateByUrl(this.navigationService.constructUrl('START'));
      }
    } else {
      this._router.navigateByUrl(this.navigationService.constructUrl('START'));
    }
  }

  /**
   * Changes cinema mode state
   * @param isCinemaMode Cinema mode enable
   */
  cinemaMode(isCinemaMode = false) {
    if (typeof document === 'undefined') return;

    const tooltips = document.querySelectorAll('.tooltip.tooltip-show');
    Array.prototype.forEach.call(tooltips, function (element) {
      element.parentNode.removeChild(element);
    });
    this.isCinemaMode = isCinemaMode;

    if (this.isCinemaMode) {
      localStorage.setItem(storageConstants['CINEMA_MODE'], '1');
    } else {
      localStorage.removeItem(storageConstants['CINEMA_MODE']);
    }
  }

  setMetaTags(game) {
    this.titleService.setTitle(this.titleService.getTitle().replace(':slug', game.name));
  }

  getPlayngoGameCommunicator() {
    let _instance = this;

    const GameCommunicator = {
      source: undefined,
      targetOrigin: undefined,
      /**
       * Initiates the communication with the Iframe
       * @@param {iframe} element
       */
      init: function (element, instance) {
        _instance = instance;

        if (!(window as any).playngoInitialized) {
          window.addEventListener('message', this.processGameMessage.bind(this));
        }

        this.source = element.contentWindow;
        this.targetOrigin = _instance.origin; // target origin url (the base used in the iframe src)
        GameCommunicator.postMessage({ messageType: 'addEventListener', eventType: 'backToLobby' });
        GameCommunicator.postMessage({ messageType: 'addEventListener', eventType: 'playForReal' });
        GameCommunicator.postMessage({ messageType: 'addEventListener', eventType: 'reloadGame' });

        if (!_instance.isDemoMode) {
          GameCommunicator.postMessage({ messageType: 'addEventListener', eventType: 'balanceUpdate' });
        }

        (window as any).playngoInitialized = true;
      },
      /**
       * Sends the message to the Iframe
       * @@param {object} data
       * Example of adding an Engage event listener: GameCommunicator.postMessage({ messageType: "addEventListener", eventType: "roundStarted" })
       * Example of calling Engage function: GameCommunicator.postMessage({ messageType: "request", request: "spin" })
       * See Engage API documentation for full scope of requests and events
       */
      postMessage: function (data) {
        console.log('GameCommunicator sent the following message:', data);
        this.source.postMessage(data, this.targetOrigin);
      },
      /**
       * Receives the messages the PNG game dispatches
       * @@param {object} e
       */
      processGameMessage: function (e) {
        console.log('GameCommunicator received: ', e.data);
        switch (e.data.type) {
          case 'backToLobby':
            _instance.closeGame.call(_instance);
            break;
          case 'playForReal':
            _instance.goToGameDetails.call(_instance, _instance.templateData, true, 'full');
            break;
          case 'reloadGame':
          case 'balanceUpdate':
            _instance.casinoService.refreshUserInformations.call(_instance.casinoService);
            break;
        }
      },
    };

    return GameCommunicator;
  }

  handlePlayngoGameCommunication(element) {
    if (this.isInformationMode) return;
    this.playngoGameCommunicator.init(element, this);
  }

  /**
   * Communication between iframe and app
   */
  handleGameCommunication() {
    if (this.isInformationMode) return;
    const self = this;

    window.addEventListener(
      'message',
      function (e) {
        switch (e.data) {
          case 'closePopup':
            self.closeGame();
            break;
          case 'removeGame':
            self.closeGame();
            break;
          case 'showCashier':
            self._router.navigateByUrl(self.navigationService.constructUrl('CASHIER', { tab: 'payIn' }));
            break;
          case 'refresh':
            self.casinoService.refreshUserInformations();
            break;
          default:
            break;
        }
      },
      false
    );
  }

  /**
   * Destroys component.
   */
  ngOnDestroy() {
    commonPagesHelper.unsubscribeObserves([
      this.routerSubscribe,
      this.routerDataSubscribe,
      this.routerQueryParametersSubscribe,
      this.gameServiceOpenGameSubscribe,
      this.gameDetailsRequestSubscribe,
      this.sendGameRatingRequestSubscribe,
      this.sendGameCommentRequestSubscribe,
      this.ratingTranslateSubscribe,
      this.commentTranslateSubscribe,
      this.showErrorTranslateSubscribe,
      this.jurisdictionTranslateSubscribe,
      this.runtimeErrorsTranslateSubscribe,
      this.fullVersionInvitationSubscribe,
      this.userBalanceReloadingSubscribe,
    ]);

    this.gameService.turnOffRealityChecker();

    clearTimeout(this.runtimeErrorsTimer);
    clearInterval(this.cyclicInviteToPlayFullVersion);
    clearInterval(this.cyclicReloadUserInformations);

    // Make sure to not bind closeGame() to gameDetails itself
    if (this.platformService.browser) localStorage.removeItem(storageConstants['FOLLOW_PREVIOUS_URL']);
  }

  openGame(urlSlug: string, type = 'demo') {
    this.layout.goToTop();
    this.gameService.openGame(urlSlug, type);
  }

  goToGameDetails(gameData, withRedirect = false, automaticGame = '') {
    this.layout.goToTop();
    return this.gameService.goToGameDetails(gameData, withRedirect, automaticGame);
  }
}

