import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { LandingPageData, LandingPageFilter } from './landingPage.model';
import { Observable } from 'rxjs';
import { LandingPageService } from './landingPage.service';
import { Injectable } from '@angular/core';
import { AppLoadService } from '../../services';
import { map, catchError } from 'rxjs/operators';
import { DeviceDetectorService } from 'ngx-device-detector';

@Injectable()
export class LandingPageResolver implements Resolve<LandingPageData> {
  constructor(
    private appService: AppLoadService,
    private deviceService: DeviceDetectorService,
    private landingPageService: LandingPageService
  ) { }

  resolve(route: ActivatedRouteSnapshot): Observable<LandingPageData> {
    if (route.params.refId) {
      this.appService.setCookie('ref_id', route.params.refId);
    }
    if (route.params.refCode) {
      this.appService.setCookie('ref_code', route.params.refCode);
    }
    if (!route.params.refId || !route.params.slug) {
      return this.landingPageService.getDefault();
    }
    const filter: LandingPageFilter = {
      refCode: route.params.refCode,
      refId: route.params.refId,
      slug: route.params.slug,
    };
    
    return this.landingPageService.find(filter).pipe(
      map<LandingPageData, LandingPageData>(it => ({
        backgroundImageDesktop:
          it.backgroundImageDesktop ||
          '/assets/images/landing-page/lp-bg-desktop.jpg',
        backgroundImageMobile:
          it.backgroundImageMobile ||
          '/assets/images/landing-page/lp-bg-mobile.jpg',
        bonusAmount: it.bonusAmount,
        freeSpinAmount: it.freeSpinAmount,
        title: it.title,
      })),
      catchError(() => this.landingPageService.getDefault())
    );
  }
}
