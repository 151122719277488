import { Injectable } from '@angular/core';
import { requestConstants } from '../../constants/index';
import { requestHelper } from '../../helpers/index';
import { RequestService } from './request.service';

@Injectable({
  providedIn: 'root'
}) export class WebauthnRequestService {
  /**
   * @constructor
   */
  constructor(private requestService: RequestService) { }

  
  /**
   * Makes 'ATTESTATION__OPTIONSLIONLINE' request.
   * @param {Object} parametersMap
   * @returns <promise>
   */
  getRegisterOptions(parametersMap) {
    const withToken = true;
    const requestServiceMethod = requestConstants['ATTESTATION__OPTIONSLIONLINE']['METHOD'].toUpperCase();
    const url = requestConstants['API_URL'] + requestConstants['ATTESTATION__OPTIONSLIONLINE']['URL'];
    
    return this.requestService[requestServiceMethod](url, parametersMap, withToken);
  }

  /**
   * Makes 'ATTESTATION__RESULTCREATE' request.
   * @param {Object} parametersMap
   * @returns <promise>
   */
  saveRegisterBiometric(parametersMap) {
    const withToken = true;
    const requestServiceMethod = requestConstants['ATTESTATION__RESULTCREATE']['METHOD'].toUpperCase();
    const url = requestConstants['API_URL'] + requestConstants['ATTESTATION__RESULTCREATE']['URL'];
     return this.requestService[requestServiceMethod](url, parametersMap, withToken, true);
  }
  
  getBiometricUserBrowserList(){
    const withToken = true;
    const requestServiceMethod = requestConstants['BIOMETRIC__AUTHENTICATORS_LIST']['METHOD'].toUpperCase();
    const url = requestConstants['API_URL'] + requestConstants['BIOMETRIC__AUTHENTICATORS_LIST']['URL'];
    return this.requestService[requestServiceMethod](url, [], withToken, true);
  }

  deleteBiometricBrowser(browserID){
    const withToken = true;
    const requestServiceMethod = requestConstants['BIOMETRIC__DELETE_AUTHENTICATORS']['METHOD'].toUpperCase();
    const url = requestConstants['API_URL'] + requestConstants['BIOMETRIC__DELETE_AUTHENTICATORS']['URL']+browserID;
    return this.requestService[requestServiceMethod](url, [], withToken, true);
  }

  /*Login*/
    /**
   * Makes 'ATTESTATION__OPTIONSLIONLINE' request.
   * @param {Object} parametersMap
   * @returns <promise>
   */
    getLoginOptions(parametersMap) {
      const withToken = true;
      const requestServiceMethod = requestConstants['ASSERTION_AUTHOPTIONLOGIN']['METHOD'].toUpperCase();
      const url = requestConstants['API_URL'] + requestConstants['ASSERTION_AUTHOPTIONLOGIN']['URL'];
      return this.requestService[requestServiceMethod](url, parametersMap, withToken, true);
    }

    loginBiometricUser(parametersMap) {
      const withToken = true;
      const requestServiceMethod = requestConstants['ASSERTION__RESULTLOGIN']['METHOD'].toUpperCase();
      const url = requestConstants['API_URL'] + requestConstants['ASSERTION__RESULTLOGIN']['URL'];
      return this.requestService[requestServiceMethod](url, parametersMap, withToken, true);
    }

    checkAuthenticator(browserId) {
      let parametersMap={"browser": browserId};
      const withToken = true;
      const requestServiceMethod = requestConstants['CHECK_AUTHENTICATOR']['METHOD'].toUpperCase();
      const url = requestConstants['API_URL'] + requestConstants['CHECK_AUTHENTICATOR']['URL'];
      return this.requestService[requestServiceMethod](url, parametersMap, withToken, true);
    }

    getUserIdByBrowserId(browserId) {
      let parametersMap={"browser": browserId};
      const withToken = true;
      const requestServiceMethod = requestConstants['GET_USER_BY_AUTHENTICATOR']['METHOD'].toUpperCase();
      const url = requestConstants['API_URL'] + requestConstants['GET_USER_BY_AUTHENTICATOR']['URL'];
      return this.requestService[requestServiceMethod](url, parametersMap, withToken, true);
    }

}
