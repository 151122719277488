import { Injectable } from '@angular/core';
import { requestConstants } from '../../constants/index';
import { requestHelper } from '../../helpers/index';
import { RequestService } from './request.service';

@Injectable() export class CashierRequestService {
  /**
   * @constructor
   */
  constructor(private requestService: RequestService) { }

  /**
   * Makes 'document list' request.
   * @param {Object} [parametersMap = {}]
   * @param {Number} [offset = 0]
   * @param {Number} [limit = 20]
   * @returns <promise>
   */
  documentList(parametersMap = {}, offset = 0, limit = 20) {
    const withToken = true;
    const url = requestHelper.urlReplace(
      requestConstants['API_URL'] + requestConstants['CASHIER_DOCUMENT_LIST']['URL'],
      {
        offset: offset,
        limit: limit
      }
    );
    
    return this.requestService.listRequest(url, parametersMap, withToken);
  }

  /**
   * Makes 'add document' request.
   * @param {Object} parametersMap
   * @returns <promise>
   */
  addDocument(parametersMap) {
    const withToken = true;
    const requestServiceMethod = requestConstants['CASHIER_ADD_DOCUMENT']['METHOD'].toUpperCase();
    const url = requestConstants['API_URL'] + requestConstants['CASHIER_ADD_DOCUMENT']['URL'];
    
    return this.requestService[requestServiceMethod](url, parametersMap, withToken);
  }

  /**
   * Makes 'transaction list' request.
   * @param {Object} [parametersMap = {}]
   * @param {Number} [offset = 0]
   * @param {Number} [limit = 20]
   * @returns <promise>
   */
  transactionList(parametersMap = {}, offset = 0, limit = 20) {
    const withToken = true;
    const url = requestHelper.urlReplace(
      requestConstants['API_URL'] + requestConstants['CASHIER_TRANSACTION_LIST']['URL'],
      {
        offset: offset,
        limit: limit
      }
    );
    
    return this.requestService.listRequest(url, parametersMap, withToken);
  }

  /**
   * Makes 'block account' request.
   * @param {Object} parametersMap
   * @returns <promise>
   */
  blockAccount(parametersMap) {
    const withToken = true;
    const requestServiceMethod = requestConstants['CASHIER_BLOCK_ACCOUNT']['METHOD'].toUpperCase();
    const url = requestConstants['API_URL'] + requestConstants['CASHIER_BLOCK_ACCOUNT']['URL'];
    
    return this.requestService[requestServiceMethod](url, parametersMap, withToken);
  }

  /**
   * Makes 'game rounds list' request.
   * @param {Object} [parametersMap = {}]
   * @param {Number} [offset = 0]
   * @param {Number} [limit = 20]
   * @returns <promise>
   */
  gameRoundsList(parametersMap = {}, offset = 0, limit = 20) {
    const withToken = true;
    const url = requestHelper.urlReplace(
      requestConstants['API_URL'] + requestConstants['CASHIER_GAME_ROUNDS_LIST']['URL'],
      {
        offset: offset,
        limit: limit
      }
    );
    
    return this.requestService.listRequest(url, parametersMap, withToken);
  }

  /**
   * Makes 'set promo code' request.
   * @param {Object} parametersMap
   * @returns <promise>
   */
  setPromoCode(parametersMap) {
    const withToken = true;
    const requestServiceMethod = requestConstants['CASHIER_SET_PROMO_CODE']['METHOD'].toUpperCase();
    const url = requestConstants['API_URL'] + requestConstants['CASHIER_SET_PROMO_CODE']['URL'];
    
    return this.requestService[requestServiceMethod](url, parametersMap, withToken);
  }
}
