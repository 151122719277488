import { Component, Input, OnInit, OnChanges } from '@angular/core';
import { RequestService, AuthenticationService } from '../../services/index';

@Component({
  selector: 'table-component',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
})
export class TableComponent implements OnInit, OnChanges {
  @Input()
  ajaxURL = undefined
  @Input()
  responseDataKey = undefined
  @Input()
  providedData = undefined
  @Input()
  tableStructure = []
  @Input()
  checkboxes = true
  @Input()
  settings = []

  checkedAll = false
  rowCheckedMap = []

  tableData = []
  loading = true

  /**
   * @costructor
   */
  constructor(private auth: AuthenticationService,private requestService: RequestService) {
    // ...
  }

  /**
   * It's native Angular 'on initiation' method.
   * Fired after constructor.
   */
  ngOnInit() {
    if (typeof this.settings === 'boolean') {
      this.settings = [];
    }
    this.setDataTable();
  }

  /**
   * It's native Angular 'on changes' method.
   */
  ngOnChanges(changes) {
    if (typeof changes['ajaxURL'] !== 'undefined') {
      if (changes['ajaxURL']['firstChange']) {
        return;
      }

      this.tableData = [];

      setTimeout(() => {
        const currentValue = changes['ajaxURL']['currentValue'];
        this.ajaxURL = currentValue;
        this.setDataTable();
      }, 500);
    }

    if (typeof changes['providedData'] !== 'undefined') {
      if (changes['providedData']['firstChange']) {
        return;
      }

      this.tableData = changes['providedData']['currentValue'];
      this.setDataTable();
    }
  }

  /**
   * Sets data from response.
   */
  setDataTable() {
    if (Array.isArray(this.providedData)) {
      this.tableData = this.providedData;
      if (typeof this.responseDataKey === 'string') {
        this.tableData = this.tableData[this.responseDataKey];
      }
      this.loading = false;

      this.transformSpecificKey();
      this.setActions();
      this.setRowCheckedMap();
      this.setSeparator();
    }

    if (typeof this.ajaxURL === 'string') {
      this.requestService.listRequest(this.ajaxURL, { }, false).subscribe(
        data => {
          this.tableData = data;
          if (typeof this.responseDataKey === 'string') {
            this.tableData = this.tableData[this.responseDataKey];
          }
          this.loading = false;
          this.transformSpecificKey();
          this.setActions();
          this.setRowCheckedMap();
          this.setSeparator();
        },
        error => {
          this.showError(error);
          this.loading = false;
        }
      );
    }
  }

  /**
   * Sets row actions.
   */
  setActions() {
    const definedActionKeys = this.settings.slice();

    if (!(definedActionKeys.length > 0)) {
      return;
    }

    this.tableData.forEach(rowObject => {
      if (
        !(
          typeof rowObject._links !== 'undefined' &&
          rowObject._links &&
          Object.keys(rowObject._links).length > 0
        )
      ) {
        return;
      }
      rowObject.settings = [];

      this.settings.forEach(actionObject => {
        const nastedPatterns = [];

        const clonedActionObject = Object.assign({}, actionObject);
        const findedSpecificItem =
          typeof rowObject._links[clonedActionObject.action] !== 'undefined' ||
          typeof rowObject._links[clonedActionObject.actionAs] !== 'undefined';

        if (!findedSpecificItem) {
          return;
        }

        Object.keys(rowObject).forEach(key => {
          const pattern = new RegExp(`{{${  key  }}}`, 'gi');

          clonedActionObject.icon = clonedActionObject.icon.replace(
            pattern,
            rowObject[key]
          );
          clonedActionObject.action = clonedActionObject.action.replace(
            pattern,
            rowObject[key]
          );
          clonedActionObject.description = clonedActionObject.description.replace(
            pattern,
            rowObject[key]
          );
          clonedActionObject.url = clonedActionObject.url.replace(
            pattern,
            rowObject[key]
          );
        });

        clonedActionObject.url.replace(/\{\{(.+?)\}\}/g, (mustacheKey, key) => {
          if (key.indexOf('.') !== -1) {
            nastedPatterns.push(key);
          }
          
          return mustacheKey;
        });

        if (Array.isArray(nastedPatterns) && nastedPatterns.length > 0) {
          nastedPatterns.forEach(key => {
            let evalValue;
            const thisInStart = key.indexOf('this') === 0;
            const pattern = new RegExp(`{{${  key  }}}`, 'gi');

            const valueToEval = thisInStart
              ? key.replace('this', 'clonedActionObject')
              : `rowObject.${key}`;

            try {
              evalValue = eval(valueToEval);
            } catch (e) {
              if (e instanceof SyntaxError) {
                console.info(e.message);
              }
            }

            clonedActionObject.url = clonedActionObject.url.replace(
              pattern,
              evalValue
            );
          });
        }

        rowObject.settings.push({
          icon: clonedActionObject.icon,
          action: clonedActionObject.action,
          actionAs: clonedActionObject.actionAs,
          description: clonedActionObject.description,
          url: clonedActionObject.url,
          urlPrefix: clonedActionObject.urlPrefix,
        });
      });
    });
  }

  /**
   * Sets row checked map.
   */
  setRowCheckedMap() {
    this.rowCheckedMap = [];

    this.tableData.forEach(() => {
      this.rowCheckedMap.push(false);
    });
  }

  /**
   * HTML wrapper for row elements.
   * Template function.
   * @param {String} wrapper - html wrapper
   * @param {String} value
   * @param {Object} tableDataItem - Table row object.
   * @returns {String} returnValue - wrappered value
   */
  HTMLwrapper(wrapper, value, tableDataItem) {
    const tableDataItemKeys = Object.keys(tableDataItem);
    let returnValue = wrapper.replace(/{{this}}/gi, value);
    const nastedPatterns = [];

    returnValue.replace(/\{\{(.+?)\}\}/g, (mustacheKey, key) => {
      if (key.indexOf('.') !== -1) {
        nastedPatterns.push(key);
      }
      
      return mustacheKey;
    });

    tableDataItemKeys.forEach(key => {
      const pattern = new RegExp(`{{${  key  }}}`, 'gi');
      returnValue = returnValue.replace(pattern, tableDataItem[key]);
    });

    if (nastedPatterns.length === 0) {
      return returnValue;
    }

    nastedPatterns.forEach(key => {
      const thisInStart = key.indexOf('this') === 0;
      const pattern = new RegExp(`{{${  key  }}}`, 'gi');
      const valueToEval = thisInStart
        ? key.replace('this', 'value')
        : `tableDataItem.${key}`;
      const evalValue = eval(valueToEval);

      returnValue = returnValue.replace(pattern, evalValue);
    });

    return returnValue;
  }

  /**
   * Sets separator character for arrays elements.
   */
  setSeparator() {
    const elementsWithSeparator = this.tableStructure.filter(value => {
      return typeof value['separator'] !== 'undefined';
    });

    this.tableData.forEach(element => {
      let findedElements;
      const elementKeys = Object.keys(element);

      elementKeys.forEach(key => {
        findedElements = elementsWithSeparator.find(value => {
          return key === value.rowKeyValue;
        });

        if (typeof findedElements === 'undefined') {
          return;
        }

        if (typeof findedElements['separatorMap'] !== 'undefined') {
          element[key] = element[key]
            .map(findedElements['separatorMap'])
            .join(findedElements.separator);
          
          return;
        }
        element[key] = element[key].join(findedElements.separator);
      });
    });
  }

  /**
   * Transforms specific key in table data (.
   */
  transformSpecificKey() {
    const self = this;

    self.tableStructure.filter(structureElement => {
      const transformValueExists =
        typeof structureElement.transformValueFunction !== 'undefined';
      if (transformValueExists) {
        const keyFound = structureElement.rowKeyValue;
        const transformValueFunction = structureElement.transformValueFunction;

        self.tableData.forEach(dataElement => {
          dataElement[keyFound] = transformValueFunction.call(
            self,
            dataElement[keyFound]
          );
        });
      }
    });
  }

  /**
   * Selects (toggle) all checkboxes.
   */
  selectAll() {
    this.tableData.forEach(element => {
      element['checked'] = !this.checkedAll;
    });
  }

  /**
   * Shows error
   * @param {String} message
   */
  showError(message: string) {
    console.error(message);
    //window.alert(message);
  }

  /**
   * Returns true if minimum one checkbox is checked.
   * @handler
   * @private
   */
  private isChecked(element) {
    return element.checked;
  }

  /**
   * Opens url in new window.
   * @handler
   * @param URL {String}
   * @returns {String}
   */
  openURL(URL, URLPrefix) {
    if (typeof URL === 'undefined' || !URL) {
      return;
    }
    if (typeof URLPrefix === 'string') {
      URL = URLPrefix + URL;
    }
    window.open(URL);
  }

  /**
   * Toggles multi-action-container.
   * @returns {Boolean}
   */
  multiActionToggle() {
    const minimumOneSelected =
      typeof this.tableData.find(this.isChecked) !== 'undefined';
    const showMultiActionContainer = !(this.checkedAll || minimumOneSelected);
    
    return showMultiActionContainer;
  }
}
