export const licenseBarPaymentLogos = [
  {
    img: 'assets/images/logos/visa.png',
    url: '',
    pageUrl: 'PAYMENT_PROVIDERS',
    pageUrlData: {
      type: 'visa'
    },
    allowedCountries: ['de', 'en', 'fi', 'no', 'pl', 'se']
  },
  {
    img: 'assets/images/logos/mastercard.png',
    url: '',
    pageUrl: 'PAYMENT_PROVIDERS',
    pageUrlData: {
      type: 'mastercard'
    },
    allowedCountries: ['de', 'en', 'fi', 'no', 'pl', 'se']
  },
  {
    img: 'assets/images/logos/paysafecard.png',
    url: '',
    pageUrl: 'PAYMENT_PROVIDERS',
    pageUrlData: {
      type: 'paysafe'
    },
    allowedCountries: ['de', 'en', 'fi', 'no', 'pl', 'se']
  },
  {
    img: 'assets/images/logos/sofort-white.png',
    url: '',
    pageUrl: 'PAYMENT_PROVIDERS',
    pageUrlData: {
      type: 'sofort'
    },
    allowedCountries: ['de', 'en', 'fi', 'no', 'pl', 'se']
  },
  {
    img: 'assets/images/logos/ecopayz.png',
    url: '',
    pageUrl: 'PAYMENT_PROVIDERS',
    pageUrlData: {
      type: 'ecopayz'
    },
    allowedCountries: ['de', 'en', 'fi', 'no', 'pl', 'se']
  },
  {
    img: 'assets/images/logos/neteller.png',
    url: '',
    pageUrl: 'PAYMENT_PROVIDERS',
    pageUrlData: {
      type: 'neteller'
    },
    allowedCountries: ['de', 'en', 'fi', 'no', 'pl', 'se']
  },
  {
    img: 'assets/images/logos/skrill.png',
    url: '',
    pageUrl: 'PAYMENT_PROVIDERS',
    pageUrlData: {
      type: 'skrill'
    },
    allowedCountries: ['de', 'en', 'fi', 'no', 'pl', 'se']
  },
  {
    img: 'assets/images/logos/brite.png',
    url: '',
    pageUrl: 'PAYMENT_PROVIDERS',
    pageUrlData: {
      type: 'brite'
    },
    allowedCountries: ['de', 'en', 'fi', 'no', 'pl', 'se']
  },
];
