import { Component, Host } from '@angular/core';
import { CoreLayout } from '../../layouts';

@Component({
  selector: 'privacy-policy',
  templateUrl: './privacyPolicy.component.html',
  styleUrls: ['./privacyPolicy.component.scss']
})

export class PrivacyPolicyComponent {
  layout: CoreLayout
  /**
	 * @Consturctor
	 */
  constructor (@Host() parent: CoreLayout) {
    this.layout = parent;
  }
}
