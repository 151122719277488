import { objectHelper } from './object.helper';

export const stringHelper = {

  /**
	 * Capitalizes first letter.
	 * @param {String} string
	 * @returns {String}
	 */
  capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  },

  /**
	 * Encodes special characters (with dots).
	 * @param {String} string
	 * @returns {String}
	 */
  encodeURI(string) {
    return encodeURIComponent(string).split('.').join('%2E');
  },


  /**
	 * Removes all characters except letters and numbers from a string.
	 * @param {String} value 
	 * @returns {String}
	 */
  normalize(value: string) {
    if (value === undefined || value === null)
      throw Error('Argument value is not set.');
    if (!objectHelper.isFunction(value.trim))
      throw Error('Argument value is not a string.');

    return value.toLowerCase().replace(/[^a-z0-9]/g, '');
  }
};