import { commonPagesHelper } from '../../helpers/commonPages.helper';
import { AuthenticationService } from '../../services/authentication.service';
import { Component, OnDestroy } from '@angular/core';
import { NavigationService } from '../../services';
import { Router, ActivatedRoute } from '@angular/router';
import { AccessTokenService } from '../../services/access-token.service';

@Component({
  selector: 'login-as-user',
  template: '<ng-content></ng-content>'
})

export class LoginAsUserComponent implements OnDestroy {
  loginAsUserSubscriber
  componentRouteSubscriber
  /**
	 * @constructor
	 */
  constructor(
    private authenticationService: AuthenticationService,
    private navigationService: NavigationService,
    private router: Router,
    private route: ActivatedRoute,
    public accessToken: AccessTokenService) {
    if (this.accessToken.isAccessTokenSet) {
      this.authenticationService.logout();
    }

    commonPagesHelper.loader('show');

    this.componentRouteSubscriber = this.route.params.subscribe(params => {
      const token = params.token;
      this.loginAsUserSubscriber = this.authenticationService.loginAsUser(
        token
      ).subscribe(() => {
        commonPagesHelper.loader('hide');
        this.navigationService.goToDefaultPage();
      }, (error) => {
        console.warn(error);
        commonPagesHelper.loader('hide');
        this.router.navigateByUrl('404');
      });
    });
  }

  ngOnDestroy() {
    this.loginAsUserSubscriber.unsubscribe();
    this.componentRouteSubscriber.unsubscribe();
  }
}
