<div class="container container--noVerticalPadding container--darker">
	<h1 class="big withoutStar">{{ 'GLOBAL.PAGE_NOT_FOUND_LABEL' | translate }}</h1>
</div>

<div class="container container--minimalHeight">
	<div class="staticPagesContainer">
		<div class="staticPagesContainer__inside">
			<img src="assets/images/404-error-decoration.png" alt="404" class="left-float">
			<h3>
				<span class="errorType">{{ 'GLOBAL.ERROR_LABEL' | translate }} 404:</span>
				<span>{{ 'GLOBAL.ERROR_NOT_FOUND_DESCRIPTION_LABEL' | translate }}</span>
			</h3>
			<p [innerHTML]="'GLOBAL.ERROR_NOT_FOUND_PARAGRAPH_LABEL' | translate"></p>

			<a class="button button--red" [routerLink]="navigationService.constructUrl('START')">
				<span class="textInside">{{ 'GLOBAL.ERROR_HOME_BUTTON' | translate }}</span><i class="fa fa-angle-right right-position" aria-hidden="true"></i>
			</a>
		</div>
	</div>
</div>