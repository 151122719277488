import { BaseCashierComponent } from '../base.component';
import { Component } from '@angular/core';

@Component({
  selector: 'cashier-limits',
  templateUrl: '../base.component.html',
  styleUrls: ['../base.component.scss']
})

export class LimitsComponent extends BaseCashierComponent {
  public iframeId = 'limits'
}