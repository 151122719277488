<div class="container container--noVerticalPadding container--darker">
	<h1 class="big withoutStar">{{ 'CONTACT.CONTACT_HEADER' | translate }}</h1>
</div>

<div class="container">
	<div class="staticPagesContainer">
		<div class="staticPagesContainer__leftColumn">
			<div class="staticPagesContainer__leftColumn-item">
				<!-- TODO Move to new Sharable Component, Passing Type and Message -->
				<div class="alert alert--success mt-40" *ngIf="hasBeenSent">
					<p class="alert__header">{{ 'CONTACT.CONTACT_HEADER' | translate }}</p>
					<p class="alert__paragraph" [innerHTML]="'CONTACT.MAIL_SENT_SUCCESSFULLY_LABEL' | translate"></p>
				</div>
				<div *ngIf="!hasBeenSent">
					<div class="paragraphs" [innerHTML]="'CONTACT.CONTACT_PARAGRAPH' | translate"></div>

					<div class="contactComponent">
						<form class="contactComponentInside" [formGroup]="form" (ngSubmit)="onSubmit(form.value)" (keyup.enter)="onSubmit(form.value)">
							<div class="contactComponentInside__column">
								<h3>{{ 'CONTACT.CONTACT_DATA_HEADER' | translate }}</h3>
								<div class="form-element contactComponentInside__column-item">
									<label for="email">{{ 'CONTACT.EMAIL_INPUT_LABEL' | translate }}<span class="required">*</span></label>
									<input type="email" id="email" formControlName="email" required [readonly]="disableUserFields">
									<span class="errorMessage" *ngIf="formErrors.email.length">
										<span class="errorMessage__inside">
											<span *ngFor="let emailMessage of formErrors.email;">
												{{ emailMessage | translate }} 
											</span>
										</span>
									</span>
								</div>
								<div class="form-element contactComponentInside__column-item">
									<label for="firstName">{{ 'CONTACT.FIRST_NAME_INPUT_LABEL' | translate }}<span class="required">*</span></label>
									<input id="firstName" formControlName="firstName" required [readonly]="disableUserFields">
									<span class="errorMessage" *ngIf="formErrors.firstName.length">
										<span class="errorMessage__inside">
											<span *ngFor="let emailMessage of formErrors.firstName;">
												{{ emailMessage | translate }} 
											</span>
										</span>
									</span>
								</div>
								<div class="form-element contactComponentInside__column-item">
									<label for="lastName">{{ 'CONTACT.LAST_NAME_INPUT_LABEL' | translate }}<span class="required">*</span></label>
									<input id="lastName" formControlName="lastName" required [readonly]="disableUserFields">
									<span class="errorMessage" *ngIf="formErrors.lastName.length">
										<span class="errorMessage__inside">
											<span *ngFor="let emailMessage of formErrors.lastName;">
												{{ emailMessage | translate }} 
											</span>
										</span>
									</span>
								</div>
								<div class="form-element contactComponentInside__column-item">
									<label for="phone">{{ 'CONTACT.PHONE_INPUT_LABEL' | translate }}<span class="required">*</span></label>
									<input type="phone" id="phone" formControlName="phone" required [readonly]="disableUserFields">
									<span class="errorMessage" *ngIf="formErrors.phone.length">
										<span class="errorMessage__inside">
											<span *ngFor="let emailMessage of formErrors.phone;">
												{{ emailMessage | translate }} 
											</span>
										</span>
									</span>
								</div>
							</div>

							<div class="contactComponentInside__column">
								<h3>{{ 'CONTACT.CONCERNS_HEADER' | translate }}</h3>
								<div class="form-element contactComponentInside__column-item">
									<label for="subject">{{ 'CONTACT.SUBJECT_INPUT_LABEL' | translate }}<span class="required">*</span></label>
									<input id="subject" formControlName="subject" required>
									<span class="errorMessage" *ngIf="formErrors.subject.length">
										<span class="errorMessage__inside">
											<span *ngFor="let emailMessage of formErrors.subject;">
												{{ emailMessage | translate }} 
											</span>
										</span>
									</span>
								</div>

								<div class="form-element contactComponentInside__column-item">
									<label for="message">{{ 'CONTACT.YOUR_MESSAGE_INPUT_LABEL' | translate }}<span class="required">*</span></label>
									<textarea id="message" formControlName="message" required></textarea>
									<span class="errorMessage errorMessageBottomCorrection" *ngIf="formErrors.message.length">
										<span class="errorMessage__inside">
											<span *ngFor="let emailMessage of formErrors.message;">
												{{ emailMessage | translate }} 
											</span>
										</span>
									</span>
								</div>
							</div>

							<div class="contactComponentInside__column button-row">
								<a class="button button--red" *ngIf="(form.valid || sending)" (click)="onSubmit(form.value)">{{ 'CONTACT.SUBMIT_BUTTON_LABEL' | translate }}<i class="fa fa-angle-right" aria-hidden="true"></i></a>
								<a class="button button--red button--disabled" tooltip="{{ 'GLOBAL.INVALID_VALIDATION_FORM_FIELDS_TOOLTIP' | translate }}" *ngIf="!(form.valid || sending)">{{ 'CONTACT.SUBMIT_BUTTON_LABEL' | translate }}<i class="fa fa-angle-right" aria-hidden="true"></i></a>
							</div>
						</form>
					</div>
				</div><!-- hasBeenSent -->
			</div>
		</div>

		<div class="staticPagesContainer__rightColumn">
			<static-pages-right-column></static-pages-right-column>
		</div>
	</div>
</div>

