<div class="applicationWrapper">
    <div class="game-sidebar" [ngClass]="{'game-sidebar-visible': sideBarOpened}" *ngIf="sideBarOpened">
        <div class="game-sidebar__header">
            <h3>{{ sideBarContent.name }} <a class="closePanel" (click)="sideBarClose()">
              <i class="fa fa-times" aria-hidden="true"></i></a>
            </h3>
        </div>
        <div class="game-sidebar__container">
            <div class="game-sidebar__content" *ngIf="sideBarContentLoaded">
                <img class="gameCover" src="{{ sideBarContent.gameImageUrl }}" (error)="replaceSidebarGameImage($event)"
                     alt="{{ sideBarContent.name }}">
                <div class="game-details">
                    <div class="game-detail-stats" *ngIf="sideBarContent.meta.minRtp > 0">
                        <span class="title">{{ 'ALL_GAMES.SIDEBAR.RETURN_TO_PLAYER_LABEL' | translate}}</span>
                        <span class="game-details-meta-min-rtp">{{ sideBarContent.meta.minRtp }}%</span>
                    </div>
                    <div class="game-detail-stats" *ngIf="sideBarContent.meta.minBet > 0">
                        <span class="title">{{ 'ALL_GAMES.SIDEBAR.MINIMUM_BET_LABEL' | translate }}</span>
                        <span class="game-details-meta-min-bet">{{ sideBarContent.meta.minBet }}&euro;</span>
                    </div>
                    <div class="game-detail-stats" *ngIf="sideBarContent.meta.maxBet > 0">
                        <span class="title">{{ 'ALL_GAMES.SIDEBAR.MAXIMUM_BET_LABEL' | translate }}</span>
                        <span class="game-details-meta-max-bet">{{ sideBarContent.meta.maxBet }}&euro;</span>
                    </div>
                    <div class="game-detail-stats">
                        <span class="title">{{ 'ALL_GAMES.SIDEBAR.FREE_GAMES_LABEL' | translate }}</span>
                        <span class="game-details-meta-specials"
                              [ngClass]="{'active': sideBarContent.meta.freeGames}"></span>
                        <!-- class active add confirmation icon  -->
                    </div>
                </div>

                <ng-template [ngIf]="userService.isLogged() && !userService.isFullyRegistered()">
                    <div class="buttons">
<!--                        <a class="button button&#45;&#45;red" [routerLink]="navigationService.constructUrl('USER_ACCOUNT')">-->
<!--                            {{ 'START.PLAY_NOW_BUTTON' | translate }} <i class="fa fa-gamepad fa-lg"></i>-->
<!--                        </a>-->
                        <a class="button" (click)="openGame(sideBarContent.urlSlug, 'demo')"
                           *ngIf="sideBarContent.isDemoEnabled">
                            {{ 'START.DEMO_BUTTON' | translate }} <i class="fa fa-arrow-right fa-lg"></i>
                        </a>
                    </div>
                </ng-template>

                <ng-template [ngIf]="userService.isLogged() && userService.isFullyRegistered()">
                    <div class="buttons">
<!--                        <a class="button button&#45;&#45;red" (click)="openGame(sideBarContent.urlSlug, 'full')">-->
<!--                            {{ 'START.PLAY_NOW_BUTTON' | translate }} <i class="fa fa-gamepad fa-lg"></i>-->
<!--                        </a>-->
                        <a class="button" (click)="openGame(sideBarContent.urlSlug, 'demo')"
                           *ngIf="sideBarContent.isDemoEnabled">
                            {{ 'START.DEMO_BUTTON' | translate }} <i class="fa fa-arrow-right fa-lg"></i>
                        </a>
                    </div>
                </ng-template>

                <ng-template [ngIf]="!userService.isLogged()">
                    <div class="buttons">
<!--                        <a class="button button&#45;&#45;red" (click)="sideBarClose();toggleLoginBar()">-->
<!--                            {{ 'START.PLAY_NOW_BUTTON' | translate }} <i class="fa fa-gamepad fa-lg"></i>-->
<!--                        </a>-->
                        <a class="button" (click)="openGame(sideBarContent.urlSlug, 'demo')"
                           *ngIf="sideBarContent.isDemoEnabled">
                            {{ 'START.DEMO_BUTTON' | translate }} <i class="fa fa-arrow-right fa-lg"></i>
                        </a>
                    </div>
                </ng-template>

                <div class="game-rating game-rating--panel" *ngIf="sideBarContent.ratingsCount > 0">
                    <bar-rating #rating [theme]="'fontawesome-o'" [rate]="sideBarContent.rating" [max]="5"></bar-rating>
                </div>

                <div class="game-description">
                    {{ sideBarContent.description }}
                </div>
                <a class="button button--last" (click)="gameService.goToGameDetails(sideBarContent, true, 'info')">
                  {{ 'START.MORE_INFORMATION' | translate }}
                </a>
            </div>
            <div *ngIf="!sideBarContentLoaded">
                loading...
            </div>

        </div>
    </div>

    <div>
      <livechat-widget [licenseId]="7151541" [group]="11" *ngIf="(platformBrowser && currentLanguage != 'de' && !isDevMode)"></livechat-widget>
      <livechat-widget [licenseId]="7151541" [group]="12" *ngIf="platformBrowser && currentLanguage == 'de' && !isDevMode"></livechat-widget>
    </div>

    <div class="stickyFooter" *ngIf="!isLogged()">
        <div class="stickyFooter__item">
            <a class="button button--gray" (click)="closeRegisterPanel(); toggleLoginBar()"><span class="textInside">{{ 'LAYOUT.LOGIN_LABEL' | translate }}</span><i
                    class="fa fa-angle-right right-position" aria-hidden="true"></i></a>
        </div>
        <div class="stickyFooter__item">
            <a class="button button--red" (click)="closeLoginPanel(); toggleRegisterBar()"><span class="textInside">{{ 'LAYOUT.REGISTER_LABEL' | translate }}</span><i
                    class="fa fa-angle-right right-position" aria-hidden="true"></i></a>
        </div>
    </div>
    <div class="stickyFooter stickyFooter--logged" *ngIf="isLogged()">
        <div class="stickyFooter__item stickyFooter__item--balance">
            <div class="balance">
                <p class="label">{{ 'LAYOUT.MY_BALANCE_LABEL' | translate }}</p>
                <p class="value">
                    {{ loggedUserData?.balance.totalBalance | currency_format:loggedUserData?.currency }}
                </p>
            </div>
        </div>

        <div class="stickyFooter__item stickyFooter__item--bonus" [class.headerMenu__item--inactive]="bonuses.length === 0">
            <div class="itemBonus--box">
                <a class="headerMenu__itemBonus" #bonusCounter [attr.data-counter]="bonuses.length" *ngIf="!mobile.show.bonus" (click)="setMobileHeaderMenu('bonus', 'show')"></a>
                <a class="headerMenu__itemClose" *ngIf="mobile.show.bonus" (click)="setMobileHeaderMenu('bonus', 'hide')"></a>
            </div>
            <div class="headerMenu__item-content headerMenu__itemBonus-content" [class.show]="mobile.show.bonus" *ngIf="bonuses.length > 0">
                <div class="bonusContainer">
                    <div class="bonusContainer__item" [class.bonusContainer__item--last-child]="((bonuses.length - 1) == index)" *ngFor="let bonus of bonuses; let index = index">


                        <ng-template [ngIf]="bonus.type === 'free_spin'">
                            <div class="bonusWidget__box">
                                <div class="bonusWidget__graphic">
                                    <img class="bonusWidget__game-img" alt="{{ bonus.game.name }}" src="{{ replaceGameUrl(bonus.game.gameThumbImageUrl) }}" />
                                </div>
                                <div class="bonusWidget__main">
                                    <a class="button button--orange" (click)="bonusActivate(bonus);setMobileHeaderMenu('all', 'hide')" [innerHTML]="'LAYOUT.BONUS_WIDGET.COLLECT_FREE_SPINS' | translate:bonus">

                                    </a>
                                    <p class="bonusWidget__text" [innerHTML]="'LAYOUT.BONUS_WIDGET.FREE_SPINS_TEXT' | translate:bonus">
                                </div>
                            </div>
                        </ng-template>
                        <ng-template [ngIf]="bonus.type === 'free_spin_per_deposit'">
                            <div class="bonusWidget__box">
                                <div class="bonusWidget__graphic">
                                    <img class="bonusWidget__game-img" alt="{{ bonus.game.name }}" src="{{ replaceGameUrl(bonus.game.gameThumbImageUrl) }}" />
                                </div>
                                <div class="bonusWidget__main">
                                    <a *ngIf="!bonus.showUpToAmount" class="button button--orange" (click)="bonusActivate(bonus);setMobileHeaderMenu('all', 'hide')" [innerHTML]="'LAYOUT.BONUS_WIDGET.COLLECT_FREE_SPINS_PER_DEPOSIT' | translate:{amount: bonus.amount, currentDepositAmount: (bonus.currentDepositAmount/100 | number:'1.2'), maxAmount: bonus.maxAmount}"></a>
                                    <p *ngIf="!bonus.showUpToAmount" class="bonusWidget__text" [innerHTML]="'LAYOUT.BONUS_WIDGET.FREE_SPINS_PER_DEPOSIT_TEXT' | translate:{amount: bonus.amount, currentDepositAmount: (bonus.currentDepositAmount/100 | number:'1.2') | number:'1.2', maxAmount: bonus.maxAmount}"></p>

                                    <a *ngIf="bonus.showUpToAmount" class="button button--orange" (click)="bonusActivate(bonus);setMobileHeaderMenu('all', 'hide')" [innerHTML]="'LAYOUT.BONUS_WIDGET.COLLECT_FREE_SPINS_PER_DEPOSIT_UP_TO' | translate:{amount: bonus.amount, currentDepositAmount: (bonus.currentDepositAmount/100 | number:'1.2'), maxAmount: bonus.maxAmount}"></a>
                                    <p *ngIf="bonus.showUpToAmount" class="bonusWidget__text" [innerHTML]="'LAYOUT.BONUS_WIDGET.FREE_SPINS_PER_DEPOSIT_UP_TO_TEXT' | translate:{amount: bonus.amount, currentDepositAmount: (bonus.currentDepositAmount/100 | number:'1.2') | number:'1.2', maxAmount: bonus.maxAmount}"></p>
                                </div>
                            </div>
                        </ng-template>
                        <ng-template [ngIf]="bonus.type === 'bonus'">
                            <div class="bonusWidget__box">
                                <div class="bonusWidget__graphic">
                                    <div class="bonusWidget__icon">
                                        <img class="bonusWidget__game-img" src="assets/images/bonus-icon-bg.png" alt="bonus icon" />
                                        <i class="fa fa-eur" aria-hidden="true"></i>
                                    </div>
                                </div>
                                <div class="bonusWidget__main">
                                    <a class="button button--orange" (click)="bonusActivate(bonus);setMobileHeaderMenu('all', 'hide')" [innerHTML]="'LAYOUT.BONUS_WIDGET.COLLECT_BONUS' | translate:{amount: bonus.amount/100 | number:'1.2'}">
                                        <!-- Collect {{ bonus.amount/100 }}&euro; bonus -->
                                    </a>
                                    <p class="bonusWidget__text" [innerHTML]="'LAYOUT.BONUS_WIDGET.BONUS_TEXT' | translate:{amount: bonus.amount/100 | number:'1.2'}"><!-- Gives you {{ bonus.amount/100 }}&euro; bonus money to play with--></p>
                                </div>
                            </div>
                        </ng-template>
                        <ng-template [ngIf]="bonus.type === 'deposit_bonus'">
                           <div class="bonusWidget__box">
                                <div class="bonusWidget__graphic">
                                    <div class="bonusWidget__icon">
                                        <img class="bonusWidget__game-img" src="assets/images/bonus-icon-bg.png" alt="bonus icon" />
                                        <i class="fa fa-percent" aria-hidden="true"></i>
                                    </div>
                                </div>
                                <div class="bonusWidget__main">
                                    <!-- NO DEPOSIT - PERCENT ONLY -->
                                    <a class="button button--orange" (click)="bonusActivate(bonus);setMobileHeaderMenu('all', 'hide')" [innerHTML]="'LAYOUT.BONUS_WIDGET.DEPOSIT_BONUS.COLLECT_PERCENT' | translate:bonus" *ngIf="bonus.amount == 0">
                                        <!--{{ bonus.percent }}% deposit bonus-->
                                    </a>
                                    <p class="bonusWidget__text" [innerHTML]="'LAYOUT.BONUS_WIDGET.DEPOSIT_BONUS.PERCENT_TEXT' | translate:bonus" *ngIf="bonus.amount == 0">
                                        <!--Gives you {{ bonus.percent }}% extra on your next deposit-->
                                    </p>
                                    <!-- NO DEPOSIT - AMOUNT ONLY -->
                                    <a class="button button--orange" (click)="bonusActivate(bonus);setMobileHeaderMenu('all', 'hide')" [innerHTML]="'LAYOUT.BONUS_WIDGET.DEPOSIT_BONUS.COLLECT_AMOUNT' | translate:{percent: bonus.percent, amount: bonus.amount/100 | number:'1.2'}" *ngIf="bonus.percent == 0">
                                        <!--{{ bonus.percent }}% deposit bonus-->
                                    </a>
                                    <p class="bonusWidget__text" [innerHTML]="'LAYOUT.BONUS_WIDGET.DEPOSIT_BONUS.AMOUNT_TEXT' | translate:{percent: bonus.percent, amount: bonus.amount/100 | number:'1.2'}" *ngIf="bonus.percent == 0">
                                        <!--Gives you {{ bonus.percent }}% extra on your next deposit-->
                                    </p>
                                    <!-- NO DEPOSIT - PERCENT + AMOUNT -->
                                    <a class="button button--orange" (click)="bonusActivate(bonus);setMobileHeaderMenu('all', 'hide')" [innerHTML]="'LAYOUT.BONUS_WIDGET.DEPOSIT_BONUS.COLLECT_PLUS' | translate:{percent: bonus.percent, amount: bonus.amount/100 | number:'1.2'}" *ngIf="bonus.amount != 0 && bonus.percent != 0">
                                        <!--{{ bonus.percent }}% deposit bonus-->
                                    </a>
                                    <p class="bonusWidget__text" [innerHTML]="'LAYOUT.BONUS_WIDGET.DEPOSIT_BONUS.PLUS_TEXT' | translate:{percent: bonus.percent, amount: bonus.amount/100 | number:'1.2'}" *ngIf="bonus.amount != 0 && bonus.percent != 0">
                                        <!--Gives you {{ bonus.percent }}% extra on your next deposit-->
                                    </p>
                                </div>
                            </div>
                        </ng-template>
                        <ng-template [ngIf]="bonus.type === 'cash_back'">
                            <div class="bonusWidget__box">
                                <div class="bonusWidget__graphic">
                                    <div class="bonusWidget__icon">
                                        <img class="bonusWidget__game-img" src="assets/images/bonus-icon-bg.png" alt="bonus icon" />
                                        <!--<i class="fa fa-undo" aria-hidden="true"></i>-->
                                        <span class="fa-stack fa-lg fa">
                                                        <i class="fa fa-undo fa-stack-2x" style="font-size: 1.25em;"></i>
                                                        <i class="fa fa-euro fa-stack-1x" style="font-size: 0.35em"></i>
                                        </span>
                                    </div>
                                </div>
                                <div class="bonusWidget__main">
                                    <a class="button button--orange" (click)="bonusActivate(bonus);setMobileHeaderMenu('all', 'hide')" [innerHTML]="'LAYOUT.BONUS_WIDGET.COLLECT_CASHBACK' | translate:bonus">
                                        <!-- Collect {{ bonus.percent }}% Cashback bonus -->
                                    </a>
                                    <p class="bonusWidget__text" [innerHTML]="'LAYOUT.BONUS_WIDGET.CASHBACK_TEXT' | translate:bonus"><!-- Gives you {{ bonus.percent }}% Cashback bonus --></p>
                                </div>
                            </div>
                        </ng-template>

                        <!--
                        <a class="decorator" (click)="setMobileHeaderMenu('all', 'hide')">
                            <div class="image" style="background-image: url('https://cdn.lapalingo.com/assets/images/games/thumbs/adp_super7reels.png?93')" *ngIf="bonus.type === 'url'"></div>
                            <i class="fa fa-eur" aria-hidden="true" *ngIf="bonus.type !== 'url'"></i>
                        </a>
                        <a class="button button--red" (click)="setMobileHeaderMenu('all', 'hide')">
                            {{ bonus.own_type }} / {{ bonus.amount }} <i aria-hidden="true" class="fa fa-eur"></i>
                        </a>
                        -->

                    </div>
                </div>
            </div>
        </div>


        <div class="stickyFooter__item">
            <a class="button button--green" *ngIf="loggedUserData?.isFullyRegistered"
               [routerLink]="navigationService.constructUrl('CASHIER')" [queryParams]="{ tab: 'payIn' }"
               (click)="setMobileHeaderMenu('all', 'hide')">
                <i aria-hidden="true" class="fa fa-plus-circle left-position"></i>{{ 'LAYOUT.MAKE_DEPOSIT_ANCHOR_LABEL'
                | translate }}
            </a><a class="button button--green" *ngIf="!loggedUserData?.isFullyRegistered"
                   [routerLink]="navigationService.constructUrl('USER_ACCOUNT')"
                   (click)="setMobileHeaderMenu('all', 'hide')">
            <i aria-hidden="true" class="fa fa-plus-circle left-position"></i>{{ 'LAYOUT.MAKE_DEPOSIT_ANCHOR_LABEL' |
            translate }}
        </a>
        </div>
    </div>
    <a class="goToTop" (click)="goToTop()"></a>

    <div class="loaderContainer" #loaderContainer attr.data-text="{{ 'LAYOUT.LOADING_LABEL' | translate }}">
        <span class="loader"></span>
    </div>

    <div class="panelMask">
        <div class="tac__dropdown" [class.active]="visibleTACModal">
            <div class="tac__dropdown-wrapper">
                <h2><span>{{ tacTitle }}</span></h2>
                <div class="tac__content" [innerHtml]="tacText"></div>
                <div class="tac__dropdown-inside darkBackground paddingForDecorator">
                    <div class="tac__dropdown-insideElement withoutMargin">
                        <a class="button button--gray iconRightPosition" (click)="acceptTAC()"><span class="textInside">{{ tacButtonText }}</span></a>
                    </div>
                </div>
            </div>
        </div>

        <div app-register-modal class="register__dropdown" [class.active]="visibleRegisterBar" (close)="closeRegisterPanel($event)"></div>
        <div app-webauthn-register-modal class="webauthn__dropdown" [class.active]="visibleWebauthnRegister"  (close)="closeWebauthnPanel($event)"></div>

        <div class="login__dropdown js--login__dropdown" [class.active]="visibleLoginBar">
            <a class="closeLoginDropdownButton" (click)="closeLoginPanel()"><i class="fa fa-times"
                                                                               aria-hidden="true"></i></a>
            <div class="login__dropdown-wrapper">
                <div class="login__dropdown-inside first" *ngIf="showWebAuthn">
                    <h2><span>{{ 'LAYOUT.LOGIN_LABEL' | translate }}</span></h2>
                    <img src="/assets/images/webauthn/login_faceprint.png" alt="Biometrical login" id="webauthn_img" class="webauthn-cookie-support" />
                    <button
                        (click) = "webauthLogin()"
                        id="btn-login-webauth-popup" class="btn btn-sm btn-primary webauthn-login-button button button--green" type="submit"  >
                        <i class="load-animate fa" [ngClass]="{'fa-spinner fa-spin': isWebauthnLoginExec}"></i> <span>{{ 'LAYOUT.WELCOME_BACK_MODAL_WEBAUTH' | translate }}</span>
                    </button>
                </div>

                <div class="login__dropdown-inside"
                    [ngClass]="{'first': !showWebAuthn, 'pb0': showWebAuthn}"
                >
                    <h2 *ngIf="!showWebAuthn"><span>{{ 'LAYOUT.LOGIN_LABEL' | translate }}</span></h2>
                    <h2 *ngIf="showWebAuthn"><span>{{ 'LAYOUT.OR' | translate }}</span></h2>
                </div>


                <div class="login__dropdown-inside" *ngIf="showWebAuthn && !showUserPassword">
                    <div class="login__dropdown-insideElement withoutMargin">
                        <a class="button button--green" (click)="showUserPasswordForm()">
                            <span class="textInside">{{ 'LAYOUT.USE_USER_AND_PASSWORD' | translate }}</span></a>
                    </div>
                </div>

                <form class="login__dropdown-inside" [formGroup]="loginForm" (ngSubmit)="login(loginForm.value)"
                      (keyup.enter)="login(loginForm.value)" *ngIf="!isLogged() && (!showWebAuthn || showUserPassword)">

                    <div class="form-element login__dropdown-insideElement moreMarginBottom">
                        <label for="loginUsername">{{ 'LAYOUT.LOG_IN_BAR_EMAIL_PLACEHOLDER' | translate }}</label>
                        <input id="loginUsername" #loginUsername formControlName="username" required autocomplete="off"
                               [class.error]="loginFormErrors.username.length" type="email">
                        <span class="errorMessage" *ngIf="loginFormErrors.username.length">
							<span class="errorMessage__inside">
								<span *ngFor="let usernameMessage of loginFormErrors.username">{{ usernameMessage | translate }}</span>
							</span>
						</span>
                    </div>
                    <div class="form-element login__dropdown-insideElement quarterMarginBottom">
                        <label for="loginPassword">{{ 'LAYOUT.LOG_IN_BAR_PASSWORD_PLACEHOLDER' | translate }}</label>
                        <input id="loginPassword" type="password" formControlName="password" required autocomplete="off"
                               [class.error]="loginFormErrors.password.length">
                        <span class="errorMessage" *ngIf="loginFormErrors.password.length">
							<span class="errorMessage__inside">
								<span *ngFor="let passwordMessage of loginFormErrors.password;">
									{{ passwordMessage | translate }}
								</span>
							</span>
						</span>
                    </div>
                    <div class="login__dropdown-insideElement moreMarginBottom">
                        <a class="anchor" [routerLink]="navigationService.constructUrl('PASSWORD_RESET')"
                           (click)="closeLoginPanel()">{{ 'LAYOUT.FORGOT_PASSWORD_LABEL' | translate }}</a>
                        <a class="anchor" [routerLink]="navigationService.constructUrl('ACCOUNT_ACTIVATION')"
                           (click)="closeLoginPanel()">{{ 'LAYOUT.ACCOUNT_ACTIVATION_LABEL' | translate }}</a>
                    </div>
                    <div class="login__dropdown-insideElement withoutMargin">
                        <a class="button button--green" disabled *ngIf="!loginForm.valid || sendingLogin">
                            <i class="fa fa-user-circle-o" aria-hidden="true"></i><span class="textInside">{{ 'LAYOUT.LOG_IN_BAR_LOG_IN_LABEL' | translate }}</span>
                        </a>
                        <a class="button button--green" *ngIf="!(!loginForm.valid || sendingLogin)"
                           (click)="login(loginForm.value)">
                            <i class="fa fa-user-circle-o" aria-hidden="true"></i><span class="textInside">{{ 'LAYOUT.LOG_IN_BAR_LOG_IN_LABEL' | translate }}</span>
                        </a>
                    </div>
                </form>
                <div class="login__dropdown-inside withoutVerticalPadding" *ngIf="!isLogged()">
                    <hr attr.data-text="{{ 'LAYOUT.OR_LABEL' | translate }}">
                </div>
                <div class="login__dropdown-inside" *ngIf="!isLogged() && facebookLogin">
                    <div class="login__dropdown-insideElement withoutMargin">
                        <a class="button button&#45;&#45;blue" (click)="loginViaFacebook(true)"><i
                                class="fa fa-facebook-official" aria-hidden="true"></i><span class="textInside">{{ 'LAYOUT.FACEBOOK_LOGIN_LABEL' | translate }}</span></a>
                    </div>
                </div>
                <div class="login__dropdown-inside darkBackground paddingForDecorator" *ngIf="!isLogged()">
<!--                    <div class="login__dropdown-insideElement withoutMargin">-->
<!--                        <h3>{{ 'LAYOUT.DO_NOT_HAVE_AN_ACCOUNT_LABEL' | translate }}</h3>-->
<!--                        <a class="button button&#45;&#45;gray iconRightPosition"-->
<!--                           (click)="closeLoginPanel(); toggleRegisterBar()"><span class="textInside">{{ 'LAYOUT.REGISTER_LABEL' | translate }}</span><i-->
<!--                                class="fa fa-angle-right" aria-hidden="true"></i></a>-->
<!--                    </div>-->
                </div>
                <div class="login__dropdown-inside darkBackground paddingForDecorator" *ngIf="isLogged()">
                    <div class="login__dropdown-insideElement withoutMargin">
                        <a class="button button--red" (click)="logout()">{{ 'LAYOUT.LOG_IN_BAR_LOG_OUT_LABEL' |
                            translate }}</a>
                    </div>
                </div>
            </div>
        </div>


        <!-- Webauthn login suggess modal-->
        <div class="webauthn_suggess__dropdown" [class.active]="visibleWebauthnSuggessModal">
            <a class="closeLoginDropdownButton" (click)="closeWebauthnSuggessPanel()"><i class="fa fa-times"
                                                                               aria-hidden="true"></i></a>
            <div class="webauthn_suggess__dropdown-wrapper">
                <div class="webauthn_suggess_dropdown-inside first">
                    <h2 ><span class="p0">{{ 'LAYOUT.USER_BIOMETRIC' | translate }}</span></h2>
                    <img src="/assets/images/webauthn/login_faceprint.png" alt="Biometrical login" id="webauthn_img" class="webauthn-cookie-support" />

                    <p>{{ 'LAYOUT.WEBAUTHN_SUGESS_MODAL_REGISTER_SUGESS' | translate }}</p>
                    <p>{{ 'LAYOUT.WEBAUTHN_SUGESS_MODAL_REGISTER_SUGESS_TEXT' | translate }}</p>

                    <button
                        (click) = "webauthRegister()"
                        class="btn btn-sm btn-primary webauthn-login-button button button--green" type="submit"  >
                        <i class="load-animate fa" [ngClass]="{'fa-spinner fa-spin': isWebauthnLoginExec}"></i> <span>{{ 'LAYOUT.WEBAUTHN_SUGESS_MODAL_REGISTER' | translate }}</span>
                    </button>
                </div>
                <div class="webauthn_suggess_dropdown-inside first">
                    <h2><span>{{ 'LAYOUT.OR' | translate }}</span></h2>
                    <button
                        (click) = "skipWebauthRegister()"
                        class="btn btn-sm btn-primary webauthn-login-button button button--red" type="submit"  >
                        <span>{{ 'LAYOUT.WEBAUTHN_SUGESS_MODAL_CONTINUE' | translate }}</span>
                    </button>
                </div>
            </div>
        </div>



    </div>
    <div class="latestOpenedGames" *ngIf="!!latestOpenedGames.length && !isLogged()">
        <a class="latestOpenedGames-item" *ngFor="let latestGame of latestOpenedGames" (click)="openGame(latestGame.urlSlug, 'demo')">
            <span class="latestOpenedGames-itemImage"
                  [style.background-image]="'url(' + replaceGameUrl(latestGame?.gameThumbImageUrl) + ')'"></span><span
                class="latestOpenedGames-itemName">{{ latestGame?.name }}</span>
        </a>
    </div>

    <div class="latestOpenedGames" *ngIf="latestOpenedGamesLoggedUser.length && isLogged()">
        <a class="latestOpenedGames-item" *ngFor="let latestGameLoggedUser of latestOpenedGamesLoggedUser" (click)="openGame(latestGameLoggedUser.urlSlug, 'demo')">
            <span class="latestOpenedGames-itemImage"
                  [style.background-image]="'url(' + replaceGameUrl(latestGameLoggedUser?.gameThumbImageUrl) + ')'"></span><span
                class="latestOpenedGames-itemName">{{ latestGameLoggedUser?.name }}</span>
        </a>
    </div>
    <header id="top">
        <div class="headerContainer headerContainerMobile visible-only-mobile">
            <ul class="headerMenu">
                <li class="headerMenu__item headerMenu__item--menu">
                    <a class="headerMenu__itemHamburger" *ngIf="!mobile.show.menu"
                       (click)="setMobileHeaderMenu('menu', 'show')"></a>
                    <a class="headerMenu__itemClose" *ngIf="mobile.show.menu"
                       (click)="setMobileHeaderMenu('menu', 'hide')"></a>
                    <div class="headerMenu__item-content headerMenu__itemHamburger-content"
                         [class.show]="mobile.show.menu">
                        <div class="headerMenu__item-contentInside" #scrollContent>
                            <ul class="mainMenu">
                                <li class="mainMenu__item">
                                    <span class="mainMenu__item-separator"></span>
                                </li>
                                <ng-template ngFor let-mobileMenuItem [ngForOf]="mainMenu">
                                    <li class="mainMenu__item" *ngIf="mobileMenuItem.isVisible">
                                        <span class="mainMenu__item-separator"
                                              *ngIf="mobileMenuItem.separator && mobileMenuItem.isVisible"></span>
                                        <a class="mainMenu__item-anchor"
                                           routerLinkActive="mainMenu__item-anchor--active"
                                           routerLink="{{ mobileMenuItem.link }}"
                                           [queryParams]="mobileMenuItem.queryStringParameters"
                                           *ngIf="!mobileMenuItem.separator && mobileMenuItem.isVisible"
                                           (click)="setMobileHeaderMenu('all', 'hide')">
                                            <i *ngIf="!mobileMenuItem.imgSrc"
                                               class="{{ mobileMenuItem.iconName ? mobileMenuItem.iconName : 'fa-circle-o' }}"></i>
                                            <img *ngIf="mobileMenuItem.imgSrc" class="mainMenu__custom-img"
                                                 src="{{ mobileMenuItem.imgSrc }}" alt="{{ mobileMenuItem.label }}"/>
                                            <span *ngIf="!mobileMenuItem.label">{{ mobileMenuItem.translationKey | translate }}</span>
											<span *ngIf="mobileMenuItem.label">{{ mobileMenuItem.label }}</span>
                                        </a>
                                    </li>
                                </ng-template>
                                <li class="mainMenu__item" *ngIf="isLogged()">
                                    <span class="mainMenu__item-separator"></span>
                                </li>
                                <li class="mainMenu__item" *ngIf="isLogged()">
                                    <a class="mainMenu__item-anchor"
                                       (click)="logout(); setMobileHeaderMenu('all', 'hide')">
                                        <i class="icon-icon13"></i>
                                        <span>{{ 'LAYOUT.LOGOUT_LABEL' | translate }}</span>
                                    </a>
                                </li>
                            </ul>
                            <div class="language-switcher-wrapper" #mobileLanguageWrapper
                                 (click)="toggleMobileLanguages()">
                                <language-switcher class="languagesBar__switcher mobile"></language-switcher>
                            </div>
                        </div>
                    </div>
                </li>
                <li class="headerMenu__item headerMenu__item--search">
                    <a class="headerMenu__itemSearch" *ngIf="!mobile.show.search"
                       (click)="setMobileHeaderMenu('search', 'show')"></a>
                    <a class="headerMenu__itemClose" *ngIf="mobile.show.search"
                       (click)="setMobileHeaderMenu('search', 'hide')"></a>
                    <div class="headerMenu__item-content headerMenu__itemSearch-content"
                         [class.show]="mobile.show.search">
                        <div class="headerMenu__item-contentInside">
                            <search-bar></search-bar>
                        </div>
                    </div>
                </li>
                <li class="headerMenu__item headerMenu__item--logo">
                    <a class="headerMenu__itemLogo" [routerLink]="navigationService.constructUrl('START')"
                       (click)="setMobileHeaderMenu('all', 'hide');sideBarClose();"></a>
                </li>
                <li class="headerMenu__item headerMenu__item--responsible" *ngIf="!isLogged()">
                    <a [routerLink]="navigationService.constructUrl('RESPONSIBLE_GAMBLING')">
                        <img src="assets/images/badges/responsiblegaming.png" alt="Responsible Gaming">
                    </a>
                </li>
                <li class="headerMenu__item headerMenu__item--responsible" *ngIf="isLogged()">
                    <a [routerLink]="navigationService.constructUrl('CASHIER')" [queryParams]="{tab: 'limits'}">
                        <img src="assets/images/badges/responsiblegaming.png" alt="Responsible Gaming">
                    </a>
                </li>
                <li class="headerMenu__item headerMenu__item--support">
                    <a class="headerMenu__itemSupport" (click)="window.LC_API.open_chat_window()"
                       *ngIf="showLiveChatLink"></a>
                </li>

                <li class="headerMenu__item headerMenu__item--userPanel">
                    <a class="headerMenu__itemUserPanel" *ngIf="!mobile.show.userPanel && !isLogged() && !userPicture"
                       (click)="toggleLoginBar()"></a>
                    <a class="headerMenu__itemUserPanel withAvatar"
                       *ngIf="!mobile.show.userPanel && !isLogged() && userPicture" (click)="toggleLoginBar()"><img
                            class="userPicture" alt="Avatar" src="{{ userPicture }}" *ngIf="userPicture"></a>

                    <a class="headerMenu__itemUserPanel" *ngIf="!mobile.show.userPanel && isLogged() && !userPicture"
                       (click)="setMobileHeaderMenu('userPanel', 'show')"></a>
                    <a class="headerMenu__itemUserPanel withAvatar"
                       *ngIf="!mobile.show.userPanel && isLogged() && userPicture"
                       (click)="setMobileHeaderMenu('userPanel', 'show')"><img class="userPicture" alt="Avatar"
                                                                               src="{{ userPicture }}"
                                                                               *ngIf="userPicture"></a>

                    <a class="headerMenu__itemClose" *ngIf="mobile.show.userPanel"
                       (click)="setMobileHeaderMenu('userPanel', 'hide')"></a>
                    <div class="headerMenu__item-content headerMenu__itemUserPanel-content"
                         [class.show]="mobile.show.userPanel">
                        <div class="headerMenu__item-contentInside" *ngIf="!isLogged()">
                            guest
                        </div>
                        <div class="headerMenu__item-contentInside" *ngIf="isLogged()">
                            <div class="userPanel__container">
                                <img class="userPicture leftIcon" alt="Avatar" src="{{ userPicture }}" *ngIf="userPicture">
                                <i class="fa fa-user-circle-o leftIcon" aria-hidden="true" *ngIf="!userPicture"></i>
                                <p class="user">{{ loggedUserData?.firstName }} {{ loggedUserData?.lastName }}</p>
                                <p class="lastLogin" *ngIf="loggedUserData?.lastLogin.date">
                                    {{ 'LAYOUT.LAST_LOGIN_LABEL' | translate }}: {{ loggedUserData?.lastLogin.date | date: 'yyyy-MM-dd, HH:mm'}}
                                </p>
                                <p class="lastLogin" *ngIf="!loggedUserData?.lastLogin.date">{{
                                    'LAYOUT.LAST_LOGIN_LABEL' | translate }}: ?</p>

                                <div class="horizontal-separator"></div>

                                <div class="myBalanceAndAccount">
                                    <div class="myBalanceAndAccount__item">
                                        <div class="myBalanceAndAccount__itemInside">
                                            <p class="myBalanceAndAccount__itemInside-label">{{ 'LAYOUT.MY_BALANCE_LABEL' | translate }}</p>
                                            <p class="myBalanceAndAccount__itemInside-value">
                                                {{ loggedUserData?.balance.realBalance | currency_format:loggedUserData?.currency }}
                                            </p>
                                        </div>
                                    </div>
<!--                                    <div class="myBalanceAndAccount__item">-->
<!--                                        <div class="myBalanceAndAccount__itemInside">-->
<!--                                            <p class="myBalanceAndAccount__itemInside-label">{{ 'LAYOUT.MY_BONUS_LABEL' | translate }}</p>-->
<!--                                            <p class="myBalanceAndAccount__itemInside-value">-->
<!--                                                {{ loggedUserData?.balance.bonusBalance | currency_format:loggedUserData?.currency }}-->
<!--                                            </p>-->
<!--                                        </div>-->
<!--                                    </div>-->
                                </div>

                                <div class="horizontal-separator"></div>

                                <a *ngIf="loggedUserData?.isFullyRegistered"
                                   class="button button--topMargin button--bottomMargin"
                                   [routerLink]="navigationService.constructUrl('CASHIER')"
                                   [queryParams]="{ tab: 'payIn' }" (click)="setMobileHeaderMenu('all', 'hide')">
                                    <i aria-hidden="true" class="fa fa-plus-circle"></i>{{
                                    'LAYOUT.MAKE_DEPOSIT_ANCHOR_LABEL' | translate }}
                                </a><a *ngIf="!loggedUserData?.isFullyRegistered"
                                       class="button button--topMargin button--bottomMargin"
                                       [routerLink]="navigationService.constructUrl('USER_ACCOUNT')"
                                       (click)="setMobileHeaderMenu('all', 'hide')">
                                <i aria-hidden="true" class="fa fa-plus-circle"></i>{{
                                'LAYOUT.MAKE_DEPOSIT_ANCHOR_LABEL' | translate }}
                            </a>

                                <a class="button button--red" *ngIf="loggedUserData?.isFullyRegistered"
                                   [routerLink]="navigationService.constructUrl('CASHIER')"
                                   [queryParams]="{ tab: 'payOut' }" (click)="setMobileHeaderMenu('all', 'hide')">
                                    <i aria-hidden="true" class="icon-icon27"></i>{{ 'LAYOUT.PAY_OUT_ANCHOR_LABEL' |
                                    translate }}
                                </a><a class="button button--red" *ngIf="!loggedUserData?.isFullyRegistered"
                                       [routerLink]="navigationService.constructUrl('USER_ACCOUNT')"
                                       (click)="setMobileHeaderMenu('all', 'hide')">
                                <i aria-hidden="true" class="icon-icon27"></i>{{ 'LAYOUT.PAY_OUT_ANCHOR_LABEL' | translate
                                }}
                            </a>

                                <div class="myProfileAndLogout">
                                    <a class="myProfileAndLogout__item link smallButton smallButton--half smallButton--half-left"
                                       [routerLink]="navigationService.constructUrl('USER_ACCOUNT')"
                                       (click)="setMobileHeaderMenu('all', 'hide')">
                                        <i aria-hidden="true" class="fa fa-cog"></i><span>{{ 'LAYOUT.MY_PROFILE_LABEL' | translate }}</span>
                                    </a>
                                    <a class="myProfileAndLogout__item link smallButton smallButton--half smallButton--half-right"
                                       (click)="logout(); setMobileHeaderMenu('all', 'hide')">
                                        <i aria-hidden="true" class="icon-icon13"></i><span>{{ 'LAYOUT.LOGOUT_LABEL' | translate }}</span>
                                    </a>
                                </div>

                                <div class="accountAndSportsbook__item">
                                    <a class="link" *ngIf="loggedUserData?.isFullyRegistered"
                                       [routerLink]="navigationService.constructUrl('CASHIER')"
                                       [queryParams]="{ tab: 'overview' }" (click)="setMobileHeaderMenu('all', 'hide')">
                                        <i aria-hidden="true" class="fa fa-eur"></i><span>{{ 'LAYOUT.MY_ACCOUNT_LABEL' | translate }}</span>
                                    </a><a class="link" *ngIf="!loggedUserData?.isFullyRegistered"
                                           [routerLink]="navigationService.constructUrl('USER_ACCOUNT')"
                                           (click)="setMobileHeaderMenu('all', 'hide')">
                                    <i aria-hidden="true" class="fa fa-eur"></i><span>{{ 'LAYOUT.MY_ACCOUNT_LABEL' | translate }}</span>
                                </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </li>
            </ul>
        </div>

        <div class="headerContainer visible-only-desktop">
            <div class="leftColumn"></div>

            <div class="rightColumn">
                <search-bar></search-bar>
                <ul class="headerMenu">
                    <!-- <li class="headerMenu__item headerMenu__item-additional">
                        <div class="helpPanel">
                            <a class="fixedButtons__item--icon"><i class="fa fa-question-circle-o" aria-hidden="true"></i></a>
                        </div>
                        <div class="eighteenPanel">
                            <a class="fixedButtons__item--icon">18</a>
                        </div>
                    </li> -->

<!--                    <li class="headerMenu__item bonusWidget headerMenu__item&#45;&#45;pointer" *ngIf="isLogged()">-->
<!--                        <a #bonusCounter class="headerMenu__item-bonus" [attr.data-counter]="bonuses.length" [ngClass]="(bonuses.length > 0)?'showBonus':''" (click)="toggleBonusPanel()">{{ 'LAYOUT.BONUS_BAR_ANCHOR_LABEL' | translate }}</a>-->
<!--                        <a class="headerMenu__item-bonus" [attr.data-counter]="bonuses.length" *ngIf="bonuses.length === 0" [ngClass]="(bonuses.length === 0)?'showBonus':''">{{ 'LAYOUT.BONUS_BAR_ANCHOR_LABEL' | translate }}</a>-->

<!--                        <div class="bonusWidget__container-wrapper" *ngIf="bonuses.length > 0"  [class.active]="commonPagesHelper.bonusPanelState">-->
<!--                            <div class="bonusWidget__container" perfect-scrollbar>-->
<!--                                <div class="bonusWidget__container-item" [class.bonusWidget__container-item&#45;&#45;last-child]="((bonuses.length - 1) == index)" *ngFor="let bonus of bonuses; let index = index">-->

<!--                                    <ng-template [ngIf]="bonus.type === 'free_spin'">-->
<!--                                        <div class="bonusWidget__free-spin">-->
<!--                                            <div class="bonusWidget__graphic">-->
<!--                                                <img class="bonusWidget__game-img" alt="{{ bonus.game.name }}" src="{{ replaceGameUrl(bonus.game.gameThumbImageUrl) }}" />-->
<!--                                            </div>-->
<!--                                            <a class="button button&#45;&#45;orange" (click)="bonusActivate(bonus)" [innerHTML]="'LAYOUT.BONUS_WIDGET.COLLECT_FREE_SPINS' | translate:bonus">-->
<!--                                                &lt;!&ndash; Collect {{ bonus.amount }} free spins &ndash;&gt;-->
<!--                                            </a>-->
<!--                                            <p class="bonusWidget__text" [innerHTML]="'LAYOUT.BONUS_WIDGET.FREE_SPINS_TEXT' | translate:bonus">&lt;!&ndash; Gives you {{ bonus.amount }} free spins in {{ bonus.game.name }} &ndash;&gt;</p>-->
<!--                                        </div>-->
<!--                                    </ng-template>-->
<!--                                    <ng-template [ngIf]="bonus.type === 'free_spin_per_deposit'">-->
<!--                                        <div class="bonusWidget__free-spin">-->
<!--                                            <div class="bonusWidget__graphic">-->
<!--                                                <img class="bonusWidget__game-img" alt="{{ bonus.game.name }}" src="{{ replaceGameUrl(bonus.game.gameThumbImageUrl) }}" />-->
<!--                                            </div>-->
<!--                                            <a *ngIf="!bonus.showUpToAmount" class="button button&#45;&#45;orange" (click)="bonusActivate(bonus)" [innerHTML]="'LAYOUT.BONUS_WIDGET.COLLECT_FREE_SPINS_PER_DEPOSIT' | translate:{amount: bonus.amount, currentDepositAmount: (bonus.currentDepositAmount/100 | number:'1.2'), maxAmount: bonus.maxAmount}"></a>-->
<!--                                            <p *ngIf="!bonus.showUpToAmount" class="bonusWidget__text" [innerHTML]="'LAYOUT.BONUS_WIDGET.FREE_SPINS_PER_DEPOSIT_TEXT' | translate:{amount: bonus.amount, currentDepositAmount: (bonus.currentDepositAmount/100 | number:'1.2'), maxAmount: bonus.maxAmount}"></p>-->

<!--                                            <a *ngIf="bonus.showUpToAmount" class="button button&#45;&#45;orange" (click)="bonusActivate(bonus)" [innerHTML]="'LAYOUT.BONUS_WIDGET.COLLECT_FREE_SPINS_PER_DEPOSIT_UP_TO' | translate:{amount: bonus.amount, currentDepositAmount: (bonus.currentDepositAmount/100 | number:'1.2'), maxAmount: bonus.maxAmount}"></a>-->
<!--                                            <p *ngIf="bonus.showUpToAmount" class="bonusWidget__text" [innerHTML]="'LAYOUT.BONUS_WIDGET.FREE_SPINS_PER_DEPOSIT_UP_TO_TEXT' | translate:{amount: bonus.amount, currentDepositAmount: (bonus.currentDepositAmount/100 | number:'1.2'), maxAmount: bonus.maxAmount}"></p>-->
<!--                                        </div>-->
<!--                                    </ng-template>-->
<!--                                    <ng-template [ngIf]="bonus.type === 'bonus'">-->
<!--                                        <div class="bonusWidget__bonus">-->
<!--                                            <div class="bonusWidget__graphic">-->
<!--                                                <span class="bonusWidget__icon">-->
<!--                                                    <i class="fa fa-eur" aria-hidden="true"></i>-->
<!--                                                </span>-->
<!--                                            </div>-->
<!--                                            <a class="button button&#45;&#45;orange" (click)="bonusActivate(bonus)" [innerHTML]="'LAYOUT.BONUS_WIDGET.COLLECT_BONUS' | translate:{amount: bonus.amount/100 | number:'1.2'}">-->
<!--                                                &lt;!&ndash; Collect {{ bonus.amount/100 }}&euro; bonus &ndash;&gt;-->
<!--                                            </a>-->
<!--                                            <p class="bonusWidget__text" [innerHTML]="'LAYOUT.BONUS_WIDGET.BONUS_TEXT' | translate:{amount: bonus.amount/100 | number:'1.2'}">&lt;!&ndash; Gives you {{ bonus.amount/100 }}&euro; bonus money to play with &ndash;&gt;</p>-->
<!--                                        </div>-->
<!--                                    </ng-template>-->
<!--                                    <ng-template [ngIf]="bonus.type === 'deposit_bonus'">-->
<!--                                       <div class="bonusWidget__deposit-bonus">-->
<!--                                            <div class="bonusWidget__graphic">-->
<!--                                                <span class="bonusWidget__icon">-->
<!--                                                    <i class="fa fa-percent" aria-hidden="true"></i>-->
<!--                                                </span>-->
<!--                                            </div>-->
<!--                                           &lt;!&ndash; NO DEPOSIT - PERCENT ONLY &ndash;&gt;-->
<!--                                           <a class="button button&#45;&#45;orange" (click)="bonusActivate(bonus)" [innerHTML]="'LAYOUT.BONUS_WIDGET.DEPOSIT_BONUS.COLLECT_PERCENT' | translate:bonus" *ngIf="bonus.amount == 0">-->
<!--                                                &lt;!&ndash;{{ bonus.percent }}% deposit bonus&ndash;&gt;-->
<!--                                            </a>-->
<!--                                            <p class="bonusWidget__text" [innerHTML]="'LAYOUT.BONUS_WIDGET.DEPOSIT_BONUS.PERCENT_TEXT' | translate:bonus" *ngIf="bonus.amount == 0">-->
<!--                                                &lt;!&ndash;Gives you {{ bonus.percent }}% extra on your next deposit&ndash;&gt;-->
<!--                                            </p>-->
<!--                                           &lt;!&ndash; NO DEPOSIT - AMOUNT ONLY &ndash;&gt;-->
<!--                                           <a class="button button&#45;&#45;orange" (click)="bonusActivate(bonus)" [innerHTML]="'LAYOUT.BONUS_WIDGET.DEPOSIT_BONUS.COLLECT_AMOUNT' | translate:{percent: bonus.percent, amount: bonus.amount/100 | number:'1.2'}" *ngIf="bonus.percent == 0">-->
<!--                                                &lt;!&ndash;{{ bonus.percent }}% deposit bonus&ndash;&gt;-->
<!--                                            </a>-->
<!--                                            <p class="bonusWidget__text" [innerHTML]="'LAYOUT.BONUS_WIDGET.DEPOSIT_BONUS.AMOUNT_TEXT' | translate:{percent: bonus.percent, amount: bonus.amount/100 | number:'1.2'}" *ngIf="bonus.percent == 0">-->
<!--                                                &lt;!&ndash;Gives you {{ bonus.percent }}% extra on your next deposit&ndash;&gt;-->
<!--                                            </p>-->
<!--                                           &lt;!&ndash; NO DEPOSIT - PERCENT + AMOUNT &ndash;&gt;-->
<!--                                           <a class="button button&#45;&#45;orange" (click)="bonusActivate(bonus)" [innerHTML]="'LAYOUT.BONUS_WIDGET.DEPOSIT_BONUS.COLLECT_PLUS' | translate:{percent: bonus.percent, amount: bonus.amount/100 | number:'1.2'}" *ngIf="bonus.amount != 0 && bonus.percent != 0">-->
<!--                                                &lt;!&ndash;{{ bonus.percent }}% deposit bonus&ndash;&gt;-->
<!--                                            </a>-->
<!--                                            <p class="bonusWidget__text" [innerHTML]="'LAYOUT.BONUS_WIDGET.DEPOSIT_BONUS.PLUS_TEXT' | translate:{percent: bonus.percent, amount: bonus.amount/100 | number:'1.2'}" *ngIf="bonus.amount != 0 && bonus.percent != 0">-->
<!--                                                &lt;!&ndash;Gives you {{ bonus.percent }}% extra on your next deposit&ndash;&gt;-->
<!--                                            </p>-->
<!--                                        </div>-->
<!--                                    </ng-template>-->
<!--                                    <ng-template [ngIf]="bonus.type === 'cash_back'" >-->
<!--                                        <div class="bonusWidget__cash-back">-->
<!--                                            <div class="bonusWidget__graphic">-->
<!--                                                <span class="bonusWidget__icon">-->
<!--                                                    &lt;!&ndash;i class="fa fa-undo" aria-hidden="true"></i&ndash;&gt;-->
<!--                                                    <span class="fa-stack fa-lg">-->
<!--                                                        <i class="fa fa-undo fa-stack-2x" style="font-size: 2.5em;"></i>-->
<!--                                                        <i class="fa fa-euro fa-stack-1x" style="font-size: 0.75em"></i>-->
<!--                                                    </span>-->
<!--                                                </span>-->
<!--                                            </div>-->
<!--                                            <a class="button button&#45;&#45;orange" (click)="bonusActivate(bonus)" [innerHTML]="'LAYOUT.BONUS_WIDGET.COLLECT_CASHBACK' | translate:bonus">-->
<!--                                                &lt;!&ndash;Collect {{ bonus.percent }}% Cashback bonus&ndash;&gt;-->
<!--                                            </a>-->
<!--                                            <p class="bonusWidget__text" [innerHTML]="'LAYOUT.BONUS_WIDGET.CASHBACK_TEXT' | translate:bonus">-->
<!--                                                &lt;!&ndash;Gives you {{ bonus.percent }}% Cashback bonus&ndash;&gt;-->
<!--                                            </p>-->
<!--                                        </div>-->
<!--                                    </ng-template>-->

<!--                                    &lt;!&ndash;<a class="decorator">-->
<!--                                        <div class="image" style="background-image: url('https://cdn.lapalingo.com/assets/images/games/thumbs/adp_super7reels.png?93')" *ngIf="bonus.type === 'url'"></div>-->
<!--                                        <i class="fa fa-eur" aria-hidden="true" *ngIf="bonus.type !== 'url'"></i>-->
<!--                                    </a>-->
<!--                                    <a class="button button&#45;&#45;red" (click)="bonusActivate(bonus)">-->
<!--                                        Claim {{ bonus.own_type }} / {{ bonus.amount }} <i aria-hidden="true" class="fa fa-eur"></i>-->
<!--                                    </a>-->
<!--                                    &ndash;&gt;-->
<!--                                </div>-->
<!--                            </div>-->
<!--                        </div>-->
<!--                    </li>-->
                    <li class="headerMenu__item headerMenu__item--responsible" *ngIf="!isLogged()">
                        <a [routerLink]="navigationService.constructUrl('RESPONSIBLE_GAMBLING')">
                            <img src="assets/images/badges/responsiblegaming.png" alt="Responsible Gaming">
                        </a>
                    </li>
                    <li class="headerMenu__item headerMenu__item--responsible" *ngIf="isLogged()">
                        <a [routerLink]="navigationService.constructUrl('CASHIER')" [queryParams]="{tab: 'limits'}">
                            <img src="assets/images/badges/responsiblegaming.png" alt="Responsible Gaming">
                        </a>
                    </li>
<!--                    <li (click)="toggleRegisterBar()" class="headerMenu__item register" *ngIf="!isLogged()">-->
<!--                        <a class="headerMenu__item-register"><i aria-hidden="true" class="fa fa-user-plus"></i>{{-->
<!--                            'LAYOUT.REGISTER_BAR_REGISTER_ANCHOR_LABEL' | translate }}</a>-->
<!--                    </li>-->
                    <li class="headerMenu__item makeDeposit" *ngIf="isLogged()">
                        <a class="headerMenu__item-myBalance" *ngIf="loggedUserData?.isFullyRegistered"
                           [routerLink]="navigationService.constructUrl('CASHIER')" [queryParams]="{ tab: 'overview' }">
							<span class="headerMenu__item-myBalance--label">
								{{ 'LAYOUT.MY_BALANCE_LABEL' | translate }}
							</span>
                            <span class="headerMenu__item-myBalance--value">
								{{ loggedUserData?.balance.totalBalance | currency_format:loggedUserData?.currency }}
							</span>
                        </a><a class="headerMenu__item-myBalance" *ngIf="!loggedUserData?.isFullyRegistered"
                               [routerLink]="navigationService.constructUrl('USER_ACCOUNT')">
							<span class="headerMenu__item-myBalance--label">
								{{ 'LAYOUT.MY_BALANCE_LABEL' | translate }}
							</span>
                        <span class="headerMenu__item-myBalance--value">
								{{ loggedUserData?.balance.totalBalance | currency_format:loggedUserData?.currency }}
							</span>
                    </a><a *ngIf="loggedUserData?.isFullyRegistered"
                           [routerLink]="navigationService.constructUrl('CASHIER')" [queryParams]="{ tab: 'payIn' }"
                           class="headerMenu__item-makeDeposit"><i aria-hidden="true" class="fa fa-plus-circle"></i>{{
                        'LAYOUT.MAKE_DEPOSIT_ANCHOR_LABEL' | translate }}</a><a
                            *ngIf="!loggedUserData?.isFullyRegistered"
                            [routerLink]="navigationService.constructUrl('USER_ACCOUNT')"
                            class="headerMenu__item-makeDeposit"><i aria-hidden="true" class="fa fa-plus-circle"></i>{{
                        'LAYOUT.MAKE_DEPOSIT_ANCHOR_LABEL' | translate }}</a>
                    </li>
                    <li class="headerMenu__item login" *ngIf="!isLogged()" (click)="toggleLoginBar()">
                        <div class="login__button">
                            <a class="headerMenu__item-login"><img class="userPicture" alt="Avatar"
                                                                   src="{{ userPicture }}" *ngIf="userPicture"><i
                                    aria-hidden="true" class="fa fa-user-circle-o" *ngIf="!userPicture"></i><span>{{ 'LAYOUT.LOG_IN_BAR_LOG_IN_LABEL' | translate }}</span></a>
                        </div>
                    </li>
                    <li class="headerMenu__item userPanel" *ngIf="isLogged()" (click)="toggleUserPanel()">
                        <div class="userPanel__button">
                            <a class="headerMenu__item-login headerMenu__item-login--logged">
                                <img class="userPicture" alt="Avatar" src="{{ userPicture }}" *ngIf="userPicture">
                                <i aria-hidden="true" class="fa fa-user-circle-o" *ngIf="!userPicture"></i>
                                <span>
								{{ 'LAYOUT.MY_ACCOUNT_LABEL' | translate }}
								</span>
                            </a>
                        </div>
                        <div class="userPanel__container-wrapper" [class.active]="visibleUserPanel">
                            <div class="userPanel__container">
                                <p class="user" *ngIf="loggedUserData?.isFullyRegistered">{{ loggedUserData?.firstName +
                                    ' ' + loggedUserData?.lastName }}</p>
                                <p class="user" *ngIf="!loggedUserData?.isFullyRegistered">{{ 'LAYOUT.HI_LABEL' |
                                    translate }}</p>
                                <p class="lastLogin" *ngIf="loggedUserData?.lastLogin.date">{{ 'LAYOUT.LAST_LOGIN_LABEL'
                                    | translate }}: {{ loggedUserData?.lastLogin.date | date: 'yyyy-MM-dd,
                                    HH:mm'}}</p>
                                <p class="lastLogin" *ngIf="!loggedUserData?.lastLogin.date">{{
                                    'LAYOUT.LAST_LOGIN_LABEL' | translate }}: ?</p>

                                <!-- <div class="bonusContainer">
                                    <div class="progressBar">
                                        <span class="progressBar__item progressBar__item--25percent" style="width: 50%"></span>
                                    </div>
                                    <p>45% wagered from the bonus!<br><span class="gray">(€20 of $45)</span></p>

                                </div> -->

                                <div class="horizontal-separator"></div>

                                <div class="myBalanceAndAccount">
                                    <div class="myBalanceAndAccount__item">
                                        <div class="myBalanceAndAccount__itemInside">
                                            <p class="myBalanceAndAccount__itemInside-label">{{
                                                'LAYOUT.MY_BALANCE_LABEL' | translate }}</p>
                                            <p class="myBalanceAndAccount__itemInside-value">
                                                {{ loggedUserData?.balance.realBalance | currency_format:loggedUserData?.currency }}
                                            </p>
                                        </div>
                                    </div>
<!--                                    <div class="myBalanceAndAccount__item">-->
<!--                                        <div class="myBalanceAndAccount__itemInside">-->
<!--                                            <p class="myBalanceAndAccount__itemInside-label">{{ 'LAYOUT.MY_BONUS_LABEL' | translate }}</p>-->
<!--                                            <p class="myBalanceAndAccount__itemInside-value">-->
<!--                                                {{ loggedUserData?.balance.bonusBalance | currency_format:loggedUserData?.currency }}-->
<!--                                            </p>-->
<!--                                        </div>-->
<!--                                    </div>-->
                                </div>

                                <div class="horizontal-separator"></div>

                                <a class="button button--topMargin button--bottomMargin"
                                   *ngIf="loggedUserData?.isFullyRegistered"
                                   [routerLink]="navigationService.constructUrl('CASHIER')"
                                   [queryParams]="{ tab: 'payIn' }">
                                    <i aria-hidden="true" class="fa fa-plus-circle"></i>{{
                                    'LAYOUT.MAKE_DEPOSIT_ANCHOR_LABEL' | translate }}
                                </a><a class="button button--topMargin button--bottomMargin"
                                       *ngIf="!loggedUserData?.isFullyRegistered"
                                       [routerLink]="navigationService.constructUrl('USER_ACCOUNT')">
                                <i aria-hidden="true" class="fa fa-plus-circle"></i>{{
                                'LAYOUT.MAKE_DEPOSIT_ANCHOR_LABEL' | translate }}
                            </a>

                                <a class="button button--red button--bottomMargin"
                                   *ngIf="loggedUserData?.isFullyRegistered"
                                   [routerLink]="navigationService.constructUrl('CASHIER')"
                                   [queryParams]="{ tab: 'payOut' }">
                                    <i aria-hidden="true" class="icon-icon27"></i>{{ 'LAYOUT.PAY_OUT_ANCHOR_LABEL' |
                                    translate }}
                                </a>
                                <a class="button button--red button--bottomMargin"
                                   *ngIf="!loggedUserData?.isFullyRegistered"
                                   [routerLink]="navigationService.constructUrl('USER_ACCOUNT')">
                                    <i aria-hidden="true" class="icon-icon27"></i>{{ 'LAYOUT.PAY_OUT_ANCHOR_LABEL' |
                                    translate }}
                                </a>

                                <div class="horizontal-separator"></div>

                                <div class="myProfileAndLogout">
                                    <a class="myProfileAndLogout__item link smallButton smallButton--half smallButton--half-left"
                                       *ngIf="loggedUserData?.isFullyRegistered"
                                       [routerLink]="navigationService.constructUrl('CASHIER')"
                                       [queryParams]="{ tab: 'overview' }">
                                        <i aria-hidden="true" class="fa fa-eur"></i><span>{{ 'LAYOUT.MY_ACCOUNT_LABEL' | translate }}</span>
                                    </a><a
                                        class="myProfileAndLogout__item link smallButton smallButton--half smallButton--half-left"
                                        *ngIf="!loggedUserData?.isFullyRegistered"
                                        [routerLink]="navigationService.constructUrl('USER_ACCOUNT')">
                                    <i aria-hidden="true" class="fa fa-eur"></i><span>{{ 'LAYOUT.MY_ACCOUNT_LABEL' | translate }}</span>
                                </a>

                                    <a class="myProfileAndLogout__item link smallButton smallButton--half smallButton--half-right"
                                       [routerLink]="navigationService.constructUrl('USER_ACCOUNT')">
                                        <i aria-hidden="true" class="fa fa-cog"></i><span>{{ 'LAYOUT.MY_PROFILE_LABEL' | translate }}</span>
                                    </a>
                                </div>

                                <a class="item link smallButton myProfileAndLogout2" (click)="logout();">
                                    <i aria-hidden="true" class="icon-icon13"></i><span>{{ 'LAYOUT.LOGOUT_LABEL' | translate }}</span>
                                </a>
                            </div>
                        </div>
                    </li>
                    <li class="headerMenu__item languageSwitcherPanel">
                        <language-switcher class="languagesBar__switcher" [(isActive)]="visibleLanguagePanel"></language-switcher>
                    </li>
                </ul>
            </div>
        </div>

    </header>

    <main>
        <div class="smallLogo visible-only-desktop">
            <a (click)="fullscreenModeOff(true)" alt="ElCarado logo" hide-delay="0"
               tooltip="{{ 'LAYOUT.GO_TO_START_PAGE_TOOLTIP' | translate }}" placement="right"></a>
        </div>
        <a class="hamburger visible-only-desktop" (click)="mainMenuToggle()" hide-delay="0"
           tooltip="{{ 'LAYOUT.TOGGLE_MENU_TOOLTIP' | translate }}" placement="right"></a>
        <div class="leftColumn visible-only-desktop">
            <div class="logo">
                <a [routerLink]="navigationService.constructUrl('START')" class="logo__element" alt="ElCarado logo"
                   hide-delay="0" tooltip="{{ 'LAYOUT.GO_TO_START_PAGE_TOOLTIP' | translate }}" placement="right"></a>
            </div>
            <perfect-scrollbar class="mainMenuWrapper">
                <ul class="mainMenu" *ngIf="!visibleMenu">
                    <ng-template ngFor let-menuItem [ngForOf]="mainMenu">
                        <li class="mainMenu__item" *ngIf="menuItem.isVisible"
                            [ngClass]="menuItem.itemClass ? menuItem.itemClass : ''">
                            <span class="mainMenu__item-separator"
                                  *ngIf="menuItem.separator && menuItem.isVisible"></span>
                            <ng-template [ngIf]="menuItem.translationKey">
                                <a class="mainMenu__item-anchor" routerLinkActive="mainMenu__item-anchor--active"
                                   routerLink="{{ menuItem.link }}" [queryParams]="menuItem.queryStringParameters"
                                   hide-delay="0" tooltip="{{ menuItem.translationKey | translate }}" placement="right"
                                   *ngIf="!menuItem.separator && menuItem.isVisible"
                                   (click)="goToTop()">
                                    <i class="{{ menuItem.iconName ? menuItem.iconName : 'fa-circle-o' }}"></i>
                                    <span>{{ menuItem.translationKey | translate }}</span>
                                </a>
                            </ng-template>
                            <ng-template [ngIf]="!menuItem.translationKey">
                                <a class="mainMenu__item-anchor" routerLinkActive="mainMenu__item-anchor--active"
                                   routerLink="{{ menuItem.link }}" [queryParams]="menuItem.queryStringParameters"
                                   hide-delay="0" tooltip="{{ menuItem.label }}" placement="right"
                                   *ngIf="!menuItem.separator && menuItem.isVisible"
                                   (click)="goToTop()">
                                    <img *ngIf="menuItem.imgSrc" class="mainMenu__custom-img"
                                         src="{{ menuItem.imgSrc }}" alt="{{ menuItem.label }}"/>
                                    <i *ngIf="!menuItem.imgSrc" class="icon-icon12"></i>
                                    <span>{{ menuItem.label }}</span>
                                </a>
                            </ng-template>
                        </li>
                    </ng-template>
                    <li class="mainMenu__item" *ngIf="isLogged()">
                        <span class="mainMenu__item-separator"></span>
                    </li>
                    <li class="mainMenu__item" *ngIf="isLogged()">
                        <a class="mainMenu__item-anchor" routerLinkActive="mainMenu__item-anchor--active"
                           (click)="logout();" tooltip="{{ 'LAYOUT.LOGOUT_LABEL' | translate }}" placement="right">
                            <i class="icon-icon13"></i>
                            <span>{{ 'LAYOUT.LOGOUT_LABEL' | translate }}</span>
                        </a>
                    </li>
                </ul>
                <ul class="mainMenu" *ngIf="visibleMenu">
                    <ng-template ngFor let-menuItemTwo [ngForOf]="mainMenu">
                        <li class="mainMenu__item" *ngIf="menuItemTwo.isVisible"
                            [ngClass]="menuItemTwo.itemClass ? menuItemTwo.itemClass : ''">
                            <span class="mainMenu__item-separator"
                                  *ngIf="menuItemTwo.separator && menuItemTwo.isVisible"></span>
                            <ng-template [ngIf]="menuItemTwo.translationKey">
                                <a class="mainMenu__item-anchor" routerLinkActive="mainMenu__item-anchor--active"
                                   routerLink="{{ menuItemTwo.link }}" [queryParams]="menuItemTwo.queryStringParameters"
                                   hide-delay="0" tooltip="{{ menuItemTwo.translationKey | translate }}"
                                   placement="right" *ngIf="!menuItemTwo.separator && menuItemTwo.isVisible"
                                   (click)="goToTop()">
                                    <i class="{{ menuItemTwo.iconName ? menuItemTwo.iconName : 'fa-circle-o' }}"></i>
                                    <span>{{ menuItemTwo.translationKey | translate }}</span>
                                </a>
                            </ng-template>
                            <ng-template [ngIf]="!menuItemTwo.translationKey">
                                <a class="mainMenu__item-anchor" routerLinkActive="mainMenu__item-anchor--active"
                                   routerLink="{{ menuItemTwo.link }}" [queryParams]="menuItemTwo.queryStringParameters"
                                   *ngIf="!menuItemTwo.separator && menuItemTwo.isVisible"
                                   (click)="goToTop()">
                                    <img *ngIf="menuItemTwo.imgSrc" class="mainMenu__custom-img"
                                         src="{{ menuItemTwo.imgSrc }}" alt="{{ menuItemTwo.label }}"/>
                                    <i *ngIf="!menuItemTwo.imgSrc" class="icon-icon12"></i>
                                    <span>{{ menuItemTwo.label }}</span>
                                </a>
                            </ng-template>
                        </li>
                    </ng-template>
                    <li class="mainMenu__item" *ngIf="isLogged()">
                        <span class="mainMenu__item-separator"></span>
                    </li>
                    <li class="mainMenu__item" *ngIf="isLogged()">
                        <a class="mainMenu__item-anchor" (click)="logout();">
                            <i class="icon-icon13"></i>
                            <span>{{ 'LAYOUT.LOGOUT_LABEL' | translate }}</span>
                        </a>
                    </li>
                </ul>
            </perfect-scrollbar>
        </div>
        <div class="rightColumn">
            <div *ngIf="isFrozen && isLogged()" class="alert alert--error">
              <p class="alert__header">Oppps….Your account is frozen!</p>
              <p class="alert__paragraph">Kindly complete verification or upload any requested documents by accessing the “My Account/Documents” section within your account.</p>
            </div>

            <router-outlet></router-outlet>

            <div class="footer container container--dark container--topPadding">
                <div class="columnContainer__column footerMenu">
                    <div class="footerMenu__item footerMenu__item--left">
                        <div class="crestList">
                            <a class="crestList__item">
                                <img src="assets/images/badges/18.png"
                                     alt="{{ 'LAYOUT.PLAY_WITH_RESPONSIBILITY' | translate }}">
                            </a>
                            <a class="crestList__item specialOne">
                                <img src="assets/images/badges/topcasino.png"
                                     alt="{{ 'LAYOUT.TOP_CASINO' | translate }}">
                            </a>
                            <a class="crestList__item">
                                <img src="assets/images/badges/secure.png" alt="{{ 'LAYOUT.CASH_OUT' | translate }}">
                            </a>
                            <a class="crestList__item">
                                <img src="assets/images/badges/eugaminglicense.png"
                                     alt="{{ 'LAYOUT.VERIFIED_LICENSE' | translate }}">
                            </a>
                        </div>
                    </div>

                    <div class="footerMenu__item footerMenu__item--center">
                        <div class="columns">
                            <div class="column withPadding">
                                <h4>{{ 'LAYOUT.GAMES_SUMMARY_HEADER' | translate }}</h4>
                                <ul>
                                    <li *ngFor="let footerMenuItem of footerMenu?.gamesSummary">
                                        <a routerLink={{footerMenuItem.link}}
                                           [queryParams]="footerMenuItem.queryStringParameters"
                                           (click)="goToTop()">
                                           <ng-template [ngIf]="footerMenuItem.translationKey">
                                            {{ footerMenuItem.translationKey | translate }}
                                           </ng-template>
                                           <ng-template [ngIf]="!footerMenuItem.translationKey">
                                            {{ footerMenuItem.label }}
                                           </ng-template>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                            <div class="column withPadding">
                                <h4>{{ 'LAYOUT.SERVICE_HEADER' | translate }}</h4>
                                <ul>
                                    <li *ngFor="let footerMenuItem of footerMenu?.service; let index = index">
                                        <a routerLink={{footerMenuItem.link}}
                                           [queryParams]="footerMenuItem.queryStringParameters"
                                           *ngIf="(index <= 2)"
                                           (click)="goToTop()">{{
                                            footerMenuItem.translationKey | translate }}</a>
                                    </li>
                                    <li class="mergedServiceList"
                                        *ngFor="let footerMenuItem of footerMenu?.service; let index = index">
                                        <a routerLink={{footerMenuItem.link}}
                                           [queryParams]="footerMenuItem.queryStringParameters"
                                           *ngIf="(index > 2)"
                                           (click)="goToTop()">{{
                                            footerMenuItem.translationKey | translate }}</a>
                                    </li>
                                </ul>
                            </div>
                            <div class="column withPadding slicedServiceList">
                                <h4>{{ 'LAYOUT.SAFETY_HEADER' | translate }}</h4>
                                <ul>
                                    <li *ngFor="let footerMenuItem of footerMenu?.service; let index = index">
                                        <a routerLink={{footerMenuItem.link}}
                                           [queryParams]="footerMenuItem.queryStringParameters"
                                           *ngIf="(index > 2)"
                                           (click)="goToTop()">{{
                                            footerMenuItem.translationKey | translate }}</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    <div class="footerMenu__item footerMenu__item--right">
                        <div class="columns">
                            <div class="column">
                                <h4>Elcarado</h4>
                                <ul>
                                    <li *ngFor="let footerMenuItem of footerMenu?.about; let index = index">
                                        <a routerLink={{footerMenuItem.link}}
                                           [queryParams]="footerMenuItem.queryStringParameters"
                                           (click)="goToTop()">{{
                                            footerMenuItem.translationKey | translate }}</a>
                                    </li>
                                    <li>
                                      <a href="https://www.rabbit-affiliates.com/" nofollow noopener target="_blank">RABBIT AFFILIATES</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="footer container container--withoutImage container--last">
                <div class="columnContainer__column footerCopyright">
                    <div class="footerCopyright__item footerCopyright__item--left">
                        <div class="footerLogos">
<!--                            <div class="footerLogos__item">-->
<!--                                <a href="https://authorisation.mga.org.mt/verification.aspx?lang=en&company=66fea165-070a-4cfa-bbb1-809c044196cc"-->
<!--                                   target="_blank">-->
<!--                                    <img src="assets/images/footer-color-logo1.png" alt="mga licensed">-->
<!--                                </a>-->
<!--                            </div>-->
                            <div class="footerLogos__item">
                                <!--a href="http://www.gaminglabs.com/extranetGLI/cmsAdmin/getsplash.asp?i=197"
                                   target="_blank">
                                    <img src="assets/images/footer-color-logo2.png" alt="Gaming Labs Certified">
                                </a-->
                                <ng-container *ngIf="!isLogged()">
                                    <a [routerLink]="navigationService.constructUrl('RESPONSIBLE_GAMBLING')">
                                        <img src="assets/images/badges/responsiblegaming.png" alt="Responsible Gaming">
                                    </a>
                                </ng-container>
                                <ng-container *ngIf="isLogged()">
                                    <a [routerLink]="navigationService.constructUrl('CASHIER')"
                                       [queryParams]="{tab: 'limits'}">
                                        <img src="assets/images/badges/responsiblegaming.png" alt="Responsible Gaming">
                                    </a>
                                </ng-container>
                            </div>
                        </div>
                    </div>

                    <div class="footerCopyright__item footerCopyright__item--center">
                        <div [innerHtml]="'LAYOUT.FOOTER_INFO.FIRST_CONTENT' | translate"></div>
                        <p class="applicationVersion">Application v. {{applicationVersion}}.</p>
                    </div>

                    <div class="footerCopyright__item footerCopyright__item--right"
                         [innerHtml]="'LAYOUT.FOOTER_INFO.SECOND_CONTENT' | translate"></div>
                </div>
            </div>
        </div>
    </main>
    <div id="gdpr-cookie-bar">
        <div class="gdprBlock">
            <button class="button" (click)="setGdprCookie()">OK</button>
            <p>{{ 'LAYOUT.FOOTER_INFO.GDPR_SENTENCE' | translate }} <a class="link" target="_blank"
                                                                       [routerLink]="navigationService.constructUrl('PRIVACY_POLICY')">{{
                'LAYOUT.FOOTER_INFO.GDPR_LINK' | translate }}</a>
            </p>
        </div>
    </div>
    <div id="conversion-tracking-pixel"></div>
</div>
