import { TranslateService } from '@ngx-translate/core';
import { Component } from '@angular/core';
import { NavigationService } from '../../services';

@Component({
  selector: 'restricted-area',
  templateUrl: './restrictedArea.component.html',
  styleUrls: ['./restrictedArea.component.scss']
})

export class RestrictedAreaComponent {

  restrictedAreaContent
  /**
	 * @constructor
	 */
  constructor(
    private translate: TranslateService,
    private navigationService: NavigationService
  ) {
    const url = `${this.navigationService.constructUrl('CASHIER')  }?tab=payOut`;
    this.translate.get('RESTRICTED_AREA.CONTENT', {
      cashierPayoutUrl: url
    }).subscribe((translatedText: string) => {
      this.restrictedAreaContent = translatedText;
    });
  }
}
