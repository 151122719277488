import { storageConstants } from '../../constants/storage.constants';
import { Router, ActivatedRoute } from '@angular/router';
import { CoreLayout } from '../../layouts/index';

import { Component, OnDestroy, Host } from '@angular/core';

import {
  FormGroup,
  FormBuilder,
  Validators,
} from '@angular/forms';

import {
  regExpValidationConstants,
  routingConstants,
  globalConstants,
} from '../../constants/index';

import {
  AuthenticationService,
  NavigationService,
  ComboNotificationService,
} from '../../services/index';
import { UserModel } from '../../models/index';
import { commonPagesHelper, formHelper } from '../../helpers/index';
import { TranslateService } from '@ngx-translate/core';
import { ComboNotificationType } from '../../enums/alert-type.enum';

@Component({
  selector: 'login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnDestroy {
  globalConstants = globalConstants
  layout

  userModel
  sending = false
  followUrl: boolean
  toFullVersion: boolean
  emailFromURL: string
  followUrlTimeout
  facebookLogin: boolean

  routingConstants = routingConstants

  validationMessages = {
    email: {
      required: 'GLOBAL.VALIDATION_MESSAGES.REQUIRED',
      pattern: 'GLOBAL.VALIDATION_MESSAGES.WRONG_FORMAT_EMAIL',
    },
    password: {
      required: 'GLOBAL.VALIDATION_MESSAGES.REQUIRED',
      minlength: 'GLOBAL.VALIDATION_MESSAGES.LEAST_SIX_CHARACTERS_LONG',
      maxlength:
        'GLOBAL.VALIDATION_MESSAGES.MORE_THAN_NINETYSIX_CHARACTERS_LONG',
    },
  }

  formErrors = {
    email: [],
    password: [],
  }

  form: FormGroup

  /**
   * Constructor.
   */
  constructor(
   parent: CoreLayout,
    private _translate: TranslateService,
    private comboNotificationService: ComboNotificationService,
    private router: Router,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private authenticationService: AuthenticationService,
    public navigationService: NavigationService,
  ) {
    this.layout = parent;
    this.emailFromURL = this.route.snapshot.params['email'];
    this.userModel = new UserModel();
    this.userModel.email = this.emailFromURL;

    if (this.layout.logged) {
      this.router.navigate([this.navigationService.constructUrl('START')]);
    }
  }

  /**
   * It's native Angular 'on initiation' method.
   * Fired after constructor.
   */
  ngOnInit(): void {
    this.followUrl = this.route.snapshot.queryParams['follow'] ? true : false;
    this.toFullVersion = this.route.snapshot.queryParams['toFullVersion']
      ? true
      : false;
    this.buildForm();
    this.facebookLogin = this.layout.facebookLogin;
  }

  /**
   * Builds form.
   */
  buildForm(): void {
    const formBuilderOptions = {
      email: [
        this.userModel.email,
        [
          Validators.required,
          Validators.pattern(regExpValidationConstants['EMAIL']),
        ],
      ],
      password: [
        this.userModel.password,
        [
          Validators.required,
          Validators.minLength(6),
          Validators.maxLength(96),
        ],
      ],
    };

    this.form = this.formBuilder.group(formBuilderOptions);
    this.form.valueChanges.subscribe(() => {
      formHelper.onValueChanged(
        this.form,
        this.formErrors,
        this.validationMessages
      );
    });
    formHelper.onValueChanged(
      this.form,
      this.formErrors,
      this.validationMessages
    ); // reset validation messages
  }

  /**
   * Submit preprocess.
   * @param {Object} data
   * @returns {object}
   */
  submitPreprocess(data) {
    return data;
  }

  /**
   * On submit handler
   * @handler
   */
  onSubmit() {
    const parameters = this.submitPreprocess(this.form.value);
    this.login(parameters);
  }

  /**
   * Login.
   * @param {Object} event
   */
  login(parametersMap: object) {
    if (!this.form.valid) return;

    commonPagesHelper.loader('show');

    this.sending = true;

    this.authenticationService
      .login(parametersMap['email'], parametersMap['password'])
      .subscribe(
        () => {
          this.sending = false;

          // this.layout['logged'] = this.layout.isLogged();
          // this.layout['loggedUserData'] = this.layout.getLoggedUserData();

          // We need to replace query string to redirect user for a full version of the game after login
          if (this.followUrl && this.toFullVersion) {
            const previousURL = localStorage.getItem(
              storageConstants['PREVIOUS_URL']
            );
            localStorage.setItem(
              storageConstants['PREVIOUS_URL'],
              previousURL.replace('type=demo', 'type=full')
            );
          }

          // Redirect to previous page
          if (
            this.followUrl ||
            localStorage.getItem(storageConstants['PREVIOUS_URL']) !== '/'
          ) {
            commonPagesHelper.loader('show');

            this.followUrlTimeout = setTimeout(() => {
              this.navigationService.goToSavedRoute();
              
              return;
            }, 2000);
          } else {
            this.router.navigateByUrl(commonPagesHelper.constructUrl('START'));
          }
          commonPagesHelper.loader('hide');
        },
        error => {
          commonPagesHelper.loader('hide');
          this.sending = false;
          // * Show SnackBar Notification
          this.comboNotificationService.notification(`${error.error_description}.`, ComboNotificationType.Error);
        }
      );
  }

  /**
   * It's native Angular 'on destroy' method.
   * Destroys component.
   */
  ngOnDestroy() {
    clearTimeout(this.followUrlTimeout);
  }
}
