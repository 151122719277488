export const formHelper = {

  /**
	 * On value changed handler.
	 * @handler
	 * @param specificForm {Object}
	 * @param formErrors {Object}
	 * @param formValidationMessages {Object}
	 */
  onValueChanged(specificForm, formErrors, formValidationMessages) {
    if (!specificForm) {
      return;
    }

    const form = specificForm;
    for (const field in formErrors) {
      formErrors[field] = [];
      const control = form.get(field);

      if (control && control.dirty && !control.valid) {
        const messages = formValidationMessages[field];
        for (const key in control.errors) {
          formErrors[field].push(messages[key]);
        }
      }
    }
  }
};