<div class="sliderBlock">
    <slider *ngIf="slider" class="main-slider" [options]="mainSliderOptions">
        <ng-container *ngFor="let slide of slider; let i = index">
            <div *slide-item [ngClass]="'slide-item-' + i">
                <div class="button-wrapper button-wrapper--customText">
                    <a href="{{ slide.targetUrl }}" class="visible-only-mobile button-wrapper-link"
                        *ngIf="slide.button && null === slide.game" style="display: none;">
                        <div class="button-wrapper-inside visible-only-mobile" style="display: none;">
                        <p class="limited " *ngIf="slide.button && null === slide.game" style="display: none;">{{ slide.subTitle }}</p>
                            <p class="cosmic-madness" *ngIf="slide.button && null === slide.game" style="display: none;">{{ slide.title }}</p>
                            <p class="deposit" *ngIf="slide.button && null === slide.game" [innerHtml]="slide.text | safeHtml" style="display: none;"></p>
                        </div>
                    </a>
                    <a (click)="gameService.goToGameDetailsWithPlay(slide.game, true)"
                        class=" button-wrapper-link visible-only-mobile"
                        *ngIf="slide.button && null !== slide.game && layout.isLogged()" style="display: none;">
                        <div class="button-wrapper-inside visible-only-mobile">
                        <p class="limited" *ngIf="slide.button && null !== slide.game && layout.isLogged()" style="display: none;">{{ slide.subTitle }}</p>
                            <p class="cosmic-madness" *ngIf="slide.button && null !== slide.game && layout.isLogged()" style="display: none;">{{ slide.title }}</p>
                            <p class="deposit" *ngIf="slide.button && null !== slide.game && layout.isLogged()" [innerHtml]="slide.text | safeHtml" style="display: none;"></p>
                        </div>
                    </a>
                    <a (click)="gameService.goToGameDetailsWithDemo(slide.game, true)"
                        class="visible-only-mobile  button-wrapper-link"
                        *ngIf="slide.button && null !== slide.game && !layout.isLogged()" style="display: none;">
                        <div class="button-wrapper-inside visible-only-mobile">
                        <p class="limited" *ngIf="slide.button && null !== slide.game && !layout.isLogged()" style="display: none;">{{ slide.subTitle }}</p>
                            <p class="cosmic-madness" *ngIf="slide.button && null !== slide.game && !layout.isLogged()" style="display: none;">{{ slide.title }}</p>
                            <p class="deposit" *ngIf="slide.button && null !== slide.game && !layout.isLogged()" [innerHtml]="slide.text | safeHtml" style="display: none;"></p>
                        </div>
                    </a>
                    <div class="button-wrapper-inside visible-only-desktop" style="display: none;">
                        <p class="limited visible-only-desktop">{{ slide.subTitle }}</p>
                        <p class="cosmic-madness visible-only-desktop">{{ slide.title }}</p>
                        <div class="deposit visible-only-desktop" [innerHtml]="slide.text | safeHtml"></div>
                        <a href="{{ slide.targetUrl }}" class="button button--wider button--slider visible-only-desktop"
                            *ngIf="slide.button && null === slide.game" (click)="navigateTo(slide.targetUrl)">{{ slide.buttonTitle }}</a>
                        <a href="{{ slide.targetUrl }}" (click)="gameService.goToGameDetailsWithPlay(slide.game, true)"
                            class="button button--wider button--slider visible-only-desktop"
                            *ngIf="slide.button && null !== slide.game && layout.isLogged()">{{ slide.buttonTitle }}</a>
                        <a href="{{ slide.targetUrl }}" (click)="gameService.goToGameDetailsWithDemo(slide.game, true)"
                            class="button button--wider button--slider visible-only-desktop"
                            *ngIf="slide.button && null !== slide.game && !layout.isLogged()">{{ slide.buttonTitle }}</a>
                    </div>
                </div>
                <img class="visible-only-desktop" src="{{ slide.backgroundImage }}" alt="{{ slide.title }}" width="100%">
                <img class="visible-only-mobile" src="{{ slide.backgroundImageMobile }}" alt="{{ slide.title }}" width="100%">

            </div>
        </ng-container>

        <slider-paginator class="slider-paginator"></slider-paginator>
    </slider>
</div>

<div class="container container--first">

    <div class="licenseBar" *ngIf="!layout.logged">
        <div class="licenseBarContainer">
            <div class="licenseBar__element licenseBar__element-leftAlign">
                <!-- <img class="licenseBar__element-why visible-only-desktop" src="assets/images/why.png" alt="{{ 'START.WHY_ELCARADO_ALT' | translate }}"> -->
                <div class="licenseBar__element-trustlyBackground">
                    <span class="licenseBar__element-trustly" [routerLink]="navigationService.constructUrl('PAYMENT_PROVIDERS')" [queryParams]="{type:'trustly'}" (click)="goToTop()"></span>
                    <span class="licenseBar__element-ssl"></span>
<!--                    <span class="licenseBar__element-mga"></span>-->
                    <span class="licenseBar__element-fastPayments"></span>
                </div>
            </div>
            <div class="licenseBar__element licenseBar__element-crests">
                <div class="licenseBar__element-row payments">
                  <slider *ngIf="licenseBarVendorLogos" class="carousel paymentsSlider" [optionsResolver]="paymentsSliderOptionsResolver">
                        <ng-container *ngFor="let logo of licenseBarPaymentLogos">
                            <div *slide-item>
                                <div class="licenseBar__element-image">
                                    <a [routerLink]="navigationService.constructUrl(logo.pageUrl)"
                                        [queryParams]="logo.pageUrlData"
                                        (click)="goToTop()">
                                        <img [src]="logo.img" [alt]="logo.pageUrlData.type" [class]="logo.pageUrlData.type">
                                    </a>
                                </div>
                            </div>
                        </ng-container>
                    </slider>
                </div>
                <div class="licenseBar__element-row vendors">
                    <slider *ngIf="licenseBarVendorLogos" class="carousel paymentsSlider" [optionsResolver]="vendorsSliderOptionsResolver">
                        <ng-container *ngFor="let logo of licenseBarVendorLogos">
                            <div *slide-item>
                                <div class="licenseBar__element-image" >
                                    <a [routerLink]="navigationService.constructUrl(logo.pageUrl, logo.pageUrlData)" (click)="goToTop()">
                                        <img [src]="logo.img" [alt]="logo.pageUrlData.vendor" [class]="logo.pageUrlData.vendor">
                                    </a>
                                </div>
                            </div>
                        </ng-container>
                    </slider>
                </div>

            </div>
            <!-- <div class="licenseBar__element licenseBar__element-rightAlign visible-only-desktop">
                <p [innerHtml]="'START.LICENSE_BAR_OFFICIAL_CASINO' | translate"></p>
            </div> -->
        </div>
    </div>


    <ng-template #loading>Loading User Data...</ng-template>

    <section>
        <h2 class="bottomDecorator"><span class="decorator"><i class="fa fa-user-circle-o"
                                                               aria-hidden="true"></i></span> {{
            'START.GAMES_FOR_YOU_HEADER' | translate }}</h2>
        <div class="gameList">
            <div class="gameList__item gameList__item--loading" *ngIf="gameList.forYou.loading"></div>
            <div class="gameList__item gameList__item--no-data"
                 *ngIf="!gameList.forYou.loading && gameList.forYou.data.length == 0">{{ 'START.NO_DATA' | translate }}
            </div>
            <div id="sliderGamesForYou" class="splide">
                <div class="splide__track">
                  <ul class="splide__list">
                     <li class="splide__slide"  *ngFor="let game of gameList.forYou.data">
                        <game-item [game]="game"></game-item>
                    </li>
                  </ul>
                </div>
             </div>
        </div>
    </section>
</div>

<div class="container">
    <h2 class="bottomDecorator"><span class="decorator"><i class="fa fa-paper-plane" aria-hidden="true"></i></span> {{
        'START.TRENDING_GAMES_HEADER' | translate }}</h2>

    <div class="gameList">
        <div class="gameList__item gameList__item--loading" *ngIf="gameList.trending.loading"></div>
        <div class="gameList__item gameList__item--no-data"
             *ngIf="!gameList.trending.loading && gameList.trending.data.length == 0">{{ 'START.NO_DATA' | translate }}
        </div>
        <div id="sliderTrending" class="splide">
            <div class="splide__track">
              <ul class="splide__list">
                 <li class="splide__slide"  *ngFor="let game of gameList.trending.data">
                    <game-item [game]="game"></game-item>
                </li>
              </ul>
            </div>
         </div>

    </div>
</div>

<div class="container">
    <h2 class="bottomDecorator"><span class="decorator"><i class="fa fa-bell" aria-hidden="true"></i></span> {{
        'START.NEW_GAMES_HEADER' | translate }}</h2>

    <div class="gameList">
        <div class="gameList__item gameList__item--loading" *ngIf="gameList.new.loading"></div>
        <div class="gameList__item gameList__item--no-data"
             *ngIf="!gameList.new.loading && gameList.new.data.length == 0">{{ 'START.NO_DATA' | translate }}
        </div>
        <div id="sliderNew" class="splide">
            <div class="splide__track">
              <ul class="splide__list">
                 <li class="splide__slide"  *ngFor="let game of gameList.new.data">
                    <game-item [game]="game"></game-item>
                </li>
              </ul>
            </div>
         </div>
    </div>
</div>
<div class="container">
    <section *ngIf="gameList && gameList.jackpots && gameList.jackpots.data && gameList.jackpots.data.length">
        <h2 class="bottomDecorator"><span class="decorator"><i class="fa fa-user-circle-o"
                                                               aria-hidden="true"></i></span> {{ 'START.JACKPOTS_HEADER'
            | translate }}</h2>
        <!-- <div class="greyBox jackpots" #jackpotsContainer>
          <div class="jackpots__element jackpots__element--loading" *ngIf="gameList.jackpots.loading"></div>
          <div class="jackpots__element jackpots__element--no-data" *ngIf="!gameList.jackpots.loading && gameList.jackpots.data.length == 0">No data to display!</div>
          <div class="jackpots__element" *ngFor="let jackpot of gameList.jackpots.data">
            <div class="jackpots__element-column jackpots__element-column--left">
              <a [routerLink]="gameService.goToGameDetails(jackpot)" [queryParams]="{type: 'demo'}" *ngIf="!layout.isLogged()">
                <img src="{{ imagesPath }}{{ jackpot.gameThumbImageUrl }}" alt="" class="jackpots__element-columnImage jackpots__element-column">
              </a>
              <a [routerLink]="gameService.goToGameDetails(jackpot)" *ngIf="layout.isLogged()">
                <img src="{{ imagesPath }}{{ jackpot.gameThumbImageUrl }}" alt="" class="jackpots__element-columnImage jackpots__element-column">
              </a>
            </div>
            <div class="jackpots__element-column jackpots__element-column--right">
              <p class="gameName">{{ jackpot.gameName }}</p>
              <p class="amount">{{ jackpot.jackpotAmount | currency:'EUR':'symbol':'1.2-2' }}</p>
              <a class="button" (click)="layout.isLogged() ? gameService.goToGameDetailsWithPlay(jackpot, true) : layout.toggleLoginBar()">{{ 'START.PLAY_NOW_BUTTON' | translate }} <i class="fa fa-arrow-right" aria-hidden="true"></i></a>
            </div>
          </div>
        </div> -->
        <div class="gameList gameList--jackpots">
            <div class="gameList__item gameList__item--loading" *ngIf="gameList.jackpots.loading"></div>
            <div class="gameList__item gameList__item--no-data"
                 *ngIf="!gameList.jackpots.loading && gameList.jackpots.data.length == 0">{{ 'START.NO_DATA' | translate
                }}
            </div>
            <div id="sliderJackpot" class="splide">
                <div class="splide__track">
                  <ul class="splide__list">
                     <li class="splide__slide"  *ngFor="let game of gameList.jackpots.data">
                        <game-item [game]="game" [gameType]="'jackpot'"></game-item>
                    </li>
                  </ul>
                </div>
             </div>
        </div>
    </section>
</div>

<div class="container">
    <section class="winnersContainer">
        <h3 class="bottomDecorator">{{ 'START.WINNERS_HEADER' | translate }}</h3>
        <div class="winnersContainer--loading" *ngIf="gameList.winners.loading"></div>
        <div class="winnersContainer--no-data" *ngIf="!gameList.winners.loading && gameList.winners.data.length == 0">No
            data to display!
        </div>
        <slider *ngIf="!gameList.winners.loading && gameList.winners.data.length > 0" class="carousel winnersSlider" [optionsResolver]="winnerSliderOptionsResolver">
            <ng-container *ngFor="let winner of gameList.winners.data">
                <div *slide-item class="item">
                    <a (click)="gameService.goToGameDetailsWithPlay(winner.game, true)"
                    *ngIf="layout.isLogged() && winner.game.isPlayable">
                        <img src="{{ imagesPath }}{{ replaceGameUrl(winner.game.gameThumbImageUrl) }}" alt="{{ winner.game.name }}"
                            class="slide__image">
                    </a>
                    <a (click)="gameService.goToGameDetailsWithDemo(winner.game, true)"
                    *ngIf="!layout.isLogged() && winner.game.isPlayable">
                        <img src="{{ imagesPath }}{{ replaceGameUrl(winner.game.gameThumbImageUrl) }}" alt="{{ winner.game.name }}"
                            class="slide__image">
                    </a>
                    <img src="{{ imagesPath }}{{ replaceGameUrl(winner.game.gameThumbImageUrl) }}" alt="{{ winner.game.name }}"
                        class="slide__image" *ngIf="!winner.game.isPlayable">
                    <div class="winner-info">
                        <p class="userName">{{ winner.winnerName }}</p>
                        <p class="gameName">{{ winner.game.name }}</p>
                        <p class="dateTime">{{ winner.dateTime.date | date: 'yyyy-MM-dd, HH:mm' }}</p>
                        <p class="amount">{{ 'START.WINNER_WIN' | translate }}: {{ winner.win | currency_format:winner.currency }}</p>
                        <p class="bet">{{ 'START.WINNER_BET' | translate }}: {{ winner.bet | currency_format:winner.currency }}</p>
                        <p class="winFactor">{{ winner.winFactor }}X</p>
                    </div>
<!--                    <a class="button"-->
<!--                    (click)="layout.isLogged() ? gameService.goToGameDetailsWithPlay(winner.game, true) : layout.toggleLoginBar()"-->
<!--                    *ngIf="winner.game.isPlayable">{{ 'START.PLAY_NOW_BUTTON' | translate }} <i class="fa fa-arrow-right"-->
<!--                                                                                                aria-hidden="true"></i></a>-->
                </div>
            </ng-container>
        </slider>
    </section>
</div>

<div class="container">
    <div class="columnContainer__column logosContainer">
        <div class="greyBox">
            <slider class="carousel logosSlider" [optionsResolver]="logoSliderOptionsResolver">
                <ng-container *ngFor="let logo of logos">
                    <div *slide-item class="item logosSlider__item">
                        <a *ngIf="(logo.url && logo.url !== '')" href="{{ logo.url }}" target="_blank">
                            <img src="{{ logo.img }}" alt="{{ logo.pageUrlData.type }}" width="100%">
                        </a>
                        <a *ngIf="(logo.pageUrl && logo.pageUrl !== '')"
                            href="{{navigationService.constructUrlWithQueryParams(logo.pageUrl, logo.pageUrlData)}}">
                            <img src="{{ logo.img }}" alt="{{ logo.pageUrlData.type }}" width="100%">
                        </a>
                    </div>
                </ng-container>
            </slider>
        </div>
    </div>
</div>

<div class="container">
    <div class="columnContainer">
        <section>
            <h1 class="bottomDecorator"><span class="decorator"><i class="fa fa-diamond" aria-hidden="true"></i></span>
                {{ 'START.WELCOME_AT_CASINO_HEADER' | translate }}</h1>

            <p><strong>{{ 'START.WELCOME_AT_CASINO_TEXT1' | translate }}</strong></p>

            <span [innerHtml]="'START.WELCOME_AT_CASINO_TEXT2' | translate"></span>

        </section>
        <!-- <div class="columnContainer__column columnContainer__column--right columnContainer__column--qualityPromise">
            <section>
                <h2 class="bottomDecorator"><span class="decorator"><i class="fa fa-diamond" aria-hidden="true"></i></span> {{ 'START.QUALITY_PROMISE_HEADER' | translate }}</h2>

                <div class="greyBox qualityPromise">
                    <div class="qualityPromise__column qualityPromise__column--left">
                        <img class="VerifiedLicense" src="assets/images/crest3-big.png" alt="{{ 'START.VERIFIED_LICENSE' | translate }}">
                    </div>
                    <div class="qualityPromise__column qualityPromise__column--right">
                        <p [innerHtml]="'START.SECURE_AND_RESPONSIBLE_TEXT' | translate"></p>
                    </div>
                </div>
            </section>
        </div> -->


        <!-- TO ZOSTAWIC !!! -->
        <!-- <div class="columnContainer__column">
            <h2 class="bottomDecorator"><span class="decorator"><i class="fa fa-diamond" aria-hidden="true"></i></span> {{ 'START.SEO_HEADER' | translate }}</h2>
                <p><strong>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin nibh augue, suscipit a, scelerisque sed, lacinia in, mi. Cras vel lorem. Etiam pellentesque aliquet tellus. Phasellus pharetra nulla ac diam. Quisque semper justo at risus. Donec venenatis, turpis vel hendrerit interdum, dui ligula ultricies purus, sed posuere libero dui id orci. Nam congue, pede vitae dapibus aliquet, elit magna vulputate arcu, vel tempus metus leo non est. Etiam sit amet lectus quis est congue mollis. Phasellus congue lacus eget neque.</strong></p>

                <p>Phasellus ornare, ante vitae consectetuer consequat, purus sapien ultricies dolor, et mollis pede metus eget nisi. Praesent sodales velit quis augue. Cras suscipit, urna at aliquam rhoncus, urna quam viverra nisi, in interdum massa nibh nec erat. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin nibh augue, suscipit a, scelerisque sed, lacinia in, mi. Cras vel lorem. Etiam pellentesque aliquet tellus. Phasellus pharetra nulla ac diam. Quisque semper justo at risus. Donec venenatis, turpis vel hendrerit interdum, dui ligula ultricies purus, sed posuere libero dui id orci. Nam congue, pede vitae dapibus aliquet, elit magna vulputate arcu, vel tempus metus leo non est. Etiam sit amet lectus quis est congue mollis. Phasellus congue lacus eget neque. Phasellus ornare, ante vitae consectetuer consequat, purus sapien ultricies dolor, et mollis pede metus eget nisi. Praesent sodales velit quis augue. Cras suscipit, urna at aliquam rhoncus, urna quam viverra nisi, in interdum massa nibh nec erat.</p>

                <p>Donec venenatis, turpis vel hendrerit interdum, dui ligula ultricies purus, sed posuere libero dui id orci. Nam congue, pede vitae dapibus aliquet, elit magna vulputate arcu, vel tempus metus leo non est. Etiam sit amet lectus quis est congue mollis. Phasellus congue lacus eget neque. Phasellus ornare, ante vitae consectetuer consequat, purus sapien ultricies dolor, et mollis pede metus eget nisi. Praesent sodales velit quis augue. Cras suscipit, urna at aliquam rhoncus, urna quam viverra nisi, in interdum.</p>
        </div> -->
        <!-- TO ZOSTAWIC !!! -->
    </div>
</div>

<!-- TO ZOSTAWIC !!! -->
<!-- <div class="container container--dark">
	<div class="columnContainer">
		<div class="columnContainer__column">
			<h2 class="bottomDecorator"><span class="decorator"><i class="fa fa-diamond" aria-hidden="true"></i></span> {{ 'START.SEO_HEADER' | translate }}</h2>
				<p><strong>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin nibh augue, suscipit a, scelerisque sed, lacinia in, mi. Cras vel lorem. Etiam pellentesque aliquet tellus. Phasellus pharetra nulla ac diam. Quisque semper justo at risus. Donec venenatis, turpis vel hendrerit interdum, dui ligula ultricies purus, sed posuere libero dui id orci. Nam congue, pede vitae dapibus aliquet, elit magna vulputate arcu, vel tempus metus leo non est. Etiam sit amet lectus quis est congue mollis. Phasellus congue lacus eget neque.</strong></p>

				<p>Phasellus ornare, ante vitae consectetuer consequat, purus sapien ultricies dolor, et mollis pede metus eget nisi. Praesent sodales velit quis augue. Cras suscipit, urna at aliquam rhoncus, urna quam viverra nisi, in interdum massa nibh nec erat. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin nibh augue, suscipit a, scelerisque sed, lacinia in, mi. Cras vel lorem. Etiam pellentesque aliquet tellus. Phasellus pharetra nulla ac diam. Quisque semper justo at risus. Donec venenatis, turpis vel hendrerit interdum, dui ligula ultricies purus, sed posuere libero dui id orci. Nam congue, pede vitae dapibus aliquet, elit magna vulputate arcu, vel tempus metus leo non est. Etiam sit amet lectus quis est congue mollis. Phasellus congue lacus eget neque. Phasellus ornare, ante vitae consectetuer consequat, purus sapien ultricies dolor, et mollis pede metus eget nisi. Praesent sodales velit quis augue. Cras suscipit, urna at aliquam rhoncus, urna quam viverra nisi, in interdum massa nibh nec erat.</p>

				<p>Donec venenatis, turpis vel hendrerit interdum, dui ligula ultricies purus, sed posuere libero dui id orci. Nam congue, pede vitae dapibus aliquet, elit magna vulputate arcu, vel tempus metus leo non est. Etiam sit amet lectus quis est congue mollis. Phasellus congue lacus eget neque. Phasellus ornare, ante vitae consectetuer consequat, purus sapien ultricies dolor, et mollis pede metus eget nisi. Praesent sodales velit quis augue. Cras suscipit, urna at aliquam rhoncus, urna quam viverra nisi, in interdum.</p>
		</div>
	</div>
</div>

<div class="container">
	<div class="columnContainer">
		<div class="columnContainer__column">
			<h2 class="bottomDecorator"><span class="decorator"><i class="fa fa-diamond" aria-hidden="true"></i></span> {{ 'START.SEO_HEADER' | translate }}</h2>
				<p><strong>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin nibh augue, suscipit a, scelerisque sed, lacinia in, mi. Cras vel lorem. Etiam pellentesque aliquet tellus. Phasellus pharetra nulla ac diam. Quisque semper justo at risus. Donec venenatis, turpis vel hendrerit interdum, dui ligula ultricies purus, sed posuere libero dui id orci. Nam congue, pede vitae dapibus aliquet, elit magna vulputate arcu, vel tempus metus leo non est. Etiam sit amet lectus quis est congue mollis. Phasellus congue lacus eget neque.</strong></p>

				<p>Phasellus ornare, ante vitae consectetuer consequat, purus sapien ultricies dolor, et mollis pede metus eget nisi. Praesent sodales velit quis augue. Cras suscipit, urna at aliquam rhoncus, urna quam viverra nisi, in interdum massa nibh nec erat. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin nibh augue, suscipit a, scelerisque sed, lacinia in, mi. Cras vel lorem. Etiam pellentesque aliquet tellus. Phasellus pharetra nulla ac diam. Quisque semper justo at risus. Donec venenatis, turpis vel hendrerit interdum, dui ligula ultricies purus, sed posuere libero dui id orci. Nam congue, pede vitae dapibus aliquet, elit magna vulputate arcu, vel tempus metus leo non est. Etiam sit amet lectus quis est congue mollis. Phasellus congue lacus eget neque. Phasellus ornare, ante vitae consectetuer consequat, purus sapien ultricies dolor, et mollis pede metus eget nisi. Praesent sodales velit quis augue. Cras suscipit, urna at aliquam rhoncus, urna quam viverra nisi, in interdum massa nibh nec erat.</p>

				<p>Donec venenatis, turpis vel hendrerit interdum, dui ligula ultricies purus, sed posuere libero dui id orci. Nam congue, pede vitae dapibus aliquet, elit magna vulputate arcu, vel tempus metus leo non est. Etiam sit amet lectus quis est congue mollis. Phasellus congue lacus eget neque. Phasellus ornare, ante vitae consectetuer consequat, purus sapien ultricies dolor, et mollis pede metus eget nisi. Praesent sodales velit quis augue. Cras suscipit, urna at aliquam rhoncus, urna quam viverra nisi, in interdum.</p>
		</div>
	</div>
</div> -->
<!-- TO ZOSTAWIC !!! -->
