import { globalConstants } from './global.constants';
import { envConstants } from './env.constants';

function getEnvConstant(key, fallback) {
  if (envConstants[key]) {
    return envConstants[key];
  } else {
    return fallback;
  }
}

const API_URL       = getEnvConstant('API_URL', globalConstants['API_URL']);
const APPLICATION_NAME       = getEnvConstant('APPLICATION_NAME', globalConstants['APPLICATION_NAME']);
const CLIENT_ID     = getEnvConstant('CLIENT_ID', globalConstants['AUTENTICATION']['CLIENT_ID']);
const CLIENT_SECRET = getEnvConstant('CLIENT_SECRET', globalConstants['AUTENTICATION']['CLIENT_SECRET']);

export const requestConstants = {
  API_URL: API_URL,
  APPLICATION_NAME: APPLICATION_NAME,

  /* AUTHENTICATION */

  AUTHENTICATE: {
    URL: '/authenticate',
    METHOD: ''
  },

  REFRESH_TOKEN: {
    URL: `${API_URL  }/oauth/v2/\${locale}/token?grant_type=refresh_token&client_id=\${clientID}&client_secret=\${clientSecret}&refresh_token=\${refreshToken}`,
    METHOD: 'GET',
    GRANT_TYPE: globalConstants['REFRESH_TOKEN']['GRANT_TYPE'],
    REFRESH_TOKEN: '...',
    CLIENT_ID: CLIENT_ID,
    CLIENT_SECRET: CLIENT_SECRET
  },

  OAUTH_AUTHENTICATE: {
    URL: `${API_URL  }/oauth/v2/\${locale}/token`,
    METHOD: 'GET',
    GRANT_TYPE: globalConstants['AUTENTICATION']['GRANT_TYPE'],
    CLIENT_ID: CLIENT_ID,
    CLIENT_SECRET: CLIENT_SECRET
  },

  OAUTH_AUTHENTICATE_AS_USER: {
    URL: `${API_URL  }/oauth/v2/login-as-user/\${token}`,
    METHOD: 'GET',
    CLIENT_ID: CLIENT_ID,
    CLIENT_SECRET: CLIENT_SECRET
  },

  FACEBOOK_AUTHENTICATE: {
    URL: `${API_URL  }/connect/check-facebook`,
    METHOD: 'POST'
  },

  LOGOUT: {
    URL: `${API_URL  }/api-user/\${locale}/logout`,
    METHOD: 'GET'
  },

  /* LANGUAGE */

  SET_CAGE_LOCALE: {
    URL: '/api-user/${deviceType}/${locale}/set-cage-locale',
    METHOD: 'POST'
  },

  /* USERS */

  USER_CHECK: {
    URL: '/api-user/users/request-password',
    METHOD: 'PATCH'
  },

  USER_CHANGE_PASSWORD: {
    URL: '/api-user/${locale}/profile/password/update',
    METHOD: 'POST'
  },

  USER_CHANGE_PASSWORD_AS_GUEST: {
    URL: '/api-user/users/reset-password/${temporaryToken}',
    METHOD: 'PATCH'
  },

  USER_CONFIRM_PASSWORD_CHANGE: {
    URL: '/api-user/users/reset-password/${temporaryToken}',
    METHOD: 'PATCH'
  },

  USER_CREATE: {
    URL: '/api/${locale}/user/register',
    METHOD: 'POST'
  },

  USER_CREATE_VERIFICATION: {
    URL: '/api/${locale}/user/registration/confirm/${temporaryToken}',
    METHOD: 'GET'
  },

  USER_CREATE_VIA_FACEBOOK: {
    URL: `${API_URL  }/connect/check-facebook`,
    METHOD: 'POST'
  },

  USER_CONFIRM_EMAILE_CHANGE: {
    URL: '/api-user/users/confirm-email-change/${temporaryToken}',
    METHOD: 'PATCH'
  },

  USER_UPDATE: {
    URL: `${API_URL  }/api-user/\${deviceType}/\${locale}/profile`,
    METHOD: 'POST'
  },

  USER_DETAILS: {
    URL: '/api-user/${deviceType}/${locale}/profile/with/glv',
    METHOD: 'GET'
  },

  USER_BALANCE: {
    URL: '/api-user/${deviceType}/${locale}/balance',
    METHOD: 'GET'
  },

  USER_PASSWORD_RESET: {
    URL: '/api/${locale}/password-reset/request',
    METHOD: 'POST'
  },

  USER_PASSWORD_RESET_VERIFICATION: {
    URL: '/api/${locale}/password-reset/${temporaryToken}',
    METHOD: 'POST'
  },

  USER_CONFIRM_EMAIL_CHANGE: {
    URL: '/api/${locale}/user/confirm-email-change/${temporaryToken}',
    METHOD: 'GET'
  },

  USER_ACCOUNT_ACTIVATION: {
    URL: '/api/${locale}/resend-activation-email',
    METHOD: 'POST'
  },

  USER_SESSION_EXTEND: {
    URL: '/api-user/${locale}/session-extend',
    METHOD: 'GET'
  },

  USER_SESSION_CHECK: {
    URL: '/api-user/${locale}/check-user-state',
    METHOD: 'GET'
  },

  /* BONUS */

  BONUS_LIST: {
    URL: '/api/${deviceType}/${locale}/bonus/list',
    METHOD: 'GET'
  },

  USER_BONUS_LIST: {
    URL: '/api-user/${deviceType}/${locale}/bonus-offer/list',
    METHOD: 'GET'
  },

  BONUS_ACTIVATE: {
    URL: '/api-user/${deviceType}/${locale}/bonus-offer/activate/${bonusId}',
    METHOD: 'GET'
  },

  /* GAMES */

  AUTOSUGGEST_GAME_LIST: {
    URL: '/api/${deviceType}/${locale}/game/autosuggest',
    METHOD: 'GET'
  },

  RANKING_LIST: { // * new!
    URL: '/api/${deviceType}/${locale}/ranking/list',
    METHOD: 'GET'
  },

  RANKING_LIST_BY_TYPE: { // * new!
    URL: '/api/${deviceType}/${locale}/ranking/${type}',
    METHOD: 'GET'
  },

  GAME_LIST: {
    URL: '/api/${deviceType}/${locale}/games/list/${offset}/${limit}',
    METHOD: 'GET'
  },

  GAME_LIST_WITH_FREE_SPINS: {
    URL: '/api/${deviceType}/${locale}/games/list/with/free/spins/${offset}/${limit}',
    METHOD: 'GET'
  },

  GAME_LIST_WITHOUT_FREE_SPINS: {
    URL: '/api/${deviceType}/${locale}/games/list/without/free/spins/${offset}/${limit}',
    METHOD: 'GET'
  },

  GAME_LIST_LAST_PLAYED: {
    URL: '/api/${deviceType}/${locale}/games/list/last/played/${offset}/${limit}',
    METHOD: 'GET'
  },

  REALITY_CHECKER: {
    URL: '/api-user/${deviceType}/${locale}/reality-check/${urlSlug}',
    METHOD: 'GET'
  },

  CONFIRM_REALITY_CHECKER: {
    URL: '/api-user/${deviceType}/${locale}/confirm-reality-check/${status}',
    METHOD: 'GET'
  },

  GAME_DETAILS: {
    URL: '/api/${deviceType}/${locale}/game/details/${urlSlug}',
    METHOD: 'GET'
  },

  FULL_GAME_DETAILS: {
    URL: '/api/${deviceType}/${locale}/game/play-url/${urlSlug}',
    METHOD: 'GET'
  },

  DEMO_GAME_DETAILS: {
    URL: '/api/${deviceType}/${locale}/game/demo-url/${urlSlug}',
    METHOD: 'GET'
  },

  SEND_GAME_RATING: {
    URL: '/api/${deviceType}/${locale}/game/${urlSlug}/rate',
    METHOD: 'POST'
  },

  SEND_GAME_COMMENT: {
    URL: '/api/${deviceType}/${locale}/game/${urlSlug}/comments/add',
    METHOD: 'POST'
  },

  WINNER_LIST: {
    URL: '/api/${deviceType}/${locale}/game-winners/${limit}',
    METHOD: 'GET'
  },

  JACKPOT_LIST: {
    URL: '/api/${deviceType}/${locale}/jackpots/${limit}',
    METHOD: 'GET'
  },

  VENDOR_LIST: {
    URL: '/api/${deviceType}/${locale}/vendors',
    METHOD: 'GET'
  },

  /* FAVOURITE GAMES */

  ADD_TO_FAVOURITE_LIST: {
    URL: '/api/${deviceType}/${locale}/game/${urlSlug}/favourite/add',
    METHOD: 'GET'
  },

  REMOVE_FROM_FAVOURITE_LIST: {
    URL: '/api/${deviceType}/${locale}/game/${urlSlug}/favourite/remove',
    METHOD: 'GET'
  },

  FAVOURITE_GAME_LIST: {
    URL: '/api/${deviceType}/${locale}/games/list/favourite/${offset}/${limit}',
    METHOD: 'GET'
  },

  /* CONTACT FORM */

  SEND_CONTACT_MESSAGE: {
    URL: '/api/${deviceType}/${locale}/contact',
    METHOD: 'POST'
  },

  /* NEWSLETTER FORM */

  SUBSCRIBE_TO_NEWSLETTER: {
    URL: '/api/${locale}/user/nl/register',
    METHOD: 'POST'
  },

  UNSUBSCRIBE_FROM_NEWSLETTER: {
    URL: '/api/${locale}/user/nl/unregister',
    METHOD: 'GET'
  },

  UNSUBSCRIBE_FROM_NEWSLETTER_BY_PUBLIC_ID: {
    URL: '/api/${locale}/newsletter-unsubscribe/${publicId}',
    METHOD: 'GET'
  },

  /* CHANGE LANGUAGE */

  CHANGE_LANGUAGE: {
    URL: '/api/${locale}/change-language',
    METHOD: 'POST'
  },

  /* CASHIER */

  CASHIER_DOCUMENT_LIST: {
    URL: '/api-cashier/${deviceType}/${locale}/documents/${offset}/${limit}',
    METHOD: 'GET'
  },

  CASHIER_ADD_DOCUMENT: {
    URL: '/api-cashier/${deviceType}/${locale}/documents/add',
    METHOD: 'POST'
  },

  CASHIER_TRANSACTION_LIST: {
    URL: '/api-cashier/${deviceType}/${locale}/transactions/${offset}/${limit}',
    METHOD: 'GET'
  },

  CASHIER_BLOCK_ACCOUNT: {
    URL: '/api-cashier/${deviceType}/${locale}/block-account',
    METHOD: 'POST'
  },

  CASHIER_GAME_ROUNDS_LIST: {
    URL: '/api-cashier/${deviceType}/${locale}/game-rounds/${offset}/${limit}',
    METHOD: 'GET'
  },

  CASHIER_SET_PROMO_CODE: {
    URL: '/api-cashier/${deviceType}/${locale}/promo-code',
    METHOD: 'POST'
  },

  CASHIER_PAYIN_DEPOSIT_BONUS_CODE: {
    URL: '/api-user/${locale}/cashier/payin?depositBonusCode=${depositBonusCode}',
    METHOD: 'GET'
  },

  CASHIER_IFRAME: {
    URL: '/api-user/${locale}/cashier/${redirect}',
    METHOD: 'GET'
  },

  HOMEPAGE_SLIDER: {
    URL: '/api/${deviceType}/${locale}/homepage-slider',
    METHOD: 'GET'
  },

  /* TERMS AND CONDITIONS */
  TAC_ACCEPTATION: {
    URL: '/api-user/${locale}/accept-tac-version/${type}',
    METHOD: 'POST'
  },

  /* LANDING PAGE */
  LANDING_PAGE: {
    URL: '/api/${deviceType}/${locale}/landing-page/${refId}/${slug}/${refCode}',
    METHOD: 'GET'
  },

  /* STATIC PAGE */
  STATIC_PAGE: {
    URL: '/api/${deviceType}/${locale}/static-page/${slug}',
    METHOD: 'GET'
  },


  /*** WEBAUTHN */
  /*assertion/authOptionLogin*/
  ASSERTION_AUTHOPTIONLOGIN: {
    URL: '/api/assertion/authOptionLoginAPI',
    METHOD: 'POST'
  },

  /*attestation/optionsLionline*/
  ATTESTATION__OPTIONSLIONLINE: {
    URL: '/api/attestation/optionsLionlineAPI',
    METHOD: 'POST'
  },

  /* attestation/resultLionline */
  ATTESTATION__RESULTCREATE: {
    URL: '/api/attestation/resultLionlineAPI',
    METHOD: 'POST'
  },

    /* assertion/resultLionlineAPI */
  ASSERTION__RESULTLOGIN: {
      URL: '/api/assertion/resultLionlineAPI',
      METHOD: 'POST'
    },

  /* get list of authenticators biometric */
  BIOMETRIC__AUTHENTICATORS_LIST: {
    URL: '/api/profile/authenticatorAPI',
    METHOD: 'POST'
  },

  /* delete a biometric authenticator */
  BIOMETRIC__DELETE_AUTHENTICATORS: {
    URL: '/api/profile/authenticator/deleteAPI/',
    METHOD: 'POST'
  },

  /* check if authenticator is valid */
  CHECK_AUTHENTICATOR: {
    URL: '/api/check-webauth-enableAPI',
    METHOD: 'POST'
  },
  /* check if authenticator is valid */
  GET_USER_BY_AUTHENTICATOR: {
    URL: '/api/webauth-userAPI',
    METHOD: 'POST'
  },

};
