/* User Model */
export class UserModel {
  /**
	 * @constructor
	 */
  constructor(
    public email?: string,
    public username?: string,
    public password?: any,
    public passwordRepeat?: any,
    public acceptTermsAndConditions?: any,
    public isSubscribedToNewsletter?: any
  ) {  }
}