import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { globalConstants } from '../../constants/index';

import { AppLanguageService, NavigationService } from '../../services/index';
@Component({
  template : '<router-outlet></router-outlet>'
})
export class LanguageComponent {

  public currentLanguage

  /**
     * @constructor
     */
  constructor(
    private activatedRoute: ActivatedRoute,
    private appLanguageService: AppLanguageService,
    private navigationService: NavigationService,
  ) {
    const snapshot = this.activatedRoute.snapshot;
    const isAbsoluteDomain = (snapshot.url.length === 0);
    const onlyValueLangages = globalConstants['LANGUAGES'].map(element => {
      return element.value;
    });

    if (isAbsoluteDomain) {
      this.navigationService.goToDefaultPage();  
      
      return;
    }

    this.activatedRoute.params.subscribe(() => {
      const probablyLanguage = snapshot.url[0].path;
      const languageIsExists = (onlyValueLangages.indexOf(probablyLanguage) > -1);
      if (probablyLanguage) {
        if (!languageIsExists) {
          return;
        }
        this.currentLanguage = probablyLanguage;
        this.appLanguageService.setLanguageByHand(probablyLanguage, false, true);
      }
    });
  }
}
