import { Injectable } from '@angular/core';

import { requestConstants } from '../../constants/index';
import { requestHelper } from '../../helpers/index';
import { RequestService } from './request.service';

@Injectable() export class LanguageRequestService {

  /**
   * @constructor
   */
  constructor(
    private requestService: RequestService
  ) {
    //...
  }

  /**
   * Makes 'set cage locale' request.
   * @returns <promise>
   */
  setCageLocaleRequest(newLanguage) {
    const withToken = true;
    let url = requestConstants['API_URL'] + requestConstants['SET_CAGE_LOCALE']['URL'];
    url = requestHelper.urlReplace(url, {
      locale: newLanguage
    });
    
    return this.requestService.GET(url, {}, withToken);
  }
}
