import { environment } from '../../environments/environment';

export const envConstants = {
  APPLICATION_NAME: environment.APPLICATION_NAME,
  API_URL: environment.API_URL,
  API_AVAILABILITY_TIMEOUT: environment.API_AVAILABILITY_TIMEOUT,
  USER_IDLE_INTERVAL: environment.USER_IDLE_INTERVAL,
  CACHE_DURATION: environment.CACHE_DURATION,
  COOKIE_DOMAIN: environment.COOKIE_DOMAIN,
  BONUS_REFRESH_INTERVAL: 30000,
  SOCIAL_PROVIDERS: {
    'facebook': {
      'clientId': '2065065290390012',
      'apiVersion': 'v2.4'
    }
  },
  FACEBOOK_LOGIN: 1
};
