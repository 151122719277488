import { Component, Host } from '@angular/core';
import { SliderComponent } from '../slider/slider.component';

@Component({
  selector: 'slider-paginator',
  templateUrl: './slider-paginator.component.html',
  styleUrls: ['./slider-paginator.component.scss'],
})
export class SliderPaginatorComponent {
  constructor(@Host() private slider: SliderComponent) {}

  get pageActive() {
    return (this.slider.slideCurrent % this.slider.slideItems.length) + 1;
  }

  get pageCount() {
    return this.slider.slideItems.length;
  }

  get pages(): number[] {
    return Array.from({ length: this.slider.slideItems.length }, (_v, k) => k + 1);
  }

  slideTo(page) {
    this.slider.slideTo(page - 1);
  }
}