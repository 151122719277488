import { Injectable } from '@angular/core';

import { requestConstants } from '../../constants/index';
import { requestHelper } from '../../helpers/index';
import { RequestService } from './request.service';

@Injectable() export class JackpotRequestService {

  /**
     * @constructor
     */
  constructor(
    private requestService: RequestService
  ) {
    //...
  }

  /**
     * Makes 'jackpot list' request.
     * @param {Number} [limit = 8]
     * @returns <promise>
     */
  listRequest(limit = 8) {
    const url = requestHelper.urlReplace(
      requestConstants['API_URL'] + requestConstants['JACKPOT_LIST']['URL'],
      {
        limit: limit
      }
    );
    
    return this.requestService.listRequest(url, {}, false);
  }

}
