<div class="container container--noVerticalPadding container--darker">
	<h1 class="big withoutStar">{{ 'FAQ.FAQ_HEADER' | translate }}</h1>
</div>

<div class="container">
	<div class="staticPagesContainer">
		<div class="staticPagesContainer__leftColumn">
			<div class="staticPagesContainer__leftColumn-item" *ngFor="let FAQItem of FAQData; let FAQItemIndex = index;">
				<div>
					<h2 class="bottomDecorator title">
						<span class="decorator"><i class="fa fa-question-circle" aria-hidden="true"></i></span>
						{{ (FAQItemIndex + 1) }}. {{ FAQItem.translationKeyQuestion | translate }}
					</h2>
					<p class="paragraph">{{ FAQItem.translationKeyAnswer | translate }}</p>
				</div>
			</div>
		</div>

		<div class="staticPagesContainer__rightColumn">
			<static-pages-right-column></static-pages-right-column>
		</div>
	</div>
</div>

