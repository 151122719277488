import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

import { commonPagesHelper } from '../../../helpers/index';
import { UserRequestService, ComboNotificationService, NavigationService, UserService } from '../../../services/index';
import { PlatformService } from '../../../services/platform.service';
import { ComboNotificationType } from '../../../enums/alert-type.enum';
import { AccessTokenService } from '../../../services/access-token.service';

@Component({
  selector: 'register--confirm',
  templateUrl: './confirm.component.html',
  styleUrls: ['./confirm.component.scss']
})

export class RegisterConfirmComponent implements OnInit, OnDestroy {
  private temporaryToken: any;
  registerConfirmationSubscriber: any;
  isRequestFlag = false;
  tokenIsValid: boolean;
  autoRedirectSetTimeout: any;
  userLoginSubscriber: any;

  constructor(
    public router: Router,
    private route: ActivatedRoute,
    private userRequestService: UserRequestService,
    private comboNotificationService: ComboNotificationService,
    private _translate: TranslateService,
    private navigationService: NavigationService,
    private accessToken: AccessTokenService,
    private userService: UserService,
    private platformService: PlatformService) {
    this.temporaryToken = this.route.snapshot.params['temporaryToken'];
  }

  /**
	 * It's native Angular 'on initiation' method.
	 * Fired after constructor.
	 */
  ngOnInit() {
    if (! this.temporaryToken) {
      this.tokenIsValid = false;
      
      return;
    }

    if (this.platformService.browser) {
      setTimeout(() => {
        this.tokenVerification(this.temporaryToken);
      }, 2000);
    }
  }

  /**
	 * Verifications token.
	 * @param {String} temporaryToken
	 */
  tokenVerification(temporaryToken) {
    commonPagesHelper.loader('show');

    this.registerConfirmationSubscriber = this.userRequestService.registerConfirmation(temporaryToken)
      .subscribe((data) => {
        this.isRequestFlag = true;
        if (data.result !== 'ok') {
          // * Show SnackBar Notification
          this.comboNotificationService.notification(data.message, ComboNotificationType.Error);
          this.tokenIsValid = false;
          commonPagesHelper.loader('hide');
        
          return;
        }
        // * Set AccessToken
        this.accessToken.setAccessToken(data.token);
        this._translate.get(['GLOBAL.NOTIFICATIONS.SUCCESS_LABEL', 'CONTACT.MAIL_SENT_SUCCESSFULLY_LABEL'])
          .subscribe((translatedValue: string) => {
            //get user infomation
            this.userLoginSubscriber = this.userRequestService.detailsRequest().subscribe(userData => {
              this.userService.storeUserData(userData.user);
              if (userData.favourites) {
                this.userService.setFavoriteGames(userData.favourites);
              }
              commonPagesHelper.loader('hide');
              // * Show SnackBar Notification
              this.comboNotificationService.notification(translatedValue['CONTACT.MAIL_SENT_SUCCESSFULLY_LABEL'], ComboNotificationType.Success);
              this.tokenIsValid = true;
              this.autoRedirectSetTimeout = setTimeout(() => {
                this.navigationService.goToDefaultPage();
              }, 4000);
            })
          });
      }, (error) => {
        console.error('error data -->', error);
        this.isRequestFlag = true;
        this.tokenIsValid = false;
        commonPagesHelper.loader('hide');
      });
  }

  /**
	 * It's native Angular 'on destroy' method.
	 * Destroys component.
	 */
  ngOnDestroy() {
    if (typeof(this.registerConfirmationSubscriber) !== 'undefined') {
      this.registerConfirmationSubscriber.unsubscribe();
    }
    if (this.userLoginSubscriber) {
      this.userLoginSubscriber.unsubscribe();
    }
    clearTimeout(this.autoRedirectSetTimeout);
  }
}
