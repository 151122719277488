import { StartComponentData } from './start.model';
import { Resolve } from '@angular/router';
import { Observable, forkJoin } from 'rxjs';
import { Injectable, NgZone } from '@angular/core';
import { SliderRequestService, GameRequestService, WinnerRequestService, JackpotRequestService, GameService } from '../../services';
import { map } from 'rxjs/operators';
import { PlatformService } from '../../services/platform.service';

@Injectable({
  providedIn: 'root'
})
export class StartResolver implements Resolve<StartComponentData> {
  constructor(
    private gameRequestService: GameRequestService,
    private gameService: GameService,
    private jackpotRequestService: JackpotRequestService,
    private platformService: PlatformService,
    private sliderRequestService: SliderRequestService,
    private winnerRequestService: WinnerRequestService,
    private zone: NgZone) { }

  resolve(): StartComponentData | Observable<StartComponentData> | Promise<StartComponentData> {
    return forkJoin(
      this.sliderRequestService.getList(),
      this.resolveGameList()
    ).pipe(
      map(results => ({
        slider: results[0].slides,
        gameList: results[1]
      }))
    );
  }

  resolveGameList() {
    return forkJoin(
      this.gameRequestService.listRequest({ orderField: 'itemOrder' }, 0, 32),
      this.gameRequestService.listRequest({ searchField: 'isPromotedAsTop', searchString: '1', orderField: 'itemOrder' }, 0, 32),
      this.gameRequestService.listRequest({ searchField: 'isPromotedAsNew', searchString: '1', orderField: 'itemOrder' }, 0, 32),
      this.winnerRequestService.listRequest(12),
      this.resolveJackpots()
    ).pipe(
      map(results => ({
        forYou: {
          data: results[0].games
        },
        trending: {
          data: results[1].games
        },
        new: {
          data: results[2].games
        },
        winners: {
          data: results[3].latestWinners
        },
        jackpots: {
          data: results[4]
        }
      }))
    );
  }

  private resolveJackpots() {
    return this.jackpotRequestService.listRequest(8).pipe(
      map(
        data => {
          this.gameService.jackpotUpdateHandles.forEach(it => clearInterval(it));
          this.gameService.jackpotUpdateHandles = [];

          data['jackpots'].forEach(jackpot => {
            jackpot['jackpotOriginalAmount'] = parseFloat(jackpot['jackpotAmount']);
            // Jackpot amount is received as cents and it increments by x cents
            jackpot['jackpotAmount'] = jackpot['jackpotOriginalAmount'] / 100;
            jackpot['jackpotValueIncrementPerInterval'] = parseFloat(jackpot['jackpotValueIncrementPerInterval']);

            if (this.platformService.browser) {
              this.zone.runOutsideAngular(() => {
                const jackpotUpdateHandle = setInterval(() => {
                  this.zone.runOutsideAngular(() => {
                    jackpot['jackpotOriginalAmount'] += jackpot['jackpotValueIncrementPerInterval'];
                    jackpot['jackpotAmount'] = jackpot['jackpotOriginalAmount'] / 100;
                  });
                }, parseFloat(jackpot['jackpotValueIncrementIntervalSeconds']) * 1000);
                this.gameService.jackpotUpdateHandles.push(jackpotUpdateHandle);
              });
            }

          });

          return data['jackpots'];
        }
      )
    );
  }
}