<div class="container container--noVerticalPadding container--darker">
	<h1 class="big withoutStar">{{ 'PAYMENT_PROVIDERS.PAYMENT_PROVIDERS_HEADER' | translate }}</h1>
</div>

<div class="container">
	<div class="staticPagesContainer">
		<div class="staticPagesContainer__leftColumn">
			<div class="staticPagesContainer__leftColumn-item">
				<p class="paragraph">{{ 'PAYMENT_PROVIDERS.PAYMENT_PROVIDERS_CONTENT' | translate }}</p>

				<h2 id="deposit" class="bottomDecorator title">
					<span class="decorator"><i class="fa fa-eur" aria-hidden="true"></i></span>
					{{ 'PAYMENT_PROVIDERS.PAYMENT_PROVIDERS_HEADER2' | translate }}
				</h2>

				<p class="paragraph">{{ 'PAYMENT_PROVIDERS.PAYMENT_PROVIDERS_CONTENT2' | translate }}</p>

				<div class="black-container">
					<div class="visible-only-mobile">
						<div class="mobilePaymentList" *ngFor="let paymentProvider of paymentProviders">
							<div class="mobilePaymentList__item" *ngIf="showProvider(paymentProvider)">
								<div class="mobilePaymentList__item-cell mobilePaymentList__item-cell--header">
									{{ 'PAYMENT_PROVIDERS.TABLE_ONE.PROVIDER_LABEL' | translate }}
								</div>
								<div class="mobilePaymentList__item-cell mobilePaymentList__item-cell--white">
									<img src="{{ paymentProvider.logoSrc }}" alt="{{ paymentProvider.name }}">
								</div>

								<div class="mobilePaymentList__item-cell mobilePaymentList__item-cell--header">
									{{ 'PAYMENT_PROVIDERS.TABLE_ONE.FEE_LABEL' | translate }}
								</div>
								<div class="mobilePaymentList__item-cell">
									{{ paymentProvider.fee | translate }}
								</div>

								<div class="mobilePaymentList__item-cell mobilePaymentList__item-cell--header">
									{{ 'PAYMENT_PROVIDERS.TABLE_ONE.DURATION_LABEL' | translate }}
								</div>
								<div class="mobilePaymentList__item-cell">
									{{ paymentProvider.duration | translate }}
								</div>

								<div class="mobilePaymentList__item-cell mobilePaymentList__item-cell--header">
									{{ 'PAYMENT_PROVIDERS.TABLE_ONE.CURRENCY_LABEL' | translate }}
								</div>
								<div class="mobilePaymentList__item-cell">
									{{ paymentProvider.currency | translate }}
								</div>

								<div class="mobilePaymentList__item-cell mobilePaymentList__item-cell--header">
									<div class="buttons">
										<a [routerLink]="navigationService.constructUrl('CASHIER')" [queryParams]="{ tab: 'payIn' }" class="button button--green" *ngIf="layout.logged && layout.loggedUserData?.isFullyRegistered">{{ 'PAYMENT_PROVIDERS.DEPOSIT_NOW_BUTTON' | translate }}</a>
										<a [routerLink]="navigationService.constructUrl('USER_ACCOUNT')" class="button button--green" *ngIf="layout.logged && !layout.loggedUserData?.isFullyRegistered">{{ 'PAYMENT_PROVIDERS.DEPOSIT_NOW_BUTTON' | translate }}</a>
										<a (click)="layout.toggleLoginBar();" class="button button--green" *ngIf="!layout.logged">{{ 'PAYMENT_PROVIDERS.DEPOSIT_NOW_BUTTON' | translate }}</a>
										<a class="button button--gray" (click)="showInfo(paymentProvider.id)">{{ 'PAYMENT_PROVIDERS.INFO_BUTTON' | translate }}</a>
									</div>

									<div class="moreInformations" [class.hidden]="(openedInfo !== paymentProvider.id)" [innerHTML]="paymentProvider.info | translate"></div>
								</div>
							</div>
						</div>
					</div>
					<table class="table not-striped visible-only-desktop">
						<thead>
							<tr class="bottomBorder">
								<th>{{ 'PAYMENT_PROVIDERS.TABLE_ONE.PROVIDER_LABEL' | translate }}</th>
								<th>{{ 'PAYMENT_PROVIDERS.TABLE_ONE.FEE_LABEL' | translate }}</th>
								<th>{{ 'PAYMENT_PROVIDERS.TABLE_ONE.DURATION_LABEL' | translate }}</th>
								<th colspan="2">{{ 'PAYMENT_PROVIDERS.TABLE_ONE.CURRENCY_LABEL' | translate }}</th>
							</tr>
						</thead>
						<tbody>
							<ng-template ngFor let-paymentProvider [ngForOf]="paymentProviders">
								<tr class="bottomBorder" id="type-t1-{{ paymentProvider.id }}" *ngIf="showProvider(paymentProvider)">
									<td>
										<div class="image-wrapper">
											<img src="{{ paymentProvider.logoSrc }}" alt="{{ paymentProvider.name }}">
										</div>
									</td>
									<td>{{ paymentProvider.fee | translate }}</td>
									<td>{{ paymentProvider.duration | translate }}</td>
									<td>{{ paymentProvider.currency | translate }}</td>
									<td class="right">
										<a [routerLink]="navigationService.constructUrl('CASHIER')" [queryParams]="{ tab: 'payIn' }" class="button button--green" *ngIf="layout.logged && layout.loggedUserData?.isFullyRegistered">{{ 'PAYMENT_PROVIDERS.DEPOSIT_NOW_BUTTON' | translate }}</a>
										<a [routerLink]="navigationService.constructUrl('USER_ACCOUNT')" class="button button--green" *ngIf="layout.logged && !layout.loggedUserData?.isFullyRegistered">{{ 'PAYMENT_PROVIDERS.DEPOSIT_NOW_BUTTON' | translate }}</a>
										<a (click)="layout.toggleLoginBar();" class="button button--green" *ngIf="!layout.logged">{{ 'PAYMENT_PROVIDERS.DEPOSIT_NOW_BUTTON' | translate }}</a>
										<a class="button button--gray" (click)="showInfo(paymentProvider.id)">{{ 'PAYMENT_PROVIDERS.INFO_BUTTON' | translate }}</a>
									</td>
								</tr>
								<tr class="moreInformations" [class.hidden]="(openedInfo !== paymentProvider.id)" *ngIf="showProvider(paymentProvider)">
									<td colspan="5" class="more-info" [innerHTML]="paymentProvider.info | translate">
										...
									</td>
								</tr>
							</ng-template>
						</tbody>
					</table>
				</div>

				<h2 id="withdraw" class="bottomDecorator title">
					<span class="decorator"><i class="fa fa-eur" aria-hidden="true"></i></span>
					{{ 'PAYMENT_PROVIDERS.PAYMENT_PROVIDERS_HEADER3' | translate }}
				</h2>

				<p class="paragraph">{{ 'PAYMENT_PROVIDERS.PAYMENT_PROVIDERS_CONTENT3' | translate }}</p>

				<div class="black-container">
					<div class="visible-only-mobile">
						<div class="mobilePaymentList" *ngFor="let paymentProviderSecond of paymentProvidersSecond">
							<div class="mobilePaymentList__item" *ngIf="showProvider(paymentProviderSecond)">
								<div class="mobilePaymentList__item-cell mobilePaymentList__item-cell--header">
									{{ 'PAYMENT_PROVIDERS.TABLE_ONE.PROVIDER_LABEL' | translate }}
								</div>
								<div class="mobilePaymentList__item-cell mobilePaymentList__item-cell--white">
									<img src="{{ paymentProviderSecond.logoSrc }}" alt="{{ paymentProviderSecond.name }}">
								</div>

								<div class="mobilePaymentList__item-cell mobilePaymentList__item-cell--header">
									{{ 'PAYMENT_PROVIDERS.TABLE_ONE.FEE_LABEL' | translate }}
								</div>
								<div class="mobilePaymentList__item-cell">
									{{ paymentProviderSecond.fee | translate }}
								</div>

								<div class="mobilePaymentList__item-cell mobilePaymentList__item-cell--header">
									{{ 'PAYMENT_PROVIDERS.TABLE_ONE.DURATION_LABEL' | translate }}
								</div>
								<div class="mobilePaymentList__item-cell">
									{{ paymentProviderSecond.duration | translate }}
								</div>

								<div class="mobilePaymentList__item-cell mobilePaymentList__item-cell--header">
									{{ 'PAYMENT_PROVIDERS.TABLE_ONE.CURRENCY_LABEL' | translate }}
								</div>
								<div class="mobilePaymentList__item-cell">
									{{ paymentProviderSecond.currency | translate }}
								</div>

								<div class="mobilePaymentList__item-cell mobilePaymentList__item-cell--header">
									<div class="buttons">
										<a [routerLink]="navigationService.constructUrl('CASHIER')" [queryParams]="{ tab: 'payIn' }" class="button button--green" *ngIf="layout.logged && layout.loggedUserData?.isFullyRegistered">{{ 'PAYMENT_PROVIDERS.DEPOSIT_NOW_BUTTON' | translate }}</a>
										<a [routerLink]="navigationService.constructUrl('USER_ACCOUNT')" class="button button--green" *ngIf="layout.logged && !layout.loggedUserData?.isFullyRegistered">{{ 'PAYMENT_PROVIDERS.DEPOSIT_NOW_BUTTON' | translate }}</a>
										<a (click)="layout.toggleLoginBar();" class="button button--green" *ngIf="!layout.logged">{{ 'PAYMENT_PROVIDERS.DEPOSIT_NOW_BUTTON' | translate }}</a>
										<a class="button button--gray" (click)="showInfo(paymentProviderSecond.id)">{{ 'PAYMENT_PROVIDERS.INFO_BUTTON' | translate }}</a>
									</div>

									<div class="moreInformations" [class.hidden]="(openedInfo !== paymentProviderSecond.id)" [innerHTML]="paymentProviderSecond.info | translate"></div>
								</div>
							</div>
						</div>
					</div>
					<table class="table not-striped visible-only-desktop">
						<thead>
							<tr class="bottomBorder">
								<th>{{ 'PAYMENT_PROVIDERS.TABLE_TWO.PROVIDER_LABEL' | translate }}</th>
								<th>{{ 'PAYMENT_PROVIDERS.TABLE_TWO.FEE_LABEL' | translate }}</th>
								<th>{{ 'PAYMENT_PROVIDERS.TABLE_TWO.DURATION_LABEL' | translate }}</th>
								<th colspan="2">{{ 'PAYMENT_PROVIDERS.TABLE_TWO.CURRENCY_LABEL' | translate }}</th>
							</tr>
						</thead>
						<tbody>
							<ng-template ngFor let-paymentProviderSecond [ngForOf]="paymentProvidersSecond">
								<tr class="bottomBorder" id="type-t2-{{ paymentProvidersSecond.id }}" *ngIf="showProvider(paymentProviderSecond)">
									<td>
										<div class="image-wrapper">
											<img src="{{ paymentProviderSecond.logoSrc }}" alt="{{ paymentProviderSecond.name }}">
										</div>
									</td>
									<td>
										<span *ngIf="(paymentProviderSecond.fee && paymentProviderSecond.fee !== '')">{{ paymentProviderSecond.fee | translate }}</span>
										<span *ngIf="(paymentProviderSecond.feeValue && paymentProviderSecond.feeValue !== '')">{{ paymentProviderSecond.feeValue }}</span>
									</td>
									<td>{{ paymentProviderSecond.duration | translate }}</td>
									<td>{{ paymentProviderSecond.currency | translate }}</td>
									<td class="right">
										<a [routerLink]="navigationService.constructUrl('CASHIER')" [queryParams]="{ tab: 'payIn' }" class="button button--green" *ngIf="layout.logged && layout.loggedUserData?.isFullyRegistered">{{ 'PAYMENT_PROVIDERS.DEPOSIT_NOW_BUTTON' | translate }}</a>
										<a [routerLink]="navigationService.constructUrl('USER_ACCOUNT')" class="button button--green" *ngIf="layout.logged && !layout.loggedUserData?.isFullyRegistered">{{ 'PAYMENT_PROVIDERS.DEPOSIT_NOW_BUTTON' | translate }}</a>
										<a (click)="layout.toggleLoginBar();" class="button button--green" *ngIf="!layout.logged">{{ 'PAYMENT_PROVIDERS.DEPOSIT_NOW_BUTTON' | translate }}</a>
										<a class="button button--gray" (click)="showInfo(paymentProviderSecond.id)">{{ 'PAYMENT_PROVIDERS.INFO_BUTTON' | translate }}</a>
									</td>
								</tr>
								<tr class="moreInformations" [class.hidden]="(openedInfo !== paymentProviderSecond.id)" *ngIf="showProvider(paymentProviderSecond)">
									<td colspan="5" class="more-info" [innerHTML]="paymentProviderSecond.info | translate">
										...
									</td>
								</tr>
							</ng-template>
						</tbody>
					</table>
				</div>

			</div>
		</div>

		<div class="staticPagesContainer__rightColumn">
			<static-pages-right-column></static-pages-right-column>
		</div>
	</div>
</div>

