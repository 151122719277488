<div class="container container--noVerticalPadding container--darker">
	<h1 class="big withoutStar">{{ 'TERMS_OF_USE.TERMS_OF_USE_HEADER' | translate }}</h1>
</div>

<div class="container">
	<div class="staticPagesContainer">
		<div class="staticPagesContainer__leftColumn">
			<div class="staticPagesContainer__leftColumn-item">

				<div [innerHTML]="'TERMS_OF_USE.TERMS_OF_USE_CONTENT' | translate"></div>
			</div>
		</div>

		<div class="staticPagesContainer__rightColumn">
			<static-pages-right-column></static-pages-right-column>
		</div>
	</div>
</div>

