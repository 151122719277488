/* eslint-disable @typescript-eslint/camelcase */
import { Injectable, EventEmitter } from '@angular/core';
import { requestConstants } from '../../constants/index';
import { Router } from '@angular/router';
import { requestHelper, commonPagesHelper } from '../../helpers/index';
import { DeviceDetectorService } from 'ngx-device-detector';
import { TranslateService } from '@ngx-translate/core';
import { AppLanguageService } from '../language.service';
import { UserService } from '../user.service';
import { ComboNotificationService } from '../comboNotification.service';
import { map, catchError } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { PlatformService } from '../platform.service';
import { AccessToken } from '../../models/accessToken.model';
import { AccessTokenService } from '../access-token.service';
import { ComboNotificationType } from '../../enums/alert-type.enum';

@Injectable() export class RequestService {
  deviceType = 'desktop'
  locale = 'en'

  public requestChange$ = new EventEmitter()
  public changedTAC$ = new EventEmitter()
  public renderTrackingPixel$ = new EventEmitter()
  confirmIsVisible = false

  /**
   * @constructor
   */
  constructor(
    private accessToken: AccessTokenService,
    private http: HttpClient,
    private router: Router,
    private deviceService: DeviceDetectorService,
    private translate: TranslateService,
    private userService: UserService,
    public appLanguageService: AppLanguageService,
    public comboNotificationService: ComboNotificationService,
    private platformService: PlatformService) {
    this.deviceDetection();
    this.languageDetection();
    this.locale = this.appLanguageService.getLanguageFromStorage();
  }

  // * Language Detection
  private languageDetection(): void {
    this.appLanguageService.languageChange$.subscribe((language) => {
      this.locale = language;
    });
  }

  // * Get Http Headers, with Authentication or not
  getRequestHeaders(isJsonRequest = false): HttpHeaders {
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', isJsonRequest ? 'application/json' : 'application/x-www-form-urlencoded');
    headers = headers.set('Access-Control-Allow-Headers', 'Content-Type,Accept');
    headers = headers.set('Access-Control-Allow-Methods', 'GET, POST, OPTIONS, PUT, DELETE, PATCH');
    headers = headers.set('Client', requestConstants['APPLICATION_NAME']);

    // * Add Authorization Header
    if (this.getAccessToken()) {
      headers = headers.set('Authorization', `Bearer ${ this.getAccessToken().access_token }`);
    }
    
    return headers;
  }

  // * Device Detection
  private deviceDetection(): void {
    const isDesktop = this.deviceService.isDesktop();
    if (!isDesktop) { this.deviceType = 'mobile'; }
  }

  // * Show Warning Message
  private showWarning(message): void {
    // * Show SnackBar Notification
    this.comboNotificationService.notification(message, ComboNotificationType.Warning);
  }

  // * Get the Access Token from the Auth Service
  private getAccessToken(): AccessToken {
    return this.accessToken.getToken();
  }

  // * Failed Authorization, Destroy Stored Data
  authorizationFailedAction(): void {
    if (this.confirmIsVisible) return;
    // We need to delete user data first, there's no reason to keep it in Local Storage, it's not safe
    this.userService.destroyStoredData();
  }

  /**
   * Checks access expired / redirects to login page if true.
   * @param httpPromise {Promise}
   * TODO Refactoring, Huge Method
   */
  checkAccessExpired(error: any) {
    if (parseInt(error.status, 10) === 0) {
      this.translate
        .get([
          'GLOBAL.SERVICE_DOWN',
        ])
        .subscribe((translatedValue: string) => {
          this.router.navigateByUrl(commonPagesHelper.constructUrl('START'));
          this.showWarning(translatedValue['GLOBAL.SERVICE_DOWN']);
        });
      
      return;
    }

    let errorResponse = undefined;
    try {
      errorResponse = JSON.parse(error._body);
    } catch (e) {
      errorResponse = error.status == 500 ? {
        error_description: error.message
      } : error.error
        ? error.error
        : { errorDescription: 'Could not parse error body.' };
    }

    if (error.status === 302 && errorResponse.result === 'redirect') {
      if (this.userService.isLogged()) {
        this.router.navigateByUrl(commonPagesHelper.constructUrl('START'));
        this.changedTAC$.emit(errorResponse);
      } else {
        this.userService.destroyStoredData();
        this.router.navigateByUrl(commonPagesHelper.constructUrl('START'));
      }
    }

    if (error.status === 401 || error.status === 403) {
      switch(errorResponse.error) {
        case 'full_access_restricted':
          this.router.navigateByUrl(commonPagesHelper.constructUrl('RESTRICTED_AREA'));
          break;

        case 'invalid_grant':
          this.router.navigateByUrl(commonPagesHelper.constructUrl('START'));
          this.authorizationFailedAction();
          this.userService.destroyStoredData();
          break;

        case 'access_denied':
          this.router.navigateByUrl(commonPagesHelper.constructUrl('START'));
          this.showWarning(errorResponse.error_description);
          this.userService.destroyStoredData();
          break;

        case 'full_registration_required':
          this.router.navigateByUrl(commonPagesHelper.constructUrl('USER_ACCOUNT'));
          break;

        case 'account_frozen':
          this.router.navigateByUrl(commonPagesHelper.constructUrl('ACCOUNT_FROZEN'));
          break;

        default:
          this.router.navigateByUrl(commonPagesHelper.constructUrl('START'));
          if (errorResponse.error_description)
            this.showWarning(errorResponse.error_description);
          break;

      }
    } else if (error.status === 500) {
      window.history.back();
      // this.router.navigateByUrl(commonPagesHelper.constructUrl('START'));
      this.showWarning(errorResponse.error_description);

      setTimeout(() => {
        window.scrollTo(0, 0);
      }, 100);
    }
    if (this.platformService.server) throw new Error(errorResponse || 'Server error');
    else return throwError(errorResponse || 'Server error');
  }

  // * Format the URL with Device and Locale
  formatUrlLocalDevice(url: any): string {    
    return requestHelper.urlReplace(url, { locale: this.locale, deviceType: this.deviceType });
  }

  // * Format the URL with Device and Locale, Adding any Query Params
  private formatUrlLocalDeviceAddQueryString(url: any, parametersMap: any): string {
    let returnUrl = this.formatUrlLocalDevice(url);
    if (Object.keys(parametersMap).length > 0) {
      returnUrl = requestHelper.createQueryStringURL(returnUrl, parametersMap);
    }

    return returnUrl;
  }

  // * Intercept Response and Return
  private interceptReturnBody(response: any): any {
    this.interceptRequestResponse(response);
    
    return response;
  }

  mapRequestParams(parametersMap: any): HttpParams {
    let requestParameters = new HttpParams();
    Object.keys(parametersMap).forEach((key) => {
      const parameterValue = parametersMap[key];
      if (typeof(parameterValue) === 'object') {
        console.log(parameterValue);
        console.log(typeof(parameterValue));
        Object.keys(parameterValue).forEach((insideKey) => {
          const insideValue = parameterValue[insideKey];
          const nastedKey = `${key  }[${  insideKey  }]`;
          requestParameters = requestParameters.set(nastedKey, insideValue);
        });
      } else {
        requestParameters = requestParameters.set(key, parametersMap[key]);
      }
    });
    return requestParameters;
  }
    
  // * Makes a Details Request, Passing Url, If With Token and Headers
  detailsRequest(url: any, withToken = true): Observable<any> {
    return this.http.get(this.formatUrlLocalDevice(url), { headers: this.getRequestHeaders(), withCredentials: withToken })
      .pipe(
        map((response) => { return this.interceptReturnBody(response); }),
        catchError(this.checkAccessExpired.bind(this))
      );
  }

  // * Makes an Update Request, for Edit or Create
  updateRequest(urls: any, parametersMap: any, isEditView: boolean, withToken = true): Observable<any> {
    const specificRequestURL = requestConstants['API_URL'] + (isEditView ? urls['edit'] : urls['create']);

    return this.http[(isEditView ? 'put' : 'post')](this.formatUrlLocalDevice(specificRequestURL), 
      this.mapRequestParams(parametersMap), { headers: this.getRequestHeaders(), withCredentials: withToken })
      .pipe(
        map((response) => { return this.interceptReturnBody(response); }),
        catchError(this.checkAccessExpired.bind(this))
      );
  }

  // * List Request
  listRequest(url: any, parametersMap: any = { }, withToken = true): Observable<any>  {
    
    return this.http.get(
      this.formatUrlLocalDeviceAddQueryString(url, parametersMap), { headers: this.getRequestHeaders(true), withCredentials: withToken })
      .pipe(
        map((response: any) => {
          const parsedResponse = response;
          if (parsedResponse.result === 'server_error') {
            this.translate
              .get([
                'GLOBAL.PAGE_NOT_FOUND_LABEL',
              ])
              .subscribe((translatedValue: string) => {
                this.router.navigateByUrl(commonPagesHelper.constructUrl('START'));
                this.showWarning(translatedValue['GLOBAL.PAGE_NOT_FOUND_LABEL']);
              });
          }

          return this.interceptReturnBody(response);
        }),
        catchError(this.checkAccessExpired.bind(this))
      );
  }

  // * Makes a Standard Request
  standardRequest(url: any, method: string, parameters: any = { }, withToken = true, isJsonRequest=false): Observable<any> {
    method = method.toLowerCase();
    const methodsToStringifyParameters = ['put', 'patch', 'post'];

    if (methodsToStringifyParameters.indexOf(method) > -1) {

      if (isJsonRequest){
        return this.http[method](`${this.formatUrlLocalDevice(url)}`, JSON.stringify(parameters), { headers: this.getRequestHeaders(isJsonRequest), withCredentials: withToken })
        .pipe(
          map((response) => { return this.interceptReturnBody(response); }),
          catchError(this.checkAccessExpired.bind(this))
        );

      }
      return this.http[method](`${this.formatUrlLocalDevice(url)}`, this.mapRequestParams(parameters), { headers: this.getRequestHeaders(isJsonRequest), withCredentials: withToken })
        .pipe(
          map((response) => { return this.interceptReturnBody(response); }),
          catchError(this.checkAccessExpired.bind(this))
        );
    } else {
      const params = this.mapRequestParams(parameters).toString();
      const queryParams = !params ? '' :`?${params}`;

      return this.http[method](`${this.formatUrlLocalDevice(url) + queryParams}`, { headers: this.getRequestHeaders(), withCredentials: withToken })
        .pipe(
          map((response) => { return this.interceptReturnBody(response); }),
          catchError(this.checkAccessExpired.bind(this))
        );
    }
  }

  // * Intercept Handle JSON Response
  interceptRequestResponse(json: any): void {
    // Retrive event tracking informations and render tracking pixel
    if (json.track_event) {
      this.renderTrackingPixel$.emit(json);
    }
  }

  // * POST Request
  POST(url: any, parameters: any, withToken = true, isJsonRequest=false): Observable<any> {
    return this.standardRequest(url, 'post', parameters, withToken, isJsonRequest);
  }

  // * PUT Request
  PUT(url: any, parameters: any, withToken = true): Observable<any> {
    return this.standardRequest(url, 'put', parameters, withToken);
  }

  // * PATCH Request
  PATCH(url: any, parameters: any, withToken = true): Observable<any> {
    return this.standardRequest(url, 'patch', parameters, withToken);
  }

  // * GET Request
  GET(url: any, parameters: any, withToken = true): Observable<any> {
    return this.standardRequest(url, 'get', parameters, withToken);
  }

  // * DELETE Request
  DELETE(url: any, parameters: any, withToken = true) {
    return this.standardRequest(url, 'delete', parameters, withToken);
  }

}
