import { HttpHeaders } from '@angular/common/http';
import { stringHelper } from './string.helper';
import { AccessToken } from '../models/accessToken.model';

export const requestHelper = {

  /**
	 * Gets value specific parameter from query string.
	 * @param url [url=window.location.href] {String}
	 * @param parameterName {String}
	 * @returns {String}
	 */
  getValueByParameter(url = window.location.href, parameterName) {
    // eslint-disable-next-line no-useless-escape
    parameterName = parameterName.replace(/[\[\]]/g, '\\$&');
    const regex = new RegExp(`[?&]${  parameterName  }(=([^&#]*)|&|#|$)`);
    const results = regex.exec(url);

    if (!results) {
      return null;
    }

    if (!results[2]) {
      return '';
    }
    
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
  },

  /**
	 * Creates Query String URL based on url and parameters map.
	 * @param url [url=window.location.href] {String}
	 * @param parameters {Object}
	 * @returns fullURL {String}
	 */
  createQueryStringURL(url = window.location.href, parameters: Record<string, any>, decode = false) {
    let fullURL = url;
    const clearParameters = {};

    Object.keys(parameters).forEach((parameterKey) => {
      if (typeof (parameters[parameterKey]) !== 'undefined') {
        clearParameters[parameterKey] = parameters[parameterKey];
      }
    });
    if (Object.keys(clearParameters).length === 0) {
      return fullURL;
    }
    Object.keys(clearParameters).forEach((key) => {
      const separator = (fullURL.indexOf('?') > -1 ? '&' : '?');
      const value = stringHelper.encodeURI(clearParameters[key]);
      const parameterExists = (fullURL.search(key) > -1);

      if (parameterExists) {
        const existingValue = requestHelper.getValueByParameter(fullURL, key);
        fullURL = fullURL.replace(existingValue, value);
      } else {
        fullURL += `${separator}${key}=${value}`;
      }
    });

    fullURL = decodeURIComponent(fullURL);

    if (decode) {
      fullURL = fullURL.replace('%3F', '?');
      fullURL = fullURL.replace('%3D', '=');
    }

    return fullURL;
  },

  /**
	 * Replaces varriables like ${variable} from provided url.
	 * @param url {String}
	 * @param parameters {Object}
	 * @returns url {String}
	 */
  urlReplace(url, parameters) {
    Object.keys(parameters).forEach((parameterKey) => {
      const pattern = new RegExp(`\\$\\{${  parameterKey  }\\}`, 'gi');
      url = url.replace(pattern, stringHelper.encodeURI(parameters[parameterKey]));
    });

    return url;
  },

};