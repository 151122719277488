import { Component, Host, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ScrollToService, ScrollToConfigOptions } from '@nicky-lenaers/ngx-scroll-to';
import { CoreLayout } from '../../layouts/index';
import { NavigationService } from '../../services/index';
import { PlatformService } from '../../services/platform.service';

@Component({
  selector: 'payment-providers',
  templateUrl: './paymentProviders.component.html',
  styleUrls: ['./paymentProviders.component.scss']
})

export class PaymentProvidersComponent implements OnInit, OnDestroy {

  layout
  openedInfo

  paymentProviders = [
    {
      id: 'trustly',
      name: 'Trustly',
      logoSrc: '/assets/images/payment-providers/trustly.png',
      fee: 'PAYMENT_PROVIDERS.FREE_LABEL',
      duration: 'PAYMENT_PROVIDERS.IMMEDIATELY_LABEL',
      currency: 'PAYMENT_PROVIDERS.EURO_LABEL',
      info: 'PAYMENT_PROVIDERS.TABLE_ONE.INFO_DATA.TRUSTLY'
    },
    {
      id: 'visa',
      name: 'VISA',
      logoSrc: '/assets/images/payment-providers/visa.png',
      fee: 'PAYMENT_PROVIDERS.FREE_LABEL',
      duration: 'PAYMENT_PROVIDERS.IMMEDIATELY_LABEL',
      currency: 'PAYMENT_PROVIDERS.EURO_LABEL',
      info: 'PAYMENT_PROVIDERS.TABLE_ONE.INFO_DATA.VISA',
      hideCountries: [
        'DE'
      ]
    },
    {
      id: 'mastercard',
      name: 'MASTERCARD',
      logoSrc: '/assets/images/payment-providers/mastercard.png',
      fee: 'PAYMENT_PROVIDERS.FREE_LABEL',
      duration: 'PAYMENT_PROVIDERS.IMMEDIATELY_LABEL',
      currency: 'PAYMENT_PROVIDERS.EURO_LABEL',
      info: 'PAYMENT_PROVIDERS.TABLE_ONE.INFO_DATA.MASTERCARD'
    },
    {
      id: 'paysafecard',
      name: 'PaySafeCard',
      logoSrc: '/assets/images/payment-providers/paysafecard.png',
      fee: 'PAYMENT_PROVIDERS.FREE_LABEL',
      duration: 'PAYMENT_PROVIDERS.IMMEDIATELY_LABEL',
      currency: 'PAYMENT_PROVIDERS.EURO_LABEL',
      info: 'PAYMENT_PROVIDERS.TABLE_ONE.INFO_DATA.PAYSAFECARD'
    },
    {
      id: 'sofort',
      name: 'Sofort',
      logoSrc: '/assets/images/payment-providers/sofort-black.png',
      fee: 'PAYMENT_PROVIDERS.FREE_LABEL',
      duration: 'PAYMENT_PROVIDERS.IMMEDIATELY_LABEL',
      currency: 'PAYMENT_PROVIDERS.EURO_LABEL',
      info: 'PAYMENT_PROVIDERS.TABLE_ONE.INFO_DATA.SOFORT'
    },
    {
      id: 'ecopayz',
      name: 'ecoPayz',
      logoSrc: '/assets/images/payment-providers/ecopayz.png',
      fee: 'PAYMENT_PROVIDERS.FREE_LABEL',
      duration: 'PAYMENT_PROVIDERS.IMMEDIATELY_LABEL',
      currency: 'PAYMENT_PROVIDERS.EURO_LABEL',
      info: 'PAYMENT_PROVIDERS.TABLE_ONE.INFO_DATA.ECOPAYZ'
    },
    {
      id: 'neteller',
      name: 'Neteller',
      logoSrc: '/assets/images/payment-providers/neteller.png',
      fee: 'PAYMENT_PROVIDERS.FREE_LABEL',
      duration: 'PAYMENT_PROVIDERS.IMMEDIATELY_LABEL',
      currency: 'PAYMENT_PROVIDERS.EURO_LABEL',
      info: 'PAYMENT_PROVIDERS.TABLE_ONE.INFO_DATA.NETELLER'
    },
    {
      id: 'skrill',
      name: 'Skrill',
      logoSrc: '/assets/images/payment-providers/skrill.png',
      fee: 'PAYMENT_PROVIDERS.FREE_LABEL',
      duration: 'PAYMENT_PROVIDERS.IMMEDIATELY_LABEL',
      currency: 'PAYMENT_PROVIDERS.EURO_LABEL',
      info: 'PAYMENT_PROVIDERS.TABLE_ONE.INFO_DATA.SKRILL'
    },
    {
      id: 'qiwi',
      name: 'QIWI',
      logoSrc: '/assets/images/payment-providers/qiwi.png',
      fee: 'PAYMENT_PROVIDERS.FREE_LABEL',
      duration: 'PAYMENT_PROVIDERS.IMMEDIATELY_LABEL',
      currency: 'PAYMENT_PROVIDERS.EURO_LABEL',
      info: 'PAYMENT_PROVIDERS.TABLE_ONE.INFO_DATA.QIWI'
    },
    {
      id: 'monetaru',
      name: 'Moneta RU',
      logoSrc: '/assets/images/payment-providers/webmoney.png',
      fee: 'PAYMENT_PROVIDERS.FREE_LABEL',
      duration: 'PAYMENT_PROVIDERS.IMMEDIATELY_LABEL',
      currency: 'PAYMENT_PROVIDERS.EURO_LABEL',
      info: 'PAYMENT_PROVIDERS.TABLE_ONE.INFO_DATA.MONETARU'
    },
    {
      id: 'trustpay',
      name: 'TrustPay',
      logoSrc: '/assets/images/payment-providers/trustpay.png',
      fee: 'PAYMENT_PROVIDERS.FREE_LABEL',
      duration: 'PAYMENT_PROVIDERS.IMMEDIATELY_LABEL',
      currency: 'PAYMENT_PROVIDERS.EURO_LABEL',
      info: 'PAYMENT_PROVIDERS.TABLE_ONE.INFO_DATA.TRUSTPAY'
    },
    {
      id: 'brite',
      name: 'Brite',
      logoSrc: '/assets/images/payment-providers/brite.png',
      fee: 'PAYMENT_PROVIDERS.FREE_LABEL',
      duration: 'PAYMENT_PROVIDERS.IMMEDIATELY_LABEL',
      currency: 'PAYMENT_PROVIDERS.EURO_LABEL',
      info: 'PAYMENT_PROVIDERS.TABLE_ONE.INFO_DATA.BRITE'
    }
  ]

  paymentProvidersSecond = [
    {
      id: 'trustly2',
      name: 'Trustly',
      logoSrc: '/assets/images/payment-providers/trustly.png',
      fee: 'PAYMENT_PROVIDERS.FREE_LABEL',
      duration: 'PAYMENT_PROVIDERS.ONE_TWO_WORKING_DAYS',
      currency: 'PAYMENT_PROVIDERS.EURO_LABEL',
      info: 'PAYMENT_PROVIDERS.TABLE_TWO.INFO_DATA.TRUSTLY'
    },
    {
      id: 'bank',
      name: 'Bank',
      logoSrc: '/assets/images/payment-providers/bank.png',
      fee: '1,00 €',
      duration: 'PAYMENT_PROVIDERS.TWO_THREE_WORKING_DAYS',
      currency: 'PAYMENT_PROVIDERS.EURO_LABEL',
      info: 'PAYMENT_PROVIDERS.TABLE_TWO.INFO_DATA.BANK'
    },
    {
      id: 'visa2',
      name: 'VISA',
      logoSrc: '/assets/images/payment-providers/visa.png',
      fee: '',
      feeValue: '1,00 €',
      duration: 'PAYMENT_PROVIDERS.ONE_TWO_WORKING_DAYS',
      currency: 'PAYMENT_PROVIDERS.EURO_LABEL',
      info: 'PAYMENT_PROVIDERS.TABLE_TWO.INFO_DATA.VISA',
      hideCountries: [
        'DE'
      ]
    },
    {
      id: 'ecopayz2',
      name: 'ecoPayz',
      logoSrc: '/assets/images/payment-providers/ecopayz.png',
      fee: '1,00 €',
      duration: 'PAYMENT_PROVIDERS.ONE_TWO_WORKING_DAYS',
      currency: 'PAYMENT_PROVIDERS.EURO_LABEL',
      info: 'PAYMENT_PROVIDERS.TABLE_TWO.INFO_DATA.ECOPAYZ'
    },
    {
      id: 'mastercard2',
      name: 'MasterCard',
      logoSrc: '/assets/images/payment-providers/mastercard.png',
      fee: '',
      feeValue: '1,00 €',
      duration: 'PAYMENT_PROVIDERS.ONE_TWO_WORKING_DAYS',
      currency: 'PAYMENT_PROVIDERS.EURO_LABEL',
      info: 'PAYMENT_PROVIDERS.TABLE_TWO.INFO_DATA.MASTERCARD'
    },
    {
      id: 'neteller2',
      name: 'Neteller',
      logoSrc: '/assets/images/payment-providers/neteller.png',
      fee: '',
      feeValue: '1,00 €',
      duration: 'PAYMENT_PROVIDERS.ONE_TWO_WORKING_DAYS',
      currency: 'PAYMENT_PROVIDERS.EURO_LABEL',
      info: 'PAYMENT_PROVIDERS.TABLE_TWO.INFO_DATA.NETELLER'
    },
    {
      id: 'skrill2',
      name: 'Skrill',
      logoSrc: '/assets/images/payment-providers/skrill.png',
      fee: '',
      feeValue: '1,00 €',
      duration: 'PAYMENT_PROVIDERS.ONE_TWO_WORKING_DAYS',
      currency: 'PAYMENT_PROVIDERS.EURO_LABEL',
      info: 'PAYMENT_PROVIDERS.TABLE_TWO.INFO_DATA.SKRILL'
    },
    {
      id: 'brite2',
      name: 'Brite',
      logoSrc: '/assets/images/payment-providers/brite.png',
      fee: '',
      feeValue: '1,00 €',
      duration: 'PAYMENT_PROVIDERS.ONE_TWO_WORKING_DAYS',
      currency: 'PAYMENT_PROVIDERS.EURO_LABEL',
      info: 'PAYMENT_PROVIDERS.TABLE_TWO.INFO_DATA.BRITE'
    }
  ]

  paymentType
  scrollToSetTimeout
  userCountryCode

  /**
     * @Consturctor
     */
  constructor(
  parent: CoreLayout,
    private navigationService: NavigationService,
    private _scrollToService: ScrollToService,
    route: ActivatedRoute,
    private platformService: PlatformService
  ) {
    this.layout = parent;

    route.queryParams.subscribe((data) => {
      if (typeof (data.type) !== 'undefined') {
        const type = data.type;
        this.paymentType = type;
      }
    });
  }

  /**
   * It's native Angular 'on initiation' method.
   * Fired after constructor.
   */
  ngOnInit() {
    if (this.platformService.server)
      return;

    this.scrollToSetTimeout = setTimeout(() => {
      if (typeof (this.paymentType) === 'undefined') {
        return;
      }
      const config: ScrollToConfigOptions = {
        target: `type-t1-${  this.paymentType}`,
        duration: 333,
        offset: 370
      };
      this.showInfo(this.paymentType);
      this._scrollToService.scrollTo(config);
    }, 1000);
  }

  /**
     * Shows information.
     * @param {String} slug
     */
  showInfo(slug) {
    if (this.openedInfo === slug) {
      this.openedInfo = '';

      return;
    }

    this.openedInfo = slug;
  }

  showProvider(provider) {
    return !provider.hideCountries || provider.hideCountries.indexOf(this.userCountryCode) === -1;
  }

  /**
   * It's native Angular 'on destroy' method.
   * Destroys component.
   */
  ngOnDestroy() {
    if (typeof (this.scrollToSetTimeout) !== 'undefined') {
      clearTimeout(this.scrollToSetTimeout);
    }
  }
}
