import { Component, Host, OnInit, NgZone, HostListener, AfterViewInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CoreLayout } from '../../layouts/index';
import { commonPagesHelper } from '../../helpers/index';
import {
  globalConstants,
  routingConstants,
  storageConstants,
} from '../../constants/index';
import {
  VendorRequestService,
  GameRequestService,
  NavigationService,
  UserRequestService,
  CasinoService,
  CustomCategoryRequestService,
  GameService,
  UserService,
} from '../../services/index';
//import { MetaService } from '@ngx-meta/core';
import { Meta, MetaDefinition, Title } from '@angular/platform-browser';
import {
  ScrollToService,
  ScrollToConfigOptions,
} from '@nicky-lenaers/ngx-scroll-to';
import { TranslateService } from '@ngx-translate/core';
import { PlatformService } from '../../services/platform.service';
import { noop } from 'rxjs';
import * as _ from 'lodash';
import {
  liveCasinoGames
} from '../../data/livecasino.data';
import Splide from '@splidejs/splide';
@Component({
  selector: 'all-games',
  templateUrl: './allGames.component.html',
  styleUrls: ['./allGames.component.scss'],
})
export class AllGamesComponent implements OnInit, AfterViewInit {
  @HostListener('window:resize', ['$event']) _onResize() {
    this.onAsyncResize();
  }
  onAsyncResize
  onResizeSubscribers = {}
  layout

  vendors
  selectedVendor
  totalGames
  currentPage
  previousPage
  nextPage
  currentLanguage

  imagesPath = globalConstants['IMAGES_DOMAIN']
  defaultImages = globalConstants['DEFAULT_IMAGES']
  routingConstants = routingConstants

  translationKeyTitle
  translationKeyImage
  categoryIcon
  filterValue
  selectedCategory = undefined

  filterAnchors

  defaultGameList = {
    firstLoaded: false,
    fullLoaded: false,
    loading: true,
    offset: 0,
    limit: 32,
    requestMethod: 'listRequest',
    options: {},
    dataPages: [],
    liveCasinoDataPages: {
      top: [],
      second: [],
      third: [],
      bottom: []
    },
    hasData: false
  }

  gameList = Object.assign({}, this.defaultGameList)

  routeQueryParamsSubscribe
  routeParamsSubscribe

  scrollToLatestPosition = false

  isMobile = false
  showVendors = true

  pageDescription
  asyncRefreshGames

  /**
     * @constructor
     */
  constructor(
   parent: CoreLayout,
    private route: ActivatedRoute,
    private gameRequestService: GameRequestService,
    private navigationService: NavigationService,
    //private metaService: MetaService,
    private metaService: Meta,
    private titleService:Title,
    private vendorRequestService: VendorRequestService,
    private userRequestService: UserRequestService,
    public _scrollToService: ScrollToService,
    private casinoService: CasinoService,
    private gameService: GameService,
    private userService: UserService,
    public _translate: TranslateService,
    private customCategoryRequestService: CustomCategoryRequestService,
    private zone: NgZone,
    private platformService: PlatformService
  ) {
    this.layout = parent;
    this.currentLanguage = parent.appLanguageService.currentLanguage;
    if (!this.currentLanguage) {
      this.currentLanguage = parent.appLanguageService.getLanguageFromStorage();
    }

    this.isMobile = this.casinoService.isMobile();

    if (this.isMobile) {
      this.showVendors = false;
    }

    this.totalGames = 0;
    this.currentPage = 0;
    this.previousPage = 0;
    this.nextPage = 1;
    this.routeQueryParamsSubscribe = route.queryParams.subscribe(() => noop);
    this.routeParamsSubscribe = this.route.params.subscribe(data => {
      this.filterValue = data.filter;

      if (
        this.platformService.browser &&
        sessionStorage.getItem(storageConstants['FOLLOW_PREVIOUS_URL_POSITION'])
      ) {
        this.defaultGameList.limit = Number(
          sessionStorage.getItem('GAME_LIST_OFFSET')
        );
        this.scrollToLatestPosition = true;
        sessionStorage.removeItem(
          storageConstants['FOLLOW_PREVIOUS_URL_POSITION']
        );
      } else {
        this.defaultGameList.limit = 32;
        this.scrollToLatestPosition = false;
      }

      this.gameList = Object.assign({}, this.defaultGameList, {
        dataPages: [],
        liveCasinoDataPages: {
          top: [],
          second: [],
          third: [],
          bottom: []
        }
      });

      if (data.page) {
        this.currentPage = parseInt(data.page, 10);

        if (this.currentPage > 0) {
          this.previousPage = this.currentPage - 1;
          this.nextPage = this.currentPage + 1;
        } else {
          this.previousPage = 0;
          this.nextPage = 1;
        }
      } else {
        this.currentPage = 0;
        this.previousPage = 0;
        this.nextPage = 1;
      }

      this.getVendorsData();

      if (typeof data.category !== 'undefined') {
        this.filterValue = 'category';
        this.selectedVendor = data.vendor;
        this.selectedCategory = data.category;
        this.getGameList(this.filterValue, data.vendor, data.category);
        
        return;
      }

      if (data.filter && data.vendor) {
        this.selectedVendor = data.vendor;
        this.getGameList(data.filter, data.vendor);
        
        return;
      }

      if (!data.filter && !data.vendor) {
        this.selectedVendor = '';
        this.getGameList();
        
        return;
      }

      if (data.filter) {
        this.getGameList(data.filter);
      } else {
        this.filterValue = '';
      }

      if (data.vendor) {
        this.getGameList(undefined, data.vendor);
      } else {
        this.selectedVendor = '';
      }
    });

    this.onAsyncResize = _.debounce(this.onResize.bind(this), 500);
    this.asyncRefreshGames = _.debounce(this.refreshGames.bind(this), 0);
  }

  /**
     * It's native Angular 'on initiation' method.
     * Fired after constructor.
     */
  ngOnInit() {
    if (this.platformService.browser) {
      localStorage.setItem(
        storageConstants['FOLLOW_PREVIOUS_URL'],
        '1'
      );
    }
  }

  /**
     * Gets vendor list data.
     */
  getVendorsData() {
    this.vendorRequestService.listRequest().subscribe(
      data => {
        if (data.vendors && data.vendors.length > 0) {
          this.vendors = data.vendors.filter(vendor => {
            return vendor.isActive;
          });
        }
      },
      error => {
        console.error('error -->', error);
      }
    );
  }

  trackByFn(index) {
    return index; // or item.id
  }

  /**
   * Gets game list.
   * @param {String} filter
   */
  getGameList(
    filter = undefined,
    vendorValue = undefined,
    categorySlug = undefined
  ) {
    const self = this;


    if (typeof filter !== 'undefined') {
      switch (filter) {
        case 'category':
          this.gameList.options = {
            searchField: 'gameCategory.slug',
            searchString: categorySlug,
            orderField: 'itemOrder',
            orderDirection: 'asc',
          };
          break;

        case 'livecasino':
          this.translationKeyTitle = 'ALL_GAMES.LIVE_CASINO_GAMES_HEADER';
          this.categoryIcon = 'icon-icon12';
          this.gameList.options = {
            searchField: 'category',
            searchString: 'livecasino',
            orderField: 'itemOrder',
            orderDirection: 'asc',
          };
          break;

        case 'hot':
          this.translationKeyTitle = 'ALL_GAMES.HOT_GAMES_HEADER';
          this.categoryIcon = 'icon-icon10';
          this.gameList.options = {
            searchField: 'isPromotedAsTop',
            searchString: '1',
            orderField: 'itemOrder',
            orderDirection: 'asc',
          };
          break;

        case 'new':
          this.translationKeyTitle = 'ALL_GAMES.LATEST_GAMES_HEADER';
          this.categoryIcon = 'icon-icon14';
          this.gameList.options = {
            searchField: 'isPromotedAsNew',
            searchString: '1',
            orderField: 'itemOrder',
            orderDirection: 'asc',
          };
          break;

        case 'latest':
          this.translationKeyTitle = 'ALL_GAMES.LATEST_GAMES_HEADER';
          this.categoryIcon = 'icon-icon14';
          this.gameList.options = {
            orderField: 'id',
            orderDirection: 'desc',
          };
          break;

        case 'slots':
          this.translationKeyTitle = 'ALL_GAMES.SLOT_GAMES_HEADER';
          this.categoryIcon = 'icon-icon18';
          this.gameList.options = {
            searchField: 'category',
            searchString: 'slot',
            orderField: 'itemOrder',
            orderDirection: 'asc',
          };
          break;

        case 'slots-with-free-game':
          this.translationKeyTitle = 'ALL_GAMES.SLOT_WITH_FREE_GAME_HEADER';
          this.gameList.requestMethod = 'withFreeSpinsListRequest';
          this.categoryIcon = 'icon-icon18';
          this.gameList.options = {
            orderField: 'itemOrder',
            orderDirection: 'asc',
          };
          break;

        case 'slots-without-free-game':
          this.translationKeyTitle = 'ALL_GAMES.SLOT_WITHOUT_FREE_GAME_HEADER';
          this.gameList.requestMethod = 'withoutFreeSpinsListRequest';
          this.categoryIcon = 'icon-icon18';

          this.gameList.options = {
            orderField: 'itemOrder',
            orderDirection: 'asc',
          };
          break;

        case 'videopoker':
          this.translationKeyTitle = 'ALL_GAMES.VIDEOPOKER_GAMES_HEADER';
          this.categoryIcon = 'icon-icon20';

          this.gameList.options = {
            searchField: 'category',
            searchString: 'videopoker',
            orderField: 'itemOrder',
            orderDirection: 'asc',
          };
          break;

        case 'scratchcards':
          this.translationKeyTitle = 'ALL_GAMES.SCRATCHCARDS_GAMES_HEADER';
          this.categoryIcon = 'icon-icon17';

          this.gameList.options = {
            searchField: 'category',
            searchString: 'scratchcard',
            orderField: 'itemOrder',
            orderDirection: 'asc',
          };
          break;

        case 'virtual-games':
          this.translationKeyTitle = 'ALL_GAMES.VIRTUAL_GAMES_HEADER';
          this.categoryIcon = 'icon-icon21';

          this.gameList.options = {
            searchField: 'category',
            searchString: 'virtual-games',
            orderField: 'itemOrder',
            orderDirection: 'asc',
          };
          break;

        case 'table':
          this.translationKeyTitle = 'ALL_GAMES.TABLE_GAMES_HEADER';
          this.categoryIcon = 'icon-icon19';

          this.gameList.options = {
            searchField: 'category',
            searchString: 'table',
            orderField: 'itemOrder',
            orderDirection: 'asc',
          };
          break;

        case 'other':
          this.translationKeyTitle = 'ALL_GAMES.OTHER_GAMES_HEADER';
          this.categoryIcon = 'icon-icon8';

          this.gameList.options = {
            searchField: 'category',
            searchString: 'other',
            orderField: 'itemOrder',
            orderDirection: 'asc',
          };
          break;

        case 'your-favourite':
          this.translationKeyTitle = 'ALL_GAMES.FAVOURITE_GAMES_HEADER';
          this.categoryIcon = 'icon-icon22';

          this.gameList.requestMethod = 'favouriteListRequest';
          this.gameList.options = {
            orderField: 'id',
            orderDirection: 'desc',
          };
          break;

        case 'last-played':
          this.translationKeyTitle = 'ALL_GAMES.LAST_PLAYED_GAMES_HEADER';
          this.gameList.requestMethod = 'lastPlayedListRequest';
          this.categoryIcon = 'icon-icon11';

          this.gameList.options = {
            orderField: 'itemOrder',
            orderDirection: 'asc',
          };
          break;

        default:
          this.translationKeyTitle = 'ALL_GAMES.ALL_GAMES_HEADER';
          this.categoryIcon = 'all-games-icon';

          this.gameList.options = {
            orderField: 'itemOrder',
            orderDirection: 'asc',
          };
          break;
      }
    } else {
      this.translationKeyTitle = 'ALL_GAMES.ALL_GAMES_HEADER';
      this.categoryIcon = 'all-games-icon';

      this.gameList.options = {
        orderField: 'itemOrder',
        orderDirection: 'asc',
      };
    }

    if (typeof vendorValue !== 'undefined') {
      this.gameList.options['searchField'] = this.gameList.options[
        'searchField'
      ]
        ? `${this.gameList.options['searchField']  },vendor.name`
        : 'vendor.name';
      this.gameList.options['searchString'] = this.gameList.options[
        'searchString'
      ]
        ? `${this.gameList.options['searchString']  },${  vendorValue}`
        : vendorValue;
    }

    if (this.gameList.fullLoaded) {
      return;
    }

    if (this.gameList.firstLoaded) {
      this.gameList.offset += this.gameList.limit;
      if (this.platformService.browser) {
        sessionStorage.setItem(
          'GAME_LIST_OFFSET',
          this.gameList.offset.toString()
        );
      }
      this.scrollToLatestPosition = false;
    } else {
      this.gameList.firstLoaded = true;
      const offsetForGameList =
        this.gameList.offset > 0
          ? this.gameList.offset
          : this.defaultGameList.limit;
      if (this.platformService.browser)
        sessionStorage.setItem('GAME_LIST_OFFSET', offsetForGameList.toString());
    }

    this.gameList.loading = true;

    // TODO wydzielic request do nowego
    this.gameRequestService[this.gameList.requestMethod](
      this.gameList.options,
      this.gameList.offset,
      this.gameList.limit
    ).subscribe(data => {
      if(filter == 'livecasino') {
        const games = [];
        let hasMobileImages = false;

        data.games.forEach(game => {
          if(game && game.urlSlug) {
            for(const type in liveCasinoGames) {
              for(const img in liveCasinoGames[type]) {
                let _game = {
                  ...game
                };

                let _slug = liveCasinoGames[type][img].slug;

                if(this.isMobile) {
                  _slug += '-mobile';
                }

                if(_slug == game.urlSlug) {
                  const images = Object.keys(liveCasinoGames[type]);
                  const {
                    showTitle,
                    liveText,
                    btnText,
                    hasMobileImage
                  } = liveCasinoGames[type][img];
                  const indexOfImg = images.indexOf(img);
                  const imgBasePath = `/assets/images/games/${img}`;

                  _game.gameImageUrl = `${imgBasePath}.jpg`;
                  _game.originalGameImageUrl = _game.gameImageUrl;
                  _game.title = showTitle ? game.name : null;
                  _game.liveText = liveText;
                  _game.btnText = btnText;
                  _game.index = indexOfImg;
                  _game.type = type;
                  _game.hasMobileImage = hasMobileImage;
                  _game.gameMobileImageUrl = hasMobileImage ? `${imgBasePath}_mobile.jpg` : null;

                  if(hasMobileImage) {
                    hasMobileImages = true;
                  }

                  if(type == 'second') {
                    _game.place = indexOfImg % 2 ? 'even' : 'odd';
                  }
                  else if(type == 'fourth' || type == 'bottom') {
                    _game.place = indexOfImg % 3 == 0 ? 'first' : (indexOfImg % 3 == 1 ? 'middle' : 'third')
                  }

                  games.push(_game);
                }
              };
            }
          }
        });

        let loadedGames = 0;

        const topGames = games.filter(game => game.type == 'top');
        const sortedTopGames = topGames.sort((gameA, gameB) => gameA.index - gameB.index);
        self.gameList.liveCasinoDataPages['top'] = sortedTopGames;
        loadedGames += sortedTopGames.length;

        const secondGames = games.filter(game => game.type == 'second');
        const sortedSecondGames = secondGames.sort((gameA, gameB) => gameA.index - gameB.index);
        self.gameList.liveCasinoDataPages['second'] = sortedSecondGames;
        loadedGames += sortedSecondGames.length;

        const thirdGames = games.filter(game => game.type == 'third');
        const sortedThirdGames = thirdGames.sort((gameA, gameB) => gameA.index - gameB.index);
        self.gameList.liveCasinoDataPages['third'] = sortedThirdGames;
        loadedGames += sortedThirdGames.length;

        const fourthGames = games.filter(game => game.type == 'fourth');
        const sortedFourthGames = fourthGames.sort((gameA, gameB) => gameA.index - gameB.index);
        loadedGames += sortedFourthGames.length;

        const bottomGames = games.filter(game => game.type == 'bottom');
        const sortedBottomGames = bottomGames.sort((gameA, gameB) => gameA.index - gameB.index);
        self.gameList.liveCasinoDataPages['bottom'] = [
          ...sortedFourthGames,
          ...sortedBottomGames
        ];
        loadedGames += sortedBottomGames.length;

        self.gameList.hasData = loadedGames > 0;
        self.gameList.loading = false;
        self.gameList.fullLoaded = true;

        if(hasMobileImages) {
          let lastViewportUpdateWasDesktop;

          this.onResizeSubscribers['livecasino'] = () => {
            const viewportWidth = window.innerWidth;
            const currentViewPortIsDesktop = viewportWidth > 480;

            for(const type in this.gameList.liveCasinoDataPages) {
              this.gameList.liveCasinoDataPages[type].forEach(game => {
                if(game.hasMobileImage) {
                  if (viewportWidth <= 480) {
                    game.gameImageUrl = game.gameMobileImageUrl;
                  }
                  else {
                    game.gameImageUrl = game.originalGameImageUrl;
                  }
                }
              });
            }

            if(currentViewPortIsDesktop && !lastViewportUpdateWasDesktop ||
              !currentViewPortIsDesktop && lastViewportUpdateWasDesktop) {
              this.asyncRefreshGames();
            }

            lastViewportUpdateWasDesktop = viewportWidth > 480;
          }

          this.onResize();
        }
      }
      else {
        self.gameList.dataPages.push(data.games);
        self.gameList.loading = false;
        const countOfAllGames = data.total;
        const countOfLoadedGames = self.gameList.dataPages.map(it => it.length).reduce((a, b) => a + b, 0);

        if (countOfAllGames <= countOfLoadedGames) {
          self.gameList.fullLoaded = true;
        }
      }

      if (typeof (this.translationKeyTitle) !== 'undefined') {
        let metaTitle = this._translate.instant(this.translationKeyTitle);
        if (typeof (vendorValue) !== 'undefined') {
          metaTitle += ` - ${  vendorValue}`;
        }

        this.titleService.setTitle(metaTitle);
      }

      if (data.pageContent) {
        this.pageDescription = data.pageContent.content;
        this.metaService.addTag({name: 'description',content: data.pageContent.metaDescription});
        // * Set Meta Title
        if (data.pageContent.metaTitle) {
          this.titleService.setTitle(data.pageContent.metaTitle);
        } else {
          // * If Page Content is null, set Default Game Category Page Title Per Lang
          this.titleService.setTitle(this.getDefaultMetaTitle());
        }
      }

      if (data.gameCategory) {
        this.categoryIcon = null;
        this.translationKeyTitle = data.gameCategory.name;
        if (data.gameCategory.image) {
          this.translationKeyImage = data.gameCategory.image;
        } else {
          this.translationKeyImage = null;
        }
      }

      if (this.platformService.browser && this.scrollToLatestPosition) {
        setTimeout(() => {
          const offset = Number(
            sessionStorage.getItem(storageConstants['PREVIOUS_URL_DATA'])
          );
          const duration = 0;
          const config: ScrollToConfigOptions = {
            offset,
            duration,
          };
          this._scrollToService.scrollTo(config);
        }, 100);
      }
    },
    () => {
      self.gameList.loading = false;
    });
  }

  refreshGames() {
    const games = Object.assign([], this.gameList.dataPages);
    const liveCasinoGames = Object.assign({}, this.gameList.liveCasinoDataPages);

    this.gameList.dataPages = [
      ...games
    ];

    this.gameList.liveCasinoDataPages.top = [];
    this.gameList.liveCasinoDataPages.second = [];
    this.gameList.liveCasinoDataPages.third = [];
    this.gameList.liveCasinoDataPages.bottom = [];

    setTimeout(() => {
      this.gameList.liveCasinoDataPages.top = [
        ...liveCasinoGames.top
      ];
  
      this.gameList.liveCasinoDataPages.second = [
        ...liveCasinoGames.second
      ];
  
      this.gameList.liveCasinoDataPages.third = [
        ...liveCasinoGames.third
      ];
  
      this.gameList.liveCasinoDataPages.bottom = [
        ...liveCasinoGames.bottom
      ];
    }, 0);
  }

  /**
   * On window resize handler.
   * @param {Object} event
   */
  onResize(event = undefined) {
    if (typeof window === 'undefined') return;

    if(this.onResizeSubscribers && Object.keys(this.onResizeSubscribers).length) {
      for(const subscriber in this.onResizeSubscribers) {
        this.onResizeSubscribers[subscriber]();
      }
    }    
  }

  /**
   * Returns true if previous page is possible.
   * @returns {Boolean}
   */
  previousPageIsPossible() {
    if (this.currentPage > 0) {
      return true;
    }
    
    return false;
  }

  /**
   * Returns true if next page is possible.
   * @returns {Boolean}
   */
  nextPageIsPossible() {
    if (
      this.currentPage * this.gameList.limit + this.gameList.limit <
      this.totalGames
    ) {
      return true;
    }
    
    return false;
  }
  removeFromFavouritesArray(game:any){
    this.gameList.dataPages[0] = this.gameList.dataPages[0].filter(item => item.id != game.id)
 }
  /**
   * Opens (and checks acces) the game.
   * @param {Object} game
   */
  openGame(game) {
    if (this.userService.isLogged()) {
      this.gameService.goToGameDetailsWithPlay(game, true);
    } else {
      this.showLoginPanel(game);
    }
  }

  /**
   * Show login form
   * @param {Object} game
   */
  showLoginPanel(gameToOpen = null) {
    localStorage.setItem(
      storageConstants['PREVIOUS_URL'],
      this.gameService.goToGameDetails(gameToOpen)
    );
    commonPagesHelper.loginPanel('show');
  }

  getDefaultMetaTitle(): string {
    if (this.currentLanguage) {
      const lang = this.currentLanguage.toUpperCase();

      return routingConstants[lang]['ALL_GAMES_CATEGORY']['data']['meta']['title'];
    } else {

      return routingConstants['EN']['ALL_GAMES_CATEGORY']['data']['meta']['title'];
    }
  }

  /**
   * It's native Angular 'on destroy' method.
   * Destroys component.
   */
  ngOnDestroy() {
    commonPagesHelper.unsubscribeObserves([
      this.routeQueryParamsSubscribe,
      this.routeParamsSubscribe,
    ]);
  }

  onScroll() {
    this.zone.runOutsideAngular(() => {
      if (typeof this.selectedVendor === 'undefined' || !this.selectedVendor) {
        this.getGameList(this.filterValue, undefined, this.selectedCategory);
      } else {
        this.getGameList(
          this.filterValue,
          this.selectedVendor,
          this.selectedCategory
        );
      }
    });
  }

  ngAfterViewInit() {
    new Splide('#sliderAll', 
    {
      updateOnMove: true,
      type: 'slide',
      start: 0,
      perPage: 7,
      autoplay: false,
      perMove: 1,
      breakpoints: {
          912: {
              perPage: 5,
          },
          640: {
              perPage: 3,
          },
          280: {
              perPage: 2,
          },
      },
      rewind: false,
      width: '100%',
      height: 'auto',
      arrows: true,
      interval: 6000,
      pagination: false,
      pauseOnHover: true,
      wheel: false,
      padding: 0,
      drag: true,
      direction: 'ltr',
      clones: 0,
      cloneStatus: false,
      }
    ).mount();

    new Splide('#sliderTop', 
    {
      updateOnMove: true,
      type: 'slide',
      start: 0,
      perPage: 7,
      autoplay: false,
      perMove: 1,
      breakpoints: {
          912: {
              perPage: 5,
          },
          640: {
              perPage: 3,
          },
          280: {
              perPage: 2,
          },
      },
      rewind: false,
      width: '100%',
      height: 'auto',
      arrows: true,
      interval: 6000,
      pagination: false,
      pauseOnHover: true,
      wheel: false,
      padding: 0,
      drag: true,
      direction: 'ltr',
      clones: 0,
      cloneStatus: false,
      }
    ).mount();

    new Splide('#sliderSecond', 
    {
      updateOnMove: true,
      type: 'slide',
      start: 0,
      perPage: 7,
      autoplay: false,
      perMove: 1,
      breakpoints: {
          912: {
              perPage: 5,
          },
          640: {
              perPage: 3,
          },
          280: {
              perPage: 2,
          },
      },
      rewind: false,
      width: '100%',
      height: 'auto',
      arrows: true,
      interval: 6000,
      pagination: false,
      pauseOnHover: true,
      wheel: false,
      padding: 0,
      drag: true,
      direction: 'ltr',
      clones: 0,
      cloneStatus: false,
      }
    ).mount();

    new Splide('#sliderThird', 
    {
      updateOnMove: true,
      type: 'slide',
      start: 0,
      perPage: 7,
      autoplay: false,
      perMove: 1,
      breakpoints: {
          912: {
              perPage: 5,
          },
          640: {
              perPage: 3,
          },
          280: {
              perPage: 2,
          },
      },
      rewind: false,
      width: '100%',
      height: 'auto',
      arrows: true,
      interval: 6000,
      pagination: false,
      pauseOnHover: true,
      wheel: false,
      padding: 0,
      drag: true,
      direction: 'ltr',
      clones: 0,
      cloneStatus: false,
      }
    ).mount();

    new Splide('#sliderBottom', 
    {
      updateOnMove: true,
      type: 'slide',
      start: 0,
      perPage: 7,
      autoplay: false,
      perMove: 1,
      breakpoints: {
          912: {
              perPage: 5,
          },
          640: {
              perPage: 3,
          },
          280: {
              perPage: 2,
          },
      },
      rewind: false,
      width: '100%',
      height: 'auto',
      arrows: true,
      interval: 6000,
      pagination: false,
      pauseOnHover: true,
      wheel: false,
      padding: 0,
      drag: true,
      direction: 'ltr',
      clones: 0,
      cloneStatus: false,
      }
    ).mount();

  }

}
