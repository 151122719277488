import { Injectable, OnDestroy } from '@angular/core';
import { HttpResponse } from '@angular/common/http';
import { UserRequestService } from './request/userRequest.service';
import { UserService } from './user.service';

import { noop } from 'rxjs';
import { map } from 'rxjs/operators';
import { AccessTokenService } from './access-token.service';
import { storageConstants } from '../constants';

@Injectable({ providedIn: 'root' })
export class AuthenticationService implements OnDestroy {
  userLoginSubscriber;

  constructor(private userRequestService: UserRequestService, private userService: UserService, private accessToken: AccessTokenService) {}

  /**
   * Login method.
   * Sets authorized user to local storage.
   * @param {String} username
   * @param {String} password
   * @returns <http:post>
   */
  login(username: string, password: string) {
    return this.userRequestService.login(username, password).pipe(
      map((response: HttpResponse<any>) => {
        const data = response;

        if (data && data['access_token']) {
          if (username.indexOf('@') > -1) {
            data['email'] = username;
          }

          // * Set AccessToken
          this.accessToken.setAccessToken(data);
          localStorage.removeItem(storageConstants['VISITED_PAGES']);
        } else {
          this.showError(JSON.stringify(data));
        }
      })
    );
  }

  /**
   * Login as user method.
   * Sets authorized user by token to local storage.
   * @param {String} token
   * @returns <http:post>
   */
  loginAsUser(token: string) {
    return this.userRequestService.loginAsUser(token).pipe(
      map((response: HttpResponse<any>) => {
        const data = response;

        if (data && data['access_token']) {
          // * Set Access Token
          this.accessToken.setAccessToken(data);
          this.userLoginSubscriber = this.userRequestService.detailsRequest().subscribe(
            userData => {
              if (userData.result !== 'ok') {
                return;
              }
              if (!(typeof userData.user === 'object' && userData.user.locale)) {
                return;
              }

              this.userService.storeUserData(userData.user);
            },
            error => {
              console.error(error);
            }
          );
        } else {
          this.showError(JSON.stringify(data));
        }
      })
    );
  }

  /**
   * Logout method.
   * Removes current user from local storage.
   */
  logout() {
    return this.userRequestService.logout().pipe(
      map(() => {
        this.userService.destroyStoredData();
      })
    );
  }

  /**
   * Shows error
   * @param {String} message
   */
  showError(message: string) {
    window.alert(message);
  }

  ngOnDestroy() {
    if (this.userLoginSubscriber) {
      this.userLoginSubscriber.unsubscribe();
    }
  }
}

