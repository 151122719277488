<!--<div class="languageSwitcherMask" (click)="closeDropdown()" *ngIf="isActive"></div>-->
<div class="languageSwitcher" (click)="toggleDropdown()">
	<span class="languageSwitcher__flag" attr.data-language="{{ currentLanguage }}"></span>
	<span class="languageSwitcher__decorator">▾</span>

	<div class="languageSwitcher__dropdown" [class.active]="isActive">
		<div class="languageSwitcher__dropdown-inside">
			<a class="languageSwitcher__dropdown-inside_item" (click)="setLanguageByHand(language.value)" *ngFor="let language of availableLanguages" [attr.data-value]="language.value" [class.selected]="language.value === currentLanguage"><img src="{{ language.flag }}" alt="{{ language.alternativeText }}"></a>
		</div>
	</div>
</div>