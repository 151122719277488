import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { NavigationService } from '../services/index';
import { storageConstants } from '../constants/index';
import { AccessTokenService } from '../services/access-token.service';


@Injectable() export class AuthGuard implements CanActivate {

  constructor(private router: Router, private navigationService: NavigationService, private accessToken: AccessTokenService) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (this.accessToken.isAccessTokenSet) {
      return true;
    }

    localStorage.setItem(storageConstants['PREVIOUS_URL'], state.url);
    this.router.navigateByUrl(this.navigationService.constructUrl('LOGIN'));

    return false;
  }
}
