<div class="container container--noVerticalPadding container--darker">
  <ng-container *ngFor="let tabItem of tabs.data">
      <h1 class="big withoutStar" *ngIf="(tabs.activeValue === tabItem.value)">{{ tabItem.nameTranslationKey | translate }}</h1>
  </ng-container>
</div>

<div class="container">
	<ul class="cashierComponentTabs">
    <div *ngFor="let tabItem of tabs.data">
      <li *ngIf="tabItem.hide != true" class="cashierComponentTabs__item"  [class.cashierComponentTabs__item--active]="(tabs.activeValue === tabItem.value)" (click)="tabChange(tabItem.value)">
        <a class="cashierComponentTabs__item-anchor">{{ tabItem.nameTranslationKey | translate }}</a>
      </li>
    </div>

	</ul>
	<div class="cashierComponent">
		<div id="cashierComponent1" *ngIf="isMobile" ></div>

		<div class="cashierComponent__item" [class.cashierComponent__item--active]="(tabs.activeValue === 'overview')">
			<cashier-overview #overviewTab></cashier-overview>
		</div>

		<div class="cashierComponent__item" [class.cashierComponent__item--active]="(tabs.activeValue === 'payIn')">
			<cashier-pay-in #payInTab></cashier-pay-in>
		</div>

		<div class="cashierComponent__item" [class.cashierComponent__item--active]="(tabs.activeValue === 'payOut')">
			<cashier-pay-out #payOutTab></cashier-pay-out>
		</div>

		<div class="cashierComponent__item" [class.cashierComponent__item--active]="(tabs.activeValue === 'promoCode')">
			<cashier-promo-code #promoCodeTab></cashier-promo-code>
		</div>

		<div class="cashierComponent__item" [class.cashierComponent__item--active]="(tabs.activeValue === 'transactions')">
			<cashier-transactions #transactionsTab></cashier-transactions>
		</div>

		<div class="cashierComponent__item" [class.cashierComponent__item--active]="(tabs.activeValue === 'gameRounds')">
			<cashier-game-rounds #gameRoundsTab></cashier-game-rounds>
		</div>

		<div class="cashierComponent__item" [class.cashierComponent__item--active]="(tabs.activeValue === 'limits')">
			<cashier-limits #limitsTab></cashier-limits>
		</div>

		<div class="cashierComponent__item" [class.cashierComponent__item--active]="(tabs.activeValue === 'blockAccount')">
			<cashier-block-account #blockAccountTab></cashier-block-account>
		</div>

		<div class="cashierComponent__item" [class.cashierComponent__item--active]="(tabs.activeValue === 'documents')">
			<cashier-documents #documentsTab></cashier-documents>
		</div>

	</div>
</div>
