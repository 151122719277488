import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';
import { NavigationService, UserService } from '../services/index';


@Injectable() export class FullyRegistered implements CanActivate {

  /**
   * @constructor
   */
  constructor(
    private router: Router,
    private navigationService: NavigationService,
    private userService: UserService
  ) { }

  /**
   * Returns false if the user is logged in.
   * @returns {Boolean}
   */
  canActivate(): boolean {
    if (!this.userService.isFullyRegistered()) {
      this.router.navigateByUrl(this.navigationService.constructUrl('USER_ACCOUNT'));
      
      return false;
    }

    return true;
  }
}