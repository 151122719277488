import { Component } from '@angular/core';
import { ContactFormModel } from '../../models/index';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { regExpValidationConstants } from '../../constants/index';
import { ContactRequestService, ComboNotificationService, UserService } from '../../services/index';
import { TranslateService } from '@ngx-translate/core';
import { commonPagesHelper, formHelper } from '../../helpers/index';
import { ComboNotificationType } from '../../enums/alert-type.enum';

@Component({
  selector: 'contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})

export class ContactComponent {
  contactFormModel
  sending = false
  hasBeenSent = false
  successMessage = null
  disableUserFields = false

  validationMessages = {
    'email': {
      'required': 'GLOBAL.VALIDATION_MESSAGES.REQUIRED',
      'pattern': 'GLOBAL.VALIDATION_MESSAGES.WRONG_FORMAT_EMAIL'
    },
    'subject': {
      'required': 'GLOBAL.VALIDATION_MESSAGES.REQUIRED'
    },
    'firstName': {
      'required': 'GLOBAL.VALIDATION_MESSAGES.REQUIRED',
      'minlength': 'GLOBAL.VALIDATION_MESSAGES.LEAST_TWO_CHARACTERS_LONG',
      'maxlength': 'GLOBAL.VALIDATION_MESSAGES.MORE_THAN_NINETYSIX_CHARACTERS_LONG'
    },
    'lastName': {
      'required': 'GLOBAL.VALIDATION_MESSAGES.REQUIRED',
      'minlength': 'GLOBAL.VALIDATION_MESSAGES.LEAST_TWO_CHARACTERS_LONG',
      'maxlength': 'GLOBAL.VALIDATION_MESSAGES.MORE_THAN_NINETYSIX_CHARACTERS_LONG'
    },
    'phone': {
      'required': 'GLOBAL.VALIDATION_MESSAGES.REQUIRED',
      'minlength': 'GLOBAL.VALIDATION_MESSAGES.LEAST_SEVEN_CHARACTERS_LONG',
      'maxlength': 'GLOBAL.VALIDATION_MESSAGES.MORE_THAN_THIRTYSIX_CHARACTERS_LONG',
      'pattern': 'GLOBAL.VALIDATION_MESSAGES.INVALID_PHONE'
    },
    'message': {
      'required': 'GLOBAL.VALIDATION_MESSAGES.REQUIRED',
      'minlength': 'GLOBAL.VALIDATION_MESSAGES.LEAST_THREE_CHARACTERS_LONG',
      'maxlength': 'GLOBAL.VALIDATION_MESSAGES.MORE_THAN_TWOTHOUSANDFORTYSIX_CHARACTERS_LONG'
    }
  }

  formErrors = {
    'email': [],
    'subject': [],
    'firstName': [],
    'lastName': [],
    'phone': [],
    'message': []
  }

  form: FormGroup

  /**
	 * Constructor.
	 */
  constructor(
    private _translate: TranslateService,
    private comboNotificationService: ComboNotificationService,
    private contactRequestService: ContactRequestService,
    private formBuilder: FormBuilder,
    private userService: UserService) {
    this.contactFormModel = new ContactFormModel();
  }

  /**
 * It's native Angular 'on initiation' method.
 * Fired after constructor.
 */
  ngOnInit(): void {
    this.buildForm();
  }

  /**
 * Builds form.
 */
  buildForm(): void {
    const formBuilderOptions = {
      email: [
        this.contactFormModel.email,
        [
          Validators.required,
          Validators.pattern(regExpValidationConstants['EMAIL'])
        ]
      ],
      subject: [
        this.contactFormModel.subject,
        [
          Validators.required
        ]
      ],
      firstName: [
        this.contactFormModel.firstName,
        [
          Validators.required,
          Validators.minLength(2),
          Validators.maxLength(96)
        ]
      ],
      lastName: [
        this.contactFormModel.lastName,
        [
          Validators.required,
          Validators.minLength(2),
          Validators.maxLength(96)
        ]
      ],
      phone: [
        this.contactFormModel.phone,
        [
          Validators.required,
          Validators.minLength(7),
          Validators.maxLength(36),
          Validators.pattern(regExpValidationConstants['PHONE'])
        ]
      ],
      message: [
        this.contactFormModel.message,
        [
          Validators.required,
          Validators.minLength(3),
          Validators.maxLength(2048)
        ]
      ]
    };

    this.form = this.formBuilder.group(formBuilderOptions);
    this.form.valueChanges.subscribe(() => {
      formHelper.onValueChanged(
        this.form,
        this.formErrors,
        this.validationMessages
      );
    });
    formHelper.onValueChanged(
      this.form,
      this.formErrors,
      this.validationMessages
    ); // reset validation messages

    // disable some fields for fully registered user
    if (this.userService.isFullyRegistered()) {
      this.disableUserFields = true;
      const userData = this.userService.getUserData();
      this.form.setValue({
        email: userData.email,
        subject: '',
        firstName: userData.firstName,
        lastName: userData.lastName,
        phone: !userData['address']['telPersonal'] ? '' : userData['address']['telPersonal'],
        message: '',
      });
    }
  }

  /**
	 * Submit preprocess.
	 * @param {Object} data
	 * @returns {object}
	 */
  submitPreprocess(data) {
    return {
      email: data.email,
      subject: data.subject,
      firstName: data.firstName,
      lastName: data.lastName,
      phone: data.phone,
      message: data.message
    };
  }

  /**
	 * On submit handler
	 * @handler
	 */
  onSubmit() {
    const parameters = this.submitPreprocess(this.form.value);
    this.sendMessage(parameters);
  }

  /**
	 * Sends message.
	 * @param {Object} event
	 */
  sendMessage(parametersMap: object) {
    if (!parametersMap['email']) {
      return;
    }

    const self = this;
    this.sending = true;
    commonPagesHelper.loader('show');
    parametersMap['email'] = parametersMap['email'].toLowerCase();
    this.contactRequestService.sendMessage(parametersMap).subscribe((data) => {
      if (data.result === 'ok') {
        // * Show SnackBar Notification
        self.comboNotificationService.notification(data['message'], ComboNotificationType.Success);

        self.form.patchValue({
          subject: '',
          message: '',
        });

        self.sending = false;
        self.hasBeenSent = true;
        self.successMessage = data['message'];
      } else {
        // * Show SnackBar Notification
        self.comboNotificationService.notification(data['message'], ComboNotificationType.Error, data['errors']);
      }
      commonPagesHelper.loader('false');
    }, () => {
      self._translate.get(['GLOBAL.NOTIFICATIONS.WARNING_LABEL', 'CONTACT.CANNOT_SEND_MAIL_LABEL'])
        .subscribe((translatedValue: string) => {
          // * Show SnackBar Notification
          self.comboNotificationService.notification(translatedValue['CONTACT.CANNOT_SEND_MAIL_LABEL'], ComboNotificationType.Error);
        });

      self.sending = false;
      commonPagesHelper.loader('false');
    });
  }
}
