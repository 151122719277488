import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Observable } from 'rxjs';
import { tap, map } from 'rxjs/operators';

import { ComboNotificationType } from '../enums/alert-type.enum';

// * Mat Dialog
import { MatDialog, MatDialogRef } from '@angular/material/dialog';

// * Dialog Settings
import { DialogMessageConfig } from '../models/dialogMessageConfig.model';
import { SnackbarNotificationComponent } from '../components';

@Injectable() export class ComboNotificationService {

  constructor(
    public _translate: TranslateService,
    private _snackBar: MatSnackBar,
    private _dialog: MatDialog) { }

  // * Shows Alert for Type Passed, Error | Success | Info | Warning, Built with Material Design
  notification(message, type = ComboNotificationType.Success, errors = null, timeOut = 3000) {
    this._translate.get(['GLOBAL.NOTIFICATIONS.DISMISS'])
      .subscribe((translatedValue: string) => {
        if (errors && Object.keys(errors['fields']).length) {
          message = '';
          Object.keys(errors['fields']).forEach((key) => message += `${errors['fields'][key]  }\n`);
        }

        if (typeof message !== 'undefined') {
          message = message.replace(/(\.\.)$/, '.');
        }

        // * Get Class Name By Type
        const className: string = this.getSnackbarClassByType(type);

        // * Opens the Material SnackBar with the Options Passed
        this._snackBar.openFromComponent(SnackbarNotificationComponent, { 
          duration: (type === ComboNotificationType.Error) ? 50000 : timeOut, 
          verticalPosition: 'top',
          horizontalPosition: 'right',
          data: { message: message, action: translatedValue['GLOBAL.NOTIFICATIONS.DISMISS'], class: className },
          panelClass: [className]});
      });
  }

  // * Get the Class for the SnackBar to Show
  private getSnackbarClassByType(type: ComboNotificationType): string {
    switch (type) {
      case ComboNotificationType.Success:
        return 'success-snackbar';      
      case ComboNotificationType.Info:
        return 'info-snackbar';      
      case ComboNotificationType.Warning:
        return 'warning-snackbar';
      case ComboNotificationType.Error:
        return 'error-snackbar';
      default:
        return 'info-snackbar';
    }
  }

  // * Opens the Dialog Confirm
  getConfirmationMessages(): any {
    return this._translate.get(['GLOBAL.CONFIRMATIONS.YES_BUTTON', 'GLOBAL.CONFIRMATIONS.NO_BUTTON'])
      .pipe(map(res => { return res; }));
  }

  // * Mat Dialog Implementation
  openDialog(config: DialogMessageConfig): MatDialogRef<any> {
    // * If the Accept/Decline Text was not provided, get Language Default
    if (!config.data.confirmText) {
      this.getConfirmationMessages().subscribe(res => {
        config.data.confirmText = res['GLOBAL.CONFIRMATIONS.YES_BUTTON'];
        config.data.declineText = res['GLOBAL.CONFIRMATIONS.NO_BUTTON'];
      });
    }

    return this._dialog.open(config.template, {
      width: config.width,
      data: config
    });
  }

  translatedNotification(messageKey: string, type = ComboNotificationType.Success, errors: any = null, timeOut = 3000): Observable<any> {
    return this._translate.get(messageKey).pipe(
      tap((translatedMessage: string) => this.notification(translatedMessage, type, errors, timeOut)
      ));
  }
  
}
