<!-- <div class="container container--noVerticalPadding container--darker">
	<h2 class="gameTitle big withoutStar"><a class="closeGame" (click)="closeGame(); layout.fullscreenModeOff();" tooltip="{{ 'GLOBAL.CLOSE_GAME_TOOLTIP' | translate }}" placement="bottom"><i class="fa fa-times" aria-hidden="true"></i></a><a class="compressGame" (click)="layout.fullscreenModeOff()" tooltip="{{ 'GLOBAL.FULLSCREEN_OFF_TOOLTIP' | translate }}" placement="bottom" *ngIf="layout.fullscreen"><i class="fa fa-compress" aria-hidden="true"></i></a><a class="fullscreenGame" (click)="layout.fullscreenModeOn()" tooltip="{{ 'GLOBAL.FULLSCREEN_ON_TOOLTIP' | translate }}" placement="bottom" *ngIf="!layout.fullscreen"><i class="fa fa-expand" aria-hidden="true"></i></a>{{ templateData?.name }}</h2>
</div> -->

<div class="container">
	<div class="gameContainer" [ngClass]="{cinemaMode: isCinemaMode}">
		<ul class="fullscreenMenu">
			<li class="fullscreenMenu__item">
				<a (click)="closeGame(); layout.fullscreenModeOff();" hide-delay="0" tooltip="{{ 'GLOBAL.CLOSE_GAME_TOOLTIP' | translate }}" placement="bottom" class="fullscreenMenu__itemAnchor"><i class="icon-icon2" aria-hidden="true"></i></a>
			</li>
			<li class="fullscreenMenu__item">
				<a (click)="layout.fullscreenModeOff()" hide-delay="0" tooltip="{{ 'GLOBAL.FULLSCREEN_OFF_TOOLTIP' | translate }}" placement="bottom" class="fullscreenMenu__itemAnchor"><i class="icon-icon6" aria-hidden="true"></i></a>
			</li>
			<!-- <li class="fullscreenMenu__item">
				<a (click)="layout.fullscreenModeOff()" tooltip="{{ 'GLOBAL.STATS_TOOLTIP' | translate }}" placement="bottom" class="fullscreenMenu__itemAnchor"><i class="icon-icon7" aria-hidden="true"></i></a>
			</li> -->
			<li class="fullscreenMenu__item">
        <a class="fullscreenMenu__itemAnchor" hide-delay="0" tooltip="{{ 'GLOBAL.ADD_TO_FAVOURITE_TOOLTIP' | translate }}" placement="bottom" (click)="gameService.addToFavourites($event, templateData)" *ngIf="!templateData?.isFavourite && layout.isLogged()"><i class="icon-icon3" aria-hidden="true"></i></a>
        <a class="fullscreenMenu__itemAnchor active" hide-delay="0" tooltip="{{ 'GLOBAL.REMOVE_FROM_FAVOURITES_TOOLTIP' | translate }}" placement="bottom" (click)="gameService.removeFromFavourites($event, templateData)" *ngIf="templateData?.isFavourite && layout.isLogged()"><i class="icon-icon3" aria-hidden="true"></i></a>
        <a class="fullscreenMenu__itemAnchor" hide-delay="0" tooltip="{{ 'GLOBAL.LOGIN_TO_ADD_FAVOURITE' | translate }}" placement="left" (click)="false" *ngIf="!layout.isLogged()"><i class="icon-icon3" aria-hidden="true"></i></a>
			</li>
    </ul>
    <ng-template [ngIf]="isInformationMode">
      <img [src]="imagesPath + templateData?.gameImageUrl" (load)="showMobileCover = true" style="display: none;">
      <div class="gameContainer__leftColumn" *ngIf="showMobileCover && !casinoService.isMobile()">
        <div class="sandboxGame">
          <div class="sandboxGame__preview" [ngStyle]="{'background-image': 'url(' + imagesPath + templateData?.gameImageUrl + ')'}"></div>
        </div>
      </div>
      <div class="gameContainer__leftColumn" *ngIf="showMobileCover && casinoService.isMobile()">
          <img [src]="imagesPath + templateData?.gameImageUrl" (load)="showMobileCover = true" class="img-responsive">
      </div>
      <div class="gameContainer__leftColumn" *ngIf="!showMobileCover && casinoService.isMobile()">
          <img [src]="imagesPath + templateData?.gameThumbImageUrl" (load)="showMobileCover = false" class="img-responsive">
      </div>
    </ng-template>

    <ng-template [ngIf]="!isInformationMode">
      <div id="netentCasinoGame" *ngIf="casinoService.isMobile()"></div>

      <ng-template [ngIf]="runtimeMode === 'div'">
        <div class="gameContainer__leftColumn" *ngIf="!casinoService.isMobile()" id="mainGameContainer">
          <div id="netentCasinoGame"></div>
        </div>
      </ng-template>

      <ng-template [ngIf]="runtimeMode === 'iframe'">
          <div class="gameContainer__leftColumn" *ngIf="!casinoService.isMobile()" id="mainGameContainer">
            <div class="sandboxGame" [class.sandboxGame--loading]="(iframeRequest && iframeURL && !iframeLoaded)">
              <div class="sandboxGame__preview" [ngStyle]="{'background-image': 'url(' + imagesPath + templateData?.gameImageUrl + ')'}" *ngIf="!iframeLoaded" (click)="openGame(templateData.urlSlug, 'demo')"></div>
              <iframe #iframeGame frameborder="0" [attr.src]="iframeURL" class="sandboxGame__iframe" scrolling="no" allowfullscreen="true" (load)="iframeOnLoad(iframeGame)" *ngIf="currentGame"></iframe>
            </div>
          </div>
        </ng-template>
    </ng-template>
		<div class="gameContainer__rightColumn">
			<div class="gameContainer__rightColumn-inside">
				<section class="gameMenu" [ngClass]="{gameMenuMobile: casinoService.isMobile()}">
          <ng-template [ngIf]="!casinoService.isMobile()">
            <a class="gameMenu__item" (click)="cinemaMode(false)" display-mobile="false" hide-delay="0" tooltip="{{ 'GAME_DETAILS.DISABLE_CINEMA_MODE' | translate }}" placement="left" hide-delay-mobile="500" *ngIf="isCinemaMode">
              <i class="icon-icon25" aria-hidden="true"></i>
            </a>
            <a class="gameMenu__item" (click)="cinemaMode(true)" hide-delay="0" tooltip="{{ 'GAME_DETAILS.ENABLE_CINEMA_MODE' | translate }}" placement="bottom" hide-delay-mobile="500" display-mobile="false" *ngIf="!isCinemaMode">
              <i class="icon-icon25 flipX" aria-hidden="true"></i>
            </a>
            <a class="gameMenu__item" hide-delay="0" tooltip="{{ 'GLOBAL.ADD_TO_FAVOURITE_TOOLTIP' | translate }}" placement="bottom" (click)="gameService.addToFavourites($event, templateData)" *ngIf="!templateData?.isFavourite && layout.isLogged()"><i class="icon-icon3" aria-hidden="true"></i></a><a class="gameMenu__item active" tooltip="{{ 'GLOBAL.REMOVE_FROM_FAVOURITES_TOOLTIP' | translate }}" placement="bottom" (click)="gameService.removeFromFavourites($event, templateData)" *ngIf="templateData?.isFavourite && layout.isLogged()"><i class="icon-icon3" aria-hidden="true"></i></a>
            <a class="gameMenu__item" hide-delay="0" tooltip="{{ 'GLOBAL.LOGIN_TO_ADD_FAVOURITE' | translate }}" placement="bottom" (click)="false" *ngIf="!layout.isLogged()"><i class="icon-icon3" aria-hidden="true"></i></a>
            <!-- <a class="gameMenu__item" tooltip="{{ 'GLOBAL.STATS_TOOLTIP' | translate }}" placement="bottom"><i class="icon-icon7" aria-hidden="true"></i></a> -->
            <a (click)="layout.fullscreenModeOn()" class="gameMenu__item" hide-delay="0" tooltip="{{ 'GLOBAL.FULLSCREEN_ON_TOOLTIP' | translate }}" placement="bottom"><i class="icon-icon4" aria-hidden="true"></i></a>
            <a (click)="closeGame(); layout.fullscreenModeOff();" *ngIf="isCinemaMode" class="gameMenu__item" hide-delay="0" tooltip="{{ 'GLOBAL.CLOSE_GAME_TOOLTIP' | translate }}" placement="left"><i class="icon-icon2" aria-hidden="true"></i></a>
            <a (click)="closeGame(); layout.fullscreenModeOff();" *ngIf="!isCinemaMode" class="gameMenu__item" hide-delay="0" tooltip="{{ 'GLOBAL.CLOSE_GAME_TOOLTIP' | translate }}" placement="bottom"><i class="icon-icon2" aria-hidden="true"></i></a>
          </ng-template>
          <ng-template [ngIf]="casinoService.isMobile()">
            <a class="gameMenu__item" hide-delay="0" tooltip="{{ 'GLOBAL.ADD_TO_FAVOURITE_TOOLTIP' | translate }}" placement="bottom" (click)="gameService.addToFavourites($event, templateData)" *ngIf="!templateData?.isFavourite && layout.isLogged()"><i class="icon-icon3" aria-hidden="true"></i></a><a class="gameMenu__item active" tooltip="{{ 'GLOBAL.REMOVE_FROM_FAVOURITES_TOOLTIP' | translate }}" placement="bottom" (click)="gameService.removeFromFavourites($event, templateData)" *ngIf="templateData?.isFavourite && layout.isLogged()"><i class="icon-icon3" aria-hidden="true"></i></a>
            <a class="gameMenu__item" hide-delay="0" tooltip="{{ 'GLOBAL.LOGIN_TO_ADD_FAVOURITE' | translate }}" placement="bottom" (click)="false" *ngIf="!layout.isLogged()"><i class="icon-icon3" aria-hidden="true"></i></a>
            <a (click)="closeGame(); layout.fullscreenModeOff();" class="gameMenu__item" hide-delay="0" tooltip="{{ 'GLOBAL.CLOSE_GAME_TOOLTIP' | translate }}" placement="bottom"><i class="icon-icon2" aria-hidden="true"></i></a>
          </ng-template>
        </section>
				<section class="stickyTop" perfect-scrollbar>
					<h4>{{ templateData?.name }}</h4>
					<p [innerHTML]="templateData?.description" *ngIf="templateData?.description"></p>
					<p [innerHTML]="'GLOBAL.NO_DESCRIPTION' | translate"  *ngIf="!templateData?.description"></p>
				</section>

				<section class="stickyBottom" *ngIf="templateData?.gameThumbImageUrl">
          <ng-template [ngIf]="!isInformationMode">
            <a
              class="button button--green"
              *ngIf="layout.loggedUserData?.isFullyRegistered"
              [routerLink]="navigationService.constructUrl('CASHIER')"
              [queryParams]="{ tab: 'payIn' }"
            >
              <i aria-hidden="true" class="fa fa-plus-circle"></i>
              {{ 'LAYOUT.MAKE_DEPOSIT_ANCHOR_LABEL' | translate }}
            </a>
            <a
              *ngIf="!layout.loggedUserData?.isFullyRegistered"
              [routerLink]="navigationService.constructUrl('USER_ACCOUNT')"
              class="button button--green"
            >
                <i aria-hidden="true" class="fa fa-plus-circle"></i>
                {{ 'LAYOUT.MAKE_DEPOSIT_ANCHOR_LABEL' | translate }}
            </a>
          </ng-template>

          <div class="poweredBy">
						<span class="text">Powered by:</span>
						<a class="vendor pointer" [routerLink]="navigationService.constructUrl('ALL_GAMES_VENDOR', { filter: 'all-games', vendor: templateData?.vendorName.toLowerCase() })" routerLinkActive="active"><img src="{{ imagesPath + templateData?.vendorImageUrl }}" alt="{{ templateData?.vendorName }}" *ngIf="templateData?.vendorImageUrl"></a>
					</div>
				</section>

				<section class="mobile-column">
					<!-- <h4>{{ 'GAME_DETAILS.NEWSLETTER_SIGNUP_HEADER' | translate }}</h4>
					<p>{{ 'GAME_DETAILS.NEWSLETTER_DESCRIPTION_LABEL' | translate }}</p>

					<form class="newsletterForm" [formGroup]="newsletterForm" (ngSubmit)="newsletterSignUp(newsletterForm.value)" (keyup.enter)="newsletterSignUp(newsletterForm.value)">
						<input id="email" placeholder="{{ 'GAME_DETAILS.NEWSLETTER_INPUT_PLACEHOLDER' | translate }}" formControlName="email" required [class.error]="newsletterFormErrors.email.lengh">
						<span class="errorMessage" *ngIf="newsletterFormErrors.email.length">
                            <span class="errorMessage__inside">
                                <span *ngFor="let emailMessage of newsletterFormErrors.email">{{ emailMessage | translate }}</span>
                            </span>
                        </span>
						<a class="button button--red capitalize-text" (click)="newsletterSignUp(newsletterForm.value)" *ngIf="(newsletterForm.valid || sendingNewsletter)">{{ 'GAME_DETAILS.NEWSLETTER_SUBMIT_LABEL' | translate }}<i class="fa fa-envelope-o" aria-hidden="true"></i></a>
						<a class="button button--red capitalize-text button--disabled" tooltip="{{ 'GLOBAL.INVALID_VALIDATION_FORM_FIELDS_TOOLTIP' | translate }}" *ngIf="!(newsletterForm.valid || sendingNewsletter)">{{ 'GAME_DETAILS.NEWSLETTER_SUBMIT_LABEL' | translate }}<i class="fa fa-envelope-o" aria-hidden="true"></i></a>
					</form> -->
				</section>

				<section class="mobile-column" *ngIf="templateData?.gameThumbImageUrl">
					<!-- <h4>{{ 'GAME_DETAILS.GAME_GALLERY_HEADER' | translate }}</h4>
					<ul class="gallery">
						<li class="gallery__item"><img src="{{ imagesPath }}{{ templateData?.gameThumbImageUrl }}" alt="{{ templateData?.name }}"></li>
					</ul> -->
				</section>
			</div>
		</div>

	</div>
</div>


<div class="container container--withHorizontalPadding">
	<div class="gameDetails">
		<div class="gameDetails__interactions">
			<div class="gameDetails__interactions-rating">
        <bar-rating
          #rating
          [disabled]="disabledRating"
          [class]="layout.isLogged() ? '' : 'game-rating--disabled' "
          [theme]="'fontawesome-o'"
          [(rate)]="rate"
          [max]="5"
          [(ngModel)]="model.rating"
          (rateChange)="rateChange($event, rating)"
          (hover)="rateHover()"
          (leave)="rateLeave()"
          *ngIf="templateData?.rating || templateData?.rating === 0">
        </bar-rating>
	      <p class="gameDetails__interactions-ratingInfo">{{ templateData?.rating | number:'1.2-2' }} / {{ templateData?.ratingsCount }} {{ 'GAME_DETAILS.REVIEWS_LABEL' | translate }}.</p>
	    </div>

      <div class="gameDetails__interactions-buttons">
        <a class="addToFavourites" hide-delay="0" tooltip="{{ 'GLOBAL.ADD_TO_FAVOURITE_TOOLTIP' | translate }}" placement="top" (click)="gameService.addToFavourites($event, templateData)" *ngIf="!templateData?.isFavourite && layout.isLogged()"></a>
        <a class="addToFavourites active" hide-delay="0" tooltip="{{ 'GLOBAL.REMOVE_FROM_FAVOURITES_TOOLTIP' | translate }}" placement="top" (click)="gameService.removeFromFavourites($event, templateData)" *ngIf="templateData?.isFavourite && layout.isLogged()"></a>
        <a class="addToFavourites" hide-delay="0" tooltip="{{ 'GLOBAL.LOGIN_TO_ADD_FAVOURITE' | translate }}" placement="top" (click)="false" *ngIf="!layout.isLogged()"></a>

<!--        <a *ngIf="isInformationMode || isDemoMode || casinoService.isMobile()" class="button button&#45;&#45;red" [ngClass]="{'button&#45;&#45;mobile': casinoService.isMobile()}" (click)="goToGameDetails(templateData, true, 'full')">-->
<!--          {{ 'GAME_DETAILS.PLAY_BUTTON' | translate }}-->
<!--          <i class="fa fa-angle-right" aria-hidden="true"></i>-->
<!--        </a>-->

        <a *ngIf="templateData.isDemoEnabled && (!isDemoMode || casinoService.isMobile())" class="button button--gray" [ngClass]="{'button--mobile': casinoService.isMobile()}" (click)="goToGameDetails(templateData, true, 'demo')">
          {{ 'GAME_DETAILS.DEMO_BUTTON' | translate }}
          <i class="fa fa-angle-right" aria-hidden="true"></i>
        </a>
      </div>

      <div class="gameDetails__interactions-ratingMobile">
        <bar-rating #rating [disabled]="disabledRating" [class]="layout.isLogged() ? '' : 'game-rating--disabled' " [theme]="'fontawesome-o'" [(rate)]="rate" [max]="5" [(ngModel)]="model.rating" (rateChange)="rateChange($event, rating)" (hover)="rateHover()" (leave)="rateLeave()" *ngIf="templateData?.rating || templateData?.rating === 0"></bar-rating>
        <p class="gameDetails__interactions-ratingInfo">{{ templateData?.rating | number:'1.2-2' }} / {{ templateData?.ratingsCount }} {{ 'GAME_DETAILS.REVIEWS_LABEL' | translate }}.</p>
      </div>
		</div>

		<div class="gameDetails__parameters">
			<div class="gameDetails__parameters-item" *ngIf="templateData?.meta.minRtp > 0">
				<span class="gameDetails__parameters-itemLabel">{{ 'GAME_DETAILS.RETURN_TO_PLAYER_LABEL' | translate }}</span>
				<span class="gameDetails__parameters-itemValue">{{ templateData?.meta.minRtp/100 | percent:'1.2-2' }}</span>
			</div>
			<div class="gameDetails__parameters-item" *ngIf="templateData?.meta.minBet > 0">
				<span class="gameDetails__parameters-itemLabel">{{ 'GAME_DETAILS.MINIMUM_BET_LABEL' | translate }}</span>
				<span class="gameDetails__parameters-itemValue">{{ templateData?.meta.minBet | currency:'EUR':'symbol':'1.2-2' }}</span>
			</div>
			<div class="gameDetails__parameters-item" *ngIf="templateData?.meta.maxBet > 0">
				<span class="gameDetails__parameters-itemLabel">{{ 'GAME_DETAILS.MAXIMUM_BET_LABEL' | translate }}</span>
				<span class="gameDetails__parameters-itemValue">{{ templateData?.meta.maxBet | currency:'EUR':'symbol':'1.2-2' }}</span>
			</div>
			<div class="gameDetails__parameters-item" *ngIf="templateData?.meta.winLines > 0">
				<span class="gameDetails__parameters-itemLabel">{{ 'GAME_DETAILS.WIN_LINES_LABEL' | translate }}</span>
				<span class="gameDetails__parameters-itemValue">{{ templateData?.meta.winLines }}</span>
			</div>
		</div>

		<!-- <div class="gameDetails__description">
			<h2 class="bottomDecorator">
				<span class="decorator"><i class="fa fa-commenting" aria-hidden="true"></i></span>
				{{ 'GAME_DETAILS.GAME_DESCRIPTION_HEADER_LABEL' | translate }}
			</h2>

			<div class="gameDetails__description-paragraphs" [innerHTML]="templateData?.description" *ngIf="templateData?.description"></div>

			<div class="gameDetails__description-paragraphs" *ngIf="!templateData?.description">
				<p [innerHTML]="'GLOBAL.NO_DESCRIPTION' | translate"></p>
			</div>
		</div> -->

		<div class="similarGamesContainer">
			<h3 class="bottomDecorator">{{ 'GAME_DETAILS.SIMILAR_GAMES_HEADER' | translate }}</h3>
			<div class="similarGamesContainer--no-data" *ngIf="!templateData?.similarGames || (templateData?.similarGames && templateData?.similarGames.length == 0)">No data to display!</div>

      <slider *ngIf="templateData?.similarGames && !userService.isLogged()" class="carousel similarGamesSlider" [optionsResolver]="similarGameSliderOptionsResolver">
        <ng-container *ngFor="let slide of templateData?.similarGames">
          <div *slide-item class="item" >
            <game-item [game]="slide"></game-item>
          </div>
        </ng-container>
			</slider>

      <slider *ngIf="templateData?.similarGames && userService.isLogged() && !userService.isFullyRegistered()" class="carousel similarGamesSlider" [optionsResolver]="similarGameSliderOptionsResolver">
        <ng-container *ngFor="let slide of templateData?.similarGames">
          <div *slide-item class="item" (click)="openGame(slide.urlSlug)">
            <game-item [game]="slide"></game-item>
          </div>
        </ng-container>
      </slider>

      <slider *ngIf="templateData?.similarGames && userService.isLogged() && userService.isFullyRegistered()" class="carousel similarGamesSlider" [optionsResolver]="similarGameSliderOptionsResolver">
        <ng-container *ngFor="let slide of templateData?.similarGames">
          <div *slide-item class="item" (click)="openGame(slide.urlSlug, 'full')">
            <game-item [game]="slide"></game-item>
          </div>
        </ng-container>
      </slider>
		</div>

		<div class="gameDetails__comments">
			<h2 class="bottomDecorator">
				<span class="decorator"><i class="fa fa-commenting" aria-hidden="true"></i></span>
				{{ 'GAME_DETAILS.USER_COMMENTS_LABEL' | translate }}
			</h2>

			<div *ngIf="layout.loggedUserData; else loggedOut">
			  <p class="fistComment" *ngIf="templateData?.comments.length == 0">{{ 'GAME_DETAILS.ADD_FIRST_COMMENT_LOGGED' | translate }} {{ templateData?.name }}</p>
			</div>
			<ng-template #loggedOut>
			  <p class="fistComment">{{ 'GAME_DETAILS.ADD_FIRST_COMMENT' | translate }} {{ templateData?.name }}</p>
			</ng-template>

			<div class="flex" *ngIf="layout.loggedUserData">
				<div class="avatar no-avatar"></div>
				<form>
					<textarea #inputComment placeholder="{{ 'GAME_DETAILS.TYPE_IN_COMMENT_HERE_PLACEHOLDER' | translate }}"></textarea>
					<a class="button button--green" (click)="addComment(inputComment.value)">{{ 'GAME_DETAILS.ADD_COMMENT_BUTTON' | translate }}</a>
				</form>
			</div>

			<div class="gameDetails__comments-item" *ngFor="let comment of templateData?.comments">
				<div class="avatar">
					<div class="avatar no-avatar" *ngIf="!comment.addingUserAvatar"></div>
					<img src="{{ imagesPath }}{{ comment.addingUserAvatar }}" alt="avatar" *ngIf="comment.addingUserAvatar">
				</div>
				<div class="commentBox">
					<div class="commentText">{{ comment.text }}</div>
					<div class="commentInfo"><strong>{{ comment.addingUserName }}</strong>, <span class="commentDate" hide-delay="0" tooltip="{{ comment.dateAdded.date | date:'yyyy-MM-dd HH:mm' }}" placement="top"> <!-- {{ comment.dateAdded.date | amTimeAgo }} --> TODO</span></div>
				</div>
			</div>
		</div>
	</div>
</div>
