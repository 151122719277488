import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'bonus-details',
  templateUrl: './bonusDetails.component.html',
  styleUrls: ['./bonusDetails.component.scss']
})
export class BonusDetailsComponent {

  item: any;

  constructor(private route: ActivatedRoute) {
    this.item = this.route.snapshot.queryParams;
  }
}
